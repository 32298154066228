import { PBox, PH1, PFlowButton, PImg, PText } from "../../theme/BaseTheme";

export const Welcome = (props) => {
    return (
        <PBox css={styles}>
            <PBox className="welcome-content">
                <PH1>Welcome</PH1>
                <PText>Let's go over the basics to get started.</PText>
                <PBox className="welcome-image">
                    <PImg src={"/images/welcome.svg"} width="480px" />
                </PBox>
            </PBox>
            <PFlowButton
                type="primary"
                className="welcome-next-button"
                onClick={() => props.next()}
            >
                Next
            </PFlowButton>
        </PBox>
    );
};

const styles = {
    "& .welcome-image": {
        marginTop: "40px",
    },
    "& .welcome-content": {
        minHeight: "480px",
    },
    "& .welcome-next-button": {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderColor: "$colors$primary",
        "&:hover": {
            backgroundColor: "#fff",
            color: "$colors$primary",
        },
    },
};

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PSection, PH1, PBox, PContainer } from "../../theme/BaseTheme";
import { EditableCard } from "./EditableCard";
import { deleteProduct } from "../../../actions/product";

const EditableProductsGrid = (props) => {
    return (
        <PSection css={styles}>
            <PBox className="editable-products">
                <PBox className="editable-products-list">
                    {props.data?.map((item, idx) => {
                        return (
                            <EditableCard
                                key={"editable-product-" + idx.toString()}
                                img={item.product_cover_image?.url}
                                shop={item.shop}
                                title={item.product_name}
                                rating={item.average_rating}
                                price={item.price}
                                revenue={item.revenue}
                                number_of_orders={item.number_of_orders}
                                status={item.status}
                                uuid={item.uuid}
                                shop_uuid={props.shop_uuid}
                                deleteProduct={props.deleteProduct}
                                product_highlights={item.product_highlights}
                            />
                        );
                    })}
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    "& .editable-products": {
        margin: "40px 0",
    },
    "& .editable-products-title": {
        textAlign: "center",
        fontFamily: "Poppins, sans-serif",
        fontSize: "1.875rem",
        fontWeight: "200",
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "1.25rem",
        paddingBottom: "1.25rem",
        marginTop: "3rem",
        marginBottom: "3rem",
        borderBottom: "2px solid $colors$primary",
    },
    "& .editable-products-list": {
        display: "grid",
        gridGap: "2.5rem",
        "@bp1": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp2": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp3": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp5": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
    },
};

export default connect(null, { deleteProduct })(
    withRouter(EditableProductsGrid)
);

import { combineReducers } from "redux";

const fetchProductList = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_PRODUCT_LIST_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                productList: action.payload,
            };
        case "FETCH_PRODUCT_LIST_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                productList: action.payload,
            };
        default:
            return state;
    }
};

const fetchFeaturedProducts = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_FEATURED_PRODUCTS_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                productList: action.payload.productList,
                totalProducts: action.payload.totalProducts,
            };
        case "FETCH_FEATURED_PRODUCTS_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                productList: action.payload,
            };
        default:
            return state;
    }
};

const fetchNewProducts = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_NEW_PRODUCTS_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                productList: action.payload.productList,
                totalProducts: action.payload.totalProducts,
            };
        case "FETCH_NEW_PRODUCTS_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                productList: action.payload,
            };
        default:
            return state;
    }
};

const fetchPickedProducts = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_PICKED_PRODUCTS_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                productList: action.payload.productList,
                totalProducts: action.payload.totalProducts,
            };
        case "FETCH_PICKED_PRODUCTS_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                productList: action.payload,
            };
        default:
            return state;
    }
};

const fetchLandingPageProductLists = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_LANDING_PRODUCT_LISTS_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                productList: action.payload,
            };
        case "FETCH_LANDING_PRODUCT_LISTS_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                productList: action.payload,
            };
        default:
            return state;
    }
};

const fetchProductListByMerchant = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_PRODUCT_LIST_BY_MERCHANT_SUCCESS":
            state[action.shop_uuid] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                productList: action.payload,
            };
        case "FETCH_PRODUCT_LIST_BY_MERCHANT_FAIL":
            state[action.shop_uuid] = {
                isLoading: false,
                isError: true,
                isSuccess: false,
                productList: action.payload,
                totalProducts: 0,
            };
        default:
            return state;
    }
};

const fetchProductListDetailsByMerchant = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_PRODUCT_LIST_DETAILS_BY_MERCHANT_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                productList: action.payload.productList,
                totalProducts: action.payload.totalProducts,
            };
        case "FETCH_PRODUCT_LIST_DETAILS_BY_MERCHANT_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                productList: action.payload,
                totalProducts: 0,
            };
        default:
            return state;
    }
};

const fetchTopProductListByMerchant = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_TOP_PRODUCT_LIST_BY_MERCHANT_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                productList: action.payload,
            };
        case "FETCH_TOP_PRODUCT_LIST_BY_MERCHANT_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                productList: action.payload,
            };
        default:
            return state;
    }
};

const fetchProductDisplay = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_PRODUCT_DISPLAY_SUCCESS":
            state[action.product_uuid] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                product: action.payload,
            };
            return state;
        case "FETCH_PRODUCT_DISPLAY_FAIL":
            state[action.product_uuid] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                product: action.payload,
            };
            return state;
        default:
            return state;
    }
};

const fetchProductEdit = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_PRODUCT_EDIT_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                product: action.payload,
            };
        case "FETCH_PRODUCT_EDIT_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                product: action.payload,
            };
        default:
            return state;
    }
};

const fetchSitemap = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_SITEMAP_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                products: action.payload.products,
                merchants: action.payload.merchants,
            };
        case "FETCH_SITEMAP_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                products: action.payload.products,
                merchants: action.payload.merchants,
            };
        default:
            return state;
    }
};

export default combineReducers({
    fetchProductList,
    fetchProductListByMerchant,
    fetchProductListDetailsByMerchant,
    fetchTopProductListByMerchant,
    fetchProductDisplay,
    fetchProductEdit,
    fetchLandingPageProductLists,
    fetchFeaturedProducts,
    fetchNewProducts,
    fetchPickedProducts,
    fetchSitemap,
});

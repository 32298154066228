import { PBox, PSection, PText, PImg, PLink } from "../../theme/BaseTheme";
import CardForFree from "./CardForFree";
import CardForPay from "./CardForPay";
import { Rating } from "./Rating";
import SaveProduct from "./SaveProduct";
import { Link } from "react-router-dom";

export const DetailCard = (props) => {
    return (
        <PBox css={styles} className="detail-card">
            <PBox className="detail-card-wrapper">
                <PText className="detail-card-title">
                    {props.product?.product_name}
                </PText>
                <PBox className="detail-card-buttons">
                    <PBox className="detail-card-favorites">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M178,32c-20.65,0-38.73,8.88-50,23.89C116.73,40.88,98.65,32,78,32A62.07,62.07,0,0,0,16,94c0,70,103.79,126.66,108.21,129a8,8,0,0,0,7.58,0C136.21,220.66,240,164,240,94A62.07,62.07,0,0,0,178,32ZM128,206.8C109.74,196.16,32,147.69,32,94A46.06,46.06,0,0,1,78,48c19.45,0,35.78,10.36,42.6,27a8,8,0,0,0,14.8,0c6.82-16.67,23.15-27,42.6-27a46.06,46.06,0,0,1,46,46C224,147.61,146.24,196.15,128,206.8Z"></path>
                        </svg>{" "}
                        {props.product?.product_stat?.number_of_saves}
                    </PBox>
                    {/* Todo: react-custom-share */}
                    {/*<PBox className="detail-card-share">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M237.66,106.35l-80-80A8,8,0,0,0,144,32V72.35c-25.94,2.22-54.59,14.92-78.16,34.91-28.38,24.08-46.05,55.11-49.76,87.37a12,12,0,0,0,20.68,9.58h0c11-11.71,50.14-48.74,107.24-52V192a8,8,0,0,0,13.66,5.65l80-80A8,8,0,0,0,237.66,106.35ZM160,172.69V144a8,8,0,0,0-8-8c-28.08,0-55.43,7.33-81.29,21.8a196.17,196.17,0,0,0-36.57,26.52c5.8-23.84,20.42-46.51,42.05-64.86C99.41,99.77,127.75,88,152,88a8,8,0,0,0,8-8V51.32L220.69,112Z"></path>
                        </svg>
                    </PBox>*/}
                </PBox>
                <PBox className="detail-card-merchant">
                    {props.product?.merchant?.shop_logo && (
                        <PImg src={props.product?.merchant?.shop_logo?.url} />
                    )}
                    {!props.product?.merchant?.shop_logo && (
                        <PImg src={"/images/default-shop-logo.png"} />
                    )}
                    <PBox className="detail-card-seller">
                        <PText>Seller</PText>
                        <Link
                            className="detail-card-name"
                            to={`/shop/${props.product?.merchant?.uuid}/1`}
                            target="_blank"
                        >
                            {props.product?.merchant?.shop_name}
                        </Link>
                    </PBox>
                </PBox>
                <PBox className="detail-card-product-highlights">
                    <PText>{props.product?.product_highlights}</PText>
                </PBox>
                <PBox className="detail-card-tags">
                    {props.product?.tags?.map((item, idx) => {
                        return (
                            <PBox className="detail-card-tag">
                                {item?.tag_name}
                            </PBox>
                        );
                    })}
                </PBox>
                <PBox className="detail-card-rating">
                    <PText className="detail-card-rating-label">Reviews</PText>
                    {props.product?.number_of_ratings > 0 && (
                        <Rating
                            rating={props.product?.average_rating}
                            reviews={props.product?.number_of_ratings}
                        />
                    )}
                    {props.product?.number_of_ratings === 0 && (
                        <PBox>0 Reviews</PBox>
                    )}
                </PBox>
                <PBox className="detail-card-price">
                    <PText className="detail-card-price-label">Price</PText>
                    {props.product?.price > 0 && (
                        <PText className="detail-card-price-value">
                            ${props.product?.price}
                        </PText>
                    )}
                    {props.product?.price === 0 && (
                        <PText className="detail-card-price-value">Free</PText>
                    )}
                </PBox>
                <PBox className="detail-card-payment">
                    {props.product?.price > 0 && (
                        <CardForPay product_uuid={props.product?.uuid} />
                    )}
                    {(props.product?.price_option === "free" ||
                        props.product?.price === 0) && (
                        <CardForFree product_uuid={props.product?.uuid} />
                    )}
                </PBox>
                <SaveProduct product={props.product} />
            </PBox>
        </PBox>
    );
};

const styles = {
    position: "relative",
    "& .detail-card-wrapper": {
        "@bp4": { position: "-webkit-sticky", position: "sticky", top: "80px" },
        display: "block",
        boxShadow: "1px 1px 5px 4px rgba(20,24,31,.06)",
        borderRadius: "24px",
        padding: "32px",
    },
    "& .detail-card-title": {
        textAlign: "left",
        fontFamily: "Poppins, sans-serif",
        fontSize: "28px",
        lineHeight: "34px",
        fontWeight: "500",
        paddingBottom: "1.25rem",
        marginTop: "0",
        marginBottom: "0",
        position: "relative",
    },
    "& .detail-card-rating": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "$colors$lightBackground",
        padding: "2px 12px",
        borderRadius: "16px",
        margin: "12px 0",
    },
    "& .detail-card-rating-label": {
        color: "$colors$label",
        fontSize: "13px",
    },
    "& .detail-card-price": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "$colors$lightBackground",
        padding: "2px 12px",
        borderRadius: "16px",
        marginBottom: "28px",
    },
    "& .detail-card-price-label": {
        color: "$colors$label",
        fontSize: "13px",
    },
    "& .detail-card-price-value": {
        fontWeight: "700",
        fontSize: "18px",
        lineHeight: "1.25",
        margin: "0px 0px",
    },
    "& .detail-card-accordions": {
        margin: "48px 0px",
    },
    "& .detail-card-tags": {
        margin: "0 0 16px 0",
        display: "flex",
        flexWrap: "wrap",
        "& .detail-card-tag": {
            cursor: "pointer",
            fontSize: "12px",
            backgroundColor: "$colors$lightPrimary",
            color: "$colors$primary",
            padding: "6px 12px",
            borderRadius: "12px",
            margin: "2px 8px 2px 0",
        },
    },
    "& .detail-card-buttons": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "12px",
    },
    "& .detail-card-favorites": {
        display: "flex",
        alignItems: "center",
        backgroundColor: "$colors$lightBackground",
        padding: "9px 15px",
        borderRadius: "40px",
        fontSize: "14px",
        fontWeight: "500",
        "& svg": {
            marginRight: "4px",
        },
    },
    "& .detail-card-share": {
        backgroundColor: "$colors$lightBackground",
        padding: "8px",
        cursor: "pointer",
        borderRadius: "50%",
        transition: "all 0.3s ease",
        "& svg": { display: "block", margin: "0", padding: "0" },
        "&:hover": {
            backgroundColor: "$colors$primary",
            "& svg": {
                fill: "#fff",
            },
        },
    },
    "& .detail-card-merchant": {
        display: "flex",
        alignItems: "center",
        backgroundColor: "$colors$lightBackground",
        padding: "12px",
        borderRadius: "16px",
        marginBottom: "0px",
        "& img": {
            width: "40px",
            height: "40px",
            borderRadius: "15px",
            marginRight: "12px",
        },
    },
    "& .detail-card-seller": {
        "& p": {
            color: "$colors$label",
            fontSize: "13px",
            lineHeight: "21px",
            margin: "0",
            padding: "0",
        },
        "& a:hover": {
            color: "$colors$primary",
        },
    },
    "& .detail-card-name": {
        fontWeight: "700",
        textDecoration: "none",
        color: "$colors$text",
    },
    "& .detail-card-product-highlights": {
        fontSize: "14px",
        lineHeight: "22px",
        color: "$colors$label",
        margin: "0",
        padding: "0",
    },
    "& .detail-card-payment": {
        width: `calc(100% - 40px)`,
    },
};

import { useState } from "react";
import clsx from "clsx";
import {
    PSection,
    PBox,
    PContainer,
    PH1,
    PH3,
    PH2,
    PH4,
    PText,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { faqData } from "../../../data/faqs";

export const FAQAccordion = (props) => {
    const [isActive, setIsActive] = useState(props.isActiveDefault);
    return (
        <PBox css={styles}>
            <PBox className={clsx("faq-accordion", isActive ? "active" : "")}>
                <PBox
                    className="faq-accordion-title"
                    onClick={() => setIsActive(!isActive)}
                >
                    <PH3>{props.title}</PH3>
                    <PBox className="faq-accordion-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 6v12m6-6H6"
                            ></path>
                        </svg>
                    </PBox>
                </PBox>
                {isActive && (
                    <PBox className="faq-accordion-content">
                        {props.content}
                    </PBox>
                )}
            </PBox>
        </PBox>
    );
};

export const FAQs = (props) => {
    return (
        <Layout>
            <PSection css={styles}>
                <PBox className="faqs">
                    <PContainer>
                        <PBox className="faqs-container">
                            <PBox className="faqs-title">
                                <PH4>Shopping on Phair</PH4>
                                <PH2>Frequently Asked Questions</PH2>
                                <PText>
                                    Can't find the answer you're looking for?
                                    Reach out to our{" "}
                                    <a href="/contact">customer support</a>{" "}
                                    team.
                                </PText>
                            </PBox>
                            {faqData.map((section, idx) => {
                                return (
                                    <PBox className="faqs-section">
                                        <PH3 className="faqs-section-title">
                                            {section.title}
                                        </PH3>
                                        {section.content.map((item, idx) => {
                                            return (
                                                <FAQAccordion
                                                    isActiveDefault={false}
                                                    title={item.title}
                                                    content={item.content}
                                                />
                                            );
                                        })}
                                    </PBox>
                                );
                            })}
                        </PBox>
                    </PContainer>
                </PBox>
            </PSection>
        </Layout>
    );
};

const styles = {
    "& .faqs-container": {
        padding: "2rem 1.5rem",
        margin: "0 auto",
        maxWidth: "56rem",
        "@bp2": { padding: "4rem 1.5rem" },
        "@bp4": { padding: "4rem 2rem" },
    },
    "& .faqs-title": {
        "& h2": { lineHeight: "2.5rem", fontWeight: "700", fontSize: "2.5rem" },
        "& h4": { color: "$colors$primary" },
        "& p": { color: "rgb(75 85 99)" },
        "& a": {
            color: "$colors$primary",
            textDecoration: "none",
            fontWeight: "600",
        },
        "& a:hover": {
            color: "$colors$primary500",
        },
    },
    "& .faqs-section": {
        marginTop: "4rem",
        marginBottom: "4rem",
    },
    "& .faqs-section-title": {
        paddingTop: "4rem",
        paddingBottom: "0.5rem",
        color: "rgb(148 163 184)",
        fontSize: "0.875rem",
    },
    "& .faq-accordion": {
        borderTop: "1px solid $colors$borderColor",
        paddingTop: "1.5rem",
        marginTop: "1.5rem",
        transition: "all 0.3s ease",
    },
    "& .faq-accordion-title": {
        height: "40px",
        display: "flex",
        alignItems: "center",
        borderRadius: "0",
        justifyContent: "space-between",
        "& h3": {
            fontWeight: "600",
            fontSize: "1rem",
            lineHeight: "1.75rem",
        },
        "&:hover": {
            cursor: "pointer",
        },
    },
    "& .faq-accordion-content": {
        transition: "all 0.3s ease",
        paddingRight: "3rem",
        marginTop: "0.5rem",
        color: "rgb(75 85 99)",
        lineHeight: "1.75rem",
        fontSize: "1rem",
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
        },
        "& a": {
            textDecoration: "none",
            color: "$colors$primary",
            fontWeight: "700",
        },
        "& a:hover": {
            fontWeight: "700",
            color: "$colors$primary",
            cursor: "pointer",
        },
        "& p": {
            lineHeight: "1.75rem",
        },
    },
    "& .faq-accordion-icon": {
        transition: "transform 0.3s ease",
        height: "1.75rem",
        width: "1.75rem",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        "& svg": {
            height: "1.5rem",
            width: "1.5rem",
            display: "block",
            margin: "0",
        },
    },
    "& .active": {
        transition: "all 0.3s ease",
        "& .faq-accordion-icon": {
            transform: "rotate(-135deg)",
            transformOrigin: "0.875rem 0.875rem",
        },
    },
};

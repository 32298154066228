import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadConnect } from "@stripe/connect-js";
import {
    ConnectAccountOnboarding,
    ConnectPayments,
    ConnectComponentsProvider,
    ConnectPayouts,
    ConnectPaymentDetails,
} from "@stripe/react-connect-js";

import {
    PBox,
    PButton,
    PFlowButton,
    PLi,
    PText,
    PH2,
    PUl,
    PHighlightButton,
    PLink,
} from "../../theme/BaseTheme";
import { Loader } from "../Loader";
import { stripeOnboardUser } from "../../../actions/stripe";
import {
    fetchStripeClientSecret,
    stripeCheckOnboarded,
} from "../../../actions/stripe";
import { keys } from "../../../config/keys";

class Stripe extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, stripeConnectInstance: null };
    }

    componentDidMount() {
        this.props
            .stripeCheckOnboarded()
            .then(async () => {
                if (this.props.onboarded) {
                    await this.props.fetchStripeClientSecret();
                    const stripeConnect = await loadConnect();
                    if (this.props.clientSecret) {
                        const stripeConnectInstance =
                            await stripeConnect.initialize({
                                publishableKey: keys.stripePublishableKey,
                                clientSecret: this.props.clientSecret,
                                appearance: {
                                    overlays: "dialog",
                                    variables: {
                                        colorPrimary: "#000",
                                    },
                                },
                                refreshClientSecret: async () => {
                                    return await this.props.fetchStripeClientSecret();
                                },
                            });
                        this.setState({
                            stripeConnectInstance: stripeConnectInstance,
                        });
                    }
                }
            })
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles}>
                {!this.props.onboarded && (
                    <Fragment>
                        <PBox className="setup-stripe">
                            <PH2>
                                Setup Your Stripe Account To Receive Payouts
                            </PH2>
                            {this.props.onboardMessage && (
                                <PText>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 256 256"
                                        width="20"
                                        height="20"
                                    >
                                        <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z" />
                                    </svg>
                                    {this.props.onboardMessage}
                                </PText>
                            )}
                            <PUl>
                                <PLi>
                                    Clicking the button will open the Stripe
                                    onboarding process in a new tab.
                                </PLi>
                                <PLi>
                                    Stripe will collect information during the
                                    onboarding process from you such as your
                                    personal information, your SSN, your
                                    business details, your bank information (to
                                    receive payouts), etc.
                                </PLi>
                                <PLi>
                                    By registering your Stripe account, you
                                    agree to our{" "}
                                    <a href="/terms" target="_blank">
                                        Terms and Conditions
                                    </a>{" "}
                                    and the{" "}
                                    <a
                                        href="https://stripe.com/legal/connect-account"
                                        target="_blank"
                                    >
                                        Stripe Connected Account Agreement
                                    </a>
                                    .
                                </PLi>
                            </PUl>
                            {!this.props.onboardMessage && (
                                <PLink
                                    className="setup-button"
                                    onClick={() => {
                                        this.props.stripeOnboardUser();
                                    }}
                                >
                                    Setup Payouts on Stripe
                                </PLink>
                            )}
                            {this.props.onboardMessage && (
                                <PLink
                                    className="setup-button"
                                    onClick={() => {
                                        this.props.stripeOnboardUser();
                                    }}
                                >
                                    Continue Connecting to Stripe
                                </PLink>
                            )}
                        </PBox>
                    </Fragment>
                )}
                {this.props.onboarded && this.state.stripeConnectInstance && (
                    <>
                        <PH2>Payments</PH2>
                        <ConnectComponentsProvider
                            connectInstance={this.state.stripeConnectInstance}
                        >
                            <ConnectPayments />
                            <ConnectPayouts />
                            <ConnectPaymentDetails
                                onClose={() => {
                                    console.log("closed");
                                }}
                                chargeId="pi_test123"
                                visible
                            />
                        </ConnectComponentsProvider>
                    </>
                )}
            </PBox>
        );
    }
}

const styles = {
    padding: "20px 40px 40px 40px",
    "& .setup-button": {
        display: "flex",
        alignItems: "center",
        margin: "1rem 0.5rem",
        color: "#fff",
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        padding: "0.5rem 0.75rem",
        backgroundColor: "$colors$primary",
        borderRadius: "0.375rem",
        textDecoration: "none",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "$colors$primary500",
        },
    },
    "& .setup-stripe": {
        maxWidth: "80rem",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        "& h2": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            fontWeight: "700",
            color: "rgb(17 24 39)",
        },
        "& ul": {
            fontSize: "1rem",
            paddingLeft: "1rem",
            //color: "rgb(107, 114, 128)",
        },
        "& li": {
            lineHeight: "1.7",
        },
        "& a": {
            textDecoration: "none",
        },
        "& p": {
            backgroundColor: "$colors$lightPrimary",
            padding: "20px",
            borderRadius: "0.375rem",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
        },
        "& svg": {
            fill: "$colors$primary",
            marginRight: "0.5rem",
        },
    },
};

function mapStateToProps(state) {
    return {
        onboarded:
            state.stripeReducer?.stripeCheckOnboarded?.onboarded?.onboarded,
        onboardMessage:
            state.stripeReducer?.stripeCheckOnboarded?.onboarded?.message,
        clientSecret:
            state.stripeReducer?.stripeConnectAccountSecret?.clientSecret,
    };
}

export default connect(mapStateToProps, {
    stripeOnboardUser,
    fetchStripeClientSecret,
    stripeCheckOnboarded,
})(withRouter(Stripe));

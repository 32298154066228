import { combineReducers } from "redux";

const fetchMerchantDisplay = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_MERCHANT_DISPLAY_SUCCESS":
            state[action.shop_uuid] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                merchant: action.payload,
            };
        case "FETCH_MERCHANT_DISPLAY_FAIL":
            state[action.shop_uuid] = {
                isLoading: false,
                isError: true,
                isSuccess: false,
                merchant: action.payload,
            };
        default:
            return state;
    }
};

const fetchMerchantEdit = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_MERCHANT_EDIT_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                merchant: action.payload,
            };
        case "FETCH_MERCHANT_EDIT_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                merchant: action.payload,
            };
        default:
            return state;
    }
};

const fetchMerchantStats = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_MERCHANT_STATS_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                merchantStats: action.payload,
            };
        case "FETCH_MERCHANT_STATS_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                merchantStats: action.payload,
            };
        default:
            return state;
    }
};

const fetchTopMerchants = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_TOP_MERCHANTS_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                topMerchants: action.payload,
            };
        case "FETCH_TOP_MERCHANTS_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                topMerchants: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({
    fetchMerchantEdit,
    fetchMerchantDisplay,
    fetchMerchantStats,
    fetchTopMerchants,
});

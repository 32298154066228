import axios from "axios";
import { message } from "antd";

export const fetchSearchProductsFull =
    (urlParams, offset, limit) => async (dispatch) => {
        var key = await urlParams.get("key");
        var cat = (await urlParams.getAll("cat").toString())
            ? urlParams.getAll("cat").toString()
            : null;
        var p_attr = (await urlParams.getAll("p_attr").toString())
            ? urlParams.getAll("p_attr").toString()
            : null;
        var r_attr = (await urlParams.getAll("r_attr").toString())
            ? urlParams.getAll("r_attr").toString()
            : null;
        await axios
            .get(
                `/api/filters/search_full/${key}/${cat}/${p_attr}/${r_attr}/${offset}/${limit}`
            )
            .then(async (response) => {
                dispatch({
                    type: "FETCH_SEARCH_PRODUCTS_FULL_SUCCESS",
                    payload: response.data,
                    key: "search_full" + urlParams.toString(),
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

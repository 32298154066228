import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { Progress } from "antd";
import {
    PBox,
    PH1,
    PText,
    PH2,
    PHighlightLink,
    PH5,
    PH4,
    PLink,
    PFlowLink,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import OrdersByCategory from "../../components/seller/OrdersByCategory";
import TopProducts from "../../components/seller/TopProducts";
import RecentOrders from "../../components/seller/RecentOrders";
import Earnings from "../../components/seller/Earnings";
import { Loader } from "../../components/Loader";
import { fetchMerchantStatsByMerchantID } from "../../../actions/merchant";

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
        "Bytes",
        "KiB",
        "MiB",
        "GiB",
        "TiB",
        "PiB",
        "EiB",
        "ZiB",
        "YiB",
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchMerchantStatsByMerchantID(
                this.props.match.params.shop_uuid,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles} className="dashboard">
                    <PBox className="dashboard-welcome">
                        <PBox>
                            {this.props.merchantStats?.seller?.seller_name && (
                                <PH4>
                                    Welcome,{" "}
                                    {
                                        this.props.merchantStats?.seller
                                            ?.seller_name
                                    }
                                    !
                                </PH4>
                            )}
                            {!this.props.merchantStats?.seller?.seller_name && (
                                <PH4>Welcome!</PH4>
                            )}
                            <PText>
                                Here's what's happening with your store.
                            </PText>
                        </PBox>
                        <PBox className="dashboard-welcome-buttons">
                            <PLink
                                href={`/seller/${this.props.match.params.shop_uuid}/create`}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1rem"
                                    height="1rem"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
                                </svg>{" "}
                                New Product
                            </PLink>
                            <PLink
                                href={`/shop/${this.props.match.params.shop_uuid}/1`}
                                target="_blank"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M232,96a7.89,7.89,0,0,0-.3-2.2L217.35,43.6A16.07,16.07,0,0,0,202,32H54A16.07,16.07,0,0,0,38.65,43.6L24.31,93.8A7.89,7.89,0,0,0,24,96v16a40,40,0,0,0,16,32v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V144a40,40,0,0,0,16-32ZM54,48H202l11.42,40H42.61Zm50,56h48v8a24,24,0,0,1-48,0Zm-16,0v8a24,24,0,0,1-48,0v-8ZM200,208H56V151.2a40.57,40.57,0,0,0,8,.8,40,40,0,0,0,32-16,40,40,0,0,0,64,0,40,40,0,0,0,32,16,40.57,40.57,0,0,0,8-.8Zm-8-72a24,24,0,0,1-24-24v-8h48v8A24,24,0,0,1,192,136Z"></path>
                                </svg>{" "}
                                My Storefront
                            </PLink>
                        </PBox>
                    </PBox>
                    {!this.props.merchantStats?.beginner_todos_finished && (
                        <PBox className="dashboard-todo">
                            <PH4>Get Started with Phair</PH4>
                            <PText>
                                Complete 3 tasks to start selling on Phair. Your
                                products will become available once you've
                                linked your Stripe account.
                            </PText>
                            <PBox className="dashboard-todo-items">
                                {!this.props.merchantStats
                                    ?.beginner_create_product_finished && (
                                    <PBox className="dashboard-todo-item">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="#000000"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M239.2,97.29a16,16,0,0,0-13.81-11L166,81.17,142.72,25.81h0a15.95,15.95,0,0,0-29.44,0L90.07,81.17,30.61,86.32a16,16,0,0,0-9.11,28.06L66.61,153.8,53.09,212.34a16,16,0,0,0,23.84,17.34l51-31,51.11,31a16,16,0,0,0,23.84-17.34l-13.51-58.6,45.1-39.36A16,16,0,0,0,239.2,97.29Zm-15.22,5-45.1,39.36a16,16,0,0,0-5.08,15.71L187.35,216v0l-51.07-31a15.9,15.9,0,0,0-16.54,0l-51,31h0L82.2,157.4a16,16,0,0,0-5.08-15.71L32,102.35a.37.37,0,0,1,0-.09l59.44-5.14a16,16,0,0,0,13.35-9.75L128,32.08l23.2,55.29a16,16,0,0,0,13.35,9.75L224,102.26S224,102.32,224,102.33Z"></path>
                                        </svg>{" "}
                                        <PLink
                                            href={`/seller/${this.props.match.params.shop_uuid}/create`}
                                        >
                                            Create a new product.
                                        </PLink>
                                    </PBox>
                                )}
                                {this.props.merchantStats
                                    ?.beginner_create_product_finished && (
                                    <PBox className="dashboard-todo-item-finished">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="#000000"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M149.61,85.71l-89.6,88a8,8,0,0,1-11.22,0L10.39,136a8,8,0,1,1,11.22-11.41L54.4,156.79l84-82.5a8,8,0,1,1,11.22,11.42Zm96.1-11.32a8,8,0,0,0-11.32-.1l-84,82.5-18.83-18.5a8,8,0,0,0-11.21,11.42l24.43,24a8,8,0,0,0,11.22,0l89.6-88A8,8,0,0,0,245.71,74.39Z"></path>
                                        </svg>{" "}
                                        Create a new product.
                                    </PBox>
                                )}
                                {!this.props.merchantStats
                                    ?.beginner_connect_stripe_finished && (
                                    <PBox className="dashboard-todo-item">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="#000000"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M168,152c0,17.65-17.94,32-40,32s-40-14.35-40-32a8,8,0,0,1,16,0c0,8.67,11,16,24,16s24-7.33,24-16c0-9.48-8.61-13-26.88-18.26C109.37,129.2,89.78,123.55,89.78,104c0-18.24,16.43-32,38.22-32,15.72,0,29.18,7.3,35.12,19a8,8,0,1,1-14.27,7.22C145.64,91.94,137.65,88,128,88c-12.67,0-22.22,6.88-22.22,16,0,7,9,10.1,23.77,14.36C145.78,123,168,129.45,168,152ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208H208Z"></path>
                                        </svg>{" "}
                                        <PLink
                                            href={`/seller/${this.props.match.params.shop_uuid}/payment`}
                                        >
                                            Connect to your Stripe account.
                                        </PLink>
                                    </PBox>
                                )}
                                {this.props.merchantStats
                                    ?.beginner_connect_stripe_finished && (
                                    <PBox className="dashboard-todo-item-finished">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="#000000"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M149.61,85.71l-89.6,88a8,8,0,0,1-11.22,0L10.39,136a8,8,0,1,1,11.22-11.41L54.4,156.79l84-82.5a8,8,0,1,1,11.22,11.42Zm96.1-11.32a8,8,0,0,0-11.32-.1l-84,82.5-18.83-18.5a8,8,0,0,0-11.21,11.42l24.43,24a8,8,0,0,0,11.22,0l89.6-88A8,8,0,0,0,245.71,74.39Z"></path>
                                        </svg>{" "}
                                        Connect to your Stripe account.
                                    </PBox>
                                )}
                                {!this.props.merchantStats
                                    ?.beginner_make_product_public_finished && (
                                    <PBox className="dashboard-todo-item">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="#000000"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M239.37,70.1A13.16,13.16,0,0,0,227.9,61l-37.22-3.15L176.16,24a13.24,13.24,0,0,0-24.31,0L137.33,57.86,100.1,61a13.13,13.13,0,0,0-7.49,23.06l28.16,24-8.43,35.73a13.1,13.1,0,0,0,5,13.58,13.25,13.25,0,0,0,14.63.7l32-19,32,19a13.25,13.25,0,0,0,14.63-.7,13.09,13.09,0,0,0,5-13.58l-8.43-35.73,28.15-24A13.07,13.07,0,0,0,239.37,70.1Zm-43.86,27a13.06,13.06,0,0,0-4.26,13l7.31,31-27.78-16.51a13.24,13.24,0,0,0-13.56,0L129.44,141l7.31-31a13,13,0,0,0-4.25-13L108.24,76.38l32.09-2.72a13.16,13.16,0,0,0,11-7.94L164,36.24l12.64,29.48a13.18,13.18,0,0,0,11,7.94l32.09,2.72ZM85.66,125.66l-56,56a8,8,0,0,1-11.32-11.32l56-56a8,8,0,0,1,11.32,11.32Zm16,56-56,56a8,8,0,0,1-11.32-11.32l56-56a8,8,0,0,1,11.32,11.32Zm72-11.32a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32-11.32l56-56A8,8,0,0,1,173.66,170.34Z"></path>
                                        </svg>{" "}
                                        <PLink
                                            href={`/seller/${this.props.match.params.shop_uuid}/listings`}
                                        >
                                            Make your product public.
                                        </PLink>
                                    </PBox>
                                )}
                                {this.props.merchantStats
                                    ?.beginner_make_product_public_finished && (
                                    <PBox className="dashboard-todo-item-finished">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="#000000"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M149.61,85.71l-89.6,88a8,8,0,0,1-11.22,0L10.39,136a8,8,0,1,1,11.22-11.41L54.4,156.79l84-82.5a8,8,0,1,1,11.22,11.42Zm96.1-11.32a8,8,0,0,0-11.32-.1l-84,82.5-18.83-18.5a8,8,0,0,0-11.21,11.42l24.43,24a8,8,0,0,0,11.22,0l89.6-88A8,8,0,0,0,245.71,74.39Z"></path>
                                        </svg>{" "}
                                        Make your product public.
                                    </PBox>
                                )}
                            </PBox>
                        </PBox>
                    )}
                    {this.props.merchantStats?.notifications?.length > 0 && (
                        <PBox className="dashboard-notification-container">
                            <PBox className="dashboard-notification-title">
                                <PH2>Product Status Updates</PH2>
                                <PText>Last 5 days</PText>
                            </PBox>
                            {this.props.merchantStats?.notifications?.map(
                                (notification) => {
                                    return (
                                        <PBox className="dashboard-notification">
                                            <PBox>
                                                {notification?.type ===
                                                    "error" && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 256 256"
                                                        width="24"
                                                        height="24"
                                                        fill="#EC8D31"
                                                    >
                                                        <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM120,104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,88a12,12,0,1,1,12-12A12,12,0,0,1,128,192Z" />
                                                    </svg>
                                                )}
                                                {notification?.type ===
                                                    "success" && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 256 256"
                                                        width="24"
                                                        height="24"
                                                        fill="#1BA37B"
                                                    >
                                                        <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z" />
                                                    </svg>
                                                )}
                                            </PBox>
                                            <PText>
                                                {notification?.message}{" "}
                                                {/*notification.type ===
                                                    "success" && (
                                                    <PLink
                                                        href={`/item/${notification?.product?.uuid}`}
                                                        target="_blank"
                                                    >
                                                        View on Phair
                                                    </PLink>
                                                    )*/}
                                                {notification?.type ===
                                                    "error" && (
                                                    <PLink
                                                        href={`/seller/${this.props.match.params.shop_uuid}/item/${notification?.product?.uuid}/edit`}
                                                    >
                                                        Edit
                                                    </PLink>
                                                )}
                                            </PText>
                                            <PText className="dashboard-notification-date">
                                                {new Date(
                                                    notification?.updatedAt
                                                ).toDateString()}
                                            </PText>
                                        </PBox>
                                    );
                                }
                            )}
                        </PBox>
                    )}
                    {this.props.merchantStats?.beginner_todos_finished && (
                        <>
                            <PBox className="dashboard-mini-cards">
                                <PBox className="dashboard-mini-card">
                                    <PText>TOTAL EARNINGS</PText>
                                    <PH1>
                                        $
                                        {this.props.merchantStats?.merchant_stat
                                            ?.total_revenue
                                            ? Math.round(
                                                  this.props.merchantStats
                                                      ?.merchant_stat
                                                      ?.total_revenue * 100
                                              ) / 100
                                            : 0.0}
                                    </PH1>
                                </PBox>
                                <PBox className="dashboard-mini-card">
                                    <PText>STORAGE USAGE</PText>
                                    <PH1>
                                        {formatBytes(
                                            this.props.merchantStats
                                                ?.merchant_stat?.storage_usage
                                        )}
                                    </PH1>
                                    {/*<Progress
                                percent={
                                    Math.round(
                                        (parseFloat(
                                            this.props.merchantStats
                                                ?.merchant_stat?.storage_usage
                                        ) /
                                            5368709120) *
                                            10
                                    ) / 10
                                }
                                strokeColor="#5344a9"
                                trailColor="rgb(238, 236, 255)"
                            />*/}
                                </PBox>
                                <PBox className="dashboard-mini-card">
                                    <PText>ORDERS</PText>
                                    <PH1>
                                        {this.props.merchantStats?.merchant_stat
                                            ?.number_of_orders
                                            ? this.props.merchantStats
                                                  ?.merchant_stat
                                                  ?.number_of_orders
                                            : 0}
                                    </PH1>
                                </PBox>
                                <PBox className="dashboard-mini-card">
                                    <PText>ACTIVE LISTINGS</PText>
                                    <PH1>
                                        {this.props.merchantStats?.merchant_stat
                                            ?.active_items
                                            ? this.props.merchantStats
                                                  ?.merchant_stat?.active_items
                                            : 0}
                                    </PH1>
                                </PBox>
                            </PBox>
                            <PBox
                                className={clsx(
                                    "dashboard-card",
                                    "monthly-earnings"
                                )}
                            >
                                <PBox className="dashboard-card-title">
                                    <PH2>Monthly Revenue</PH2>
                                    <PLink
                                        href={`/seller/${this.props.match.params.shop_uuid}/payment`}
                                    >
                                        View Payments
                                    </PLink>
                                </PBox>
                                <Earnings />
                            </PBox>

                            <PBox
                                className={clsx(
                                    "dashboard-card",
                                    "sales-by-categories"
                                )}
                            >
                                <PBox className="dashboard-card-title">
                                    <PH2>Orders by Category</PH2>
                                    <PLink
                                        href={`/seller/${this.props.match.params.shop_uuid}/orders`}
                                    >
                                        View Orders
                                    </PLink>
                                </PBox>
                                <OrdersByCategory />
                            </PBox>
                            <PBox
                                className={clsx(
                                    "dashboard-card",
                                    "top-products"
                                )}
                            >
                                <PBox className="dashboard-card-title">
                                    <PH2>Best Selling Products</PH2>
                                    <PLink
                                        href={`/seller/${this.props.match.params.shop_uuid}/listings`}
                                    >
                                        View Listings
                                    </PLink>
                                </PBox>
                                <TopProducts />
                            </PBox>
                            <PBox
                                className={clsx(
                                    "dashboard-card",
                                    "recent-orders"
                                )}
                            >
                                <PBox className="dashboard-card-title">
                                    <PH2>Recent Orders</PH2>
                                    <PLink
                                        href={`/seller/${this.props.match.params.shop_uuid}/orders`}
                                    >
                                        View Orders
                                    </PLink>
                                </PBox>
                                <RecentOrders />
                            </PBox>
                        </>
                    )}
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    display: "grid",
    gridGap: "10px",
    margin: "20px auto",
    padding: "0 1rem",
    gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
    maxWidth: "80rem",
    "& .dashboard-welcome": {
        gridColumn: "1 / 13",
        margin: "10px 0px",
        backgroundColor: "#fff",
        padding: "10px 40px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& h4": { margin: "4px 0", fontSize: "18px" },
        "& p": { margin: "8px 0", color: "$colors$label" },
    },
    "& .dashboard-welcome-buttons": {
        display: "flex",
        "& a": {
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
            color: "#fff",
            fontWeight: "600",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "0.5rem 0.75rem",
            backgroundColor: "$colors$primary",
            borderRadius: "0.375rem",
            textDecoration: "none",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "$colors$primary500",
            },
        },
        "& svg": {
            fill: "#fff",
            marginRight: "0.5rem",
        },
    },
    "& .dashboard-todo": {
        gridColumn: "1 / 13",
        margin: "10px 20px",
        backgroundColor: "#fff",
        padding: "20px 30px",
        borderRadius: "8px",
        border: "1px solid $colors$borderColor",
        //boxShadow: "$boxShadows$dashboardCard",
        "& h4": {
            fontWeight: "600",
            fontSize: "18px",
            color: "$colors$text",
            margin: "0 0 8px 0",
        },
        "& p": {
            margin: "8px 0 16px 0",
            color: "$colors$label",
        },
    },
    "& .dashboard-todo-items": {
        display: "grid",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gridGap: "20px",
        marginTop: "1.5rem",
        "@bp4": {
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            gridColumnGap: "20px",
        },
    },
    "& .dashboard-todo-item": {
        display: "flex",
        alignItems: "center",
        lineHeight: "1.5",
        color: "rgb(75, 85, 99)",
        textDecoration: "none",
        fontWeight: "500",
        lineHeight: "1.5rem",
        fontSize: "1rem",
        "& svg": {
            marginRight: "8px",
            backgroundColor: "$colors$primary200",
            fill: "$colors$primary800",
            padding: "8px",
            borderRadius: "50%",
        },
        "& a": {
            color: "rgb(75, 85, 99)",
            textDecoration: "none",
            fontWeight: "500",
            lineHeight: "1.5rem",
            fontSize: "1rem",
        },
        "& a:hover": {
            color: "$colors$primary",
        },
    },
    "& .dashboard-todo-item-finished": {
        display: "flex",
        alignItems: "center",
        lineHeight: "1.5",
        "& svg": {
            marginRight: "8px",
            backgroundColor: "rgba(109,203,163, 1)",
            fill: "#fff",
            padding: "8px",
            borderRadius: "50%",
        },
    },
    "& .dashboard-notification-container": {
        margin: "0 20px",
        padding: "10px 30px",
        gridColumn: "1 / 13",
        borderRadius: "8px",
        border: "1px solid $colors$borderColor",
        //boxShadow: "$boxShadows$dashboardCard",
    },
    "& .dashboard-notification-title ": {
        display: "flex",
        alignItems: "center",
        "& h2": {
            fontWeight: "600",
            fontSize: "18px",
            marginRight: "8px",
        },
        "& p": {
            fontSize: "14px",
            color: "$colors$label",
        },
    },
    "& .dashboard-notification": {
        display: "flex",
        alignItems: "center",
        margin: "12px 0",
        color: "$colors$text",
        "& svg": {
            marginRight: "12px",
        },
        "& p": {
            margin: "0",
            lineHeight: "1.5",
        },
        "& a": {
            cursor: "pointer",
            color: "$colors$secondary",
        },
        "& a:hover": {
            fontWeight: "700",
        },
    },
    "& .dashboard-notification-date": {
        color: "$colors$label",
        fontSize: "13px",
        fontWeight: "500",
        paddingLeft: "8px",
        margin: 0,
    },
    "& .dashboard-mini-cards": {
        display: "grid",
        gridGap: "30px",
        margin: "10px 20px",
        gridColumn: "1 / 13",
        "& div": {
            //boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "10px",
        },
        "@bp1": {
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
        "@bp4": {
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
    },
    "& .dashboard-mini-card": {
        backgroundColor: "#fff",
        padding: "10px 30px",
        borderRadius: "4px",
        border: "1px solid $colors$borderColor",
        //boxShadow: "$boxShadows$dashboardCard",
        "& p": {
            fontWeight: "600",
            color: "$colors$label",
            fontSize: "14px",
        },
        "& h1": {
            fontSize: "24px",
        },
    },
    "& .dashboard-card": {
        backgroundColor: "#fff",
        padding: "10px 30px",
        borderRadius: "16px",
        border: "1px solid $colors$borderColor",
        //boxShadow: "$boxShadows$dashboardCard",

        "& .dashboard-card-title": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            borderBottom: "1px solid $colors$borderColor",
            "& h2": {
                fontWeight: "600",
                fontSize: "18px",
            },
            "& a": {
                color: "$colors$primary",
                lineHeight: "1.5rem",
                fontWeight: "600",
                fontSize: "0.875rem",
                textDecoration: "none",
                "&:hover": {
                    cursor: "pointer",
                    color: "$colors$primary500",
                },
            },
        },
    },
    "& .monthly-earnings,  & .top-products": {
        "@bp1": {
            gridColumn: "1 / 13",
            margin: "10px 20px",
        },
        "@bp4": {
            gridColumn: "1 / 7",
            margin: "10px 10px 10px 20px",
        },
    },
    "& .sales-by-categories, & .recent-orders": {
        "@bp1": {
            gridColumn: "1 / 13",
            margin: "10px 20px",
        },
        "@bp4": {
            gridColumn: "7 / 13",
            margin: "10px 20px 10px 10px",
        },
    },
};

function mapStateToProps(state) {
    return {
        merchantStats: state.merchantReducer?.fetchMerchantStats?.merchantStats,
    };
}

export default connect(mapStateToProps, {
    fetchMerchantStatsByMerchantID,
})(withRouter(Dashboard));

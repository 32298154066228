import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Popover } from "antd";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PHighlightLink,
    PLink,
} from "../../theme/BaseTheme";
import OrderSummary from "../../components/seller/OrderSummary";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";

class CheckoutFreeConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            urlParams: new URLSearchParams(window.location.search),
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PBox className="checkout-confirmation-section">
                        <PContainer>
                            <PBox className="checkout-confirmation-section-banner">
                                <PBox className="checkout-confirmation-section-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="64"
                                        height="64"
                                        fill="#000000"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                                    </svg>
                                </PBox>
                                <PH1>It's ordered. Hope you love it!</PH1>
                                <PText>
                                    Your order number is:{" "}
                                    {this.props.match.params.order_number}.
                                </PText>
                                <PText>
                                    The download link to the digital products
                                    will be sent to you shortly.
                                </PText>

                                <PBox className="checkout-confirmation-keep-shopping">
                                    <PLink href="/">Keep Shopping</PLink>
                                </PBox>
                            </PBox>
                        </PContainer>
                    </PBox>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    "& .checkout-confirmation-section": {
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    "& .checkout-confirmation-section-banner": {
        maxWidth: "40rem",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "4rem",
        color: "$colors$text",
        textAlign: "center",
        "& h1": {
            fontWeight: "600",
            fontSize: "2rem",
            textAlign: "center",
            marginBottom: "3rem",
        },
        "& p": {
            lineHeight: "1",
        },
    },
    "& .checkout-confirmation-link": {
        marginTop: "40px",
        marginBottom: "40px",
        //textAlign: "center",
        "& a": {
            backgroundColor: "$colors$primary",
            color: "white",
            fontWeight: "500",
            borderColor: "$colors$primary",
            "&:hover": {
                backgroundColor: "#fff",
                color: "$colors$primary",
            },
        },
    },
    "& .checkout-confirmation-keep-shopping": {
        marginTop: "4rem",
        marginBottom: "4rem",
        textAlign: "center",
        "& a": {
            color: "#fff",
            backgroundColor: "$colors$primary",
            borderRadius: "0.375rem",
            marginTop: "1.5rem",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "0.625rem 0.875rem",
            display: "block",
            textAlign: "center",
            textDecoration: "none",
            "&:hover": {
                color: "#fff",
                backgroundColor: "$colors$primary500",
            },
        },
    },
    "& .checkout-confirmation-section-icon": {
        textAlign: "center",
        "& svg": {
            fill: "rgba(109,203,163, 1)",
        },
    },
    "& .email-popover": {
        cursor: "pointer",
        "&:hover": {
            color: "$colors$primary",
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {})(withRouter(CheckoutFreeConfirm));

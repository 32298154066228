import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Component, Fragment } from "react";
import { keyframes } from "@stitches/react";
import Card from "../components/landing/Card";
import {
    PSection,
    PH1,
    PBox,
    PContainer,
    PInput,
    PLabel,
    PLink,
} from "../theme/BaseTheme";
import { Loader } from "../components/Loader";

class ProductSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            itemsToShow: 0,
            itemsToShowStart: 0,
            itemsToShowEnd: 0,
            pageLength: 0,
            currentPage: 0,
        };
        this.updateItemsToShow = this.updateItemsToShow.bind(this);
        this.prevItem = this.prevItem.bind(this);
        this.nextItem = this.nextItem.bind(this);
        this.jumpToPage = this.jumpToPage.bind(this);
    }

    componentDidMount() {
        this.updateItemsToShow();
        window.addEventListener("resize", this.updateItemsToShow);
        this.setState({
            isLoading: false,
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateItemsToShow);
    }

    updateItemsToShow() {
        if (window.innerWidth < 768) {
            if (this.props.productList?.length <= 2) {
                this.setState({
                    itemsToShow: 2,
                    itemsToShowStart: 0,
                    itemsToShowEnd: this.props.productList?.length - 1,
                    pageLength: 1,
                    currentPage: 0,
                });
            } else {
                if (
                    this.state.itemsToShowStart + 1 <
                    this.props.productList?.length
                ) {
                    this.setState({
                        itemsToShow: 2,
                        itemsToShowEnd: this.state.itemsToShowStart + 1,
                        pageLength: this.props.productList?.length - 1,
                        currentPage: this.state.itemsToShowStart,
                    });
                } else {
                    this.setState({
                        itemsToShow: 2,
                        itemsToShowStart: this.props.productList?.length - 2,
                        itemsToShowEnd: this.props.productList?.length - 1,
                        pageLength: this.props.productList?.length - 1,
                        currentPage: this.props.productList?.length - 2,
                    });
                }
            }
        } else if (window.innerWidth < 1024) {
            if (this.props.productList?.length <= 3) {
                this.setState({
                    itemsToShow: 3,
                    itemsToShowStart: 0,
                    itemsToShowEnd: this.props.productList?.length - 1,
                    pageLength: 1,
                    currentPage: 0,
                });
            } else {
                if (
                    this.state.itemsToShowStart + 2 <
                    this.props.productList?.length
                ) {
                    this.setState({
                        itemsToShow: 3,
                        itemsToShowEnd: this.state.itemsToShowStart + 2,
                        pageLength: this.props.productList?.length - 2,
                        currentPage: this.state.itemsToShowStart,
                    });
                } else {
                    this.setState({
                        itemsToShow: 3,
                        itemsToShowStart: this.props.productList?.length - 3,
                        itemsToShowEnd: this.props.productList?.length - 1,
                        pageLength: this.props.productList?.length - 1,
                        currentPage: this.props.productList?.length - 3,
                    });
                }
            }
        } else {
            if (this.props.productList?.length <= 4) {
                this.setState({
                    itemsToShow: 4,
                    itemsToShowStart: 0,
                    itemsToShowEnd: this.props.productList?.length - 1,
                    pageLength: 1,
                    currentPage: 0,
                });
            } else {
                if (
                    this.state.itemsToShowStart + 3 <
                    this.props.productList?.length
                ) {
                    this.setState({
                        itemsToShow: 4,
                        itemsToShowEnd: this.state.itemsToShowStart + 3,
                        pageLength: this.props.productList?.length - 3,
                        currentPage: this.state.itemsToShowStart,
                    });
                } else {
                    this.setState({
                        itemsToShow: 4,
                        itemsToShowStart: this.props.productList?.length - 4,
                        itemsToShowEnd: this.props.productList?.length - 1,
                        pageLength: this.props.productList?.length - 1,
                        currentPage: this.props.productList?.length - 4,
                    });
                }
            }
        }
    }

    prevItem() {
        if (window.innerWidth < 768) {
            if (this.state.itemsToShowStart > 1) {
                this.setState({
                    itemsToShowStart: this.state.itemsToShowStart - 2,
                    itemsToShowEnd: this.state.itemsToShowEnd - 2,
                    currentPage: this.state.currentPage - 1,
                });
            }
        } else if (window.innerWidth < 1024) {
            if (this.state.itemsToShowStart > 2) {
                this.setState({
                    itemsToShowStart: this.state.itemsToShowStart - 3,
                    itemsToShowEnd: this.state.itemsToShowEnd - 3,
                    currentPage: this.state.currentPage - 1,
                });
            }
        } else {
            if (this.state.itemsToShowStart > 3) {
                this.setState({
                    itemsToShowStart: this.state.itemsToShowStart - 4,
                    itemsToShowEnd: this.state.itemsToShowEnd - 4,
                    currentPage: this.state.currentPage - 1,
                });
            }
        }
    }

    nextItem() {
        if (window.innerWidth < 768) {
            if (
                this.state.itemsToShowStart <
                this.props.productList?.length - 2
            ) {
                this.setState({
                    itemsToShowStart: this.state.itemsToShowStart + 2,
                    itemsToShowEnd: this.state.itemsToShowEnd + 2,
                    currentPage: this.state.currentPage + 1,
                });
            }
        } else if (window.innerWidth < 1024) {
            if (
                this.state.itemsToShowStart <
                this.props.productList?.length - 3
            ) {
                this.setState({
                    itemsToShowStart: this.state.itemsToShowStart + 3,
                    itemsToShowEnd: this.state.itemsToShowEnd + 3,
                    currentPage: this.state.currentPage + 1,
                });
            }
        } else {
            if (
                this.state.itemsToShowStart <
                this.props.productList?.length - 4
            ) {
                this.setState({
                    itemsToShowStart: this.state.itemsToShowStart + 4,
                    itemsToShowEnd: this.state.itemsToShowEnd + 4,
                    currentPage: this.state.currentPage + 1,
                });
            }
        }
    }

    jumpToPage(idx) {
        this.setState({
            currentPage: idx,
            itemsToShowStart: idx * this.state.itemsToShow,
            itemsToShowEnd:
                idx * this.state.itemsToShow + this.state.itemsToShow - 1,
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <>
                {this.props.productList?.length > 0 && (
                    <PSection css={styles}>
                        <PBox className="product-slider">
                            <PBox className="product-slider-wrapper">
                                <PBox className="product-slider-title-wrapper">
                                    <PH1 className="product-slider-title">
                                        {this.props.title}
                                    </PH1>
                                    <PLink href={this.props.linkToMore}>
                                        EXPLORE MORE
                                    </PLink>
                                </PBox>
                                <PBox className="product-slider-list">
                                    {this.props.productList?.map(
                                        (item, idx) => {
                                            return (
                                                <Fragment>
                                                    {idx <=
                                                        this.state
                                                            .itemsToShowEnd &&
                                                        idx >=
                                                            this.state
                                                                .itemsToShowStart && (
                                                            <Card
                                                                key={
                                                                    "product-slider-" +
                                                                    idx.toString()
                                                                }
                                                                product={item}
                                                            />
                                                        )}
                                                </Fragment>
                                            );
                                        }
                                    )}
                                </PBox>
                                <PBox className="product-slider-radio-group">
                                    <PBox>
                                        {Array(
                                            Math.round(
                                                this.state.pageLength /
                                                    this.state.itemsToShow
                                            ) + 1
                                        )
                                            .fill(0)
                                            .map((item, idx) => {
                                                return (
                                                    <Fragment>
                                                        <PInput
                                                            className={
                                                                "product-slider-radio" +
                                                                this.props
                                                                    .sliderName
                                                            }
                                                            id={
                                                                "radio" +
                                                                this.props
                                                                    .sliderName +
                                                                idx
                                                            }
                                                            type="radio"
                                                            checked={
                                                                idx ===
                                                                this.state
                                                                    .currentPage
                                                            }
                                                            onClick={() => {
                                                                this.jumpToPage(
                                                                    idx
                                                                );
                                                            }}
                                                        />
                                                        <PLabel
                                                            for={
                                                                "radio" +
                                                                this.props
                                                                    .sliderName +
                                                                idx
                                                            }
                                                        />
                                                    </Fragment>
                                                );
                                            })}
                                    </PBox>
                                </PBox>
                                {this.state.itemsToShowStart !== 0 && (
                                    <PBox
                                        className="product-slider-prev"
                                        onClick={this.prevItem}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
                                        </svg>
                                    </PBox>
                                )}
                                {this.state.itemsToShowEnd <
                                    this.props.productList?.length - 1 && (
                                    <PBox
                                        className="product-slider-next"
                                        onClick={this.nextItem}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                                        </svg>
                                    </PBox>
                                )}
                            </PBox>
                        </PBox>
                    </PSection>
                )}
            </>
        );
    }
}

const rainbow = keyframes({
    "0%": { backgroundPosition: "left" },
    "50%": { backgroundPosition: "right" },
    "100%": { backgroundPosition: "left" },
});

const styles = {
    marginBottom: "2.5rem",
    maxWidth: "80rem",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0 2rem",
    "& .product-slider": {
        marginTop: "2rem",
        marginBottom: "2rem",
        position: "relative",
    },
    "& .product-slider-title-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& a": {
            position: "relative",
            fontWeight: "700",
            fontSize: "14px",
            letterSpacing: "0.1em",
            lineHeight: "20px",
            textDecoration: "none",
            color: "transparent",
            animation: `${rainbow} 2s ease-in-out infinite`,
            transition: "color 0.2s ease-in-out",
            background: "linear-gradient(to right, #bb5098,#5344a9,#bb5098)",
            backgroundClip: "text",
            "-webkit-background-clip": "text",
            backgroundSize: "200% 200%",
        },
        "& a:after": {
            content: "",
            position: "absolute",
            left: "0",
            bottom: "-4px",
            width: "100%",
            height: "1px",
            background:
                "linear-gradient(216.56deg, $colors$tertiary 5.32%, $colors$primary 94.32%)",
        },
    },
    "& .product-slider-title": {
        fontSize: "1.5rem",
        fontWeight: "700",
        lineHeight: "2rem",
        color: "rgb(17 24 39)",
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    "& .product-slider-list": {
        display: "grid",
        gridGap: "2rem",
        "@bp1": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp2": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp3": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
    "& .product-slider-wrapper": {
        position: "relative",
    },
    "& .product-slider-prev": {
        cursor: "pointer",
        position: "absolute",
        left: "-25px",
        top: "50%",
        padding: "10px",
        borderRadius: "40px",
        backgroundColor: "$colors$lightBackground",
        "& svg": {
            margin: "0",
            verticalAlign: "top",
            fill: "$colors$primary",
        },
        "&:hover": {
            backgroundColor: "$colors$primary",
            "& svg": {
                fill: "#fff",
            },
        },
    },
    "& .product-slider-next": {
        cursor: "pointer",
        position: "absolute",
        right: "-25px",
        top: "50%",
        padding: "10px",
        borderRadius: "40px",
        backgroundColor: "$colors$lightBackground",
        "& svg": {
            margin: "0",
            verticalAlign: "top",
            fill: "$colors$primary",
        },
        "&:hover": {
            backgroundColor: "$colors$primary",
            "& svg": {
                fill: "#fff",
            },
        },
    },
    "& .product-slider-radio-group": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& input": {
            cursor: "pointer",
            display: "none",
        },
        "& label": {
            cursor: "pointer",
            padding: "2px 7px",
            margin: "4px",
            fontSize: "9px",
            borderRadius: "50%",
            backgroundColor: "$colors$lightPrimary",
        },
        "& input:checked+label": {
            backgroundColor: "$colors$primary",
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {})(withRouter(ProductSlider));

import { keys } from "../config/keys";

export const BlogPosts = [
    {
        cover: `https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/banner.png`,
        categories: ["Inspiration"],
        title: "20 Midjourney Prompts to Create Seamless Pattern for Passive Income",
        link: "/blog/midjourney-prompts-seamless-patterns",
        author: "Phair Team",
        date: "Feb 2, 2024",
        excerpt:
            "Looking to make passive income from generative AI art? Think about creating and selling seamless pattern textures with Midjourney. These patterns are a favorite in the print-on-demand market because they're perfect for a vast array of products.",
    },
    {
        cover: `${keys.imageBaseUrl}stripe-connect.png`,
        categories: ["Store Center"],
        title: "Step-by-Step Guide to Connecting Your Stripe Account with Phair",
        link: "/blog/connect-to-stripe",
        author: "Phair Team",
        date: "Jan 21, 2024",
        excerpt:
            "Ready to start earning through your Phair shop? One crucial step is setting up your Stripe account for seamless transactions. Don't worry, it's a straightforward process, and I'm here to guide you through it, step by step. Let's get your earnings flowing!",
    },
    {
        cover: `${keys.imageBaseUrl}ui-kit-trends.jpg`,
        categories: ["Trends"],
        title: "Exploring the 2024 Trends in Online UI Kit Sales",
        link: "/blog/ui-kit-trends-2024",
        author: "Phair Team",
        date: "Jan 19, 2024",
        excerpt:
            "In the fast-paced world of web and app design, staying updated with the latest trends is crucial to create visually appealing and user-friendly interfaces. UI (User Interface) kits have become indispensable tools for designers, providing them with pre-designed elements and templates that streamline the design process.",
    },
    {
        cover: `${keys.imageBaseUrl}online-trends.jpg`,
        categories: ["Trends"],
        title: "Navigating 2024's Landscape: Trends in Best-Selling Notion Templates",
        link: "/blog/notion-templates-trends-2024",
        author: "Phair Team",
        date: "Jan 7, 2024",
        excerpt:
            "As we advance into 2024, the digital productivity space continues to evolve, and with it, Notion's role as a versatile and powerful tool grows. Understanding the latest trends in best-selling Notion templates is essential for creators looking to stay ahead of the curve. This post delves into the emerging trends and popular themes in Notion templates for 2024, offering insights for those eager to create and capitalize on these evolving demands.",
    },
    {
        cover: `${keys.imageBaseUrl}start-selling.jpg`,
        categories: ["Store Center"],
        title: "So, You Wanna Sell on Phair? Let's Make It Happen!",
        link: "/blog/how-to-sell-on-phair",
        author: "Phair Team",
        date: "Jan 3, 2024",
        excerpt:
            "Hello there! Ready to jump into the world of selling on Phair? Super cool decision! Selling here isn't just about transactions; it's about being part of a vibrant digital marketplace and sharing your creativity with folks who'll love what you do. Let me guide you through getting started - it's easy and fun!",
    },
    {
        cover: `${keys.imageBaseUrl}welcome.jpg`,
        categories: ["News"],
        title: "Hey There, Welcome to Phair - Your New Go-To for All Things Digital!",
        link: "/blog/welcome-to-phair",
        author: "Phair Team",
        date: "Jan 1, 2024",
        excerpt:
            "Hey there! Super excited you've dropped by. Welcome to Phair, our little corner of the internet where buying and selling digital goodies is not just easy, it's a whole lot of fun too! Stick around, and I'll give you a quick tour of what makes Phair the place to be.",
    },
];

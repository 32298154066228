import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Pagination } from "antd";
import { Products } from "../../sections/Products";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PContainer, PBox, PH2, PH4, PText, PH3 } from "../../theme/BaseTheme";
import {
    fetchFeaturedProducts,
    fetchNewProducts,
    fetchPickedProducts,
} from "../../../actions/product";

class MoreProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            pathname: window.location.pathname,
            featuredPage: 1,
            newPage: 1,
            picksPage: 1,
            pageSize: 20,
        };
    }
    async componentDidMount() {
        if (this.state.pathname === "/featured") {
            await this.props.fetchFeaturedProducts(
                (this.state.featuredPage - 1) * this.state.pageSize,
                this.state.pageSize
            );
        } else if (this.state.pathname === "/new") {
            await this.props.fetchNewProducts(
                (this.state.newPage - 1) * this.state.pageSize,
                this.state.pageSize
            );
        } else if (this.state.pathname === "/picks") {
            await this.props.fetchPickedProducts(
                (this.state.picksPage - 1) * this.state.pageSize,
                this.state.pageSize
            );
        }
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        console.log("this.props.pickedProducts: ", this.props.pickedProducts);
        console.log(
            "this.props.totalPickedProducts: ",
            this.props.totalPickedProducts
        );
        return (
            <Layout>
                <PContainer>
                    <PBox css={styles}>
                        <PBox className="search-results-wrapper">
                            {this.state.pathname === "/featured" && (
                                <>
                                    <PH3>Featured</PH3>
                                    <Products
                                        productList={
                                            this.props.featuredProducts
                                        }
                                        isSearchResult={false}
                                    />
                                </>
                            )}
                            {this.state.pathname === "/new" && (
                                <>
                                    <PH3>Newly Added</PH3>
                                    <Products
                                        productList={this.props.newProducts}
                                        isSearchResult={false}
                                    />
                                </>
                            )}
                            {this.state.pathname === "/picks" && (
                                <>
                                    <PH3>Phair's Picks</PH3>
                                    <Products
                                        productList={this.props.pickedProducts}
                                        isSearchResult={false}
                                    />
                                </>
                            )}
                        </PBox>
                        <PBox className="search-results-pagination">
                            {this.state.pathname === "/featured" && (
                                <Pagination
                                    defaultCurrent={1}
                                    total={this.props.totalFeaturedProducts}
                                    pageSize={this.state.pageSize}
                                    showSizeChanger={false}
                                    showQuickJumper={true}
                                    onChange={async (page) => {
                                        await this.setState({
                                            featuredPage: page,
                                        });
                                        await this.props.fetchFeaturedProducts(
                                            (this.state.featuredPage - 1) *
                                                this.state.pageSize,
                                            this.state.pageSize
                                        );
                                    }}
                                />
                            )}
                            {this.state.pathname === "/new" && (
                                <Pagination
                                    defaultCurrent={1}
                                    total={this.props.totalNewProducts}
                                    pageSize={this.state.pageSize}
                                    showSizeChanger={false}
                                    showQuickJumper={true}
                                    onChange={async (page) => {
                                        await this.setState({
                                            newPage: page,
                                        });
                                        await this.props.fetchNewProducts(
                                            (this.state.newPage - 1) *
                                                this.state.pageSize,
                                            this.state.pageSize
                                        );
                                    }}
                                />
                            )}
                            {this.state.pathname === "/picks" && (
                                <Pagination
                                    defaultCurrent={1}
                                    total={this.props.totalPickedProducts}
                                    pageSize={this.state.pageSize}
                                    showSizeChanger={false}
                                    showQuickJumper={true}
                                    onChange={async (page) => {
                                        await this.setState({
                                            picksPage: page,
                                        });
                                        await this.props.fetchPickedProducts(
                                            (this.state.picksPage - 1) *
                                                this.state.pageSize,
                                            this.state.pageSize
                                        );
                                    }}
                                />
                            )}
                        </PBox>
                    </PBox>
                </PContainer>
            </Layout>
        );
    }
}

const styles = {
    "& .filters": {
        marginTop: "40px",
        marginBottom: "40px",
        minWidth: "200px",
        marginRight: "20px",
    },
    "& .search-results-pagination": {
        display: "flex",
        justifyContent: "center",
        marginTop: "40px",
        marginBottom: "80px",
    },
    "& .search-results-wrapper": {
        marginTop: "40px",
        flexGrow: "1",
        "& h4": {
            fontWeight: "500",
        },
        "& h3": {},
    },
    "& .search-results-no-results": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "$colors$text",
    },
    "& .products-title": {
        textAlign: "center",
        fontFamily: "Poppins, sans-serif",
        fontSize: "2.5rem",
        fontWeight: "500",
        lineHeight: "2.5rem",
        paddingTop: "1.25rem",
        marginTop: "3rem",
        marginBottom: "3rem",
    },
};

function mapStateToProps(state) {
    return {
        featuredProducts:
            state.productReducer?.fetchFeaturedProducts?.productList,
        totalFeaturedProducts:
            state.productReducer?.fetchFeaturedProducts?.totalProducts,
        newProducts: state.productReducer?.fetchNewProducts?.productList,
        totalNewProducts: state.productReducer?.fetchNewProducts?.totalProducts,
        pickedProducts: state.productReducer?.fetchPickedProducts?.productList,
        totalPickedProducts:
            state.productReducer?.fetchPickedProducts?.totalProducts,
    };
}

export default connect(mapStateToProps, {
    fetchFeaturedProducts,
    fetchNewProducts,
    fetchPickedProducts,
})(withRouter(MoreProducts));

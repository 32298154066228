import { PBox, PH1, PLi, PText, PUl } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const SellerCodeOfConduct = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>Seller Code of Conduct</PH1>
                <PText>
                    <i>Last Updated: November 14, 2023</i>
                </PText>
                <PText>
                    The Seller Code of Conduct provides a foundation for a fair
                    marketplace for both sellers and buyers. All sellers are
                    expected to adhere to the following policies when listing
                    products on Phair, manage their business on Phair honestly,
                    and comply with the Code of Conduct.
                </PText>
                <PText>All Phair sellers must:</PText>
                <PUl>
                    <PLi>
                        Provide current and accurate information to Phair and
                        our buyers at all times.
                    </PLi>
                    <PLi>
                        Act fairly and treat with respect buyers, other sellers,
                        Phair team members.
                    </PLi>
                    <PLi>
                        Not attempt to circumvent Phair sales processes, divert
                        buyers or transactions, or avoid Phair fees.
                    </PLi>
                    <PLi>
                        Not operate more than one selling account on Phair.
                    </PLi>
                    <PLi>Adhere to all Phair policies.</PLi>
                </PUl>
                <PText>
                    Seller offenses and policy violations can result in actions
                    against your account, including but not limited to
                    cancellation of listings, suspension of payments, removal of
                    selling privileges, and suspension of your Phair account.
                </PText>
            </PBox>
            <HelpNav
                prev={"Privacy Policy"}
                prev_link={"/help/privacy-policy"}
                next={"Anti-Discrimination Policy"}
                next_link={"/help/anti-discrimination"}
            />
        </Layout>
    );
};

import { PBox, PH1, PImg, PText, PFlowButton } from "../../theme/BaseTheme";

export const Finish = (props) => {
    return (
        <PBox css={styles}>
            <PBox className="finish-content">
                <PH1>You Are All Set</PH1>
                <PText>Click Finish to view your store dashboard.</PText>
                <PBox className="finish-image">
                    <PImg src={"/images/store.svg"} width="240px" />
                </PBox>
                <PText className="finish-terms">
                    * By clicking Finish, you acknowledge you have read and
                    agree to our{" "}
                    <a href="/terms" target="_blank">
                        Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a href="/privacy" target="_blank">
                        Privacy Policy
                    </a>
                    .
                </PText>
            </PBox>
            <PFlowButton
                type="primary"
                className="finish-prev-button"
                onClick={() => props.prev()}
            >
                Prev
            </PFlowButton>
            <PFlowButton
                type="primary"
                className="finish-next-button"
                onClick={() => props.finish()}
            >
                Finish
            </PFlowButton>
        </PBox>
    );
};

const styles = {
    "& .finish-image": {
        marginTop: "60px",
    },
    "& .finish-terms": {
        marginTop: "60px",
        fontSize: "12px",
        "& a": { textDecoration: "none" },
    },
    "& .finish-content": {
        minHeight: "480px",
    },
    "& .finish-prev-button": {
        cursor: "pointer",
        marginRight: "20px",
        backgroundColor: "$colors$lightPrimary",
        color: "$colors$primary",
        borderColor: "$colors$lightPrimary",
        "&:hover": {
            borderColor: "$colors$Primary",
            backgroundColor: "#fff",
        },
    },
    "& .finish-next-button": {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderColor: "$colors$primary",
        "&:hover": {
            backgroundColor: "#fff",
            color: "$colors$primary",
        },
    },
};

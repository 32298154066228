import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Button, Form, Checkbox } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Loader } from "../../components/Loader";
import {
    PH2,
    PLink,
    PText,
    PBox,
    PHighlightButton,
    PImg,
    PHighlightLink,
} from "../../theme/BaseTheme";
import { userSignIn } from "../../../actions/auth";
import { keys } from "../../../config/keys";

class CheckoutSignIn extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    onFinish = (values) => {
        console.log("Success:", values);
        values["redirect"] = `/checkout/${this.props.product_uuid}`;
        this.props.userSignIn(values, this.props.history).then(() => {});
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles} className="sign-in">
                <PBox className="sign-in-title">
                    <PH2>Sign In to Checkout</PH2>
                </PBox>
                <Form
                    name="basic"
                    style={{
                        width: "100%",
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: "email",
                                message: "${label} is not a valid email.",
                            },
                            {
                                required: true,
                                message: "Please enter your email.",
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder="Email"
                            prefix={<MailOutlined />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password.",
                            },
                        ]}
                    >
                        <Input.Password
                            size="large"
                            placeholder="Password"
                            prefix={<LockOutlined />}
                        />
                    </Form.Item>

                    <PBox
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                        className="remember-forgot-pwd-form"
                    >
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox className="remember-me">
                                Stay signed in for 7 days
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <PLink href="/forgot-password">
                                Forgot your password?
                            </PLink>
                        </Form.Item>
                    </PBox>
                    <Form.Item>
                        <PHighlightButton
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className="sign-in-button"
                        >
                            Sign In & Checkout
                        </PHighlightButton>
                        <PHighlightButton
                            className="sign-in-button"
                            onClick={() => {
                                window.open("/sign-up");
                            }}
                        >
                            Create an Account
                        </PHighlightButton>
                    </Form.Item>
                </Form>
                <PText>- Or continue with -</PText>
                <PBox className="sign-in-button-group">
                    <a href="/api/auths/google">
                        <PBox className="sign-in-oauth-button">
                            <PImg src={keys.imageBaseUrl + "google-logo.png"} />
                            Continue with Google
                        </PBox>
                    </a>
                    <PLink
                        className="sign-in-oauth-button"
                        href={`/checkout/${this.props.product_uuid}`}
                    >
                        Checkout as Guest
                    </PLink>
                </PBox>
            </PBox>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    backgroundColor: "#fff",
    padding: "4px 24px 16px 24px",
    borderRadius: "12px",
    "& h2": {
        color: "#1b1b1b",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "#1b1b1b",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "#5344a9",
        "&:hover": {
            color: "#000",
        },
    },
    "& .sign-in-button": {
        color: "#fff",
        backgroundColor: "#5344a9",
        width: "100%",
        borderColor: "#5344a9",
        marginTop: "10px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#5344a9",
        },
    },
    "& .sign-in-title": {
        marginBottom: "10px",
    },
    "& .sign-in-button-group": {
        margin: "20px auto",
        width: "100%",
        "& a": {
            textDecoration: "none",
        },
    },
    "& .sign-in-oauth-button": {
        textAlign: "center",
        padding: "6px 0",
        borderRadius: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #e6e6e6",
        width: "100%",
        textDecoration: "none",
        color: "#1b1b1b",
        marginBottom: "10px",
        "& img": {
            width: "24px",
            height: "24px",
            marginRight: "10px",
        },
        "& svg": {
            fill: "#e1665d",
        },
        "&:hover": {
            backgroundColor: "rgb(238, 236, 255)",
            borderColor: "rgb(238, 236, 255)",
            "& svg": {
                fill: "#fff",
            },
        },
    },
    "& .remember-forgot-pwd-form": {
        "& .ant-form-item": {
            marginBottom: "10px",
        },
    },
    "& .agree-terms": {
        fontSize: "14px",
        marginBottom: "20px",
        "& a": { textDecoration: "none" },
    },
};

function mapStateToProps(state) {}

export default connect(mapStateToProps, { userSignIn })(
    withRouter(CheckoutSignIn)
);

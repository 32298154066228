import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const SalesTax = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How does Phair handle sales tax?</PH1>
                <PText>
                    Phair collects the sales tax on transactions and submits the
                    amount collected to the appropriate entities. After the end
                    of the year, we will provide you a summary of the taxes paid
                    based on your transactions.
                </PText>
                <PText>
                    <i>
                        Disclaimer: This documentation is neither legal advice
                        nor tax advice. Phair recommends that you speak to your
                        tax advisor with any questions or concerns regarding tax
                        reporting.
                    </i>
                </PText>
            </PBox>
            <HelpNav
                prev={"Payment methods"}
                prev_link={"/help/payment-methods"}
                next={"Customization"}
                next_link={"/help/customization"}
            />
        </Layout>
    );
};

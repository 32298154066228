import { Fragment, useState } from "react";
import { PBox, PImg, PInput, PLabel, PLi, PUl } from "../../theme/BaseTheme";

export const Slider = (props) => {
    const [selectedItem, setSelectedItem] = useState(0);
    const prevItem = () => {
        if (selectedItem !== 0) {
            setSelectedItem(selectedItem - 1);
        } else {
            setSelectedItem(props.images.length - 1);
        }
    };
    const nextItem = () => {
        if (selectedItem !== props.images.length - 1) {
            setSelectedItem(selectedItem + 1);
        } else {
            setSelectedItem(0);
        }
    };
    return (
        <PBox css={styles} className="slider">
            <PBox className="slider-wrapper">
                <PUl className="slider-image-group">
                    {props.images.map((item, idx) => {
                        return (
                            <PLi>
                                {idx === selectedItem && (
                                    <PImg
                                        className="slider-image"
                                        src={item?.url}
                                    />
                                )}
                            </PLi>
                        );
                    })}
                </PUl>
                <PBox className="slider-radio-group">
                    {props.images.map((item, idx) => {
                        return (
                            <Fragment>
                                <PInput
                                    className="slider-radio"
                                    id={"radio" + idx}
                                    type="radio"
                                    checked={idx === selectedItem}
                                    onClick={() => {
                                        setSelectedItem(idx);
                                    }}
                                />
                                <PLabel for={"radio" + idx} />
                            </Fragment>
                        );
                    })}
                </PBox>
                <PBox className="slider-prev" onClick={prevItem}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 256 256"
                    >
                        <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
                    </svg>
                </PBox>
                <PBox className="slider-next" onClick={nextItem}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 256 256"
                    >
                        <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                    </svg>
                </PBox>
            </PBox>
        </PBox>
    );
};

const styles = {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px",
    "& .slider-wrapper": {
        position: "relative",
        height: "600px",
        "& .slider-radio-group": {
            position: "absolute",
            bottom: "0",
            left: "50%",
            transform: "translate(-50%, -50%)",
            "& input": {
                cursor: "pointer",
                display: "none",
            },
            "& label": {
                cursor: "pointer",
                padding: "2px 7px",
                margin: "4px",
                fontSize: "9px",
                borderRadius: "50%",
                backgroundColor: "$colors$lightPrimary",
            },
            "& input:checked+label": {
                backgroundColor: "$colors$primary",
            },
        },
        "& .slider-image-group": {
            listStyle: "none",
            transition: "left .8s cubic-bezier(0.77, 0, 0.175, 1)",
        },
        "& .slider-image": {
            maxWidth: "100%",
            maxHeight: "600px",
            display: "block",
            margin: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "16px",
            border: "1px solid $colors$borderColor",
        },
        "& .slider-prev": {
            cursor: "pointer",
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translate(0, -50%)",
            padding: "10px",
            borderRadius: "40px",
            backgroundColor: "$colors$lightBackground",
            "& svg": {
                margin: "0",
                verticalAlign: "top",
                fill: "$colors$primary",
            },
            "&:hover": {
                backgroundColor: "$colors$primary",
                "& svg": {
                    fill: "#fff",
                },
            },
        },
        "& .slider-next": {
            cursor: "pointer",
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translate(0, -50%)",
            padding: "10px",
            borderRadius: "40px",
            backgroundColor: "$colors$lightBackground",
            "& svg": {
                margin: "0",
                verticalAlign: "top",
                fill: "$colors$primary",
            },
            "&:hover": {
                backgroundColor: "$colors$primary",
                "& svg": {
                    fill: "#fff",
                },
            },
        },
    },
};

import { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    PBox,
    PContainer,
    PSection,
    PHighlightLink,
    PLink,
} from "../theme/BaseTheme";
import { Badge, Dropdown } from "antd";
import { keyframes } from "@stitches/react";
import Sticky from "react-sticky-el";
import { userSignOut } from "../../actions/auth";

class DashboardHeader extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {}

    render() {
        return (
            <PSection css={styles} className="dashboard-header">
                <Sticky>
                    <PBox className="header">
                        <PBox
                            className="dashboard-header-handler"
                            onClick={() =>
                                this.props.setShowSidebar(
                                    !this.props.showSidebar
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 256 256"
                            >
                                <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path>
                            </svg>
                        </PBox>
                        <PBox className="search-bar"></PBox>
                        {this.props.userAuth?.isLoggedIn && (
                            <PBox className="dashboard-header-account">
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                label: (
                                                    <a
                                                        href={`/buyer/${this.props.userAuth?.userAccount?.uuid}/order-history`}
                                                    >
                                                        My Orders
                                                    </a>
                                                ),
                                                key: "orders",
                                            },
                                            {
                                                label: (
                                                    <a
                                                        href={`/buyer/${this.props.userAuth?.userAccount?.uuid}/profile`}
                                                    >
                                                        Profile
                                                    </a>
                                                ),
                                                key: "profile",
                                            },
                                            { type: "divider" },
                                            {
                                                label: (
                                                    <PBox
                                                        onClick={() => {
                                                            this.props
                                                                .userSignOut()
                                                                .then(() => {
                                                                    this.props.history.push(
                                                                        "/sign-out"
                                                                    );
                                                                });
                                                        }}
                                                    >
                                                        Sign Out
                                                    </PBox>
                                                ),
                                                key: "signout",
                                            },
                                        ],
                                    }}
                                    trigger={["click"]}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path>
                                    </svg>
                                </Dropdown>
                            </PBox>
                        )}
                        <PBox className="dashboard-header-help">
                            <PLink href="/help">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="28"
                                    height="28"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M224,48H160a40,40,0,0,0-32,16A40,40,0,0,0,96,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H96a24,24,0,0,1,24,24,8,8,0,0,0,16,0,24,24,0,0,1,24-24h64a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM96,192H32V64H96a24,24,0,0,1,24,24V200A39.81,39.81,0,0,0,96,192Zm128,0H160a39.81,39.81,0,0,0-24,8V88a24,24,0,0,1,24-24h64Z"></path>
                                </svg>
                            </PLink>
                        </PBox>
                        <PHighlightLink
                            className="dashboard-header-new-product"
                            href={`/seller/${this.props.match.params.shop_uuid}/create`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 256 256"
                            >
                                <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
                            </svg>
                        </PHighlightLink>
                    </PBox>
                </Sticky>
            </PSection>
        );
    }
}
const rainbow = keyframes({
    "0%": { backgroundPosition: "left" },
    "50%": { backgroundPosition: "right" },
    "100%": {
        backgroundPosition: "left",
    },
});

const styles = {
    zIndex: "100",
    "& .header": {
        backgroundColor: "#fff",
        paddingTop: "10px",
        paddingBottom: "10px",
        borderBottom: "1px solid $colors$borderColor",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "30px",
        paddingRight: "30px",
    },
    "& .dashboard-header-handler": {
        transition: "ease-in-out 0.3s",
        margin: "0",
        background: "#fff",
        zIndex: "10",
        "& svg": {
            fill: "$colors$text",
            display: "block",
        },
        "&:hover": {
            cursor: "pointer",
        },
        "& svg:hover": {
            fill: "$colors$link",
        },
    },
    "& .search-bar": {
        display: "flex",
        flexGrow: "1",
        marginLeft: "40px",
        marginRight: "40px",
        "& input": {
            width: "100%",
            height: "40px",
            border: "1px solid $colors$borderColor",
            borderTopLeftRadius: "24px",
            borderBottomLeftRadius: "24px",
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
            borderRight: "0",
            outline: "none",
            paddingLeft: "20px",
            transition: "all 0.2s ease",
            "&:focus": {
                border: "2px solid black",
                "& + button": {
                    backgroundColor: "black",
                    borderColor: "black",
                    "& svg": { fill: "white" },
                },
            },
        },
        "& button": {
            border: "1px solid $colors$borderColor",
            backgroundColor: "#fff",
            borderLeft: "0",
            borderTopRightRadius: "24px",
            borderBottomRightRadius: "24px",
            paddingRight: "12px",
            paddingLeft: "12px",
            transition: "all 0.2s ease",
            "& svg": { fill: "$colors$borderColor" },
            "&:hover": { cursor: "pointer" },
        },
    },
    "& .sign-in": {
        marginRight: "10px",
        textDecoration: "none",
        backgroundColor: "$colors$secondary",
    },
    "& .dashboard-header-new-product": {
        marginRight: "20px",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        color: "#fff",
        fontWeight: "500",
        borderColor: "#fff",
        transition: "all 0.3s ease",
        padding: "6px 6px",
        background: "linear-gradient(to right, #5344a9,#bb5098)",
        backgroundSize: "200% 200%",
        "& svg": {
            fill: "#fff",
            transition: "all 0.3s ease",
        },
        "&:hover": {
            transform: "scale(1.2)",
            transition: "all 0.3s ease",
            animation: `${rainbow} 2s ease infinite`,
            animationTimingFunction: "linear",
            "& svg": {},
        },
    },
    "& .shopping-cart": {
        cursor: "pointer",
        margin: "0 6px",
        "& svg:hover": { fill: "$colors$text" },
    },
    "& .shop-dashboard": {
        cursor: "pointer",
        margin: "0 6px",
        "& svg:hover": { fill: "$colors$text" },
    },
    "& .dashboard-header-account": {
        cursor: "pointer",
        margin: "0 12px 0 0",
        "& svg": { display: "block", fill: "$colors$text" },
        "& svg:hover": {
            fill: "$colors$link",
            marginBottom: "0",
        },
    },
    "& .dashboard-header-help": {
        cursor: "pointer",
        margin: "0 12px 0 0",
        "& svg": { display: "block", fill: "$colors$text" },
        "& svg:hover": {
            fill: "$colors$link",
            marginBottom: "0",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAuth: state.authReducer?.userAuth,
        saveProducts: state.saveReducer?.manageSave?.saveProducts,
    };
}

export default connect(mapStateToProps, { userSignOut })(
    withRouter(DashboardHeader)
);

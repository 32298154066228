import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Button, Input, message, Popconfirm, Space, Table, Tag } from "antd";
import { PBox, PLink } from "../../theme/BaseTheme";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteProduct } from "../../../actions/product";

const EditableProducts = (props) => {
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
            if (!record[dataIndex]) return;
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: "Product",
            dataIndex: "product_name",
            key: "product",
            ...getColumnSearchProps("product"),
            render: (_, record) => {
                return (
                    <PBox className="editable-products-name">
                        {record.status === "public" && (
                            <a
                                href={`/item/${record.uuid}`}
                                target="_blank"
                                style={{
                                    textDecoration: "none",
                                    color: "#000",
                                }}
                            >
                                {record.product_name}
                            </a>
                        )}
                        {record.status !== "public" && (
                            <p>{record.product_name}</p>
                        )}
                    </PBox>
                );
            },
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            width: "6%",
            ...getColumnSearchProps("price"),
            sorter: (a, b) => a.price - b.price,
            sortDirections: ["descend", "ascend"],
            render: (_, { price }) => {
                return <>${price}</>;
            },
        },
        {
            title: "Rating",
            dataIndex: "average_rating",
            key: "rating",
            width: "6%",
            ...getColumnSearchProps("average_rating"),
            sorter: (a, b) => a.average_rating - b.average_rating,
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Orders",
            dataIndex: "number_of_orders",
            key: "orders",
            width: "6%",
            ...getColumnSearchProps("number_of_orders"),
            sorter: (a, b) => a.number_of_orders - b.number_of_orders,
            sortDirections: ["descend", "ascend"],
            render: (_, { number_of_orders }) => {
                return (
                    <>{number_of_orders ? <>{number_of_orders}</> : <>0</>}</>
                );
            },
        },
        {
            title: "Revenue",
            dataIndex: "revenue",
            key: "revenue",
            width: "6%",
            ...getColumnSearchProps("revenue"),
            sorter: (a, b) => a.revenue - b.revenue,
            sortDirections: ["descend", "ascend"],
            render: (_, { revenue }) => {
                return <>{revenue ? <>${revenue}</> : <>$ 0</>}</>;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: "6%",
            ...getColumnSearchProps("status"),
        },
        {
            title: "Category",
            dataIndex: "category_name",
            key: "category",
            width: "6%",
            ...getColumnSearchProps("category_name"),
        },
        /*{
            
            title: "Feedback",
            dataIndex: "approval_feedback",
            key: "feedback",
            width: "5%",
            render: (_, { approval_feedback }) => {
                return <>{approval_feedback}</>;
            },
        
        },*/
        {
            title: "Tags",
            dataIndex: "tags",
            key: "tags",
            width: "10%",
            render: (_, { tags }) => (
                <>
                    {tags?.map((tag) => {
                        let color =
                            tag.tag_name.length > 5 ? "geekblue" : "green";
                        return (
                            <Tag color={color} key={tag.tag_name}>
                                {tag.tag_name.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        {record.status === "public" && (
                            <PLink
                                href={`/item/${record.uuid}`}
                                target="_blank"
                                className="editable-products-button"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path>
                                </svg>
                            </PLink>
                        )}
                        <Link
                            to={`/seller/${props.match.params.shop_uuid}/item/${record.uuid}/edit`}
                            className="editable-products-button"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path>
                            </svg>
                        </Link>
                        <PBox className="editable-products-button">
                            <Popconfirm
                                title="Delete the product"
                                description="Are you sure to delete this product? This action cannot be undone."
                                onConfirm={() => {
                                    props
                                        .deleteProduct(
                                            props.match.params.shop_uuid,
                                            record.uuid
                                        )
                                        .then(() => {
                                            window.location.reload(true);
                                        });
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                                </svg>
                            </Popconfirm>
                        </PBox>
                    </Space>
                );
            },
        },
    ];
    return (
        <PBox css={styles}>
            <Table
                columns={columns}
                dataSource={props.data}
                scroll={{ x: "1000px" }}
                pagination={false}
            />
        </PBox>
    );
};

const styles = {
    margin: "40px 0",
    "& .editable-products-button": {
        cursor: "pointer",
        "& svg": {
            fill: "$colors$text",
            "&:hover": { fill: "$colors$link" },
        },
    },
    "& .editable-products-name": {
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": "3",
    },
};

export default connect(null, { deleteProduct })(withRouter(EditableProducts));

import { combineReducers } from "redux";

const fetchRecentOrderList = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_RECENT_ORDER_LIST_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                orderList: action.payload,
            };
        case "FETCH_RECENT_ORDER_LIST_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                orderList: action.payload,
            };
        default:
            return state;
    }
};

const fetchOrderHistoryList = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_ORDER_HISTORY_LIST_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                orderList: action.payload,
            };
        case "FETCH_ORDER_HISTORY_LIST_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                orderList: action.payload,
            };
        default:
            return state;
    }
};

const fetchOrderListDetailsByMerchant = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_ORDER_LIST_DETAILS_BY_MERCHANT_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                orderList: action.payload,
            };
        case "FETCH_ORDER_LIST_DETAILS_BY_MERCHANT_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                orderList: action.payload,
            };
        default:
            return state;
    }
};

const fetchOrdersByCategory = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_ORDER_BY_CAT_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                orderCounts: action.payload,
            };
        case "FETCH_ORDER_BY_CAT_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                orderCounts: action.payload,
            };
        default:
            return state;
    }
};

const fetchMonthlyEarnings = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_MONTHLY_EARNINGS_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                monthlyEarnings: action.payload,
            };
        case "FETCH_MONTHLY_EARNINGS_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                monthlyEarnings: action.payload,
            };
        default:
            return state;
    }
};

const fetchOrderDetail = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_ORDER_DETAIL_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                order: action.payload,
            };
        case "FETCH_ORDER_DETAIL_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                order: action.payload,
            };
        default:
            return state;
    }
};

const fetchTransactionRecord = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_TRANSACTION_RECORD_SUCCESS":
            state[action.key] = {
                isLoading: false,
                isError: false,
                isSuccess: true,

                order: action.payload.order,
                merchant: action.payload.merchant,
            };
        case "FETCH_TRANSACTION_RECORD_FAIL":
            state[action.key] = {
                isLoading: false,
                isError: true,
                isSuccess: false,
                order: action.payload,
            };
        default:
            return state;
    }
};

const fetchOrderByPaymentIntent = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_ORDER_PAYMENT_SUCCEED_SUCCESS":
            state[action.key] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                order: action.payload,
            };
        case "FETCH_ORDER_PAYMENT_SUCCEED_FAIL":
            state[action.key] = {
                isLoading: false,
                isError: true,
                isSuccess: false,
                order: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({
    fetchRecentOrderList,
    fetchOrdersByCategory,
    fetchMonthlyEarnings,
    fetchOrderDetail,
    fetchOrderByPaymentIntent,
    fetchOrderListDetailsByMerchant,
    fetchOrderHistoryList,
    fetchTransactionRecord,
});

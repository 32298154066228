import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Button, Form, Checkbox } from "antd";
import {
    PH2,
    PLink,
    PText,
    PBox,
    PHighlightButton,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { LockOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { userResetPassword, confirmResetPwdToken } from "../../../actions/auth";
import { Loader } from "../../components/Loader";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
    }

    componentDidMount() {
        this.props
            .confirmResetPwdToken(
                this.props.match.params.token,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    onFinish = (values) => {
        console.log("Success:", values);
        this.props.userResetPassword(
            this.props.userEmail?.email,
            values,
            this.props.history
        );
    };
    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <AuthenticationLayout>
                <PBox css={styles}>
                    <PBox className="reset-password-title">
                        <PH2>Reset Password</PH2>
                    </PBox>
                    <PBox className="reset-password">
                        <Form
                            name="basic"
                            labelCol={{
                                span: 0,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: "100%",
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="new_password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password.",
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    size="large"
                                    placeholder="New Password"
                                    prefix={<LockOutlined />}
                                />
                            </Form.Item>

                            <Form.Item
                                name="confirm_password"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please confirm your password.",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue(
                                                    "new_password"
                                                ) === value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "The new password that you entered do not match."
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    placeholder="Confirm Password"
                                    prefix={<CheckCircleOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    span: 24,
                                }}
                            >
                                <PHighlightButton
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    className="reset-password-reset-button"
                                >
                                    Reset
                                </PHighlightButton>
                            </Form.Item>
                        </Form>
                    </PBox>
                    <PBox className="sign-in-link">
                        <PText>
                            Remember it? <PLink href="/sign-in">Sign In</PLink>
                        </PText>
                    </PBox>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .reset-password": {
        width: "400px",
        marginTop: "2rem",
        backgroundColor: "#fff",
        padding: "3rem 2rem 2rem 2rem",
        borderRadius: "0.5rem",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    },
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "#000",
        },
    },
    "& .reset-password-title": {
        fontSize: "18px",
        marginTop: "2rem",
    },
    "& .reset-password-reset-button": {
        color: "#fff",
        backgroundColor: "$colors$primary700",
        width: "100%",
        borderColor: "$colors$primary700",
        marginTop: "16px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "$colors$primary500",
            borderColor: "$colors$primary500",
        },
    },
    "& .sign-in-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};

function mapStateToProps(state) {
    return {
        userEmail: state.authReducer?.verifyToken?.userEmail,
    };
}
export default connect(mapStateToProps, {
    userResetPassword,
    confirmResetPwdToken,
})(withRouter(ResetPassword));

import { PBox, PH1, PLi, PText, PUl } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const ProhibitedProducts = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>What products are prohibited to sell on Phair?</PH1>
                <PText>
                    The following items are prohibited or restricted on Phair.
                    Phair sellers will adhere to the following guidelines. We
                    will update, change or expand this list as we deem
                    necessary.
                </PText>
                <PText>
                    We do not sell products with content that is determined to
                    be:
                </PText>
                <PUl>
                    <PLi>explicit or adult language</PLi>
                    <PLi>promotes violence or self-harm</PLi>
                    <PLi>
                        hate speech, and anything that promotes or condones
                        intolerance based on race, religion, sexual orientation
                        or gender
                    </PLi>
                    <PLi>promotes illicit substances</PLi>
                    <PLi>promotes abuse or sexual exploitation of children</PLi>
                    <PLi>contains pornography</PLi>
                    <PLi>glorifies rape or pedophilia</PLi>
                    <PLi>advocates terrorism</PLi>
                    <PLi>
                        any other content that we feel is inappropriate,
                        harmful, or offensive to our buyers
                    </PLi>
                </PUl>
            </PBox>
            <HelpNav
                prev={"Ownership and Rights"}
                prev_link={"/help/ownership-and-rights"}
            />
        </Layout>
    );
};

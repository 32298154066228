import { combineReducers } from "redux";

const stripeConnectAccountSecret = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_CLIENT_SECRET_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                clientSecret: action.payload,
            };
        default:
            return state;
    }
};

const stripeCheckOnboarded = (state = {}, action) => {
    switch (action.type) {
        case "STRIPE_CHECK_ONBOARDED_SUCCESS":
            return {
                onboarded: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({
    stripeConnectAccountSecret,
    stripeCheckOnboarded,
});

import { PBox, PH1, PLi, PText, PUl } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const AntiDiscrimination = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>Anti-Discrimination Policy</PH1>
                <PText>
                    <i>Last Updated: November 14, 2023</i>
                </PText>
                <PText>
                    Phair is where people of all backgrounds sell and buy
                    digital goods. We want everyone on Phair to feel safe, and
                    our priority is providing an inclusive environment. This
                    policy explains the kind of behavior we prohibit on Phair.
                </PText>
                <PText>
                    This policy is a part of our Seller Agreement. By using
                    Phair, you are agreeing to this policy and our Seller
                    Agreement.
                </PText>
                <PText>
                    Phair prohibits the use of our services to discriminate
                    against people based on their membership in legally
                    protected groups or based on legally protected
                    characteristics, including, but not limited to:
                </PText>
                <PUl>
                    <PLi>
                        Race, including traits historically associated with
                        race, including without limitation hair texture and
                        styles
                    </PLi>
                    <PLi>Age</PLi>
                    <PLi>Color</PLi>
                    <PLi>Ethnicity</PLi>
                    <PLi>National origin</PLi>
                    <PLi>Ancestry</PLi>
                    <PLi>Religious creed</PLi>
                    <PLi>Sex</PLi>
                    <PLi>Gender identity and expression</PLi>
                    <PLi>Sexual orientation</PLi>
                    <PLi>Physical Disability</PLi>
                    <PLi>Mental Disability</PLi>
                    <PLi>Medical Condition</PLi>
                    <PLi>Military or Veteran Status</PLi>
                    <PLi>
                        Any other legally protected class or characteristic
                    </PLi>
                </PUl>
                <PText>
                    Additionally, Phair does not allow hate speech. Hate speech
                    occurs when violent, offensive, derogatory, or demeaning
                    language is directed at a person or group based on their
                    membership in one or more protected classes.
                </PText>
                <PText>
                    Whether you're engaging with public features on Phair, such
                    as listing items, and writing reviews, or having direct
                    communication with other members of the Phair community,
                    discrimination and hate speech are strictly prohibited. As a
                    seller on Phair, your store content, including announcements
                    and policies, cannot display discriminatory behavior toward
                    protected classes. Examples of prohibited behavior include,
                    but are not limited to:
                </PText>
                <PUl>
                    <PLi>
                        Refusal of service to someone based on their membership
                        in one or more legally protected classes
                    </PLi>
                    <PLi>
                        Expressing intolerance or a lack of respect for another
                        member based on one or more legally protected
                        characteristics
                    </PLi>
                    <PLi>
                        Having a store policy that excludes sales to members of
                        one or more legally protected classes
                    </PLi>
                    <PLi>
                        Directly or indirectly making derogatory or demeaning
                        remarks about legally protected classes
                    </PLi>
                    <PLi>Using racial slurs</PLi>
                    <PLi>
                        Posts that support or glorify hate groups and their
                        members
                    </PLi>
                </PUl>
                <PText>
                    Activity and communications that do not adhere to this
                    policy may result in:
                </PText>
                <PUl>
                    <PLi>Ending or canceling listings;</PLi>
                    <PLi>
                        Hiding or demoting all listings from search results;
                    </PLi>
                    <PLi>
                        Lowering seller rating, buying or selling restrictions;
                        and/or
                    </PLi>
                    <PLi>Account suspension.</PLi>
                </PUl>
                <PText>
                    All fees paid or payable in relation to listings or accounts
                    on which we take any action will not be refunded or
                    otherwise credited to your account.
                </PText>
            </PBox>
            <HelpNav
                prev={"Seller Code of Conduct"}
                prev_link={"/help/seller-code-of-conduct"}
                next={"US Only"}
                next_link={"/help/us-only"}
            />
        </Layout>
    );
};

import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PH1, PH2, PBox, PLink } from "../../theme/BaseTheme";
import { fetchSitemap } from "../../../actions/product";

class Sitemap extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.props.fetchSitemap().then(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles}>
                    <PH1 className="sitemap-title">Sitemap</PH1>
                    <PH2>Shops</PH2>
                    {this.props.merchants?.map((merchant) => {
                        return (
                            <PBox className="sitemap-link">
                                <PLink href={`/shop/${merchant.uuid}/1`}>
                                    {merchant.shop_name}
                                </PLink>
                            </PBox>
                        );
                    })}
                    <PH2>Products</PH2>
                    {this.props.products?.map((product) => {
                        return (
                            <PBox className="sitemap-link">
                                <PLink href={`/item/${product.uuid}`}>
                                    {product.product_name}
                                </PLink>
                            </PBox>
                        );
                    })}
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    marginBottom: "2.5rem",
    maxWidth: "80rem",

    marginLeft: "auto",
    marginRight: "auto",

    padding: "2rem",
    "& .sitemap-title": {
        fontSize: "2rem",
        fontWeight: "700",
        lineHeight: "2rem",
        color: "rgb(17 24 39)",
        margin: "2rem 0",
        textAlign: "center",
    },
    "& h2": {
        padding: "0.25rem 1rem",
    },
    "& .sitemap-link": {
        padding: "0.25rem 1rem",

        "& a": {
            textDecoration: "none",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: "rgb(17 24 39)",
        },
    },
};

function mapStateToProps(state) {
    return {
        products: state.productReducer?.fetchSitemap?.products,
        merchants: state.productReducer?.fetchSitemap?.merchants,
    };
}

export default connect(mapStateToProps, { fetchSitemap })(withRouter(Sitemap));

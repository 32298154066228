import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PBox, PText } from "../../theme/BaseTheme";
import Chart from "react-apexcharts";
import { Loader } from "../Loader";
import { fetchMonthlyEarningsByMerchantID } from "../../../actions/order";
import { Empty } from "antd";

const data = {
    series: [
        {
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
    ],
    options: {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
        },
        title: {
            text: "Product Trends by Month",
            align: "left",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
        },
    },
};

class Earnings extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchMonthlyEarningsByMerchantID(this.props.match.params.shop_uuid)
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        if (
            !this.props.monthlyEarnings ||
            !Array.isArray(this.props.monthlyEarnings) ||
            this.props.monthlyEarnings.length === 0
        ) {
            return (
                <PBox
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Empty />
                </PBox>
            );
        }

        const data = {
            series: [
                {
                    data: this.props.monthlyEarnings?.map(
                        (earnings) => earnings?.earnings_by_month
                    ),
                    //data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                },
            ],
            options: {
                chart: {
                    height: 300,
                    width: "100%",
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                },
                markers: { size: 4 },
                colors: ["rgb(140,104,205)"],
                title: {
                    //text: "Product Trends by Month",
                    align: "left",
                },
                grid: {
                    row: {
                        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "$" + val.toString();
                        },
                        title: {
                            formatter: function (seriesName) {
                                return "";
                            },
                        },
                    },
                },

                xaxis: {
                    categories: this.props.monthlyEarnings?.map(
                        (earnings) => earnings.year_and_month
                    ),
                },
            },
        };

        return (
            <PBox>
                <Chart
                    options={data.options}
                    series={data.series}
                    type="line"
                    height={350}
                />
            </PBox>
        );
    }
}

function mapStateToProps(state) {
    return {
        monthlyEarnings:
            state.orderReducer?.fetchMonthlyEarnings?.monthlyEarnings,
    };
}

export default connect(mapStateToProps, {
    fetchMonthlyEarningsByMerchantID,
})(withRouter(Earnings));

import AddProduct from "../../components/seller/AddProduct";
import { PBox, PH1 } from "../../theme/BaseTheme";
import { Layout } from "./Layout";

export const Create = (props) => {
    return (
        <Layout>
            <PBox css={styles} className="create">
                <PBox className="create-card">
                    <PH1>Create A New Product</PH1>

                    <AddProduct />
                </PBox>
            </PBox>
        </Layout>
    );
};

const styles = {
    margin: "0rem auto",
    maxWidth: "80rem",
    "& .create-card": {
        margin: "0rem 2rem 2rem 2rem",
        backgroundColor: "#fff",
        padding: "2rem 2rem",
        "& h1": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            fontWeight: "700",
            color: "rgb(17 24 39)",
        },
    },
};

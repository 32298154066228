import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Empty, Timeline } from "antd";
import { PBox } from "../../theme/BaseTheme";
import { fetchRecentOrderListByMerchantID } from "../../../actions/order";
import { Loader } from "../Loader";

class RecentOrders extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.props
            .fetchRecentOrderListByMerchantID(this.props.match.params.shop_uuid)
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        if (
            !this.props.orderList ||
            !Array.isArray(this.props.orderList) ||
            this.props.orderList?.length === 0
        ) {
            return (
                <PBox
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Empty />
                </PBox>
            );
        }
        return (
            <PBox css={styles}>
                <Timeline
                    items={this.props.orderList.map((order) => {
                        var sum = 0;
                        for (var i = 0; i < order.order_items?.length; i++) {
                            sum += order?.order_items[i]?.transfer_amount;
                        }
                        return {
                            color: "rgb(64, 187, 130)",
                            /*dot: (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="rgb(64,187,130,1)"
                                    viewBox="0 0 256 256"
                                    style={{
                                        backgroundColor: "rgb(64,187,130,0.2)",
                                        padding: "6px",
                                        borderRadius: "50%",
                                    }}
                                >
                                    <path d="M222.14,58.87A8,8,0,0,0,216,56H54.68L49.79,29.14A16,16,0,0,0,34.05,16H16a8,8,0,0,0,0,16h18L59.56,172.29a24,24,0,0,0,5.33,11.27,28,28,0,1,0,44.4,8.44h45.42A27.75,27.75,0,0,0,152,204a28,28,0,1,0,28-28H83.17a8,8,0,0,1-7.87-6.57L72.13,152h116a24,24,0,0,0,23.61-19.71l12.16-66.86A8,8,0,0,0,222.14,58.87ZM96,204a12,12,0,1,1-12-12A12,12,0,0,1,96,204Zm96,0a12,12,0,1,1-12-12A12,12,0,0,1,192,204Zm4-74.57A8,8,0,0,1,188.1,136H69.22L57.59,72H206.41Z"></path>
                                </svg>
                            ),*/
                            children: (
                                <>
                                    Purchase by <b>{order?.user_name}</b> on{" "}
                                    {new Date(order?.created_at).toDateString()}
                                    , <b>${Math.round(sum * 100) / 100}</b>{" "}
                                    earned.
                                    <ul>
                                        {order?.order_items?.map(
                                            (order_item) => {
                                                return (
                                                    <li className="recent-orders-item">
                                                        {
                                                            order_item?.product
                                                                ?.product_name
                                                        }
                                                        {/*{": "}
                                                    <b>
                                                        {"$"}
                                                        {
                                                            order_item.product
                                                                .price
                                                        }{" "}
                                                        x {order_item.quantity}
                                                    </b>*/}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </>
                            ),
                        };
                    })}
                />
            </PBox>
        );
    }
}

const styles = {
    "& .recent-orders-item": {
        color: "$colors$text",
    },
};

function mapStateToProps(state) {
    return {
        orderList: state.orderReducer?.fetchRecentOrderList?.orderList,
    };
}

export default connect(mapStateToProps, {
    fetchRecentOrderListByMerchantID,
})(withRouter(RecentOrders));

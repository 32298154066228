import { PBox, PH1, PText } from "../../theme/BaseTheme";
import { BlogCard } from "./BlogCard";

export const BlogSection = (props) => {
    return (
        <PBox css={styles}>
            <PBox className="blog-title">
                <PH1>{props.title}</PH1>
                <PText>{props.subtitle}</PText>
            </PBox>
            <PBox className="blog-cards-wrapper">
                {props.posts?.map((post, idx) => {
                    return (
                        <BlogCard
                            cover={post.cover}
                            categories={post.categories}
                            title={post.title}
                            link={post.link}
                            author={post.author}
                            date={post.date}
                            excerpt={post.excerpt}
                        />
                    );
                })}
            </PBox>
        </PBox>
    );
};

const styles = {
    margin: "6rem 2rem",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .blog-title": {
        "& h1": {
            margin: "0",
            fontWeight: "700",
            textAlign: "center",
            fontSize: "2.5rem",
        },
        "& p": {
            color: "rgb(75 85 99)",
            fontSize: "1.125rem",
            marginTop: "1.5rem",
            marginBottom: "6rem",
            textAlign: "center",
            lineHeight: "2rem",
        },
    },
    "& .blog-cards-wrapper": {
        display: "grid",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        columnGap: "2rem",
        rowGap: "5rem",
        "@bp2": {
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
        "@bp4": {
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        },
    },
};

import ReactQuill, { Quill } from "react-quill";
import quillEmoji from "quill-emoji";
import "../../../styles/quill.snow.css";
import "../../../styles/quill-emoji.css";
import { EditorFormats, EditorModules } from "./EditorConfigs";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register(
    {
        "formats/emoji": EmojiBlot,
        "modules/emoji-shortname": ShortNameEmoji,
        "modules/emoji-toolbar": ToolbarEmoji,
        "modules/emoji-textarea": TextAreaEmoji,
    },
    true
);

export const QuillEditor = (props) => {
    return (
        <ReactQuill
            theme="snow"
            value={props.value}
            onChange={props.onChange}
            modules={EditorModules}
            formats={EditorFormats}
        />
    );
};

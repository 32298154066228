import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PContainer, PSection, PBox } from "../../theme/BaseTheme";
import OrderSummary from "../../components/seller/OrderSummary";
import { Layout } from "./Layout";
import { createStripePaymentIntent } from "../../../actions/stripe";
import { Loader } from "../../components/Loader";
import CheckoutForm from "../../components/landing/CheckoutForm";
import { keys } from "../../../config/keys";

const stripePromise = loadStripe(keys.stripePublishableKey);

class CheckoutBilling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            clientSecret: "",
            paymentIntentID: "",
            name: "",
            cover: "",
            price: 0,
            transactionFee: 0,
            orderAmount: 0,
            estimatedTax: 0,
        };
    }

    async componentDidMount() {
        const paymentIntent = await this.props.createStripePaymentIntent(
            this.props.match.params.product_uuid,
            this.props.history
        );
        this.setState({
            isLoading: false,
            paymentIntentID: paymentIntent?.paymentIntentID,
            clientSecret: paymentIntent?.clientSecret,
            name: paymentIntent?.name,
            cover: paymentIntent?.cover,
            price: paymentIntent?.price,
            transactionFee: paymentIntent?.transactionFee,
            estimatedOrderAmount: paymentIntent?.estimatedOrderAmount,
            estimatedTax: paymentIntent?.estimatedTax,
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        const options = {
            clientSecret: this.state.clientSecret,
            theme: "flat",
        };

        return (
            <Layout>
                <PSection css={styles}>
                    <PBox className="billing-section">
                        <PContainer>
                            <PBox className="billing-section-wrapper">
                                <PBox className="billing-section-info">
                                    <Elements
                                        stripe={stripePromise}
                                        options={options}
                                    >
                                        <CheckoutForm
                                            clientSecret={
                                                this.state.clientSecret
                                            }
                                            paymentIntentID={
                                                this.state.paymentIntentID
                                            }
                                            product_uuid={
                                                this.props.match.params
                                                    .product_uuid
                                            }
                                            product_name={this.state.name}
                                            product_price={this.state.price}
                                            transaction_fee={
                                                this.state.transactionFee
                                            }
                                        />
                                    </Elements>
                                </PBox>
                                <OrderSummary
                                    showButton={false}
                                    showSummary={true}
                                    showBag={true}
                                    price={this.state.price}
                                    transactionFee={this.state.transactionFee}
                                    estimatedOrderAmount={
                                        this.state.estimatedOrderAmount
                                    }
                                    estimatedTax={this.state.estimatedTax}
                                    name={this.state.name}
                                    cover={this.state.cover}
                                />
                            </PBox>
                        </PContainer>
                    </PBox>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    "& .billing-section": {
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    "& .billing-section-wrapper": {
        display: "grid",
        gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
        "@bp1": {
            "& .billing-section-info": {
                gridColumn: "1 / 13",
            },
            "& .order-summary": {
                marginTop: "10px",
                marginBottom: "10px",
                gridColumn: "1 / 13",
            },
        },
        "@bp3": {
            "& .billing-section-info": {
                marginRight: "60px",
                gridColumn: "1 / 9",
            },
            "& .order-summary": {
                marginTop: "10px",
                marginBottom: "10px",
                gridColumn: "9 / 13",
            },
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, { createStripePaymentIntent })(
    withRouter(CheckoutBilling)
);

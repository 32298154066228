import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tabs } from "antd";
import { PBox, PH1 } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import Stripe from "../../components/seller/Stripe";

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles} className="payments">
                    <PBox className="payments-card">
                        <Stripe />
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    margin: "0rem auto",
    maxWidth: "80rem",
    "& .payments-card": {
        margin: "0rem 2rem 2rem 2rem",
        backgroundColor: "#fff",
        padding: "2rem 2rem",
        "& h1": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            fontWeight: "700",
            color: "rgb(17 24 39)",
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {})(withRouter(Payments));

import axios from "axios";
import { message } from "antd";

// For use in buyer dashboard page for account settings
export const fetchUserAccount = (user_uuid, history) => async (dispatch) => {
    await axios
        .get(`/api/accounts/${user_uuid}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_USER_ACCOUNT_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
            if (error?.response?.data?.redirect)
                history.push(error?.response?.data?.redirect);
        });
};

// For use in merchant dashboard page for account settings
export const fetchUserAccountByMerchantID =
    (shop_uuid, history) => async (dispatch) => {
        await axios
            .get(`/api/accounts/shop/${shop_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_USER_ACCOUNT_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
                if (error?.response?.data?.redirect)
                    history.push(error?.response?.data?.redirect);
            });
    };

export const editUserAccount = (data) => async (dispatch) => {
    await axios
        .put(`/api/accounts/edit/`, data)
        .then((response) => {
            message.success(response.data.message, 3);
            dispatch({
                type: "FETCH_USER_ACCOUNT_SUCCESS",
                payload: response?.data.user,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
    PH2,
} from "../theme/BaseTheme";

const SellFaqItem = (props) => {
    return (
        <PBox className="sell-faq-container">
            <PBox className="sell-faq-question">
                <PH3>{props.question}</PH3>
            </PBox>
            <PBox className="sell-faq-answer">
                <PText>{props.answer}</PText>
            </PBox>
        </PBox>
    );
};

export const SellFaq = () => {
    return (
        <PSection css={styles}>
            <PBox className="sell-faq">
                <PH2 className="sell-faq-title">Frequently Asked Questions</PH2>
                <PBox className="sell-faq-content">
                    <SellFaqItem
                        question={"How do fees work on Phair?"}
                        answer={
                            "Joining and starting a shop on Phair is free. We don't charge product listing fees on Phair. There are two types of selling fees: a platform fee and a payment processing (transaction) fee. Once an item sells, there is a 6% + $0.3 USD platform fee on the sale price collected from the seller. There is also a 2.9% + $0.3 USD payment processing (transaction) fee collected from the buyer by Stripe."
                            //"Joining and starting a shop on Etsy is free. There are three basic selling fees: a listing fee, a transaction fee, and a payment processing fee. There's also an advertising fee for sales that come from Offsite Ads. It costs $0.20 to publish a listing to the marketplace. A listing lasts for four months or until the item is sold. Once an item sells, there is a 6.5% transaction fee on the sale price (including the shipping price you set). If you accept payments through Etsy Payments, we also collect a 3% + $0.25 payment processing fee when an item is sold. Listing fees are billed for $0.20 USD, so if your bank's currency is not USD, the amount may differ based on changes in the exchange rate. The payment processing fee may also vary by bank country. You pay an advertising fee on any sales you make from Offsite Ads. When you open your Etsy shop, you can decide if you want to participate in Etsy's advertising services."
                        }
                    />
                    <SellFaqItem
                        question={"What do I need to do to create a shop?"}
                        answer={
                            "It's easy to set up a shop on Phair. Create an Phair account (if you don't already have one), choose a shop name, create a product listing, get your product approved, and connect Phair with your Stripe account (so you can get paid)."
                        }
                    />
                    <SellFaqItem
                        question={"How do I get paid?"}
                        answer={
                            "We partner with Stripe, an easy-to-use and secure payment system. You may accept payments from a variety of payment methods such as credit and debit cards, Google Wallet, and Apple Pay. Funds from your sales are deposited directly to your bank account."
                        }
                    />
                    <SellFaqItem
                        question={"What can I sell on Phair?"}
                        answer={
                            "Phair provides a marketplace for creators to sell digital products they create (at least 18 years old) online. You could sell basically anything digital on Phair (unless it's prohibited by our Terms)."
                        }
                    />
                    <SellFaqItem
                        question={"Can I participate if I am not in US?"}
                        answer={
                            "We require sellers to have a US legal entity, US bank account, and EIN/SSN. You may not offer to sell or sell on Phair outside of the U.S."
                        }
                    />
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-faq": {
        padding: "4rem 1.5rem 2rem 1.5rem",
        maxWidth: "42rem",
        margin: "0 auto",
        "@bp2": {
            padding: "6rem 1.5rem 2rem 1.5rem",
        },
        "@bp4": {
            maxWidth: "72rem",
            padding: "8rem 2rem 2rem 2rem",
        },
    },
    "& .sell-faq-title": {
        color: "rgb(17 24 39)",
        lineHeight: "2.5rem",
        fontWeight: "700",
        fontSize: "1.5rem",
    },
    "& .sell-faq-content": {
        marginTop: "2.5rem",
    },
    "& .sell-faq-container": {
        position: "relative",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        borderTop: "1px solid $colors$borderColor",
        "@bp4": {
            display: "grid",
            gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
            gap: "2rem",
        },
    },
    "& .sell-faq-question": {
        color: "rgb(17 24 39)",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        "@bp4": { gridColumn: "span 5 / span 5" },
    },
    "& .sell-faq-answer": {
        marginTop: "1rem",
        color: "rgb(75 85 99)",
        lineHeight: "1.75rem",
        fontSize: "1rem",
        "@bp4": {
            marginTop: "0px",
            gridColumn: "span 7 / span 7",
        },
    },
};

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const Customization = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How to customize my store page?</PH1>
                <PText>
                    You can customize your store page and update your public
                    profile in the <b>dashboard</b> -{">"} <b>shop</b> settings
                    page.
                </PText>
                <PText>
                    You can upload a banner image, upload a brand logo image,
                    enter a store description, enter a store announcement, and
                    upload your profile photo on this page.
                </PText>
                <PText>
                    You can update your store page and your public profile
                    anytime.
                </PText>
            </PBox>
            <HelpNav
                prev={"Sales tax"}
                prev_link={"/help/sales-tax"}
                next={"Navigate to dashboard"}
                next_link={"/help/navigate-to-dashboard"}
            />
        </Layout>
    );
};

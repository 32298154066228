import { BaseTheme, PBox, PContainer } from "../../theme/BaseTheme";
import Header from "../../sections/Header";
import { Footer } from "../../sections/Footer";
import { ConfigProvider } from "antd";

export const BlogLayout = (props) => {
    return (
        <div className={BaseTheme}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#5344a9",
                        colorTextBase: "#002346",
                    },
                }}
            >
                <PBox css={styles} className="landing-layout">
                    <Header />
                    <PContainer>
                        <PBox className="content">{props.children}</PBox>
                    </PContainer>
                    <Footer />
                </PBox>
            </ConfigProvider>
        </div>
    );
};

const styles = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    color: "$colors$text",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",

    "& .content": {
        flexGrow: "1",
    },
};

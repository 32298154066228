import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Popover } from "antd";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PHighlightLink,
    PLink,
} from "../../theme/BaseTheme";
import OrderSummary from "../../components/seller/OrderSummary";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { fetchOrderByPaymentIntent } from "../../../actions/order";

class CheckoutConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            urlParams: new URLSearchParams(window.location.search),
            paymentIntent: "",
        };
    }

    componentDidMount() {
        this.props
            .fetchOrderByPaymentIntent(
                this.state.urlParams.get("payment_intent")
            )
            .then(() => {
                this.setState({
                    isLoading: false,
                    paymentIntent: this.state.urlParams.get("payment_intent"),
                });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PBox className="checkout-confirmation-section">
                        <PContainer>
                            <PBox className="checkout-confirmation-section-banner">
                                <PBox className="checkout-confirmation-section-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="64"
                                        height="64"
                                        fill="#000000"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                                    </svg>
                                </PBox>
                                <PH1>It's ordered. Hope you love it!</PH1>
                                <PText>
                                    Your order number is:{" "}
                                    {
                                        this.props.order[
                                            this.state.paymentIntent
                                        ]?.order?.order_number
                                    }
                                    .
                                </PText>
                                <PText>
                                    You will receive a confirmation at{" "}
                                    <b>
                                        {
                                            this.props.order[
                                                this.state.paymentIntent
                                            ]?.order?.customer_email
                                        }
                                    </b>
                                    . The download link to the digital products
                                    will be sent to you shortly.
                                </PText>
                                <PBox className="checkout-confirmation-link">
                                    <PHighlightLink
                                        href={`/buyer/${
                                            this.props.order[
                                                this.state.paymentIntent
                                            ]?.order?.user_uuid
                                        }/order-details/${
                                            this.props.order[
                                                this.state.paymentIntent
                                            ]?.order?.order_number
                                        }`}
                                    >
                                        View Your Order
                                    </PHighlightLink>
                                </PBox>
                                <PBox className="checkout-confirmation-keep-shopping">
                                    <PHighlightLink href="/">
                                        Keep Shopping
                                    </PHighlightLink>
                                </PBox>

                                <Popover
                                    content={
                                        <div style={{ width: "280px" }}>
                                            Please allow 24 hours to receive
                                            your download link email. If you
                                            have not received the email after 24
                                            hours, please contact us at
                                            buyer_support@phair.io with your
                                            order number, name and email.
                                        </div>
                                    }
                                    title="Did not receive email?"
                                    trigger="click"
                                    className="email-popover"
                                >
                                    Did not receive email?
                                </Popover>
                            </PBox>
                            <OrderSummary
                                showButton={false}
                                showSummary={true}
                                showBag={true}
                                price={
                                    this.props.order[this.state.paymentIntent]
                                        ?.order?.price
                                }
                                transactionFee={
                                    this.props.order[this.state.paymentIntent]
                                        ?.order?.transaction_fee
                                }
                                tax={
                                    this.props.order[this.state.paymentIntent]
                                        ?.order?.tax
                                }
                                orderAmount={
                                    this.props.order[this.state.paymentIntent]
                                        ?.order?.total
                                }
                                cover={
                                    this.props.order[this.state.paymentIntent]
                                        ?.order?.cover
                                }
                                name={
                                    this.props.order[this.state.paymentIntent]
                                        ?.order?.name
                                }
                            />
                        </PContainer>
                    </PBox>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    "& .checkout-confirmation-section": {
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    "& .checkout-confirmation-section-banner": {
        maxWidth: "600px",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "60px",
        color: "$colors$text",
        textAlign: "center",
        "& h1": {
            fontWeight: "600",
            fontSize: "32px",
        },
        "& p": {
            lineHeight: "1.8",
        },
    },
    "& .checkout-confirmation-link": {
        marginTop: "40px",
        marginBottom: "40px",
        textAlign: "center",
        "& a": {
            backgroundColor: "$colors$primary",
            color: "white",
            fontWeight: "500",
            borderColor: "$colors$primary",
            "&:hover": {
                backgroundColor: "#fff",
                color: "$colors$primary",
            },
        },
    },
    "& .checkout-confirmation-keep-shopping": {
        marginTop: "40px",
        marginBottom: "40px",
        textAlign: "center",
        "& a": {
            color: "$colors$primary",
            backgroundColor: "white",
            fontWeight: "500",
            "&:hover": {
                color: "#fff",
                backgroundColor: "$colors$primary",
            },
        },
    },
    "& .checkout-confirmation-section-icon": {
        textAlign: "center",
        "& svg": {
            fill: "rgba(109,203,163, 1)",
        },
    },
    "& .email-popover": {
        cursor: "pointer",
        "&:hover": {
            color: "$colors$primary",
        },
    },
};

function mapStateToProps(state) {
    return {
        order: state.orderReducer?.fetchOrderByPaymentIntent,
    };
}

export default connect(mapStateToProps, {
    fetchOrderByPaymentIntent,
})(withRouter(CheckoutConfirm));

import axios from "axios";
import { message } from "antd";

export const fetchBankInfo = (shop_uuid, history) => async (dispatch) => {
    await axios
        .get(`/api/banks/${shop_uuid}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_BANK_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
            if (error?.response?.data?.redirect)
                history.push(error?.response?.data?.redirect);
        });
};

export const fetchBillingInfo = (shop_uuid, history) => async (dispatch) => {
    await axios
        .get(`/api/billings/${shop_uuid}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_BILLING_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
            if (error?.response?.data?.redirect)
                history.push(error?.response?.data?.redirect);
        });
};

export const editBankInfo = (shop_uuid, data) => async (dispatch) => {
    await axios
        .put(`/api/banks/edit/${shop_uuid}`, data)
        .then((response) => {
            dispatch({
                type: "FETCH_BANK_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const editBillingInfo = (shop_uuid, data) => async (dispatch) => {
    await axios
        .put(`/api/billings/edit/${shop_uuid}`, data)
        .then((response) => {
            dispatch({
                type: "FETCH_BILLING_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

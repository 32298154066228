import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PBox,
    PSection,
    PText,
    PH1,
    PH3,
    PH2,
    PLink,
    PContainer,
    PImg,
} from "../theme/BaseTheme";
import React from "react";
import { Button, Form, Space, Input } from "antd";
import { submitContactForm } from "../../actions/contact";

class GetInTouch extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        return (
            <PSection css={styles}>
                <PBox className="get-in-touch">
                    <PContainer>
                        <PBox className="get-in-touch-banner">
                            <PBox className="get-in-touch-title">
                                <PH2> Get In Touch</PH2>
                                <PText>
                                    We'd love to hear from you! You may find our{" "}
                                    <PLink href="/faqs">FAQ</PLink> and{" "}
                                    <PLink href="/help">Help</PLink> useful for
                                    common questions and answers. You could also
                                    email us at <b>support@phair.io</b> to get
                                    help from our customer support.
                                </PText>
                            </PBox>
                        </PBox>

                        <PBox className="get-in-touch-form">
                            <Form
                                name="contact-form"
                                layout="vertical"
                                onFinish={async (values) => {
                                    await this.props.submitContactForm(
                                        values,
                                        this.props.history
                                    );
                                }}
                            >
                                <Form.Item
                                    name="name"
                                    label="Full Name"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter your full name",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Full Name"
                                        id="full-name"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            type: "email",
                                            message:
                                                "${label} is not a valid email.",
                                        },
                                        {
                                            required: true,
                                            message: "Please enter your email.",
                                        },
                                    ]}
                                >
                                    <Input size="large" placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    name="subject"
                                    label="Subject"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the subject of your message.",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Subject" id="subject" />
                                </Form.Item>
                                <Form.Item
                                    name="message"
                                    label="Message"
                                    style={{ marginBottom: "40px" }}
                                >
                                    <Input.TextArea
                                        rows={4}
                                        placeholder="Message"
                                        id="message"
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Send message
                                        </Button>
                                        <Button htmlType="reset">Reset</Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </PBox>
                    </PContainer>
                </PBox>
            </PSection>
        );
    }
}

const styles = {
    color: "rgb(75 85 99)",
    lineHeight: "1.8",
    maxWidth: "48rem",
    padding: "0 2rem",
    margin: "4rem auto",
    "& .get-in-touch-title": {
        margin: "0 auto",
        "& h2": {
            textAlign: "center",
            lineHeight: "2.5rem",
            fontWeight: "700",
            fontSize: "2.25rem",
            color: "rgb(17 24 39)",
        },
        "& p": {
            textAlign: "center",
            lineHeight: "2rem",
            fontSize: "1rem",
            marginTop: "0.5rem",
            color: "rgb(75 85 99)",
        },
        "& a": {
            fontWeight: 700,
            color: "$colors$primary",
            textDecoration: "none",
            "&:hover": {
                cursor: "pointer",
                color: "$colors$primary500",
            },
        },
    },
    "& .get-in-touch-form": {
        padding: "0 1rem",
        margin: "4rem auto 8rem auto",
        maxWidth: "36rem",
        color: "rgb(17 24 39)",
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "1.5rem",
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, { submitContactForm })(
    withRouter(GetInTouch)
);

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Component } from "react";
import { PBox } from "../../theme/BaseTheme";
import { Accordion } from "./Accordion";
import { Loader } from "../Loader";

const data = [
    { title: "Highlights", content: "Digital file type(s): 1 PDF" },
    {
        title: "Description",
        content:
            "Pinterest Canva Template // Small business content, Pinterest content ideas, Small business marketing, marketing templates, editable Canva",
    },
    {
        title: "Delivery",
        content:
            "Your files will be available to download once payment is confirmed. ",
    },
];

class Description extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles} className="desc">
                <PBox className="desc-accordions">
                    <Accordion
                        title={"Description"}
                        content={this.props.product?.product_description}
                    />
                    <Accordion
                        title={"Policies"}
                        content={this.props.product?.product_delivery}
                    />
                </PBox>
            </PBox>
        );
    }
}

const styles = {
    borderTop: "1px solid $colors$borderColor",
    borderBottom: "1px solid $colors$borderColor",
    "& .desc-accordions": {
        margin: "32px 0px",
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {})(withRouter(Description));

import { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { keyframes } from "@stitches/react";
import {
    PSection,
    PH1,
    PBox,
    PContainer,
    PImg,
    PText,
    PLink,
} from "../theme/BaseTheme";
import { keys } from "../../config/keys";

const catData = [
    {
        category: "Template",
        cover: `${keys.imageBaseUrl}templates.jpg`,
    },
    {
        category: "Font",
        cover: `${keys.imageBaseUrl}fonts.jpg`,
    },
    {
        category: "Printable",
        cover: `${keys.imageBaseUrl}printables.jpg`,
    },
    {
        category: "Illustration",
        cover: `${keys.imageBaseUrl}illustrations.jpg`,
    },
    {
        category: "Photo",
        cover: `${keys.imageBaseUrl}photos.jpg`,
    },
    {
        category: "Tutorial",
        cover: `${keys.imageBaseUrl}tutorials.jpg`,
    },
];

class TopCategories extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {}
    render() {
        return (
            <PSection css={styles}>
                <PBox className="top-categories">
                    <PBox className="top-categories-title-wrapper">
                        <PH1 className="top-categories-title">
                            Top Categories
                        </PH1>
                        {/*<PLink href="#">VIEW ALL</PLink>*/}
                    </PBox>
                    <PBox className="top-categories-list">
                        {catData.map((item, idx) => {
                            return (
                                <PBox className="top-categories-item">
                                    <PBox className="top-categories-cover-wrapper">
                                        <PImg
                                            src={item.cover}
                                            className="top-categories-cover"
                                            onClick={() => {
                                                this.props.history.push({
                                                    pathname: "/search",
                                                    search:
                                                        "cat=" +
                                                        '"' +
                                                        item.category +
                                                        '"',
                                                });
                                            }}
                                        />
                                    </PBox>
                                    <PBox className="top-categories-name">
                                        <PLink
                                            onClick={() => {
                                                this.props.history.push({
                                                    pathname: "/search",
                                                    search:
                                                        "cat=" +
                                                        '"' +
                                                        item.category +
                                                        '"',
                                                });
                                            }}
                                        >
                                            {item.category}
                                        </PLink>
                                    </PBox>
                                </PBox>
                            );
                        })}
                    </PBox>
                </PBox>
            </PSection>
        );
    }
}

const rainbow = keyframes({
    "0%": { backgroundPosition: "left" },
    "50%": { backgroundPosition: "right" },
    "100%": { backgroundPosition: "left" },
});

const styles = {
    maxWidth: "80rem",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "2rem",
    "& .top-categories": {
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    "& .top-categories-title-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& a": {
            position: "relative",
            fontWeight: "700",
            fontSize: "14px",
            letterSpacing: "0.1em",
            lineHeight: "20px",
            textDecoration: "none",
            color: "transparent",
            animation: `${rainbow} 2s ease-in-out infinite`,
            transition: "color 0.2s ease-in-out",
            background: "linear-gradient(to right, #bb5098,#5344a9,#bb5098)",
            backgroundClip: "text",
            "-webkit-background-clip": "text",
            backgroundSize: "200% 200%",
        },
        "& a:after": {
            content: "",
            position: "absolute",
            left: "0",
            bottom: "-4px",
            width: "100%",
            height: "1px",
            background:
                "linear-gradient(216.56deg, $colors$tertiary 5.32%, $colors$primary 94.32%)",
        },
    },
    "& .top-categories-title": {
        fontSize: "1.5rem",
        fontWeight: "700",
        lineHeight: "2rem",
        color: "rgb(17 24 39)",
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    "& .top-categories-list": {
        display: "grid",
        gridGap: "20px",
        "@bp1": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp2": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp3": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(6, minmax(0, 1fr))" },
    },
    "& .top-categories-item": {
        position: "relative",
        margin: "0 auto",
    },
    "& .top-categories-cover-wrapper:hover": {
        cursor: "pointer",
        transform: "translateY(-6px)",
        display: "block",
        "& img": {
            transform: "scale(1.1)",
            "-moz-transform": "scale(1.1)",
            "-webkit-transform": "scale(1.1)",
        },
    },
    "& .top-categories-cover-wrapper": {
        overflow: "hidden",
        borderRadius: "50%",
        width: "150px",
        height: "150px",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
    },
    "& .top-categories-cover": {
        objectFit: "cover",
        width: "100%",
        height: "150px",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
    },
    "& .top-categories-name": {
        textAlign: "center",
        fontWeight: "600",
        marginTop: "16px",
        "& a": {
            cursor: "pointer",
            textDecoration: "none",
            color: "$colors$text",
        },
        "& a:hover": {
            color: "$colors$primary",
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {})(withRouter(TopCategories));

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const OwnershipAndRights = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>What rights will Phair have to the content I post?</PH1>
                <PText>
                    You retain sole ownership rights to everything you create.
                    We do not claim any ownership rights to your store or any
                    content you post on it other than a non-exclusive license
                    you grant to us under your Seller Agreement. The license you
                    grant to us allows us to promote you, your store and your
                    listings to our buyers in our marketing to drive awareness
                    and engagement of your store on Phair.
                </PText>
            </PBox>
            <HelpNav
                prev={"US Only"}
                prev_link={"/help/us-only"}
                next={"Prohibited Products"}
                next_link={"/help/prohibited-products"}
            />
        </Layout>
    );
};

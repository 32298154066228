import { PBox, PHr, PImg, PLink, PText } from "../../theme/BaseTheme";
import { Space, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { Rating } from "../landing/Rating";

export const EditableCard = (props) => {
    return (
        <PBox css={styles}>
            <PBox className="item-card">
                <PBox className="item-cover">
                    <PImg alt="image" src={props.img} />
                    <Space size="middle" className="item-cover-buttons">
                        {props.status === "public" && (
                            <PLink
                                href={`/item/${props.uuid}`}
                                target="_blank"
                                className="editable-products-button"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path>
                                </svg>
                            </PLink>
                        )}
                        <Link
                            to={`/seller/${props.shop_uuid}/item/${props.uuid}/edit`}
                            className="editable-products-button"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path>
                            </svg>
                        </Link>
                        <PBox className="editable-products-button">
                            <Popconfirm
                                title="Delete the product"
                                description="Are you sure to delete this product? This action cannot be undone."
                                onConfirm={() => {
                                    props
                                        .deleteProduct(
                                            props.shop_uuid,
                                            props.uuid
                                        )
                                        .then(() => {
                                            window.location.reload(true);
                                        });
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                                </svg>
                            </Popconfirm>
                        </PBox>
                    </Space>
                </PBox>
                <PBox className="item-title">{props.title}</PBox>
                <PText className="item-desc">{props.product_highlights}</PText>
                {/*<PBox className="item-rating">
                    <Rating rating={props.rating} reviews={props.reviews} />
                            </PBox>*/}
                <PText className="item-price">
                    ${props.price}{" "}
                    <svg viewBox="0 0 2 2" class="nc rd aol">
                        <circle cx="1" cy="1" r="1"></circle>
                    </svg>{" "}
                    {props.number_of_orders} orders{" "}
                    <svg viewBox="0 0 2 2" class="nc rd aol">
                        <circle cx="1" cy="1" r="1"></circle>
                    </svg>{" "}
                    ${props.revenue} earned{" "}
                    <svg viewBox="0 0 2 2" class="nc rd aol">
                        <circle cx="1" cy="1" r="1"></circle>
                    </svg>{" "}
                    {props.status}
                </PText>
            </PBox>
        </PBox>
    );
};

const styles = {
    "& .item-card": {
        marginTop: "1rem",
        marginBottom: "1rem",
        borderRadius: "0.75rem",
        backgroundColor: "#fff",
        border: "1px solid $colors$borderColor",
        overflow: "hidden",
    },
    "& .item-cover": {
        overflow: "hidden",
        display: "block",
        position: "relative",
        "& img": {
            "@bp1": { height: "12rem" },
            "@bp2": { height: "14rem" },
            "@bp3": { height: "16rem" },
            "@bp4": { height: "18rem" },
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            objectFit: "cover",
            transition: "all .2s ease-in-out",
            "-moz-transition": "all .2s ease-in-out",
            "-webkit-transition": "all .2s ease-in-out",
            "&:hover": {
                transform: "scale(1.1)",
                "-moz-transform": "scale(1.1)",
                "-webkit-transform": "scale(1.1)",
            },
        },
        "& .item-cover-buttons": {
            cursor: "pointer",
            position: "absolute",
            right: "13px",
            top: "11px",
            backgroundColor: "#14141f",
            padding: "2px 11px",
            borderRadius: "8px",
            fontWeight: "300px",
            fontSize: "14px",
            lineHeight: "24px",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            "& svg": {
                fill: "#fff",
                margin: "0 4px 0 0",
                display: "block",
                padding: "0",
            },
            "& svg:hover": { fill: "$colors$secondary" },
        },
    },
    "& .item-title": {
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        padding: "1rem 1rem 0 1rem",
        fontWeight: "500",
        fontSize: "18px",
        "& a": {
            color: "$colors$text",
            textDecoration: "none",
        },
    },
    "& .item-desc": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        marginTop: "0.5rem",
        color: "rgb(107, 114, 128)",
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": "3",
    },
    "& .item-price": {
        lineHeight: "1.3",
        margin: "0rem 1rem 1rem 1rem",
        display: "flex",
        alignItems: "center",
        color: "rgb(17 24 39)",
        fontWeight: "500",
        "& a": {
            textDecoration: "none",
            color: "$colors$primary",
            "&:hover": {
                color: "$colors$primary500",
                fontWeight: "500",
            },
        },
        "& b": {
            fontWeight: "600",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: "rgb(17 24 39)",
        },
        "& svg": {
            width: "0.125rem",
            height: "0.125rem",
            display: "block",
            verticalAlign: "middle",
            margin: "0 0.25rem",
        },
    },
    "& .item-status": {
        color: "$colors$text",
        paddingLeft: "10px",
        paddingRight: "10px",
        margin: "0 0 12px 0",
    },
};

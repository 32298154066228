import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Checkbox } from "antd";
import { PBox, PH3 } from "../theme/BaseTheme";
import { fetchSearchProductsFull } from "../../actions/filter";
import { Loader } from "../components/Loader";

class Filters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            urlParams: new URLSearchParams(window.location.search),
            cat_array: [],
            p_attr_array: [],
            r_attr_array: [],
        };
        this.onCatCheckChange = this.onCatCheckChange.bind(this);
        this.onPriceCheckChange = this.onPriceCheckChange.bind(this);
        this.onRatingCheckChange = this.onRatingCheckChange.bind(this);
    }

    async componentDidMount() {
        await this.setState({
            cat_array: this.state.urlParams.getAll("cat"),
            p_attr_array: this.state.urlParams.getAll("p_attr"),
            r_attr_array: this.state.urlParams.getAll("r_attr"),
        });
        this.setState({ isLoading: false });
    }

    async onCatCheckChange(e, label) {
        if (e.target.checked) {
            await this.state.urlParams.append("cat", '"' + label + '"');
        } else {
            await this.state.urlParams.delete("cat", '"' + label + '"');
        }
        await this.props.fetchSearchProductsFull(
            this.state.urlParams,
            this.props.offset,
            this.props.limit
        );
        window.location.search = this.state.urlParams.toString();
    }
    async onPriceCheckChange(e, p_attr) {
        if (e.target.checked) {
            await this.state.urlParams.append("p_attr", p_attr);
        } else {
            await this.state.urlParams.delete("p_attr", p_attr);
        }
        await this.props.fetchSearchProductsFull(
            this.state.urlParams,
            this.props.offset,
            this.props.limit
        );
        window.location.search = this.state.urlParams.toString();
    }
    async onRatingCheckChange(e, r_attr) {
        if (e.target.checked) {
            await this.state.urlParams.append("r_attr", r_attr);
        } else {
            await this.state.urlParams.delete("r_attr", r_attr);
        }
        await this.props.fetchSearchProductsFull(
            this.state.urlParams,
            this.props.offset,
            this.props.limit
        );
        window.location.search = this.state.urlParams.toString();
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles} className="filters">
                <PBox className="filters-wrapper">
                    <PBox className="filters-category">
                        <PH3>Categories</PH3>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Tutorial"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Tutorial");
                            }}
                        >
                            Tutorial
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"eBook"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "eBook");
                            }}
                        >
                            eBook
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Photo"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Photo");
                            }}
                        >
                            Photo
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Software"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Software");
                            }}
                        >
                            Software
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Font"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Font");
                            }}
                        >
                            Font
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Music"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Music");
                            }}
                        >
                            Music
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Template"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Template");
                            }}
                        >
                            Template
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Document"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Document");
                            }}
                        >
                            Document
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Video"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Video");
                            }}
                        >
                            Video
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Graphic"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Graphic");
                            }}
                        >
                            Graphics
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Illustration"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Illustration");
                            }}
                        >
                            Illustration
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Membership"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Membership");
                            }}
                        >
                            Membership
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Data Set"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Data Set");
                            }}
                        >
                            Data Set
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"App"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "App");
                            }}
                        >
                            App
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Digital Media"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Digital Media");
                            }}
                        >
                            Digital Media
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Icon"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Icon");
                            }}
                        >
                            Icon
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Online Tool"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Online Tool");
                            }}
                        >
                            Online Tool
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.cat_array.includes(
                                '"Printable"'
                            )}
                            onChange={(e) => {
                                this.onCatCheckChange(e, "Printable");
                            }}
                        >
                            Printable
                        </Checkbox>
                    </PBox>
                    <PBox className="filters-price">
                        <PH3>Price</PH3>
                        <Checkbox
                            defaultChecked={this.state.p_attr_array.includes(
                                "1"
                            )}
                            onChange={(e) => {
                                this.onPriceCheckChange(e, 1);
                            }}
                        >
                            Free
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.p_attr_array.includes(
                                "2"
                            )}
                            onChange={(e) => {
                                this.onPriceCheckChange(e, 2);
                            }}
                        >
                            Under $10
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.p_attr_array.includes(
                                "3"
                            )}
                            onChange={(e) => {
                                this.onPriceCheckChange(e, 3);
                            }}
                        >
                            $10 - $25
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.p_attr_array.includes(
                                "4"
                            )}
                            onChange={(e) => {
                                this.onPriceCheckChange(e, 4);
                            }}
                        >
                            $25 - $50
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.p_attr_array.includes(
                                "5"
                            )}
                            onChange={(e) => {
                                this.onPriceCheckChange(e, 5);
                            }}
                        >
                            $50 - $100
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.p_attr_array.includes(
                                "6"
                            )}
                            onChange={(e) => {
                                this.onPriceCheckChange(e, 6);
                            }}
                        >
                            $100 & Up
                        </Checkbox>
                    </PBox>
                    <PBox className="filters-ratings">
                        <PH3>Ratings</PH3>
                        <Checkbox
                            defaultChecked={this.state.r_attr_array.includes(
                                "1"
                            )}
                            onChange={(e) => {
                                this.onRatingCheckChange(e, 1);
                            }}
                        >
                            1 - 2
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.r_attr_array.includes(
                                "2"
                            )}
                            onChange={(e) => {
                                this.onRatingCheckChange(e, 2);
                            }}
                        >
                            2 - 3
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.r_attr_array.includes(
                                "3"
                            )}
                            onChange={(e) => {
                                this.onRatingCheckChange(e, 3);
                            }}
                        >
                            3 - 4
                        </Checkbox>
                        <Checkbox
                            defaultChecked={this.state.r_attr_array.includes(
                                "4"
                            )}
                            onChange={(e) => {
                                this.onRatingCheckChange(e, 4);
                            }}
                        >
                            4 - 5
                        </Checkbox>
                    </PBox>
                </PBox>
            </PBox>
        );
    }
}

const styles = {
    "& .filters-wrapper": {
        marginTop: "0px",
        backgroundColor: "$colors$lightBackground",
        padding: "0 20px 20px 20px",
        borderRadius: "16px",
        lineHeight: "1.8",
    },
    "& .filters-category": {
        display: "flex",
        flexDirection: "column",
    },
    "& .filters-price": {
        display: "flex",
        flexDirection: "column",
    },
    "& .filters-ratings": {
        display: "flex",
        flexDirection: "column",
    },
    "& h3": {
        marginBottom: "10px",
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {
    fetchSearchProductsFull,
})(withRouter(Filters));

import { BaseTheme, PText, PBox } from "../../theme/BaseTheme";
import { Logo } from "../../components/Logo";

export const ErrorLayout = (props) => {
    const year = new Date().getFullYear();
    return (
        <div className={BaseTheme}>
            <PBox css={styles} className="error-page">
                {/*<PBox className="background-image-top">
                    <svg
                        id="visual"
                        viewBox="0 0 900 600"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                    >
                        <g transform="translate(0, 0)">
                            <path
                                d="M216.3 0C215.7 30.5 215 61 199.9 82.8C184.8 104.6 155.2 117.6 132.9 132.9C110.6 148.3 95.6 165.9 74.6 180.2C53.7 194.4 26.8 205.4 0 216.3L0 0Z"
                                fill="#f47f68"
                            ></path>
                        </g>
                    </svg>
                </PBox>
                <PBox className="background-image-bottom">
                    <svg
                        id="visual"
                        viewBox="0 0 900 600"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                    >
                        <g transform="translate(900, 600)">
                            <path
                                d="M-216.3 0C-217.3 -32.4 -218.3 -64.8 -199.9 -82.8C-181.4 -100.8 -143.6 -104.5 -122.3 -122.3C-101.1 -140.2 -96.5 -172.3 -78.8 -190.3C-61.2 -208.4 -30.6 -212.3 0 -216.3L0 0Z"
                                fill="#f47f68"
                            ></path>
                        </g>
                    </svg>
    </PBox>*/}
                <Logo />
                <PBox className="error-page-layout-content">
                    {props.children}
                </PBox>
                <PBox className="error-page-footer">
                    <PText></PText>
                </PBox>
            </PBox>
        </div>
    );
};

const styles = {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "$fonts$body",
    "& .background-image-top": {
        position: "absolute",
        left: "0",
        top: "0",
        zIndex: "1",
        "& svg": { display: "block", width: "80vw", height: "auto" },
    },
    "& .background-image-bottom": {
        position: "absolute",
        right: "0",
        bottom: "0",
        zIndex: "1",
        "& svg": { display: "block", width: "80vw", height: "auto" },
    },
    "& .error-page-layout-content": {
        zIndex: "10",
    },
    "& .logo": {
        margin: "60px 0",
    },
    "& .error-page-footer": {
        margin: "40px 0",
        "& p": {
            display: "flex",
            alignItems: "center",
            color: "$colors$text",
            justifyContent: "center",
            "& svg": {
                fill: "$colors$link",
                margin: "0 4px",
            },
        },
    },
};

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Component } from "react";
import { Reviews } from "../../components/landing/Reviews";
import { PBox, PH1, PHr } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { fetchProductToEdit } from "../../../actions/product";
import EditProduct from "../../components/seller/EditProduct";

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.props
            .fetchProductToEdit(
                this.props.match.params.shop_uuid,
                this.props.match.params.item_uuid,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }
    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles} className="edit">
                    <PBox className="edit-card">
                        <PH1>Edit Product</PH1>
                        <EditProduct />
                    </PBox>
                    <PBox className="edit-card">
                        <Reviews data={this.props.product?.reviews} />
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    margin: "0rem auto",
    maxWidth: "80rem",
    "& .edit-card": {
        margin: "0rem 2rem 2rem 2rem",
        backgroundColor: "#fff",
        padding: "2rem 2rem",
        "& h1": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            fontWeight: "700",
            color: "rgb(17 24 39)",
        },
    },
};

function mapStateToProps(state) {
    return {
        product: state.productReducer?.fetchProductEdit?.product,
    };
}

export default connect(mapStateToProps, { fetchProductToEdit })(
    withRouter(Edit)
);

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const Refund = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How to request / issue a refund?</PH1>
                <PText>
                    Currently payments are not refundable. All sales are final.
                </PText>
            </PBox>
            <HelpNav
                prev={"Purchase history"}
                prev_link={"/help/purchase-history"}
                next={"Download link email"}
                next_link={"/help/download-link-email"}
            />
        </Layout>
    );
};

import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import {
    PContainer,
    PH1,
    PH4,
    PSection,
    PBox,
    PImg,
    PText,
    PSpan,
    PLink,
    PUl,
    PLi,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { fetchOrderHistoryByCustomerID } from "../../../actions/order";

class OrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchOrderHistoryByCustomerID(
                this.props.match.params.user_uuid,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PBox className="order-history-title">
                        <PH1>Order history</PH1>
                        <PText>
                            Check the status of and manage recent orders.
                        </PText>
                    </PBox>
                    <PBox className="order-history-list">
                        {this.props.orderList.map((order, idx) => {
                            return (
                                <PBox className="order-history-item">
                                    <PBox className="order-history-item-meta">
                                        <PBox className="order-history-item-meta-info">
                                            <PBox className="order-history-item-order-number">
                                                <PH4>Order number</PH4>
                                                <PText>
                                                    {order.order_number}
                                                </PText>
                                            </PBox>
                                            <PBox className="order-history-item-date-placed">
                                                <PH4>Date placed</PH4>
                                                <PText>
                                                    {new Date(
                                                        order.created_at
                                                    ).toDateString()}
                                                </PText>
                                            </PBox>
                                            <PBox className="order-history-item-total-amount">
                                                <PH4>Total amount</PH4>
                                                <PText>${order.total}</PText>
                                            </PBox>
                                        </PBox>
                                        <PBox className="order-history-item-meta-buttons">
                                            <PLink
                                                className="order-history-view-order"
                                                href={`/buyer/${this.props.match.params.user_uuid}/order-details/${order.order_number}`}
                                            >
                                                View Order
                                            </PLink>
                                        </PBox>
                                    </PBox>
                                    <PBox className="order-history-item-list">
                                        <PUl>
                                            {order?.order_items?.map(
                                                (order_item, idx) => {
                                                    return (
                                                        <PLi>
                                                            <PBox className="order-history-item-product">
                                                                <PBox className="order-history-item-thumbnail">
                                                                    <PImg
                                                                        src={
                                                                            order_item
                                                                                ?.product
                                                                                ?.product_cover_image
                                                                                .url
                                                                        }
                                                                    />
                                                                </PBox>
                                                                <PBox className="order-history-item-product-info">
                                                                    <PBox className="order-history-item-product-info-title">
                                                                        <PH4>
                                                                            {
                                                                                order_item
                                                                                    ?.product
                                                                                    ?.product_name
                                                                            }
                                                                        </PH4>
                                                                        <PText>
                                                                            $
                                                                            {
                                                                                order_item
                                                                                    ?.product
                                                                                    ?.price
                                                                            }
                                                                        </PText>
                                                                    </PBox>
                                                                    <PText className="order-history-item-product-highlights">
                                                                        {
                                                                            order_item
                                                                                ?.product
                                                                                ?.product_highlights
                                                                        }
                                                                    </PText>
                                                                    <PBox className="order-history-item-product-buttons">
                                                                        <PLink
                                                                            href={`/item/${order_item?.product?.uuid}`}
                                                                        >
                                                                            View{" "}
                                                                            product
                                                                        </PLink>
                                                                        <PLink
                                                                            href={`/checkout/${order_item?.product?.uuid}`}
                                                                        >
                                                                            Buy{" "}
                                                                            again
                                                                        </PLink>
                                                                    </PBox>
                                                                </PBox>
                                                            </PBox>
                                                        </PLi>
                                                    );
                                                }
                                            )}
                                        </PUl>
                                    </PBox>
                                </PBox>
                            );
                        })}
                    </PBox>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    margin: "0 auto",
    padding: "6rem 2rem",
    maxWidth: "80rem",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .order-history-title": {
        maxWidth: "64rem",
        margin: "0 auto",
        "& h1": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            color: "rgb(17 24 39)",
            fontWeight: "700",
            margin: "0",
        },
        "& p": {
            color: "rgb(107 114 128)",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            marginTop: "0.5rem",
        },
    },
    "& .order-history-list": {
        maxWidth: "64rem",
        margin: "4rem auto 8rem auto",
    },
    "& .order-history-item": {
        borderRadius: "0.5rem",
        border: "1px solid $colors$borderColor",
        margin: "2rem 0",
    },
    "& .order-history-item-meta": {
        padding: "1.5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid $colors$borderColor",
    },
    "& .order-history-item-meta-info": {
        gridColumn: "span 2 / span 2",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        display: "flex",
        alignItems: "center",
    },
    "& .order-history-item-meta-buttons": {
        gridColumn: "span 2 / span 2",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    "& .order-history-item-order-number": {
        marginRight: "1.5rem",
        "& h4": { color: "rgb(17 24 39)", fontWeight: "500", margin: "0" },
        "& p": { color: "rgb(107 114 128)", margin: "0.25rem 0 0 0" },
    },
    "& .order-history-item-date-placed": {
        marginRight: "1.5rem",
        "& h4": { color: "rgb(17 24 39)", fontWeight: "500", margin: "0" },
        "& p": { color: "rgb(107 114 128)", margin: "0.25rem 0 0 0" },
        display: "none",
        "@bp2": { display: "block" },
    },
    "& .order-history-item-total-amount": {
        marginRight: "1.5rem",
        "& h4": { color: "rgb(17 24 39)", fontWeight: "500", margin: "0" },
        "& p": {
            color: "rgb(17 24 39)",
            fontWeight: "500",
            margin: "0.25rem 0 0 0",
        },
    },
    "& .order-history-view-order": {
        border: "1px solid $colors$borderColor",
        color: "rgb(55 65 81)",
        fontWeight: "500",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        padding: "0.5rem 0.625rem",
        borderRadius: "0.375rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "none",
        "&:hover": { backgroundColor: "#f3f4f6" },
    },
    "& .order-history-item-list": {
        "& ul": {
            listStyle: "none",
            margin: "0",
            padding: "0",
        },
        "& li": {
            padding: "1.5rem",
        },
        "& li:not(:last-child)": {
            borderBottom: "1px solid $colors$borderColor",
        },
    },
    "& .order-history-item-product": {
        display: "flex",
        alignItems: "flex-start",
    },
    "& .order-history-item-thumbnail": {
        width: "8rem",
        height: "8rem",
        backgroundColor: "rgb(229 231 235)",
        borderRadius: "0.5rem",
        overflow: "hidden",
        flexShrink: "0",
        "& img": {
            objectPosition: "center",
            objectFit: "cover",
            width: "100%",
            height: "100%",
        },
    },
    "& .order-history-item-product-info": {
        marginLeft: "1.5rem",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        flex: "1 1 0%",
    },
    "& .order-history-item-product-info-title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        color: "rgb(17 24 39)",
        fontWeight: "500",
        "& h4": { margin: "0" },
        "& p": { margin: "0" },
    },
    "& .order-history-item-product-highlights": {
        color: "rgb(107 114 128)",
        marginTop: "0.5rem",
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": "3",
    },
    "& .order-history-item-product-buttons": {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontWeight: "500",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        "& a": {
            textDecoration: "none",
            color: "$colors$primary",
            whiteSpace: "nowrap",
        },
        "& a:not(:last-child)": {
            marginRight: "1rem",
        },
    },
};

function mapStateToProps(state) {
    return {
        orderList: state.orderReducer?.fetchOrderHistoryList?.orderList,
    };
}

export default connect(mapStateToProps, {
    fetchOrderHistoryByCustomerID,
})(withRouter(OrderHistory));

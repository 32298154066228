import { useState } from "react";
import clsx from "clsx";
import { Layout } from "./Layout";
import {
    PH1,
    PBox,
    PUl,
    PLi,
    PH3,
    PLink,
    PH4,
    PText,
} from "../../theme/BaseTheme";

export const HelpAccordion = (props) => {
    const [isActive, setIsActive] = useState(props.isActiveDefault);
    return (
        <PBox css={styles}>
            <PBox className={clsx("help-accordion", isActive ? "active" : "")}>
                <PBox className="help-accordion-border"></PBox>

                <PBox
                    className="help-accordion-title"
                    onClick={() => setIsActive(!isActive)}
                >
                    <PH3>{props.title}</PH3>
                    <PBox className="help-accordion-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                            width="16"
                            height="16"
                        >
                            <path d="M215.39,92.94A8,8,0,0,0,208,88H48a8,8,0,0,0-5.66,13.66l80,80a8,8,0,0,0,11.32,0l80-80A8,8,0,0,0,215.39,92.94Z" />
                        </svg>
                    </PBox>
                </PBox>
                {isActive && (
                    <PBox className="help-accordion-content">
                        {props.content}
                    </PBox>
                )}
            </PBox>
        </PBox>
    );
};

export const Help = (props) => {
    return (
        <Layout>
            <PBox css={styles}>
                <PBox className="help-title">
                    <PH4>Selling with Phair</PH4>
                    <PH1> Support Center</PH1>
                </PBox>
                <PBox className="help-items">
                    <HelpAccordion
                        isActiveDefault={true}
                        title="Getting Started"
                        content={
                            <PUl>
                                <PLi>
                                    <PLink href="/help/start-selling">
                                        How to start selling on Phair?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/fees">
                                        What fees do Phair charge?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/need-help">
                                        Need help?
                                    </PLink>
                                </PLi>
                            </PUl>
                        }
                    />
                    <HelpAccordion
                        isActiveDefault={true}
                        title="Products"
                        content={
                            <PUl>
                                <PLi>
                                    <PLink href="/help/create-a-product">
                                        How to create a product?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/edit-a-product">
                                        How to edit my product?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/go-public">
                                        How to get my products to go public?
                                    </PLink>
                                </PLi>
                                {/*<PLi>
                                    <PLink href="/help/product-review-process">
                                        What is the product review process?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/product-review-process">
                                        How long does it take to get my products
                                        approved?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/product-review-process">
                                        How to avoid being rejected in the
                                        product review process?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/product-review-process">
                                        What do I do if my products do not get
                                        approved?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/product-review-process">
                                        If I edit my product, do I need to go
                                        through the review process again?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/product-review-process">
                                        Can I edit my product if it is under
                                        review?
                                    </PLink>
                        </PLi>*/}
                                <PLi>
                                    <PLink href="/help/delete-a-product">
                                        How to delete a product?
                                    </PLink>
                                </PLi>
                            </PUl>
                        }
                    />
                    <HelpAccordion
                        isActiveDefault={true}
                        title="Orders"
                        content={
                            <PUl>
                                <PLi>
                                    <PLink href="/help/store-orders">
                                        How to see all orders in my store?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/purchase-history">
                                        How to see all orders I purchase?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/refund">
                                        How to request / issue a refund?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/download-link-email">
                                        I did not receive the download link
                                        after payment. What can I do?
                                    </PLink>
                                </PLi>
                            </PUl>
                        }
                    />
                    <HelpAccordion
                        isActiveDefault={true}
                        title="Payments"
                        content={
                            <PUl>
                                <PLi>
                                    <PLink href="/help/getting-paid">
                                        How do I get paid?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/payment-methods">
                                        What payment methods are supported?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/sales-tax">
                                        How does Phair handle sales tax?
                                    </PLink>
                                </PLi>
                            </PUl>
                        }
                    />
                    <HelpAccordion
                        isActiveDefault={true}
                        title="Store"
                        content={
                            <PUl>
                                <PLi>
                                    <PLink href="/help/customization">
                                        How to customize my store page?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/navigate-to-dashboard">
                                        How do I navigate to my seller dashboard
                                        from Phair.io?
                                    </PLink>
                                </PLi>
                            </PUl>
                        }
                    />
                    <HelpAccordion
                        isActiveDefault={true}
                        title="Policies"
                        content={
                            <PUl>
                                <PLi>
                                    <PLink href="/help/privacy-policy">
                                        Privacy Policy
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/seller-code-of-conduct">
                                        Seller Code of Conduct
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/anti-discrimination">
                                        Anti-Discrimination Policy
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/us-only">
                                        Can I still participate if I am not in
                                        the US?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/ownership-and-rights">
                                        What rights will Phair have to the
                                        content I post?
                                    </PLink>
                                </PLi>
                                <PLi>
                                    <PLink href="/help/prohibited-products">
                                        What products are prohibited to sell on
                                        Phair?
                                    </PLink>
                                </PLi>
                            </PUl>
                        }
                    />
                </PBox>
            </PBox>
        </Layout>
    );
};

const styles = {
    "& .help-title": {
        "& h1": {
            paddingTop: "0",
            marginTop: "0",
            marginBottom: "4rem",
        },
        "& h4": {
            marginBottom: "1rem",
            color: "$colors$primary",
        },
    },
    "& .help-items": {
        display: "grid",
        gridGap: "2rem",
        position: "relative",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "@bp2": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
    },
    "& .help-accordion": {
        //margin: "32px 0px",
        transition: "all 0.3s ease",
        border: "1px solid $colors$borderColor",
        padding: "1.5rem",
        borderRadius: "0.75rem",
        overflow: "hidden",
        position: "relative",
    },
    "& .help-accordion:hover": {
        cursor: "pointer",
        borderColor: "transparent",
        borderWidth: "2px",
        inset: "-1px",
        backgroundImage:
            "linear-gradient(#fff, #fff), linear-gradient(to right, #bb5098,#5344a9,#bb5098)",
        backgroundOrigin: "border-box",
        backgroundClip: "padding-box, border-box",
    },
    "& .help-accordion-title": {
        height: "40px",
        padding: "0 24px",
        display: "flex",
        alignItems: "center",
        borderRadius: "0",
        justifyContent: "space-between",
        "& h3": {
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "26px",
            //marginTop: "16px",
        },

        "&:hover": {
            cursor: "pointer",
            //backgroundColor: "$colors$lightBackground",
        },
    },
    "& .help-accordion-content": {
        transition: "all 0.3s ease",
        padding: "0.25rem",
        color: "rgb(51 65 85)",
        lineHeight: "2rem",
        fontSize: "1rem",
        fontWeight: "500",
        marginLeft: "20px",
        marginRight: "20px",
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
        },
        "& a": {
            textDecoration: "none",
            color: "$colors$label",
        },
        "& a:hover": {
            fontWeight: "700",
            color: "$colors$primary",
            cursor: "pointer",
        },
        "& p": {
            lineHeight: "1.8rem",
        },
    },
    "& .help-accordion-icon": {
        transition: "transform 0.3s ease",
    },
    "& .active": {
        transition: "all 0.3s ease",
        "& .help-accordion-icon": {
            transform: "rotate(-180deg)",
        },
        "& .help-accordion-title": {
            //borderBottom: "1px solid $colors$primary",
            //backgroundColor: "$colors$primary50",
        },
    },
};

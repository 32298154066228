import { BaseTheme, PBox } from "../../theme/BaseTheme";
import Header from "../../sections/Header";
import { Footer } from "../../sections/Footer";
import { ConfigProvider } from "antd";

export const Layout = (props) => {
    return (
        <div className={BaseTheme}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#5344a9",
                        colorTextBase: "#002346",
                    },
                }}
            >
                <PBox css={styles} className="buyer-layout">
                    <Header />
                    <PBox className="content">{props.children}</PBox>
                    <Footer />
                </PBox>
            </ConfigProvider>
        </div>
    );
};

const styles = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    color: "$colors$text",
    fontFamily: "$fonts$body",
    "& .content": {
        flexGrow: "1",
    },
};

import { combineReducers } from "redux";

const fetchBankInfo = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_BANK_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                bankInfo: action.payload,
            };
        case "FETCH_BANK_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                bankInfo: action.load,
            };
        default:
            return state;
    }
};

export default combineReducers({ fetchBankInfo });

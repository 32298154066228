import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./App.css";
import { fetchUser } from "./actions/auth";
import history from "./history";
import ErrorBoundary from "./ErrorBoundary";

class App extends Component {
    componentDidMount() {
        this.props.fetchUser();
    }
    render() {
        return (
            <ErrorBoundary>
                <BrowserRouter history={history}>
                    <AppRoutes currentUser={this.props.userAuth} />
                </BrowserRouter>
            </ErrorBoundary>
        );
    }
}

function mapStateToProps(state) {
    console.log("state in app: ", state);
    return {
        userAuth: state.authReducer?.userAuth,
    };
}

export default connect(mapStateToProps, { fetchUser })(App);

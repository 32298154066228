import { Component, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Rate, Input, Form, Space, Button, Popconfirm, Modal } from "antd";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PH3,
    PImg,
    PLink,
    PText,
    PFlowButton,
    PSpan,
    PH4,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import OrderSummary from "../../components/seller/OrderSummary";
import { FormLabel } from "../../components/seller/FormLabel";
import { BuyerReceiptDetail } from "../../components/buyer/BuyerReceiptDetail";
import {
    orderReviewFormLabels,
    requestRefundFormLabels,
} from "../../../data/labels";
import {
    fetchOrderDetailByOrderID,
    sendReceipt,
    resendDownloadLink,
} from "../../../actions/order";
import {
    createReview,
    editReview,
    deleteReview,
} from "../../../actions/review";

const RateForm = (props) => {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");

    const onCreateFinish = async () => {
        const values = {};
        values.rating = rating;
        values.comment = comment;
        values.order_item_id = props.item.id;
        values.product_id = props.item.product.id;
        console.log("Received values of form: ", values);
        await props.createReview(props.user_uuid, values);
    };

    return (
        <PBox className="order-details-item-form">
            <FormLabel label={orderReviewFormLabels.rate} />
            <Rate
                value={rating}
                onChange={(value) => {
                    setRating(value);
                }}
            />
            <FormLabel label={orderReviewFormLabels.comment} />
            <Input.TextArea
                rows={4}
                placeholder="Leave a comment"
                id="comment"
                value={comment}
                onChange={(e) => {
                    setComment(e.target.value);
                }}
            />
            <Space className="order-details-item-form-buttons">
                <PFlowButton
                    type="primary"
                    htmlType="submit"
                    className="order-details-form-submit-button"
                    onClick={async () => {
                        await onCreateFinish();
                        props.closeReviewCard();
                    }}
                >
                    Submit
                </PFlowButton>
                <PFlowButton
                    htmlType="reset"
                    className="order-details-form-cancel-button"
                    onClick={() => {
                        setRating(0);
                        setComment("");
                        props.closeReviewCard();
                    }}
                >
                    Cancel
                </PFlowButton>
            </Space>
        </PBox>
    );
};

const EditRateForm = (props) => {
    const [rating, setRating] = useState(props.item.review.rating);
    const [comment, setComment] = useState(props.item.review.comment);

    const onEditFinish = async () => {
        const values = {};
        values.user_uuid = props.user_uuid;
        values.rating = rating;
        values.comment = comment;
        values.review_id = props.item.review.id;
        values.order_item_id = props.item.id;
        values.product_id = props.item.product.id;
        values.initial_rating = props.item.review.rating;
        console.log("Received values of form: ", values);
        await props.editReview(props.user_uuid, values.review_id, values);
    };

    const editRateFormStyles = {
        "& .rate-form-submit-button": {
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "#5344a9",
            borderColor: "#5344a9",
            "&:hover": {
                backgroundColor: "#fff",
                color: "#5344a9",
            },
        },
        "& .rate-form-cancel-button": {
            cursor: "pointer",
            marginRight: "20px",
            backgroundColor: "rgb(238, 236, 255)",
            color: "#5344a9",
            borderColor: "rgb(238, 236, 255)",
            "&:hover": {
                borderColor: "#5344a9",
                backgroundColor: "#fff",
            },
        },
    };

    return (
        <PBox className="order-details-item-form" css={editRateFormStyles}>
            <FormLabel label={orderReviewFormLabels.rate} />
            <Rate
                value={rating}
                onChange={(value) => {
                    setRating(value);
                }}
            />
            <FormLabel label={orderReviewFormLabels.comment} />
            <Input.TextArea
                rows={4}
                placeholder="Leave a comment"
                id="comment"
                value={comment}
                onChange={(e) => {
                    setComment(e.target.value);
                }}
            />
            <Space className="order-details-item-form-buttons">
                <PFlowButton
                    type="primary"
                    htmlType="submit"
                    className="rate-form-submit-button"
                    onClick={async () => {
                        await onEditFinish();
                        await props.reFetch();
                        props.toggleEditModes(props.idx);
                    }}
                >
                    Update
                </PFlowButton>
                <PFlowButton
                    htmlType="reset"
                    className="rate-form-cancel-button"
                    onClick={() => {
                        props.toggleEditModes(props.idx);
                    }}
                >
                    Cancel
                </PFlowButton>
            </Space>
        </PBox>
    );
};

const ReceiptForm = (props) => {
    const [receiptEmail, setReceiptEmail] = useState("");

    const onSendReceiptFinish = (values) => {
        props.sendReceipt(receiptEmail, props.user_uuid, props.order_number);
        props.closeModal();
    };
    const receiptFormStyles = {
        "& .receipt-form-submit-button": {
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "#5344a9",
            borderColor: "#5344a9",
            "&:hover": {
                backgroundColor: "#fff",
                color: "#5344a9",
            },
        },
        "& .receipt-form-cancel-button": {
            cursor: "pointer",
            marginRight: "20px",
            backgroundColor: "rgb(238, 236, 255)",
            color: "#5344a9",
            borderColor: "rgb(238, 236, 255)",
            "&:hover": {
                borderColor: "#5344a9",
                backgroundColor: "#fff",
            },
        },
    };
    return (
        <PBox css={receiptFormStyles}>
            <Form
                name="get_email_receipt"
                layout="vertical"
                onFinish={onSendReceiptFinish}
            >
                <FormLabel
                    label={{
                        is_required: true,
                        title: "Email",
                        description:
                            "We'll send the receipt to the email entered below.",
                    }}
                />
                <Form.Item
                    name="email"
                    rules={[
                        {
                            validator: async (_, email) => {
                                if (!receiptEmail) {
                                    return Promise.reject(
                                        new Error("Please enter your email.")
                                    );
                                }
                            },
                        },
                    ]}
                >
                    <Input
                        placeholder="Email"
                        id="email"
                        onChange={(e) => {
                            setReceiptEmail(e.target.value);
                        }}
                    />
                </Form.Item>
                <Form.Item className="account-form-buttons">
                    <Space>
                        <PFlowButton
                            type="primary"
                            htmlType="submit"
                            className="receipt-form-submit-button"
                        >
                            Submit
                        </PFlowButton>
                        <PFlowButton
                            htmlType="reset"
                            className="receipt-form-cancel-button"
                            onClick={() => {
                                props.closeModal();
                            }}
                        >
                            Cancel
                        </PFlowButton>
                    </Space>
                </Form.Item>
            </Form>
        </PBox>
    );
};

const ResendDownloadForm = (props) => {
    const resendDownloadStyles = {
        "& .resend-download-form-submit-button": {
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "#5344a9",
            borderColor: "#5344a9",
            "&:hover": {
                backgroundColor: "#fff",
                color: "#5344a9",
            },
        },
        "& .resend-download-form-cancel-button": {
            cursor: "pointer",
            marginRight: "20px",
            backgroundColor: "rgb(238, 236, 255)",
            color: "#5344a9",
            borderColor: "rgb(238, 236, 255)",
            "&:hover": {
                borderColor: "#5344a9",
                backgroundColor: "#fff",
            },
        },
    };
    return (
        <PBox css={resendDownloadStyles}>
            <PText>
                You have {props.resendsLeft} times left to request to resend the
                product.
            </PText>
            <PText>
                By clicking Resend, we will resend the product download email to
                the email address you entered during payment.
            </PText>
            <PFlowButton
                className="resend-download-form-submit-button"
                onClick={async () => {
                    await props.resendDownloadLink();
                    await props.reFetch();
                    props.closeModal();
                }}
            >
                Resend
            </PFlowButton>
        </PBox>
    );
};

const RefundForm = (props) => {
    const onRefundFinish = (values) => {
        props.closeModal();
    };
    return (
        <Form name="request_refund" layout="vertical" onFinish={onRefundFinish}>
            <FormLabel label={requestRefundFormLabels.email} />
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: "Please enter your email",
                    },
                ]}
            >
                <Input placeholder="Email" id="email" />
            </Form.Item>
            <FormLabel label={requestRefundFormLabels.refund_reason} />
            <Form.Item
                name="refund_reason"
                rules={[
                    {
                        required: true,
                        message: "Please enter reason of your request.",
                    },
                ]}
            >
                <Input.TextArea
                    rows={4}
                    placeholder="Reason to request a refund."
                    id="refund-reason"
                />
            </Form.Item>
            <Form.Item className="account-form-buttons">
                <Space>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button
                        htmlType="reset"
                        onClick={() => {
                            props.closeModal();
                        }}
                    >
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

class OrderDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            showReviewCard: false,
            editModes: Array(this.props.order?.order_items?.length).fill(false),
            isViewReceiptModalOpen: false,
            isSendReceiptModalOpen: false,
            isRefundModalOpen: false,
            isResendDownloadModalOpen: false,
        };
    }

    componentDidMount() {
        this.props
            .fetchOrderDetailByOrderID(
                this.props.match.params.user_uuid,
                this.props.match.params.order_uuid,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    toggleEditModes = (idx) => {
        let editModes = [...this.state.editModes];
        let editMode = editModes[idx];
        editModes[idx] = !editMode;
        this.setState({ editModes: editModes });
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PBox className="order-details">
                        <PBox className="order-details-title">
                            <PBox className="order-details-title-left">
                                <PH1>Order Details</PH1>
                                <PBox className="order-details-title-meta-info">
                                    <PBox className="order-details-item-order-number">
                                        <PH4>Order number</PH4>
                                        <PText>
                                            {this.props.order?.order_number}
                                        </PText>
                                    </PBox>
                                    <PBox className="order-details-item-date-placed">
                                        <PH4>Date placed</PH4>
                                        <PText>
                                            {new Date(
                                                this.props.order?.created_at
                                            ).toDateString()}
                                        </PText>
                                    </PBox>
                                    <PBox className="order-details-item-total-amount">
                                        <PH4>Total amount</PH4>
                                        <PText>
                                            ${this.props.order?.total}
                                        </PText>
                                    </PBox>
                                </PBox>
                            </PBox>
                            <PBox className="order-details-title-quick-buttons">
                                <PLink
                                    href={`/buyer/${this.props.match.params.user_uuid}/order-history`}
                                >
                                    Back to Orders<span> →</span>
                                </PLink>
                                <PLink
                                    onClick={() => {
                                        this.setState({
                                            isViewReceiptModalOpen: true,
                                        });
                                    }}
                                >
                                    View Receipt
                                </PLink>
                                <Modal
                                    title="View receipt"
                                    open={this.state.isViewReceiptModalOpen}
                                    footer={null}
                                    onCancel={() => {
                                        this.setState({
                                            isViewReceiptModalOpen: false,
                                        });
                                    }}
                                    width={700}
                                >
                                    <BuyerReceiptDetail
                                        order_number={
                                            this.props.order?.order_number
                                        }
                                        subtotal={this.props.order?.subtotal}
                                        tax={this.props.order?.tax}
                                        transaction_fee={
                                            this.props.order?.transaction_fee
                                        }
                                        total={this.props.order?.total}
                                        order_date={new Date(
                                            this.props.order?.created_at
                                        ).toDateString()}
                                        customer_name={
                                            this.props.order?.customer_name
                                        }
                                        customer_address_line1={
                                            this.props.order?.customer_address
                                                ?.line1
                                        }
                                        customer_address_line2={
                                            this.props.order?.customer_address
                                                ?.line2
                                        }
                                        customer_address_city={
                                            this.props.order?.customer_address
                                                ?.city
                                        }
                                        customer_address_state={
                                            this.props.order?.customer_address
                                                ?.state
                                        }
                                        customer_address_country={
                                            this.props.order?.customer_address
                                                ?.country
                                        }
                                        customer_address_postal_code={
                                            this.props.order?.customer_address
                                                ?.postal_code
                                        }
                                        customer_email={
                                            this.props.order?.customer_email
                                        }
                                        payment_method={this.props.order?.stripe_charge_object?.payment_method_details?.card?.brand.toUpperCase()}
                                        payment_last4={
                                            this.props.order
                                                ?.stripe_charge_object
                                                ?.payment_method_details?.card
                                                ?.last4
                                        }
                                        order_items={
                                            this.props.order?.order_items
                                        }
                                    />
                                </Modal>
                                <PLink
                                    onClick={() => {
                                        this.setState({
                                            isSendReceiptModalOpen: true,
                                        });
                                    }}
                                >
                                    Email Receipt
                                </PLink>
                                <Modal
                                    title="Get an email receipt"
                                    open={this.state.isSendReceiptModalOpen}
                                    footer={null}
                                    onCancel={() => {
                                        this.setState({
                                            isSendReceiptModalOpen: false,
                                        });
                                    }}
                                >
                                    <ReceiptForm
                                        closeModal={() => {
                                            this.setState({
                                                isSendReceiptModalOpen: false,
                                            });
                                        }}
                                        sendReceipt={this.props.sendReceipt}
                                        user_uuid={
                                            this.props.match.params.user_uuid
                                        }
                                        order_number={
                                            this.props.match.params.order_uuid
                                        }
                                    />
                                </Modal>
                            </PBox>
                        </PBox>

                        {this.props.order?.order_items.map((item, idx) => {
                            return (
                                <PBox className="order-details-item">
                                    <PBox className="order-details-item-product">
                                        <PBox className="order-details-item-product-content">
                                            <PBox className="order-details-item-thumbnail">
                                                <Link
                                                    to={`/item/${item.product?.uuid}`}
                                                >
                                                    <PImg
                                                        src={
                                                            item.product
                                                                ?.product_cover_image
                                                                ?.url
                                                        }
                                                    />
                                                </Link>
                                            </PBox>
                                            <PBox className="order-details-item-content">
                                                <PBox className="order-details-item-desc">
                                                    <PH4>
                                                        <PLink
                                                            href={`/item/${item?.product?.uuid}`}
                                                        >
                                                            {
                                                                item?.product
                                                                    ?.product_name
                                                            }
                                                        </PLink>
                                                    </PH4>
                                                    <PText>
                                                        {
                                                            item?.product
                                                                ?.product_highlights
                                                        }
                                                    </PText>
                                                </PBox>
                                                <PBox className="order-details-item-price-quantity">
                                                    <PBox className="order-details-item-price">
                                                        <PText>
                                                            <PSpan>
                                                                <b>Price </b>
                                                            </PSpan>
                                                            $
                                                            {
                                                                item.product
                                                                    ?.price
                                                            }
                                                        </PText>
                                                    </PBox>
                                                    <PBox className="order-details-item-quantity">
                                                        <PText>
                                                            <PSpan>
                                                                <b>Quantity </b>
                                                            </PSpan>

                                                            {item.quantity}
                                                        </PText>
                                                    </PBox>
                                                </PBox>
                                                <PBox className="order-details-item-buttons">
                                                    {!item.review && (
                                                        <PLink
                                                            onClick={() =>
                                                                this.setState({
                                                                    showReviewCard: true,
                                                                })
                                                            }
                                                        >
                                                            Write A Review
                                                        </PLink>
                                                    )}
                                                    {item.review && (
                                                        <PLink
                                                            onClick={() =>
                                                                this.setState({
                                                                    showReviewCard: true,
                                                                })
                                                            }
                                                        >
                                                            Edit Your Review
                                                        </PLink>
                                                    )}
                                                    <PLink
                                                        onClick={() => {
                                                            this.setState({
                                                                isResendDownloadModalOpen: true,
                                                            });
                                                        }}
                                                    >
                                                        Did Not Receive
                                                    </PLink>
                                                    <Modal
                                                        title="Did not receive download email"
                                                        open={
                                                            this.state
                                                                .isResendDownloadModalOpen
                                                        }
                                                        footer={null}
                                                        onCancel={() => {
                                                            this.setState({
                                                                isResendDownloadModalOpen: false,
                                                            });
                                                        }}
                                                    >
                                                        <ResendDownloadForm
                                                            resendsLeft={
                                                                this.props.order
                                                                    ?.resends_left
                                                            }
                                                            resendDownloadLink={async () => {
                                                                await this.props.resendDownloadLink(
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .user_uuid,
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .order_uuid
                                                                );
                                                            }}
                                                            closeModal={() => {
                                                                this.setState({
                                                                    isResendDownloadModalOpen: false,
                                                                });
                                                            }}
                                                            reFetch={async () => {
                                                                await this.props.fetchOrderDetailByOrderID(
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .user_uuid,
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .order_uuid,
                                                                    this.props
                                                                        .history
                                                                );
                                                            }}
                                                        />
                                                    </Modal>
                                                    <PLink
                                                        href={`/checkout/${item.product?.uuid}`}
                                                    >
                                                        Buy Again
                                                    </PLink>
                                                </PBox>
                                            </PBox>
                                        </PBox>
                                    </PBox>
                                    {this.state.showReviewCard && (
                                        <PBox className="order-details-item-review-form">
                                            <PBox className="order-details-section-title">
                                                <PBox className="order-details-section-title-left">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="32"
                                                        height="32"
                                                        fill="#000000"
                                                        viewBox="0 0 256 256"
                                                    >
                                                        <path d="M229.66,58.34l-32-32a8,8,0,0,0-11.32,0l-96,96A8,8,0,0,0,88,128v32a8,8,0,0,0,8,8h32a8,8,0,0,0,5.66-2.34l96-96A8,8,0,0,0,229.66,58.34ZM124.69,152H104V131.31l64-64L188.69,88ZM200,76.69,179.31,56,192,43.31,212.69,64ZM224,120v88a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h88a8,8,0,0,1,0,16H48V208H208V120a8,8,0,0,1,16,0Z"></path>
                                                    </svg>
                                                    <PH3>Review</PH3>
                                                </PBox>
                                                {item.review && (
                                                    <PBox className="order-details-review-buttons">
                                                        {!this.state.editModes[
                                                            idx
                                                        ] && (
                                                            <PLink
                                                                onClick={() => {
                                                                    this.toggleEditModes(
                                                                        idx
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </PLink>
                                                        )}
                                                        {this.state.editModes[
                                                            idx
                                                        ] && (
                                                            <PLink
                                                                onClick={() =>
                                                                    this.toggleEditModes(
                                                                        idx
                                                                    )
                                                                }
                                                            >
                                                                View
                                                            </PLink>
                                                        )}{" "}
                                                        <Popconfirm
                                                            title="Delete the review"
                                                            description="Are you sure to delete this review?"
                                                            onConfirm={async () => {
                                                                await this.props.deleteReview(
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .user_uuid,
                                                                    item.review
                                                                        .id
                                                                );
                                                                await this.props.fetchOrderDetailByOrderID(
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .user_uuid,
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .order_uuid,
                                                                    this.props
                                                                        .history
                                                                );
                                                                this.setState({
                                                                    showReviewCard: false,
                                                                });
                                                            }}
                                                            onCancel={() => {}}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <PLink>
                                                                Delete
                                                            </PLink>
                                                        </Popconfirm>
                                                        <PLink
                                                            onClick={() => {
                                                                this.setState({
                                                                    showReviewCard: false,
                                                                });
                                                            }}
                                                        >
                                                            Close
                                                        </PLink>
                                                    </PBox>
                                                )}
                                            </PBox>
                                            {item.review &&
                                                !this.state.editModes[idx] && (
                                                    <PBox>
                                                        <PText>
                                                            Rating:{" "}
                                                            {item.review.rating}
                                                        </PText>
                                                        <PText>
                                                            Comment:{" "}
                                                            {
                                                                item.review
                                                                    .comment
                                                            }
                                                        </PText>
                                                    </PBox>
                                                )}
                                            {item.review &&
                                                this.state.editModes[idx] && (
                                                    <EditRateForm
                                                        idx={idx}
                                                        item={item}
                                                        user_uuid={
                                                            this.props.match
                                                                .params
                                                                .user_uuid
                                                        }
                                                        editReview={
                                                            this.props
                                                                .editReview
                                                        }
                                                        toggleEditModes={
                                                            this.toggleEditModes
                                                        }
                                                        reFetch={async () => {
                                                            await this.props.fetchOrderDetailByOrderID(
                                                                this.props.match
                                                                    .params
                                                                    .user_uuid,
                                                                this.props.match
                                                                    .params
                                                                    .order_uuid,
                                                                this.props
                                                                    .history
                                                            );
                                                        }}
                                                    />
                                                )}
                                            {!item.review && (
                                                <RateForm
                                                    item={item}
                                                    user_uuid={
                                                        this.props.match.params
                                                            .user_uuid
                                                    }
                                                    createReview={
                                                        this.props.createReview
                                                    }
                                                    closeReviewCard={async () => {
                                                        await this.props.fetchOrderDetailByOrderID(
                                                            this.props.match
                                                                .params
                                                                .user_uuid,
                                                            this.props.match
                                                                .params
                                                                .order_uuid,
                                                            this.props.history
                                                        );
                                                        this.setState({
                                                            showReviewCard: false,
                                                        });
                                                    }}
                                                />
                                            )}
                                        </PBox>
                                    )}
                                </PBox>
                            );
                        })}
                        <PBox className="order-details-order-summary">
                            <PBox className="order-details-billing-address">
                                <PH4>Billing address</PH4>
                                <PText>{this.props.order?.customer_name}</PText>
                                <PText>
                                    {this.props.order?.customer_address?.line1}
                                </PText>
                                <PText>
                                    {this.props.order?.customer_address?.line2}
                                </PText>
                                <PText>
                                    {this.props.order?.customer_address?.city}
                                    {this.props.order?.customer_address?.city &&
                                        ", "}
                                    {this.props.order?.customer_address?.state}
                                    {this.props.order?.customer_address
                                        ?.state && ", "}
                                    {
                                        this.props.order?.customer_address
                                            ?.country
                                    }
                                    {this.props.order?.customer_address
                                        ?.country && ", "}
                                    {
                                        this.props.order?.customer_address
                                            ?.postal_code
                                    }{" "}
                                </PText>
                            </PBox>
                            <PBox className="order-details-payment-information">
                                <PH4>Payment information</PH4>
                                <PText>
                                    {this.props.order?.stripe_charge_object?.payment_method_details?.card?.brand.toUpperCase()}
                                </PText>
                                <PText>
                                    <PSpan>Ending with </PSpan>
                                    {
                                        this.props.order?.stripe_charge_object
                                            ?.payment_method_details?.card
                                            ?.last4
                                    }
                                </PText>
                            </PBox>
                            <PBox className="order-details-breakdown">
                                <PBox className="order-details-breakdown-item">
                                    <PText>Subtotal</PText>
                                    <PText>${this.props.order?.subtotal}</PText>
                                </PBox>
                                <PBox className="order-details-breakdown-item">
                                    <PText>Transaction fee</PText>
                                    <PText>
                                        ${this.props.order?.transaction_fee}
                                    </PText>
                                </PBox>
                                <PBox className="order-details-breakdown-item">
                                    <PText>Tax</PText>
                                    <PText>${this.props.order?.tax}</PText>
                                </PBox>
                                <PBox className="order-details-breakdown-item">
                                    <PText>Order total</PText>
                                    <PText>${this.props.order?.total}</PText>
                                </PBox>
                            </PBox>
                        </PBox>
                    </PBox>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    margin: "0 auto",
    padding: "6rem 2rem",
    maxWidth: "80rem",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",

    "& .order-details": {
        maxWidth: "64rem",
        margin: "0 auto",
    },
    "& .order-details-title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        borderBottom: "1px solid $colors$borderColor",
        "& h1": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            color: "rgb(17 24 39)",
            fontWeight: "700",
            margin: "0",
        },
        "& p": { margin: "0.5rem 0 0 0" },
        "& .order-details-title-left": {
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            paddingBottom: "2.5rem",
            color: "rgb(107 114 128)",
            fontWeight: "500",
            "& span": { color: "rgb(17 24 39)" },
        },
        "& .order-details-title-meta-info": {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "1.5rem",
        },
        "& .order-details-item-order-number": {
            marginRight: "1.5rem",
            "& h4": { color: "rgb(17 24 39)", fontWeight: "500", margin: "0" },
            "& p": { color: "rgb(107 114 128)", margin: "0.25rem 0 0 0" },
        },
        "& .order-details-item-date-placed": {
            marginRight: "1.5rem",
            "& h4": { color: "rgb(17 24 39)", fontWeight: "500", margin: "0" },
            "& p": { color: "rgb(107 114 128)", margin: "0.25rem 0 0 0" },
            display: "none",
            "@bp2": { display: "block" },
        },
        "& .order-details-item-total-amount": {
            marginRight: "1.5rem",
            "& h4": { color: "rgb(17 24 39)", fontWeight: "500", margin: "0" },
            "& p": {
                color: "rgb(17 24 39)",
                fontWeight: "500",
                margin: "0.25rem 0 0 0",
            },
        },
        "& .order-details-title-quick-buttons": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            alignItems: "end",
            fontWeight: "500",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            paddingBottom: "2.5rem",
            "& a": {
                textDecoration: "none",
                color: "$colors$primary",
                whiteSpace: "nowrap",
                cursor: "pointer",
                marginTop: "0.5rem",
            },
        },
    },
    "& .order-details-item": {
        padding: "1.5rem 0",
        borderBottom: "1px solid $colors$borderColor",
    },
    "& .order-details-item-product": {
        width: `100%`,
    },
    "& .order-details-item-product-content": {
        display: "flex",
        alignItems: "flex-start",
    },
    "& .order-details-item-thumbnail": {
        width: "8rem",
        height: "8rem",
        backgroundColor: "rgb(229 231 235)",
        borderRadius: "0.5rem",
        overflow: "hidden",
        flexShrink: "0",
        "& img": {
            objectPosition: "center",
            objectFit: "cover",
            width: "100%",
            height: "100%",
        },
    },
    "& .order-details-item-content": {
        marginLeft: "1.5rem",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        flex: "1 1 0%",
        "& .order-details-item-desc": {
            "& h4": { margin: "0" },
            "& p": {
                margin: "0",
                color: "rgb(107 114 128)",
                marginTop: "0.5rem",
                overflow: "hidden",
                display: "-webkit-box",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": "2",
            },
            "& a": {
                textDecoration: "none",
                color: "rgb(17 24 39)",
                "&:hover": { color: "$colors$primary" },
            },
        },
        "& .order-details-item-price-quantity": {
            display: "flex",
            alignItems: "center",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            marginTop: "0.5rem",
        },
        "& .order-details-item-price": {
            color: "rgb(55 65 81)",
            "& span": { color: "rgb(17 24 39)", fontWeight: "500" },
        },
        "& .order-details-item-quantity": {
            color: "rgb(55 65 81)",
            marginLeft: "1.5rem",
            borderLeft: "1px solid $colors$borderColor",
            paddingLeft: "1.5rem",
            "& span": { color: "rgb(17 24 39)", fontWeight: "500" },
        },
        "& p": { margin: "0" },
    },
    "& .order-details-item-buttons": {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontWeight: "500",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        paddingTop: "0.625rem",
        "& a": {
            textDecoration: "none",
            color: "$colors$primary",
            whiteSpace: "nowrap",
            cursor: "pointer",
        },
        "& a:not(:last-child)": {
            marginRight: "1rem",
        },
    },
    "& .order-details-item-review-form": {
        padding: "0 1.5rem 1.5rem 1.5rem",
        border: "1px solid $colors$borderColor",
        borderRadius: "0.375rem",
        marginTop: "1.5rem",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
    },
    "& .order-details-payment-total": {
        margin: "20px",
    },
    "& .form-hidden-item": {
        display: "none",
    },
    "& .order-details-section-title": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
        borderBottom: "1px solid $colors$borderColor",
        "& svg": {
            fill: "$colors$primary",
            marginRight: "10px",
        },
    },
    "& .order-details-section-title-left": {
        display: "flex",
        alignItems: "center",
    },
    "& .order-details-item-form-buttons": {
        marginTop: "20px",
    },
    "& .order-details-form-submit-button": {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderColor: "$colors$primary",
        "&:hover": {
            backgroundColor: "#fff",
            color: "$colors$primary",
        },
    },
    "& .order-details-form-cancel-button": {
        cursor: "pointer",
        marginRight: "20px",
        backgroundColor: "$colors$lightPrimary",
        color: "$colors$primary",
        borderColor: "$colors$lightPrimary",
        "&:hover": {
            borderColor: "$colors$Primary",
            backgroundColor: "#fff",
        },
    },
    "& .order-details-order-info-content": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "top",
    },
    "& .order-details-order-info": { textAlign: "right" },
    "& .order-details-review-buttons": {
        display: "flex",
        "& a": {
            cursor: "pointer",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            border: "1px solid $colors$primary",
            padding: "4px 12px",
            borderRadius: "8px",
            backgroundColor: "#fff",
            color: "$colors$primary",
            fontWeight: "600",
            fontSize: "14px",
            "& svg": { fill: "$colors$primary" },
            "&:hover": {
                backgroundColor: "$colors$primary",
                color: "#fff",
                "& svg": { fill: "#fff" },
            },
        },
    },
    "& .order-details-order-summary": {
        marginTop: "6rem",
        backgroundColor: "rgb(249 250 251)",
        borderRadius: "0.5rem",
        padding: "1rem 2rem",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        display: "grid",
        columnGap: "2rem",
        gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
        "& h4": {
            color: "rgb(17 24 39)",
            fontWeight: "500",
            marginBottom: "0.75rem",
        },
        "& p": {
            color: "rgb(107 114 128)",
        },
    },
    "& .order-details-billing-address": {
        gridColumn: "1 / 7",
        "@bp4": { gridColumn: "1 / 4" },
    },
    "& .order-details-payment-information": {
        gridColumn: "7 / 13",
        "@bp4": { gridColumn: "4 / 7" },
    },
    "& .order-details-breakdown": {
        gridColumn: "1 / 13",
        marginTop: "1.5rem",
        "@bp4": { gridColumn: "7 / 13", marginTop: "0" },
        "& .order-details-breakdown-item:not(:last-child)": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid $colors$borderColor",
            "& p:last-child": { color: "rgb(17 24 39)", fontWeight: "500" },
        },
        "& .order-details-breakdown-item:last-child": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& p:first-child": {
                color: "rgb(17 24 39)",
                fontWeight: "500",
            },
            "& p:last-child": {
                color: "$colors$primary",
                fontWeight: "500",
            },
        },
    },
};

function mapStateToProps(state) {
    return {
        order: state.orderReducer?.fetchOrderDetail?.order,
    };
}

export default connect(mapStateToProps, {
    fetchOrderDetailByOrderID,
    createReview,
    editReview,
    deleteReview,
    sendReceipt,
    resendDownloadLink,
})(withRouter(OrderDetails));

import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PLink,
    PBox,
    PH2,
    PText,
    PHighlightButton,
    PHighlightLink,
    PH4,
} from "../../theme/BaseTheme";
import { DownloadLayout } from "./DownloadLayout";
import { Loader } from "../../components/Loader";
import { fetchDownloadLinks } from "../../../actions/download";
import { HTMLRenderWrapper } from "../../components/editor/HTMLRenderWrapper";
import { keys } from "../../../config/keys";

class DownloadFiles extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchDownloadLinks(this.props.match.params.token)
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <DownloadLayout>
                <PBox css={styles}>
                    <PBox className="download-files-title">
                        <PH2>
                            Download Your Purchase:{" "}
                            {
                                this.props.downloads[
                                    this.props.match.params.token
                                ].downloads.product_name
                            }
                        </PH2>
                        <PText>
                            Thank you for your purchase from{" "}
                            <PLink
                                href={`${keys.baseUrl}/shop/${
                                    this.props.downloads[
                                        this.props.match.params.token
                                    ].downloads.shop_uuid
                                }/1`}
                            >
                                {
                                    this.props.downloads[
                                        this.props.match.params.token
                                    ].downloads.shop_name
                                }
                            </PLink>
                            ! Hope you enjoy the product.
                        </PText>
                    </PBox>
                    <PBox className="download-files">
                        <PBox>
                            <HTMLRenderWrapper>
                                {
                                    this.props.downloads[
                                        this.props.match.params.token
                                    ].downloads.content
                                }
                            </HTMLRenderWrapper>
                        </PBox>
                        <PBox className="download-button-group">
                            {this.props.downloads[
                                this.props.match.params.token
                            ].downloads.links.map((link, idx) => {
                                return (
                                    <PBox>
                                        <PH4>Dowload File {idx + 1}: </PH4>
                                        <PLink
                                            href={link}
                                            className="download-button"
                                        >
                                            Click to Download
                                        </PLink>
                                    </PBox>
                                );
                            })}
                        </PBox>
                    </PBox>
                </PBox>
            </DownloadLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    "& .download-files-title": {
        fontSize: "1rem",
        marginTop: "1rem",
    },
    "& .download-files": {
        width: "48rem",
        minHeight: "30rem",
        marginTop: "2rem",
        backgroundColor: "#fff",
        borderRadius: "0.5rem",
    },
    "& h2": {
        color: "$colors$text",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$primary",
        cursor: "pointer",
        textDecoration: "none",
        fontWeight: "600",
        "&:hover": {
            color: "$colors$primary500",
        },
    },
    "& .download-button": {
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderRadius: "0.375rem",
        marginTop: "1.5rem",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        padding: "0.625rem 0.875rem",
        display: "block",
        textAlign: "center",
        "&:hover": {
            color: "#fff",
            backgroundColor: "$colors$primary500",
        },
    },
    "& .download-button-group": {
        margin: "4rem auto",
        position: "relative",
        "& h4": {
            textAlign: "left",
        },
        "& a": {
            textDecoration: "none",
        },
    },
};

function mapStateToProps(state) {
    return {
        downloads: state.downloadReducer?.fetchDownloads,
    };
}
export default connect(mapStateToProps, { fetchDownloadLinks })(
    withRouter(DownloadFiles)
);

import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { PBox, PLi, PLink, PSpan, PUl } from "../../theme/BaseTheme";

export const Breadcrumbs = (props) => {
    const data = [
        { category: "Home", url: "/" },
        { category: props.category, url: "#" },
    ];
    const history = useHistory();
    return (
        <PBox css={styles} className="breadcrumbs">
            <PUl className="breadcrumbs-list">
                <PLi>
                    <PLink href="/">Home</PLink>
                </PLi>
                <PLi>/</PLi>
                <PLi>
                    <PLink
                        onClick={() => {
                            history.push({
                                pathname: "/search",
                                search: "cat=" + '"' + props.category + '"',
                            });
                        }}
                    >
                        {props.category}
                    </PLink>
                </PLi>
            </PUl>
        </PBox>
    );
};

const styles = {
    "& .breadcrumbs-list": {
        display: "flex",
        listStyle: "none",
        fontSize: "15px",
        alignItems: "center",
        flexDirection: "row",
        margin: "0",
        "& li": {
            paddingLeft: "4px",
            paddingRight: "4px",
            margin: "0",
            "& svg": {
                margin: "0",
                paddingBottom: "2px",
                verticalAlign: "middle",
                fill: "$colors$text",
            },
            "& a": {
                textDecoration: "none",
                color: "$colors$text",
            },
            "& a:hover": {
                cursor: "pointer",
                color: "$colors$primary",
                fontWeight: "700",
            },
        },
    },
};

import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { PBox, PImg, PLink, PText } from "../../theme/BaseTheme";
import { Rating } from "./Rating";
import { addProductToSave } from "../../../actions/save";
import { Tooltip } from "antd";

class Card extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {}

    render() {
        return (
            <PBox css={styles}>
                <PBox className="item-card">
                    <PBox className="item-cover">
                        <Link
                            to={`/item/${this.props.product?.uuid}`}
                            target="_blank"
                        >
                            <PImg
                                alt="image"
                                src={
                                    this.props.product?.product_cover_image?.url
                                }
                            />
                        </Link>
                        <PBox
                            className="item-add-to-favorite"
                            onClick={() =>
                                this.props.addProductToSave(
                                    this.props.product,
                                    this.props.userAuth?.userAccount?.uuid
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M178,32c-20.65,0-38.73,8.88-50,23.89C116.73,40.88,98.65,32,78,32A62.07,62.07,0,0,0,16,94c0,70,103.79,126.66,108.21,129a8,8,0,0,0,7.58,0C136.21,220.66,240,164,240,94A62.07,62.07,0,0,0,178,32ZM128,206.8C109.74,196.16,32,147.69,32,94A46.06,46.06,0,0,1,78,48c19.45,0,35.78,10.36,42.6,27a8,8,0,0,0,14.8,0c6.82-16.67,23.15-27,42.6-27a46.06,46.06,0,0,1,46,46C224,147.61,146.24,196.15,128,206.8Z"></path>
                            </svg>{" "}
                            {this.props.product?.product_stat?.number_of_saves}
                        </PBox>
                    </PBox>
                    <PBox className="item-title-wrapper">
                        <PBox className="item-title">
                            <Tooltip title={this.props.product?.product_name}>
                                <Link
                                    to={`/item/${this.props.product?.uuid}`}
                                    target="_blank"
                                >
                                    <PBox className="item-title-content">
                                        {this.props.product?.product_name}
                                    </PBox>
                                </Link>
                            </Tooltip>
                        </PBox>
                    </PBox>
                    <PBox className="item-rating">
                        <PBox>
                            {
                                <Rating
                                    rating={this.props.product?.average_rating}
                                    reviews={
                                        this.props.product?.number_of_ratings
                                    }
                                />
                            }
                        </PBox>
                        <PBox className="item-category">
                            {this.props.product?.category?.category_name}
                        </PBox>
                    </PBox>
                    <PBox className="item-merchant-wrapper">
                        <PBox className="item-merchant">
                            {/*this.props.product?.merchant?.shop_logo && (
                                <PImg
                                    src={
                                        this.props.product?.merchant?.shop_logo
                                            ?.url
                                    }
                                />
                                )*/}
                            {/*!this.props.product?.merchant?.shop_logo && (
                                <PImg src={"/images/default-shop-logo.png"} />
                            )*/}
                            <PBox className="item-seller">
                                <PText>Seller</PText>
                                <Link
                                    className="item-merchant-name"
                                    to={`/shop/${this.props.product?.merchant?.uuid}/1`}
                                    target="_blank"
                                >
                                    {this.props.product?.merchant?.shop_name}
                                </Link>
                            </PBox>
                        </PBox>
                        <PBox className="item-price">
                            <PText className="item-price-label">Price</PText>
                            {this.props.product?.price > 0 && (
                                <PText className="item-price-value">
                                    ${this.props.product?.price}
                                </PText>
                            )}
                            {this.props.product?.price === 0 && (
                                <PText className="item-price-value">Free</PText>
                            )}
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        );
    }
}

const styles = {
    //width: "240px",
    //padding: "20px",
    borderRadius: "0.75rem",
    transition: "all .4s ease",
    "-moz-transition": "all .4s ease",
    "-webkit-transition": "all .4s ease",
    //boxShadow: "0px 3px 16px rgba(47, 83, 109, 0.12)",
    border: "1px solid $colors$borderColor",
    marginBottom: "20px",
    overflow: "hidden",
    "&:hover": {
        transform: "translateY(-10px)",
        "& .item-cover img": {
            transform: "scale(1.1)",
            "-moz-transform": "scale(1.1)",
            "-webkit-transform": "scale(1.1)",
        },
    },
    "& a": {
        textDecoration: "none",
        color: "#000",
    },
    "& .item-card": {},
    "& .item-cover": {
        cursor: "pointer",
        overflow: "hidden",
        display: "block",
        //borderRadius: "0.5rem",
        position: "relative",
        "& img": {
            "@bp1": { height: "10rem" },
            "@bp2": { height: "12rem" },
            "@bp3": { height: "14rem" },
            "@bp4": { height: "16rem" },
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            objectFit: "cover",
            transition: "all .4s ease",
            "-moz-transition": "all .4s ease",
            "-webkit-transition": "all .4s ease",
        },
        "& .item-add-to-favorite": {
            cursor: "pointer",
            position: "absolute",
            right: "13px",
            top: "11px",
            backgroundColor: "#14141f",
            padding: "2px 11px",
            borderRadius: "8px",
            fontWeight: "300px",
            fontSize: "14px",
            lineHeight: "24px",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            "& svg": {
                fill: "#fff",
                margin: "0 4px 0 0",
                display: "block",
                padding: "0",
            },
        },
    },
    "& .item-shop": {
        backgroundColor: "#F9F7F2",
        borderRadius: "4px",
        color: "#212121",
        fontSize: "1rem",
        fontWeight: "400",
        marginBottom: "0.75rem",
        padding: "0.5rem 0.625rem",
        textDecoration: "none",
        display: "inline-block",
        "&:hover": {
            opacity: "0.6",
        },
    },
    "& .item-title-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        padding: "1rem 1rem 0rem 1rem",
    },
    "& .item-title": {
        maxWidth: "100%",
    },
    "& .item-title-content": {
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "0.875remx",
        lineHeight: "1.25",
        fontWeight: "500",
        color: "rgb(17 24 39)",
        textDecoration: "none",
    },
    "& .item-title-content:hover": {
        color: "$colors$primary",
    },
    "& .item-category": {
        fontSize: "12px",
        lineHeight: "24px",
        color: "$colors$text",
        backgroundColor: "$colors$lightBackground",
        borderRadius: "10px",
        padding: "0 4px",
        overflow: "hidden",
    },
    "& .item-merchant-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 1rem",
        marginBottom: "1rem",
    },
    "& .item-merchant": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& img": {
            width: "40px",
            height: "40px",
            borderRadius: "15px",
            marginRight: "12px",
        },
    },
    "& .item-seller": {
        "& p": {
            color: "rgb(107 114 128)",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            margin: "0",
            padding: "0",
        },
        "& a": {
            color: "rgb(17 24 39)",
            fontWeight: "500",
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        "& a:hover": {
            color: "$colors$primary",
        },
    },
    "& .item-price-label": {
        color: "rgb(107 114 128)",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        margin: "0",
        padding: "0",
        textAlign: "right",
    },
    "& .item-price-value": {
        color: "rgb(17 24 39)",
        fontWeight: "500",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        margin: "0",
        padding: "0",
    },
    "& .item-rating": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        padding: "0.5rem 1rem 0.25rem 1rem",
    },
};

function mapStateToProps(state) {
    return { userAuth: state.authReducer?.userAuth };
}

export default connect(mapStateToProps, { addProductToSave })(withRouter(Card));

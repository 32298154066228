import axios from "axios";
import { message } from "antd";

// For use in seller create new product
export const fetchCategoryList = () => async (dispatch) => {
    await axios
        .get("/api/categories/all")
        .then(async (response) => {
            dispatch({
                type: "FETCH_CATEGORY_LIST_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

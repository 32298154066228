import { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { PBox, PH1, PImg, PH2, PText, PSpan } from "../../theme/BaseTheme";
import { removeProductsFromSave } from "../../../actions/save";
import CardForPay from "./CardForPay";
import CardForFree from "./CardForFree";

const data = [
    {
        thumbnail: "/images/9369841.jpg",
        desc: "A set of well-crafted illustrations which include categories like Artificial intelligence, Machine learning, Blockchain, and more.",
        price: "7.59",
    },
    {
        thumbnail: "/images/62146.jpg",
        desc: "Machine learning, Blockchain, and more.",
        price: "1.90",
    },
    {
        thumbnail: "/images/natalie-behn-4XTXxJl4NQw-unsplash.jpg",
        desc: "A set of well-crafted illustrations blockchain, and more.",
        price: "2.59",
    },
];

const SaveItem = (props) => {
    return (
        <PBox className="cart-item">
            <Link to={`/item/${props.product.uuid}`} target="_blank">
                <PImg
                    className="cart-item-thumbnail"
                    src={props.product.product_cover_image.url}
                />
            </Link>
            <PBox className="cart-item-desc">
                <Link to={`/item/${props.product.uuid}`} target="_blank">
                    {props.product.product_name}
                </Link>
                {props.product?.price > 0 && (
                    <PText>${props.product.price}</PText>
                )}
                {props.product?.price === 0 && <PText>Free</PText>}
                <PBox
                    onClick={() => {
                        props.removeProductsFromSave(
                            props.product,
                            props.user_uuid
                        );
                    }}
                    className="cart-item-remove-button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#000000"
                        viewBox="0 0 256 256"
                    >
                        <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                    </svg>{" "}
                    Remove from Favorites
                </PBox>
            </PBox>
            <PBox className="cart-item-price-buttons">
                {props.product?.price > 0 && (
                    <CardForPay product_uuid={props.product?.uuid} />
                )}
                {props.product?.price === 0 && (
                    <CardForFree product_uuid={props.product?.uuid} />
                )}
            </PBox>
        </PBox>
    );
};

class SaveItems extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {}
    render() {
        return (
            <PBox css={styles} className="cart-items">
                <PBox className="cart-items-title">
                    <PH1>Your Favorites</PH1>
                    <PH2>
                        {this.props.saveProducts?.length}{" "}
                        {this.props.saveProducts?.length > 1 ? "items" : "item"}
                    </PH2>
                </PBox>
                {this.props.saveProducts?.map((item, idx) => {
                    return (
                        <SaveItem
                            user_uuid={this.props.userAuth?.userAccount?.uuid}
                            product={item.product}
                            removeProductsFromSave={
                                this.props.removeProductsFromSave
                            }
                        />
                    );
                })}
            </PBox>
        );
    }
}

const styles = {
    "& .cart-items-title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& h1": {
            fontFamily: "Poppins, sans-serif",
            fontSize: "2rem",
            fontWeight: "500",
            lineHeight: "2.5rem",
            marginTop: "2rem",
            marginBottom: "2rem",
        },
        "& h2": {
            fontWeight: "400",
            marginRight: "10px",
        },
    },
    "& .cart-item": {
        border: "1px solid $colors$borderColor",
        padding: "20px",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "row",
        margin: "20px 0",

        "& .cart-item-thumbnail": {
            width: "100px",
            height: "100px",
            borderRadius: "8px",
        },
        "& .cart-item-desc": {
            flexGrow: "1",
            padding: "4px 20px",
            fontWeight: "400",
            "& a": {
                margin: "0 4px",
                color: "$colors$text",
                lineHeight: "1.5rem",
                textDecoration: "none",
                fontWeight: "600",
            },
            "& a:hover": {
                color: "$colors$primary",
            },
            "& p": { margin: "12px 4px" },
        },
        "& .cart-item-price-buttons": {
            fontWeight: "700",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            margin: "0 40px",
            position: "relative",
            "& svg": { cursor: "pointer", "&:hover": { fill: "#fff" } },
        },
        "& .cart-item-remove-button": {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontWeight: "400",
            "& svg": { marginRight: "6px" },
        },
        "& .cart-item-remove-button:hover": {
            color: "$colors$primary",
            "& svg": { fill: "$colors$primary" },
        },
    },
};

function mapStateToProps(state) {
    return {
        saveProducts: state.saveReducer?.manageSave?.saveProducts,
        userAuth: state.authReducer?.userAuth,
    };
}

export default connect(mapStateToProps, { removeProductsFromSave })(
    withRouter(SaveItems)
);

import { Link } from "react-router-dom";
import { PBox, PImg, PText, PH3, PSpan } from "../../theme/BaseTheme";

export const BlogCard = (props) => {
    return (
        <PBox css={styles}>
            <Link to={props.link}>
                <PImg src={props.cover} />
            </Link>
            <PBox className="blog-card-categories-wrapper">
                <PSpan>{props.date}</PSpan>
                <svg viewBox="0 0 2 2" className="blog-card-separator">
                    <circle cx="1" cy="1" r="1"></circle>
                </svg>
                <PText className="blog-card-categories">
                    {props.categories?.map((cat, idx) => {
                        return (
                            <PSpan>
                                {cat}
                                <svg
                                    viewBox="0 0 2 2"
                                    className="blog-card-separator"
                                >
                                    <circle cx="1" cy="1" r="1"></circle>
                                </svg>
                                By {props.author}
                            </PSpan>
                        );
                    })}
                </PText>
            </PBox>
            <PBox className="blog-card-content">
                <Link to={props.link}>
                    <PH3>{props.title}</PH3>
                    {props.showExcerpt !== false && (
                        <PText>{props.excerpt}</PText>
                    )}
                </Link>
            </PBox>
        </PBox>
    );
};

const styles = {
    width: "100%",
    minHeight: "15px",
    alignSelf: "stretch",
    position: "relative",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "&:hover": {
        transform: "translate(0, -0.5rem)",
        transition: "0.5s ease",
    },
    "& img": {
        width: "100%",
        objectFit: "cover",
        aspectRatio: "3/2",
        borderRadius: "1rem",
        border: "1px solid $colors$borderColor",
    },
    "& .blog-card-categories-wrapper": {
        position: "relative",
        marginTop: "0.25rem",
        "& span": {
            color: "rgb(107 114 128)",
            fontWeight: "500",
            fontSize: "0.75rem",
        },
    },
    "& .blog-card-separator": {
        width: "0.125rem",
        height: "0.125rem",
        fill: "rgb(107 114 128)",
        padding: "0.125rem 0.5rem",
    },
    "& .blog-card-categories": {
        display: "inline-block",
        "& span": {
            color: "rgb(107 114 128)",
            fontWeight: "500",
            fontSize: "0.75rem",
        },
    },
    "& .blog-card-content": {
        "& a": {
            textDecoration: "none",
            color: "$colors$text",
        },
        "& a:hover": {
            color: "$colors$primary",
        },
        "& h3": {
            margin: "0",
            marginTop: "0.25rem",
            fontSize: "1.125rem",
            fontWeight: "600",
            lineHeight: "1.5rem",
            color: "rgb(17 24 39)",
        },
        "& p": {
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontSize: "0.875rem",
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "3",
        },
    },
};

import { combineReducers } from "redux";

const fetchUserAccount = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_USER_ACCOUNT_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                userAccount: action.payload,
            };
        case "FETCH_USER_ACCOUNT_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                userAccount: action.load,
            };
        default:
            return state;
    }
};

export default combineReducers({
    fetchUserAccount,
});

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PaymentElement,
    AddressElement,
    LinkAuthenticationElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { Button, Checkbox, message } from "antd";
import { PBox, PH3, PText, PButton } from "../../theme/BaseTheme";
import { calculateStripeTax } from "../../../actions/stripe";
import { keys } from "../../../config/keys";

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!props.clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(props.clientSecret);
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        await props.calculateStripeTax({
            payment_intent_id: props.paymentIntentID,
            product_uuid: props.product_uuid,
            product_name: props.product_name,
            product_price: props.product_price,
            transaction_fee: props.transaction_fee,
            address: address,
            email: email,
        });

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${keys.baseUrl}/checkout-confirm/`,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            message.error(error.message, 5);
        } else {
            message.error("An unexpected error occurred.", 5);
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
    };

    return (
        <PBox css={styles}>
            <form id="payment-form" onSubmit={handleSubmit}>
                <PH3>Contact Info</PH3>
                <LinkAuthenticationElement
                    id="link-authentication-element"
                    onChange={(e) => setEmail(e.value)}
                />
                <PH3>Payment Info</PH3>
                <PaymentElement
                    id="payment-element"
                    options={paymentElementOptions}
                />
                <PH3>Billing Info</PH3>
                <AddressElement
                    options={{ mode: "billing", allowedCountries: ["US"] }}
                    onChange={(e) => {
                        setAddress(e.value);
                    }}
                />
                <PBox className="payment-checkbox-wrapper">
                    <PText>
                        * Please review your order carefully. All orders{" "}
                        <b>Cannot Be Cancelled</b> and are <b>Non-Refundable</b>
                        .
                    </PText>
                    <PText>
                        * By clicking Pay Now, you acknowledge you have read and
                        agree to our{" "}
                        <a href="/terms" target="_blank">
                            Terms and Conditions
                        </a>{" "}
                        and{" "}
                        <a href="/privacy" target="_blank">
                            Privacy Policy
                        </a>
                        .
                    </PText>
                </PBox>
                <PButton
                    disabled={isLoading || !stripe || !elements}
                    id="submit"
                    className="payment-submit-button"
                    type="primary"
                >
                    <span id="button-text">
                        {isLoading ? (
                            <div className="spinner" id="spinner">
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 38 38"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <defs>
                                        <linearGradient
                                            x1="8.042%"
                                            y1="0%"
                                            x2="65.682%"
                                            y2="23.865%"
                                            id="a"
                                        >
                                            <stop
                                                stop-color="#fff"
                                                stop-opacity="0"
                                                offset="0%"
                                            />
                                            <stop
                                                stop-color="#fff"
                                                stop-opacity=".631"
                                                offset="63.146%"
                                            />
                                            <stop
                                                stop-color="#fff"
                                                offset="100%"
                                            />
                                        </linearGradient>
                                    </defs>
                                    <g fill="none" fill-rule="evenodd">
                                        <g transform="translate(1 1)">
                                            <path
                                                d="M36 18c0-9.94-8.06-18-18-18"
                                                id="Oval-2"
                                                stroke="url(#a)"
                                                stroke-width="4"
                                            >
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    from="0 18 18"
                                                    to="360 18 18"
                                                    dur="0.9s"
                                                    repeatCount="indefinite"
                                                />
                                            </path>
                                            <circle
                                                fill="#fff"
                                                cx="36"
                                                cy="18"
                                                r="1"
                                            >
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    from="0 18 18"
                                                    to="360 18 18"
                                                    dur="0.9s"
                                                    repeatCount="indefinite"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                </svg>
                                Processing Payment
                            </div>
                        ) : (
                            <div className="spinner">Pay Now</div>
                        )}
                    </span>
                </PButton>
            </form>
        </PBox>
    );
};

const styles = {
    "& .payment-submit-button": {
        margin: "30px 0 80px 0",
        color: "#fff",
        backgroundColor: "$colors$primary",
        width: "100%",
        borderColor: "$colors$primary",
        "&:hover": {
            backgroundColor: "$colors$primary500",
        },
        border: "2px solid",
        borderRadius: "40px",
        padding: "12px 40px",
        textDecoration: "none",
        fontWeight: "600",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
        cursor: "pointer",
    },
    "& h3": {
        fontWeight: "500",
        marginTop: "50px",
    },
    "& .payment-checkbox-wrapper": {
        marginTop: "40px",
        lineHeight: "1.6",
        fontSize: "14px",
        color: "$colors$text",
        "& p": {
            margin: "8px",
        },
        "& b": { fontWeight: "600" },
        "& a": {
            color: "$colors$primary",
            fontWeight: "600",
            textDecoration: "none",
        },
    },
    "& .spinner": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            marginRight: "4px",
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, { calculateStripeTax })(
    withRouter(CheckoutForm)
);

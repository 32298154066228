import parse from "html-react-parser";
import { PBox } from "../../theme/BaseTheme";

export const HTMLRenderWrapper = (props) => {
    return (
        <>
            {props.children && (
                <PBox css={styles}>
                    {parse("<div>" + props.children + "</div>")}
                </PBox>
            )}
        </>
    );
};

const styles = {
    width: "100%",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    color: "$colors$text",
    lineHeight: "1.8rem",
    fontSize: "16px",
    "& .ql-emojiblot": {
        marginTop: "-4px",
    },
    "& blockquote": {
        borderLeft: "4px solid $colors$borderColor",
        margin: "5px 0",
        paddingLeft: "16px",
        lineHeight: "1.8rem",
        fontSize: "16px",
    },
    "& h1": {
        fontSize: "1.5rem",
        lineHeight: "2rem",
        "& .ql-emojiblot": {
            marginTop: "-2px",
        },
    },
    "& h2": {
        fontSize: "1.2rem",
        lineHeight: "1.8rem",
    },
    "& h3": {
        fontSize: "1rem",
        lineHeight: "1.6rem",
    },
    "& p": {},
    "& code, & pre": {
        backgroundColor: "#f0f0f0",
        borderRadius: "3px",
    },
    "& pre": {
        whiteSpace: "pre-wrap",
        marginTop: "5px",
        marginBottom: "5px",
        padding: "5px 10px",
    },
    "& code": {
        fontSize: "85%",
        padding: "2px 4px",
    },

    "& ol, & ul": {
        paddingLeft: "1.5rem",
        counterReset:
            "list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9",
    },
    "& ol > li, & ul > li": {
        listStyleType: "none",
    },
    "& ul > li::before": {
        content: "\\2022",
    },
    "& ul[data-checked='true'], & ul[data-checked='false']": {
        pointerEvents: "none",
    },
    "& ul[data-checked='true'] > li *, & ul[data-checked='false'] > li *": {
        pointerEvents: "all",
    },
    "& ul[data-checked='true'] > li::before, & ul[data-checked='false'] > li::before":
        {
            color: "#777",
            cursor: "pointer",
            pointerEvents: "all",
        },
    "& ul[data-checked='true'] > li::before": {
        content: "\\2611",
    },
    "& ul[data-checked='false'] > li::before": {
        content: "'\\2610'",
    },
    "& li::before": {
        display: "inline-block",
        whiteSpace: "nowrap",
        width: "1.2rem",
    },
    "& li:not(.ql-direction-rtl)::before": {
        marginLeft: "-1.5rem",
        marginRight: "0.3rem",
        textAlign: "right",
    },
    "& li .ql-direction-rtl::before": {
        marginLeft: "0.3rem",
        marginRight: "-1.5rem",
    },
    "& ol li:not(.ql-direction-rtl), & ul li:not(.ql-direction-rtl)": {
        paddingLeft: "1.5rem",
    },
    "& ol li .ql-direction-rtl, & ul li .ql-direction-rtl": {
        paddingRight: "1.5rem",
    },
    "& ol li": {
        counterReset:
            "list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9",
        counterIncrement: "list-0",
    },
    "& ol li:before": {
        content: "counter(list-0, decimal) '. '",
    },
    "& ol li .ql-indent-1": {
        counterIncrement: "list-1",
    },
    "& ol li .ql-indent-1:before": {
        content: "counter(list-1, lower-alpha) '. '",
    },
    "& ol li .ql-indent-1": {
        counterReset: "list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9",
    },
    "& ol li .ql-indent-2": {
        counterIncrement: "list-2",
    },
    "& ol li .ql-indent-2:before": {
        content: "counter(list-2, lower-roman) '. '",
    },
    "& ol li .ql-indent-2": {
        counterReset: "list-3 list-4 list-5 list-6 list-7 list-8 list-9",
    },
    "& ol li .ql-indent-3": {
        counterIncrement: "list-3",
    },
    "& ol li .ql-indent-3:before": {
        content: "counter(list-3, decimal) '. '",
    },
    "& ol li .ql-indent-3": {
        counterReset: "list-4 list-5 list-6 list-7 list-8 list-9",
    },
    "& ol li .ql-indent-4": {
        counterIncrement: "list-4",
    },
    "& ol li .ql-indent-4:before": {
        content: "counter(list-4, lower-alpha) '. '",
    },
    "& ol li .ql-indent-4": {
        counterReset: "list-5 list-6 list-7 list-8 list-9",
    },
    "& ol li .ql-indent-5": {
        counterIncrement: "list-5",
    },
    "& ol li .ql-indent-5:before": {
        content: "counter(list-5, lower-roman) '. '",
    },
    "& ol li .ql-indent-5": {
        counterReset: "list-6 list-7 list-8 list-9",
    },
    "& ol li .ql-indent-6": {
        counterIncrement: "list-6",
    },
    "& ol li .ql-indent-6:before": {
        content: "counter(list-6, decimal) '. '",
    },
    "& ol li .ql-indent-6": {
        counterReset: "list-7 list-8 list-9",
    },
    "& ol li .ql-indent-7": {
        counterIncrement: "list-7",
    },
    "& ol li .ql-indent-7:before": {
        content: "counter(list-7, lower-alpha) '. '",
    },
    "& ol li .ql-indent-7": {
        counterReset: "list-8 list-9",
    },
    "& ol li .ql-indent-8": {
        counterIncrement: "list-8",
    },
    "& ol li .ql-indent-8:before": {
        content: "counter(list-8, lower-roman) '. '",
    },
    "& ol li .ql-indent-8": {
        counterReset: "list-9",
    },
    "& ol li .ql-indent-9": {
        counterIncrement: "list-9",
    },
    "& ol li .ql-indent-9:before": {
        content: "counter(list-9, decimal) '. '",
    },
    "& .ql-indent-1:not(.ql-direction-rtl)": {
        paddingLeft: "3rem",
    },
    "& li.ql-indent-1:not(.ql-direction-rtl)": {
        paddingLeft: "4.5rem",
    },
    "& .ql-indent-1.ql-direction-rtl.ql-align-right": {
        paddingRight: "3rem",
    },
    "& li.ql-indent-1.ql-direction-rtl.ql-align-right": {
        paddingRight: "4.5rem",
    },
    "& .ql-indent-2:not(.ql-direction-rtl)": {
        paddingLeft: "6rem",
    },
    "& li.ql-indent-2:not(.ql-direction-rtl)": {
        paddingLeft: "7.5rem",
    },
    "& .ql-indent-2.ql-direction-rtl.ql-align-right": {
        paddingRight: "6rem",
    },
    "& li.ql-indent-2.ql-direction-rtl.ql-align-right": {
        paddingRight: "7.5rem",
    },
    "& .ql-indent-3:not(.ql-direction-rtl)": {
        paddingLeft: "9rem",
    },
    "& li.ql-indent-3:not(.ql-direction-rtl)": {
        paddingLeft: "10.5rem",
    },
    "& .ql-indent-3.ql-direction-rtl.ql-align-right": {
        paddingRight: "9rem",
    },
    "& li.ql-indent-3.ql-direction-rtl.ql-align-right": {
        paddingRight: "10.5rem",
    },
    "& .ql-indent-4:not(.ql-direction-rtl)": {
        paddingLeft: "12rem",
    },
    "& li.ql-indent-4:not(.ql-direction-rtl)": {
        paddingLeft: "13.5rem",
    },
    "& .ql-indent-4.ql-direction-rtl.ql-align-right": {
        paddingRight: "12rem",
    },
    "& li.ql-indent-4.ql-direction-rtl.ql-align-right": {
        paddingRight: "13.5rem",
    },
    "& .ql-indent-5:not(.ql-direction-rtl)": {
        paddingLeft: "15rem",
    },
    "& li.ql-indent-5:not(.ql-direction-rtl)": {
        paddingLeft: "16.5rem",
    },
    "& .ql-indent-5.ql-direction-rtl.ql-align-right": {
        paddingRight: "15rem",
    },
    "& li.ql-indent-5.ql-direction-rtl.ql-align-right": {
        paddingRight: "16.5rem",
    },
    "& .ql-indent-6:not(.ql-direction-rtl)": {
        paddingLeft: "18rem",
    },
    "& li.ql-indent-6:not(.ql-direction-rtl)": {
        paddingLeft: "19.5rem",
    },
    "& .ql-indent-6.ql-direction-rtl.ql-align-right": {
        paddingRight: "18rem",
    },
    "& li.ql-indent-6.ql-direction-rtl.ql-align-right": {
        paddingRight: "19.5rem",
    },
    "& .ql-indent-7:not(.ql-direction-rtl)": {
        paddingLeft: "21rem",
    },
    "& li.ql-indent-7:not(.ql-direction-rtl)": {
        paddingLeft: "22.5rem",
    },
    "& .ql-indent-7.ql-direction-rtl.ql-align-right": {
        paddingRight: "21rem",
    },
    "& li.ql-indent-7.ql-direction-rtl.ql-align-right": {
        paddingRight: "22.5rem",
    },
    "& .ql-indent-8:not(.ql-direction-rtl)": {
        paddingLeft: "24rem",
    },
    "& li.ql-indent-8:not(.ql-direction-rtl)": {
        paddingLeft: "25.5rem",
    },
    "& .ql-indent-8.ql-direction-rtl.ql-align-right": {
        paddingRight: "24rem",
    },
    "& li.ql-indent-8.ql-direction-rtl.ql-align-right": {
        paddingRight: "25.5rem",
    },
    "& .ql-indent-9:not(.ql-direction-rtl)": {
        paddingLeft: "27rem",
    },
    "& li.ql-indent-9:not(.ql-direction-rtl)": {
        paddingLeft: "28.5rem",
    },
    "& .ql-indent-9.ql-direction-rtl.ql-align-right": {
        paddingRight: "27rem",
    },
    "& li.ql-indent-9.ql-direction-rtl.ql-align-right": {
        paddingRight: "28.5rem",
    },
};

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const PaymentMethods = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>What payment methods are supported?</PH1>
                <PText>
                    Phair makes it super easy for sellers to get paid for
                    whatever digital product you're selling! We offer multiple
                    ways to accept payment, such as credit card, Google Pay, and
                    Apple Pay. This allows buyers to have maximum flexibility
                    when purchasing your product and makes it super smooth for
                    you to receive your funds.
                </PText>
            </PBox>
            <HelpNav
                prev={"Getting paid"}
                prev_link={"/help/getting-paid"}
                next={"Sales tax"}
                next_link={"/help/sales-tax"}
            />
        </Layout>
    );
};

import { keys } from "../../config/keys";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PLink,
    PImg,
} from "../theme/BaseTheme";

export const SellBanner = (props) => {
    return (
        <PSection css={styles}>
            <PBox className="sell-banner">
                <PContainer>
                    <PBox className="sell-banner-container">
                        <svg
                            className="sell-banner-background"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                    width="200"
                                    height="200"
                                    x="50%"
                                    y="-1"
                                    patternUnits="userSpaceOnUse"
                                >
                                    <path d="M.5 200V.5H200" fill="none"></path>
                                </pattern>
                            </defs>
                            <svg x="50%" y="-1" class="adh aoo">
                                <path
                                    d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                    stroke-width="0"
                                ></path>
                            </svg>
                            <rect
                                width="100%"
                                height="100%"
                                stroke-width="0"
                                fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
                            ></rect>
                        </svg>
                        <PBox className="sell-banner-wrapper">
                            <PBox className="sell-banner-left">
                                <PBox className="sell-banner-desc">
                                    <PH1 className="sell-banner-title">
                                        Turn Your Passion into Profit
                                    </PH1>
                                    <PText className="sell-banner-text">
                                        Join our empowering platform: showcase
                                        your creativity, connect with customers,
                                        and thrive in a marketplace that
                                        celebrates digital innovation!
                                    </PText>
                                </PBox>
                                <PBox className="sell-banner-start-button">
                                    <PLink
                                        href={`/open-shop-flow/${props.user_uuid}`}
                                    >
                                        Open a Shop
                                    </PLink>
                                </PBox>
                            </PBox>
                            <PBox className="sell-banner-right">
                                <PImg
                                    className="sell-banner-img"
                                    src={keys.imageBaseUrl + "sell-banner.jpg"}
                                />
                            </PBox>
                        </PBox>
                    </PBox>
                </PContainer>
            </PBox>
        </PSection>
    );
};

const styles = {
    "& .sell-banner": {
        fontFamily:
            "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        padding: "8rem 1.5rem 4rem 1.5rem",
        maxWidth: "80rem",
        margin: "0 auto",
        "@bp4": { paddingTop: "10rem" },
    },
    "& .sell-banner-container": {
        position: "relative",
        overflow: "hidden",
    },
    "& .sell-banner-wrapper": {
        "@bp4": { display: "flex" },
        position: "relative",
    },
    "& .sell-banner-left": {
        flexShrink: "0",
    },
    "& .sell-banner-right": {
        flex: "none",
        overflow: "hidden",
        position: "relative",
        maxWidth: "48rem",
        "@bp2": { maxWidth: "64rem" },
    },
    "& .sell-banner-img": {
        borderRadius: "1rem",
        height: "auto",
        marginTop: "4rem",
        width: "48rem",
        maxWidth: "100%",
        "@bp2": {
            width: "64rem",
            marginTop: "6rem",
            marginLeft: "auto",
            marginRight: "auto",
        },
        "@bp4": { width: "48rem", marginLeft: "4rem", marginTop: "0" },
    },
    "& .sell-banner-background": {
        maskImage: "radial-gradient(24rem 24rem at center,white,transparent)",
        stroke: "#e5e7eb",
        width: "100%",
        height: "60rem",
        zIndex: "-10",
        inset: "0px",
        top: "-12.5rem",
        left: "0px",
        position: "absolute",
        display: "block",
        verticalAlign: "middle",
        overflowClipMargin: "content-box",
        overflow: "hidden",
    },
    "& .sell-banner-desc": {
        textAlign: "left",
        marginTop: "0rem",
        "@bp4": { marginTop: "5rem" },
    },
    "& .sell-banner-title": {
        fontSize: "2rem",
        lineHeight: "2.5rem",
        maxWidth: "36rem",
        "@bp2": { fontSize: "3.75rem", lineHeight: "1" },
        "@bp4": { maxWidth: "32rem" },
        "@bp5": { maxWidth: "36rem" },
    },
    "& .sell-banner-text": {
        fontSize: "1.125rem",
        marginTop: "1.5rem",
        lineHeight: "2rem",
        maxWidth: "40rem",
        color: "rgb(75 85 99)",
        "@bp4": { maxWidth: "32rem" },
        "@bp5": { maxWidth: "40rem" },
    },
    "& .sell-banner-start-button": {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        marginTop: "2.5rem",
        "& a": {
            boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)",
            color: "#fff",
            fontWeight: "600",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "0.625rem 0.875rem",
            backgroundColor: "$colors$primary700",
            borderRadius: "0.375rem",
            textDecoration: "none",
            "&:hover": {
                backgroundColor: "$colors$primary500",
            },
        },
    },
};

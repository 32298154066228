import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { HelpNav } from "../HelpNav";
import { Layout } from "../Layout";

export const StartSelling = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How to start selling on Phair?</PH1>
                <PText>
                    Starting selling on Phair is simple. If you do not have an
                    account on Phair yet, please sign up first.
                </PText>
                <PText>
                    Once you have registered, sign in to your account and start
                    the onboarding process. You will be prompted to select the
                    types of digital products you are selling, name your store,
                    and enter your profile. When finished, you will be
                    redirected to your seller dashboard where you can upload
                    your product, connect to your payments and start selling.
                </PText>
            </PBox>
            <HelpNav next={"Fees"} next_link={"/help/fees"} />
        </Layout>
    );
};

import { combineReducers } from "redux";

const fetchSearchProductsFull = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_SEARCH_PRODUCTS_FULL_SUCCESS":
            state[action.key] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                searchProducts: action.payload.searchResult,
                searchKey: action.payload.searchKey,
                totalSearchProducts: action.payload.totalSearchResult,
            };
        case "FETCH_SEARCH_PRODUCTS_FULL_FAIL":
            state[action.key] = {
                isLoading: false,
                isError: true,
                isSuccess: false,
                searchProducts: action.payload.searchResult,
                searchKey: action.payload.searchKey,
                totalSearchProducts: action.payload.totalSearchResult,
            };
        default:
            return state;
    }
};

export default combineReducers({
    fetchSearchProductsFull,
});

import { combineReducers } from "redux";

const manageSave = (state = { saveProducts: [] }, action) => {
    switch (action.type) {
        case "ADD_PRODUCT_TO_SAVE":
            return { ...state, saveProducts: action.payload };
        case "REMOVE_PRODUCT_FROM_SAVE":
            return { ...state, saveProducts: action.payload };
        case "REMOVE_PRODUCTS_FROM_SAVE":
            return { ...state, saveProducts: action.payload };
        case "FETCH_SAVE_ITEMS_SUCCESS":
            return { ...state, saveProducts: action.payload };
        default:
            return state;
    }
};

export default combineReducers({ manageSave });

import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PBox,
    PContainer,
    PImg,
    PSection,
    PText,
    PH1,
} from "../theme/BaseTheme";
import { Loader } from "../components/Loader";
import { fetchMerchantToDisplay } from "../../actions/merchant";
import { HTMLRenderWrapper } from "../components/editor/HTMLRenderWrapper";

class ShopBanner extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, merchant: {} };
    }
    componentDidMount() {
        this.props
            .fetchMerchantToDisplay(this.props.match.params.shop_uuid)
            .then(() => {
                this.setState({
                    isLoading: false,
                    merchant:
                        this.props.merchants[this.props.match.params.shop_uuid]
                            ?.merchant,
                });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PSection css={styles}>
                <PBox className="shop-banner">
                    {this.state.merchant?.shop_cover_image && (
                        <PImg
                            className="shop-banner-cover"
                            src={this.state.merchant?.shop_cover_image?.url}
                        />
                    )}
                    <PBox className="shop-banner-wrapper">
                        {this.state.merchant?.shop_logo && (
                            <PImg
                                className="shop-banner-logo"
                                src={this.state.merchant?.shop_logo?.url}
                            />
                        )}
                        {!this.state.merchant?.shop_logo && (
                            <PImg
                                className="shop-banner-logo"
                                src={"/images/default-shop-logo.png"}
                            />
                        )}
                        <PBox className="shop-banner-name">
                            <PH1>{this.state.merchant?.shop_name}</PH1>
                            <PText>
                                {this.state.merchant?.shop_description}
                            </PText>
                            <PText>
                                {
                                    this.state.merchant?.merchant_stat
                                        ?.number_of_orders
                                }{" "}
                                Sales
                            </PText>
                        </PBox>
                        <PBox className="shop-banner-seller">
                            {this.state.merchant?.seller?.user?.profile_image
                                ?.url && (
                                <PImg
                                    className="shop-banner-avatar"
                                    src={
                                        this.state.merchant?.seller?.user
                                            ?.profile_image?.url
                                    }
                                />
                            )}
                            {!this.state.merchant?.seller?.user?.profile_image
                                ?.url && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 100 100"
                                    fill="none"
                                    x="0px"
                                    y="0px"
                                >
                                    <path
                                        d="M49.5464 53.8889C57.0511 53.8889 63.846 50.909 68.7671 46.0902C77.9465 52.0584 83.9987 62.2807 83.9987 73.8889L83.9993 77.7375C83.9997 80.6166 82.7909 83.4005 80.4671 85.1003C71.9663 91.3187 61.4215 95 49.9997 95C38.5781 95 28.0334 91.3188 19.5327 85.1006C17.209 83.4009 16.0001 80.6172 16.0004 77.7383L16.0007 73.8889L16.0024 73.5479C16.1174 62.3075 21.907 52.3992 30.6967 46.4452C35.5853 51.0554 42.2283 53.8889 49.5464 53.8889Z"
                                        fill="#d3d3d3"
                                    />
                                    <path
                                        d="M26.8804 27.2222C26.8804 14.9492 37.0283 5 49.5464 5C62.0645 5 72.2124 14.9492 72.2124 27.2222C72.2124 39.4952 62.0645 49.4444 49.5464 49.4444C37.0283 49.4444 26.8804 39.4952 26.8804 27.2222Z"
                                        fill="#d3d3d3"
                                    />
                                </svg>
                            )}
                            <PText>
                                {this.state.merchant?.seller?.seller_name}
                            </PText>
                        </PBox>
                    </PBox>
                    {this.state.merchant?.shop_announcement && (
                        <PBox className="shop-banner-announcement">
                            <HTMLRenderWrapper>
                                {this.state.merchant?.shop_announcement}
                            </HTMLRenderWrapper>
                        </PBox>
                    )}
                </PBox>
            </PSection>
        );
    }
}

const styles = {
    "& .shop-banner": {
        paddingBottom: "40px",
    },
    "& .shop-banner-wrapper": {
        display: "flex",
        flexDirection: "row",
        maxWidth: "80rem",
        marginLeft: "auto",
        marginRight: "auto",
    },
    "& .shop-banner-cover": {
        width: "100%",
        maxHeight: "320px",
        //objectFit: "cover",
        marginBottom: "20px",
    },
    "& .shop-banner-logo": {
        width: "120px",
        height: "120px",
        margin: "20px 20px 20px 0",
        borderRadius: "20px",
    },
    "& .shop-banner-name": {
        flexGrow: "1",
        margin: "20px",

        "& h1": {
            fontSize: "2rem",
            fontWeight: "400",
            marginTop: "0",
            marginBottom: "16px",
        },
        "& p": {
            color: "$colors$text",
            fontSize: "0.9rem",
            margin: "0",
            lineHeight: "1.6rem",
        },
    },
    "& .shop-banner-seller": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "20px",
        "& p": {
            color: "$colors$text",
            fontSize: "14px",
            margin: "0",
            lineHeight: "1.6rem",
            fontWeight: "700",
        },
    },
    "& .shop-banner-avatar": {
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        marginBottom: "10px",
    },
    "& .shop-banner-announcement": {
        margin: "20px",
    },
};

function mapStateToProps(state) {
    return {
        merchants: state.merchantReducer?.fetchMerchantDisplay,
    };
}

export default connect(mapStateToProps, { fetchMerchantToDisplay })(
    withRouter(ShopBanner)
);

import { SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table } from "antd";
import { PBox } from "../../theme/BaseTheme";

const data = [
    {
        "order-id": "#SK2540",
        "billing-name": "John Brown",
        date: "10/07/2021",
        total: "300",
        products: [
            "From disruptive new technologies like cryptocurrency to classic robot technology",
            "We have all you need for your next design project. All free Technology Stock Illustrations can be downloaded and used in your next creative project for free under the Reshot License",
        ],
    },
];

export const AllOrders = (props) => {
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: "Order ID",
            dataIndex: "order_number",
            key: "order-id",
            width: "10%",
            ...getColumnSearchProps("order_number"),
            render: (_, { order_number }) => (
                <PBox className="all-orders-id">{order_number}</PBox>
            ),
        },
        {
            title: "Billing Name",
            dataIndex: "user_name",
            key: "billing-name",
            width: "12%",
            ...getColumnSearchProps("user_name"),
        },
        {
            title: "Date",
            dataIndex: "created_at",
            key: "date",
            ...getColumnSearchProps("created_at"),
            width: "16%",
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            sortDirections: ["descend", "ascend"],
            render: (_, { created_at }) => (
                <>{new Date(created_at).toDateString()}</>
            ),
        },
        {
            title: "Earned",
            dataIndex: "order_items",
            key: "earned",
            width: "10%",
            sorter: (a, b) => {
                var a_sum = 0,
                    b_sum = 0;
                for (var i = 0; i < a.order_items.length; i++)
                    a_sum += a.order_items[i].transfer_amount;
                for (var i = 0; i < b.order_items.length; i++)
                    b_sum += b.order_items[i].transfer_amount;
                return a_sum - b_sum;
            },
            sortDirections: ["descend", "ascend"],
            render: (_, { order_items }) => {
                var sum = 0;
                for (var i = 0; i < order_items.length; i++) {
                    sum += order_items[i].transfer_amount;
                }
                return <div>${sum}</div>;
            },
        },
        {
            title: "Products",
            dataIndex: "order_items",
            key: "products",
            render: (_, { order_items }) => (
                <PBox className="all-orders-products">
                    <ul>
                        {order_items.map((order_item) => {
                            return (
                                <li>
                                    <a
                                        href={`/item/${order_item.product.uuid}`}
                                        target="_blank"
                                    >
                                        {order_item.product.product_name}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </PBox>
            ),
        },
        {
            title: "Record",
            key: "record",
            dataIndex: "order_number",
            width: "10%",
            render: (_, { order_number }) => (
                <Space size="middle">
                    <a
                        className="all-orders-button"
                        href={`/seller/${props.shop_uuid}/record/${order_number}`}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M214.67,72H200V40a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8V72H41.33C27.36,72,16,82.77,16,96v80a8,8,0,0,0,8,8H56v32a8,8,0,0,0,8,8H192a8,8,0,0,0,8-8V184h32a8,8,0,0,0,8-8V96C240,82.77,228.64,72,214.67,72ZM72,48H184V72H72ZM184,208H72V160H184Zm40-40H200V152a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8v16H32V96c0-4.41,4.19-8,9.33-8H214.67c5.14,0,9.33,3.59,9.33,8Zm-24-52a12,12,0,1,1-12-12A12,12,0,0,1,200,116Z"></path>
                        </svg>
                    </a>
                </Space>
            ),
        },
    ];
    return (
        <PBox css={styles}>
            <Table
                bordered={false}
                columns={columns}
                dataSource={props.data}
                scroll={{ x: "1000px" }}
            />
        </PBox>
    );
};

const styles = {
    margin: "2rem 0",
    "& .all-orders-button": {
        "& svg": {
            fill: "$colors$text",
            "&:hover": { fill: "$colors$link" },
        },
    },
    "& .all-orders-id": {
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
    },
    "& .all-orders-products": {
        "& ul": {
            paddingLeft: "0",
        },
        "& li": {
            marginLeft: "0",
            marginBottom: "0.5rem",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "1",
        },
    },
};

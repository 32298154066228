import { PH1, PH3, PLink, PText, PBox, PUl, PLi } from "../../theme/BaseTheme";
import { ErrorLayout } from "./ErrorLayout";

export const Error500 = (props) => {
    return (
        <ErrorLayout>
            <PBox css={styles} className="error-500">
                <PBox className="error-500-title">
                    <PText className="error-500-500">500</PText>
                    <PH1>This page isn't working</PH1>
                    <PText className="error-500-desc">
                        The server encountered an internal error or
                        misconfiguration and was unable to complete your
                        request. Please try after some time.
                    </PText>
                </PBox>
                <PBox className="error-500-popular-pages">
                    <PUl>
                        <PLi
                            onClick={() => {
                                props.history.push("/help");
                            }}
                        >
                            <PBox className="error-500-page-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="oc se ayh"
                                >
                                    <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z"></path>
                                </svg>
                            </PBox>
                            <PBox className="error-500-page-desc">
                                <PH3>Support Center</PH3>
                                <PText>Learn how to sell on Phair.</PText>
                            </PBox>
                            <PBox className="error-500-page-arrow">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="nz sb axp"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </PBox>
                        </PLi>
                        <PLi
                            onClick={() => {
                                props.history.push("/faqs");
                            }}
                        >
                            <PBox className="error-500-page-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="oc se ayh"
                                >
                                    <path d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z"></path>
                                </svg>
                            </PBox>
                            <PBox className="error-500-page-desc">
                                <PH3>FAQs</PH3>
                                <PText>
                                    Questions and answers about shopping on
                                    Phair.
                                </PText>
                            </PBox>
                            <PBox className="error-500-page-arrow">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="nz sb axp"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </PBox>
                        </PLi>
                        <PLi
                            onClick={() => {
                                props.history.push("/blog");
                            }}
                        >
                            <PBox className="error-500-page-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="oc se ayh"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M3.75 4.5a.75.75 0 01.75-.75h.75c8.284 0 15 6.716 15 15v.75a.75.75 0 01-.75.75h-.75a.75.75 0 01-.75-.75v-.75C18 11.708 12.292 6 5.25 6H4.5a.75.75 0 01-.75-.75V4.5zm0 6.75a.75.75 0 01.75-.75h.75a8.25 8.25 0 018.25 8.25v.75a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75v-.75a6 6 0 00-6-6H4.5a.75.75 0 01-.75-.75v-.75zm0 7.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </PBox>
                            <PBox className="error-500-page-desc">
                                <PH3>Blog</PH3>
                                <PText>
                                    Read our latest news and articles.
                                </PText>
                            </PBox>
                            <PBox className="error-500-page-arrow">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="nz sb axp"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </PBox>
                        </PLi>
                    </PUl>
                </PBox>
                <PBox className="error-500-home-link">
                    <PText>
                        Go back to <PLink href="/">Home</PLink>
                    </PText>
                </PBox>
            </PBox>
        </ErrorLayout>
    );
};

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "600px",
    backgroundColor: "#fff",
    padding: "1rem 2rem 2rem 2rem",
    "& .error-500-title": {
        textAlign: "center",
    },
    "& .error-500-500": {
        fontSize: "1.2rem",
        fontWeight: "600",
        lineHeight: "2rem",
        color: "$colors$primary",
    },
    "& h1": {
        fontSize: "2rem",
        "@bp2": { fontSize: "3rem" },
        lineHeight: "1",
        fontWeight: "700",
        letterSpacing: "-0.025em",
        marginTop: "1rem",
    },
    "& .error-500-desc": {
        lineHeight: "2rem",
        fontSize: "1.125rem",
        marginTop: "1.5rem",
        color: "rgb(75 85 99)",
    },
    "& .error-500-popular-pages": {
        marginTop: "3rem",
        maxWidth: "28rem",
        "@bp2": { width: "32rem" },
        display: "flow-root",
        marginLeft: "auto",
        marginRight: "auto",
        "& ul": {
            borderTop: "1px solid $colors$borderColor",
            listStyle: "none",
            margin: "0",
            padding: "0",
        },
        "& li": {
            paddingTop: "1.5rem",
            paddingBottom: "0.8rem",
            columnGap: "1.5rem",
            display: "flex",
            position: "relative",
            borderBottom: "1px solid $colors$borderColor",
            cursor: "pointer",
        },
    },
    "& .error-500-page-desc": {
        flex: "1 1 auto",
        "& h3": {
            lineHeight: "1.5rem",
            fontWeight: "600",
            fontSize: "0.875rem",
            margin: "0",
        },
        "& p": {
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontSize: "0.875rem",
            marginTop: "0.5rem",
        },
    },
    "& .error-500-page-icon": {
        borderRadius: "0.5rem",
        justifyContent: "center",
        alignItems: "center",
        flex: "0 0 auto",
        width: "2.5rem",
        height: "2.5rem",
        display: "flex",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
        "& svg": {
            width: "1.5rem",
            height: "1.5rem",
            color: "$colors$primary700",
            display: "block",
            verticalAlign: "middle",
        },
    },
    "& .error-500-page-arrow": {
        alignSelf: "center",
        flex: "0 0 auto",
        "& svg": {
            color: "rgb(156 163 175)",
            width: "1.25rem",
            height: "1.25rem",
            display: "block",
            verticalAlign: "middle",
        },
    },
    "& .error-500-home-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};

import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, message, Steps } from "antd";
import { Layout } from "./Layout";
import {
    PSection,
    PBox,
    PContainer,
    PH1,
    PButton,
} from "../../theme/BaseTheme";
import { Name } from "../../components/open/Name";
import { Welcome } from "../../components/open/Welcome";
import { Finish } from "../../components/open/Finish";
import { Sell } from "../../components/open/Sell";
import { Profile } from "../../components/open/Profile";
import { createMerchant } from "../../../actions/merchant";
import { Loader } from "../../components/Loader";

class Flow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            current: 0,
            checkedValues: [],
            shopName: "",
            sellerDisplayName: "",
            shopDescription: "",
            certifyAge: false,
        };
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.finish = this.finish.bind(this);
        this.steps = this.steps.bind(this);
        this.items = this.items.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    steps = () => {
        return [
            {
                title: "Welcome",
                content: <Welcome next={this.next} />,
            },
            {
                title: "Sell",
                content: (
                    <Sell
                        checkedValues={this.state.checkedValues}
                        setCheckedValues={(value) => {
                            this.setState({ checkedValues: value });
                        }}
                        prev={this.prev}
                        next={this.next}
                    />
                ),
            },
            {
                title: "Name",
                content: (
                    <Name
                        shopName={this.state.shopName}
                        setShopName={(value) => {
                            this.setState({ shopName: value });
                        }}
                        prev={this.prev}
                        next={this.next}
                    />
                ),
            },
            {
                title: "Profile",
                content: (
                    <Profile
                        sellerDisplayName={this.state.sellerDisplayName}
                        setSellerDisplayName={(value) => {
                            this.setState({ sellerDisplayName: value });
                        }}
                        shopDescription={this.state.shopDescription}
                        setShopDescription={(value) => {
                            this.setState({ shopDescription: value });
                        }}
                        certifyAge={this.state.certifyAge}
                        setCertifyAge={(value) => {
                            this.setState({ certifyAge: value });
                        }}
                        prev={this.prev}
                        next={this.next}
                    />
                ),
            },
            {
                title: "Finish",
                content: <Finish prev={this.prev} finish={this.finish} />,
            },
        ];
    };

    next = () => {
        this.setState({ current: this.state.current + 1 });
    };
    prev = () => {
        this.setState({ current: this.state.current - 1 });
    };
    finish = () => {
        this.props.createMerchant(
            {
                checkedValues: this.state.checkedValues,
                shopName: this.state.shopName,
                sellerDisplayName: this.state.sellerDisplayName,
                shopDescription: this.state.shopDescription,
            },
            this.props.match.params.user_uuid,
            this.props.history
        );
    };
    items = () => {
        return this.steps().map((item) => ({
            key: item.title,
            title: item.title,
        }));
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PBox className="open-shop-flow">
                        <PContainer>
                            <PBox className="open-shop-flow-title"></PBox>

                            <Steps
                                progressDot
                                size="small"
                                current={this.state.current}
                                items={this.items()}
                            />

                            <PBox className="open-shop-flow-content">
                                {this.steps()[this.state.current].content}
                            </PBox>
                            {/*<PBox className="open-shop-flow-buttons">
                                {this.state.current > 0 && (
                                    <Button
                                        style={{ margin: "0 8px" }}
                                        onClick={() => this.prev()}
                                    >
                                        Previous
                                    </Button>
                                )}
                                {this.state.current ===
                                    this.steps().length - 1 && (
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            this.props.createMerchant(
                                                {
                                                    checkedValues:
                                                        this.state
                                                            .checkedValues,
                                                    shopName:
                                                        this.state.shopName,
                                                    sellerDisplayName:
                                                        this.state
                                                            .sellerDisplayName,
                                                    shopDescription:
                                                        this.state
                                                            .shopDescription,
                                                },
                                                this.props.match.params
                                                    .user_uuid,
                                                this.props.history
                                            );
                                        }}
                                    >
                                        Finish
                                    </Button>
                                )}
                                {this.state.current <
                                    this.steps().length - 1 && (
                                    <Button
                                        type="primary"
                                        className="open-shop-flow-next-button"
                                        onClick={() => this.next()}
                                    >
                                        Next
                                    </Button>
                                )}
                                    </PBox>*/}
                        </PContainer>
                    </PBox>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    "& .open-shop-flow": {
        marginBottom: "40px",
    },
    "& .open-shop-flow-title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "60px",
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        "& h1": {
            fontWeight: "300",
            fontSize: "36px",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    "& .open-shop-flow-content": {
        marginTop: "48px",
        minHeight: "480px",
        textAlign: "center",
    },
    "& .open-shop-flow-buttons": {
        marginTop: "48px",
        display: "flex",
        justifyContent: "center",
        marginBottom: "60px",
    },
};

function mapStateToProps(state) {
    return { userAccount: state.accountReducer?.fetchUserAccount.userAccount };
}

export default connect(mapStateToProps, {
    createMerchant,
})(withRouter(Flow));

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const CreateProduct = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How to create a product?</PH1>
                <PText>
                    Once you have finished the seller onboarding process, you
                    will be redirected to your Phair dashboard. You could manage
                    your products, orders, payments, and your account all in
                    your seller dashboard.
                </PText>
                <PText>
                    To create a product, simply click the{" "}
                    <b>Create a Product</b> button on the top right corner of
                    the dashboard. Next in the create product page, you can
                    upload the digital product file(s).You can upload more than
                    one file as part of your product. All files you upload will
                    be sent to buyers when they make a purchase for your
                    product.
                </PText>
                <PText>
                    You could add images and descriptions to help buyers know
                    more details of your product. Choosing a category to
                    describe your product will put your listing in front of
                    buyers looking for products like yours. You could add tags
                    using the keywords that best describe your product listing.
                    This allows buyers to more easily search for and discover
                    things you offer. Product highlights will be displayed in
                    the product purchase card, so it will be better to keep it
                    concise and clear. All the details can go to the product
                    description section where buyers can expand and read more.
                    In the policy section, you can specify a specific policy,
                    license, or terms of use about the product.
                </PText>
                <PText>
                    You could set the status of your product as either{" "}
                    <b>private</b> or public. Setting the status to private will
                    keep your product to yourself and you can keep editing it as
                    you like. Setting the status to <b>public</b> will get your
                    product displayed the marketplace and everyone can see and
                    purchase it.
                </PText>
            </PBox>
            <HelpNav
                prev={"Need help"}
                prev_link={"/help/need-help"}
                next={"Edit a product"}
                next_link={"/help/edit-a-product"}
            />
        </Layout>
    );
};

import { PBox, PLink } from "../../theme/BaseTheme";

export const HelpNav = (props) => {
    return (
        <PBox css={styles}>
            {props.prev && (
                <PBox className="help-nav-prev">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000000"
                        viewBox="0 0 256 256"
                    >
                        <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM149.66,93.66,115.31,128l34.35,34.34a8,8,0,0,1-11.32,11.32l-40-40a8,8,0,0,1,0-11.32l40-40a8,8,0,0,1,11.32,11.32Z"></path>
                    </svg>
                    <PLink href={props.prev_link}>{props.prev}</PLink>
                </PBox>
            )}
            {props.next && (
                <PBox className="help-nav-next">
                    <PLink href={props.next_link}>{props.next}</PLink>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000000"
                        viewBox="0 0 256 256"
                    >
                        <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm29.66-93.66a8,8,0,0,1,0,11.32l-40,40a8,8,0,0,1-11.32-11.32L140.69,128,106.34,93.66a8,8,0,0,1,11.32-11.32Z"></path>
                    </svg>
                </PBox>
            )}
        </PBox>
    );
};
const styles = {
    marginTop: "80px",
    borderTop: "1px solid $colors$borderColor",
    color: "$colors$label",
    display: "grid",
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    gridGap: "20px",
    "@bp3": {
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
    "& .help-nav-prev": {
        gridColumn: "1 / 2",
        display: "flex",
        alignItems: "center",
        marginTop: "20px",
        padding: "10px 0px",
        borderRadius: "8px",
        fontWeight: "600",
        "& svg": {
            fill: "$colors$primary",
            marginRight: "12px",
            display: "block",
            minWidth: "20px",
            minHeight: "20px",
        },
        "& a": {
            cursor: "pointer",
            textDecoration: "none",
            color: "$colors$primary",
        },
        "&:hover": {
            "& svg": { fill: "$colors$primary500" },
            "& a": { color: "$colors$primary500" },
        },
    },
    "& .help-nav-next": {
        gridColumn: "2 / 3",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        marginTop: "20px",
        padding: "10px 0px",
        borderRadius: "8px",
        fontWeight: "600",
        "& svg": {
            fill: "$colors$primary",
            marginLeft: "12px",
            display: "block",
            minWidth: "20px",
            minHeight: "20px",
        },
        "& a": {
            cursor: "pointer",
            textDecoration: "none",
            color: "$colors$primary",
        },
        "&:hover": {
            "& svg": { fill: "$colors$primary500" },
            "& a": { color: "$colors$primary500" },
        },
    },
};

import { message } from "antd";
import { Component } from "react";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
        message.error(error.toString());
        console.log(this.state.errorInfo.componentStack);
    }

    render() {
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;

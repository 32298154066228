import { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    PSection,
    PH1,
    PBox,
    PContainer,
    PImg,
    PText,
    PLink,
    PHighlightLink,
} from "../theme/BaseTheme";

class TopSellers extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    render() {
        return (
            <>
                {this.props.topMerchants?.length > 0 && (
                    <PSection css={styles}>
                        <PBox className="top-sellers">
                            <PH1 className="top-sellers-title">
                                Popular Stores
                            </PH1>
                            <PBox className="top-sellers-list">
                                {this.props.topMerchants?.map(
                                    (merchant, idx) => {
                                        return (
                                            <PBox className="top-sellers-item">
                                                <PBox className="top-sellers-cover-wrapper">
                                                    <Link
                                                        to={`/shop/${merchant?.uuid}/1`}
                                                    >
                                                        {merchant?.shop_logo && (
                                                            <PImg
                                                                src={
                                                                    merchant
                                                                        ?.shop_logo
                                                                        ?.url
                                                                }
                                                                className="top-sellers-cover"
                                                            />
                                                        )}
                                                        {!merchant?.shop_logo && (
                                                            <PImg
                                                                src={
                                                                    "/images/default-shop-logo.png"
                                                                }
                                                                className="top-sellers-cover"
                                                            />
                                                        )}
                                                    </Link>
                                                </PBox>
                                                <PBox className="top-sellers-name">
                                                    <PLink
                                                        href={`/shop/${merchant?.uuid}/1`}
                                                    >
                                                        {merchant?.shop_name}
                                                    </PLink>
                                                </PBox>
                                                <PBox className="top-sellers-sales">
                                                    <PText>
                                                        {
                                                            merchant?.number_of_orders
                                                        }{" "}
                                                        sales
                                                    </PText>
                                                </PBox>
                                            </PBox>
                                        );
                                    }
                                )}
                            </PBox>
                            <PBox className="top-sellers-button">
                                <PLink href="/sell">Become a Seller</PLink>
                            </PBox>
                        </PBox>
                    </PSection>
                )}
            </>
        );
    }
}

const styles = {
    maxWidth: "80rem",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "2rem",
    "& .top-sellers": {
        marginTop: "2rem",
        marginBottom: "6rem",
    },
    "& .top-sellers-title": {
        fontSize: "1.5rem",
        fontWeight: "700",
        lineHeight: "2rem",
        color: "rgb(17 24 39)",
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    "& .top-sellers-list": {
        display: "grid",
        gridGap: "2rem",
        "@bp1": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp2": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp3": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(6, minmax(0, 1fr))" },
    },
    "& .top-sellers-item": {
        position: "relative",
        overflow: "hidden",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
        "&:hover": {
            transform: "translateY(-6px)",
            display: "block",
            "& img": {
                transform: "scale(1.1)",
                "-moz-transform": "scale(1.1)",
                "-webkit-transform": "scale(1.1)",
            },
        },
    },
    "& .top-sellers-cover-wrapper": {
        overflow: "hidden",
        borderRadius: "1rem",
        width: "10rem",
        height: "10rem",
        cursor: "pointer",
    },
    "& .top-sellers-cover": {
        objectFit: "cover",
        width: "100%",
        height: "10rem",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
    },
    "& .top-sellers-name": {
        fontWeight: "600",
        margin: "16px 0 0 0",
        padding: "0",
        "& a": {
            textDecoration: "none",
            color: "$colors$text",
        },
        "& a:hover": {
            color: "$colors$primary",
        },
    },
    "& .top-sellers-sales": {
        color: "$colors$label",
        "& p": { margin: "4px 0", padding: "0" },
    },
    "& .top-sellers-button": {
        marginTop: "4rem",
        display: "flex",
        justifyContent: "center",
        "& a": {
            boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)",
            color: "#fff",
            fontWeight: "600",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "0.625rem 0.875rem",
            backgroundColor: "$colors$primary700",
            borderRadius: "0.375rem",
            textDecoration: "none",
            "&:hover": {
                backgroundColor: "$colors$primary500",
            },
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {})(withRouter(TopSellers));

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const NavigateToDashboard = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>
                    How do I navigate to my seller dashboard from Phair.io?
                </PH1>
                <PText>
                    When you log into your account on Phair.io, you will view
                    your Phair buyer account information only. A link to access
                    your Phair seller dashboard will be listed on the left of
                    your user icon image on the top right corner on Phair.io.
                </PText>
            </PBox>
            <HelpNav
                prev={"Customization"}
                prev_link={"/help/customization"}
                next={"Privacy Policy"}
                next_link={"/help/privacy-policy"}
            />
        </Layout>
    );
};

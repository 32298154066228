import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PBox, PH1, PH2, PText, PImg } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { AllOrders } from "../../components/seller/AllOrders";
import { Loader } from "../../components/Loader";
import { fetchOrderListDetailsByMerchantID } from "../../../actions/order";
import { Empty } from "antd";

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.props
            .fetchOrderListDetailsByMerchantID(
                this.props.match.params.shop_uuid,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles} className="orders">
                    <PBox className="all-orders">
                        <PBox className="orders-card">
                            <PH2>All Orders</PH2>
                            {!this.props.orderList && <Empty />}
                            {this.props.orderList?.length > 0 && (
                                <AllOrders
                                    data={this.props.orderList}
                                    shop_uuid={
                                        this.props.match.params.shop_uuid
                                    }
                                />
                            )}
                            {this.props.orderList?.length === 0 && (
                                <PBox className="orders-no-data">
                                    <PImg src="/images/no-data.jpg" />
                                    <PText>
                                        You don't have any orders yet.
                                    </PText>
                                </PBox>
                            )}
                        </PBox>
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    margin: "0rem auto",
    maxWidth: "80rem",
    "& .orders-card": {
        margin: "0rem 2rem 2rem 2rem",
        backgroundColor: "#fff",
        padding: "2rem 2rem",
        "& h2": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            fontWeight: "700",
            color: "rgb(17 24 39)",
        },
    },
    "& .orders-no-data": {
        padding: "30px 0",
        textAlign: "center",
        "& img": {
            width: "12rem",
        },
        "& p": {
            fontSize: "1rem",
            fontWeight: "500",
            color: "rgb(107 114 128)",
        },
    },
};

function mapStateToProps(state) {
    return {
        orderList:
            state.orderReducer?.fetchOrderListDetailsByMerchant?.orderList,
    };
}

export default connect(mapStateToProps, {
    fetchOrderListDetailsByMerchantID,
})(withRouter(Orders));

import { keyframes } from "@stitches/react";
import { PBox, PH1, PLink } from "../theme/BaseTheme";
import { BlogCard } from "../pages/blog/BlogCard";
import { BlogPosts } from "../../data/blogs";

export const NewBlogs = () => {
    return (
        <PBox css={styles}>
            <PBox className="new-blogs">
                <PBox className="new-blogs-title-wrapper">
                    <PH1 className="new-blogs-title">Recent Blogs</PH1>

                    <PLink href={"/blog"}>MORE POSTS</PLink>
                </PBox>
                <PBox className="new-blogs-wrapper">
                    {BlogPosts?.filter((item, idx) => idx < 4).map(
                        (post, idx) => {
                            return (
                                <BlogCard
                                    cover={post.cover}
                                    categories={post.categories}
                                    title={post.title}
                                    link={post.link}
                                    author={post.author}
                                    date={post.date}
                                    excerpt={post.excerpt}
                                    showExcerpt={false}
                                />
                            );
                        }
                    )}
                </PBox>
            </PBox>
        </PBox>
    );
};

const rainbow = keyframes({
    "0%": { backgroundPosition: "left" },
    "50%": { backgroundPosition: "right" },
    "100%": { backgroundPosition: "left" },
});

const styles = {
    maxWidth: "80rem",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "2rem",
    "& .new-blogs": {
        marginBottom: "2rem",
    },
    "& .new-blogs-title-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& a": {
            position: "relative",
            fontWeight: "700",
            fontSize: "14px",
            letterSpacing: "0.1em",
            lineHeight: "20px",
            textDecoration: "none",
            color: "transparent",
            animation: `${rainbow} 2s ease-in-out infinite`,
            transition: "color 0.2s ease-in-out",
            background: "linear-gradient(to right, #bb5098,#5344a9,#bb5098)",
            backgroundClip: "text",
            "-webkit-background-clip": "text",
            backgroundSize: "200% 200%",
        },
        "& a:after": {
            content: "",
            position: "absolute",
            left: "0",
            bottom: "-4px",
            width: "100%",
            height: "1px",
            background:
                "linear-gradient(216.56deg, $colors$tertiary 5.32%, $colors$primary 94.32%)",
        },
    },
    "& .new-blogs-title": {
        fontSize: "1.5rem",
        fontWeight: "700",
        lineHeight: "2rem",
        color: "rgb(17 24 39)",
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    "& .new-blogs-wrapper": {
        display: "grid",
        gridGap: "2rem",
        "@bp1": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp2": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp3": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
};

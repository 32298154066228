import {
    PSection,
    PBox,
    PContainer,
    PText,
    PH2,
    PH3,
    PH4,
    PUl,
    PLi,
    POl,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";

export const Terms = (props) => {
    return (
        <Layout>
            <PSection css={styles}>
                <PBox className="terms">
                    <PBox className="terms-title">
                        <PH2>Terms and Conditions</PH2>
                    </PBox>
                    <PBox>
                        <PText>
                            <i>Last updated: Dec 6th, 2023</i>
                        </PText>
                        <PText>
                            Welcome to Phair. Thank you for your interest in
                            Phair Labs LLC ("<b>Phair</b>", "<b>we</b>", "
                            <b>us</b>", or "<b>our</b>"), our website at
                            phair.io, as well as other related websites,
                            applications, products and services provided by us
                            (collectively, the "<b>Services</b>").
                        </PText>
                        <PText>
                            These Terms and Conditions constitute a legally
                            binding agreement made between you, whether
                            personally or on behalf of an entity ("<b>you</b>"),
                            and Phair, concerning your access to and use of the
                            Services. You agree that by clicking "I Accept", or
                            by accessing or using the Services, you have read,
                            understood, and agreed to be bound by the following
                            terms and conditions, including Phair's{" "}
                            <a href="/privacy" target="_blank">
                                privacy policy
                            </a>
                            , and other policies referenced below (together,
                            these "<b>Terms</b>"). If you are not eligible, or
                            do not agree with all of the terms, then you are
                            expressly prohibited from using the services and you
                            must discontinue use immediately. Your use of the
                            services, and Phair's provision of the service to
                            you, constitutes an agreement by Phair and by you to
                            be bound by these terms.
                        </PText>
                        <PText>
                            <b>ARBITRATION NOTICE</b>. Except for certain kinds
                            of disputes described in Section 14, you agree that
                            disputes arising under these Terms will be resolved
                            by binding, individual arbitration, and by accepting
                            these terms, you and Phair are each waiving the
                            right to a trial by jury or to participate in any
                            class action or representative proceeding.
                        </PText>
                        <PH3>1. Our Services</PH3>
                        <PText>
                            Phair operates the Services as a marketplace for
                            digital goods and services (the "<b>Products</b>")
                            where certain users ("<b>Buyers</b>") can purchase
                            the Products sold by other users ("<b>Sellers</b>").
                            All Products are provided solely by the applicable
                            Seller and Phair is not responsible for the Products
                            sold through the Services.
                        </PText>
                        <PH3>2. Accounts and Registration</PH3>
                        <PText>
                            You'll need to create an account with Phair to use
                            most of our Services.
                        </PText>
                        <PText>
                            <b>2.1 Eligibility</b>. You must be 18 years or
                            older to use our Services. By agreeing to these
                            Terms, you represent and warrant to us that: (a) you
                            are at least 18 years old; (b) you have not
                            previously been suspended or removed from the
                            Services; and (c) your registration and your use of
                            the Services is in compliance with any and all
                            applicable laws and regulations. If you are an
                            entity, organization, or company, the individual
                            accepting these Terms on your behalf represents and
                            warrants that they have authority to bind you to
                            these Terms and you agree to be bound by these
                            Terms.
                        </PText>
                        <PText>
                            <b>2.2 Account and Registration</b>. When you
                            register for an account, you may be required to
                            provide us with some information about yourself,
                            such as your name, email address, or other contact
                            information. You agree that the information you
                            provide to us is accurate, complete, and not
                            misleading, and that you will keep it accurate and
                            up to date at all times. It's prohibited to use
                            false information or impersonate another person or
                            company through your account. When you register, you
                            will be asked to create a password. You are solely
                            responsible for maintaining the confidentiality of
                            your account and password. Also, you are solely
                            responsible for all activities that occur under your
                            account. If you believe that your account is no
                            longer secure, then you should immediately notify us
                            at support@phair.io. Your accounts are not
                            transferable.
                        </PText>
                        <PH3>3. Purchases and Payments</PH3>
                        <PText>
                            <b>3.1 General Terms</b>. Certain features of the
                            Services, including the purchase or sale of certain
                            Products, may require you to pay fees. Before you
                            pay any fees, you will have an opportunity to review
                            and accept the fees that you will be charged. All
                            fees are denominated in the stated currency or other
                            means of payment and are non-refundable unless
                            otherwise specifically provided for in these Terms.
                        </PText>
                        <PText>
                            <b>3.2 Fees</b>. Phair reserves the right to
                            determine pricing for the Services and any fees it
                            may charge on transactions conducted on the
                            Services. Phair will make reasonable efforts to keep
                            pricing information published on the Services up to
                            date. We encourage you to check our pricing page
                            periodically for current pricing information. Phair
                            may change the fees for any feature of the Services,
                            including additional fees or charges, if Phair gives
                            you advance notice of changes before they apply.
                            Phair, at its sole discretion, may make promotional
                            offers with different features and different pricing
                            to any of Phair's customers. These promotional
                            offers, unless made to you, will not apply to your
                            offer or these Terms.
                        </PText>
                        <PText>
                            <b>3.3 Authorization</b>. You authorize Phair to
                            charge all sums for the orders that you make and any
                            level of Service you select as described in these
                            Terms or published by Phair, including all
                            applicable taxes, to the payment method specified by
                            you. If you pay any fees with a credit card, then
                            Phair may seek pre-authorization of your credit card
                            account prior to your purchase to verify that the
                            credit card is valid and has the necessary funds or
                            credit available to cover your purchase.
                        </PText>
                        <PText>
                            <b>3.4 Sales Tax</b>. In some instances Phair may
                            have an obligation to add sales or use tax to the
                            purchase price of Products listed on the Phair
                            marketplace. In such cases, Phair will list the
                            appropriate taxes, charge and collect them as part
                            of the marketplace transaction and either remit the
                            taxes to the applicable taxing authority or transmit
                            the collected taxes to Sellers for Seller remittance
                            and reporting to tax authorities.
                        </PText>
                        <PText>
                            <b>3.5 Product Price</b>. If you are a Seller, you
                            may set or change the price for your Products at
                            your sole discretion. You agree to keep all pricing
                            information for your Products up to date and to
                            abide by any of your listed prices for sold
                            Products.
                        </PText>
                        <PText>
                            <b>3.6 Amount Paid to Sellers</b>. When one of your
                            Products is sold, you will receive the net purchase
                            price in the form of your base currency, minus (i)
                            any applicable taxes, (ii) any applicable payment
                            processing fees and (iii) a service and platform fee
                            in the amount set forth on the pricing page, which
                            is retained by Phair.
                        </PText>
                        <PH3>4. Licenses</PH3>
                        <PText>
                            <b>4.1 Restrictions</b>. Except and solely to the
                            extent such a restriction is impermissible under
                            applicable law, you may not: (a) reproduce,
                            distribute, publicly display, publicly perform, or
                            create derivative works of the Services; (b) make
                            modifications to the Services; or (c) interfere with
                            or circumvent any feature of the Services, including
                            any security or access control mechanism. If you are
                            prohibited under applicable law from using the
                            Services, then you may not use it.
                        </PText>
                        <PText>
                            <b>4.2 Feedback</b>. We respect and appreciate the
                            thoughts and comments from our users. If you choose
                            to provide input and suggestions regarding existing
                            functionalities, problems with or proposed
                            modifications or improvements to the Services ("
                            <b>Feedback</b>"), then you hereby grant Phair an
                            unrestricted, perpetual, irrevocable, non-exclusive,
                            fully-paid, royalty-free right and license to
                            exploit the Feedback in any manner and for any
                            purpose, including to improve the Services and
                            create other products and services. We will have no
                            obligation to provide you with attribution for any
                            Feedback you provide to us.
                        </PText>
                        <PText>
                            <b>4.3 Proprietary Rights</b>. The Services is owned
                            and operated by Phair. The visual interfaces,
                            graphics, design, compilation, information, data,
                            computer code (including source code or object
                            code), software, services, and all other elements of
                            the Services, but excluding any Products, provided
                            by Phair ("
                            <b>Materials</b>") are protected by intellectual
                            property and other laws. All Materials included in
                            the Services are the property of Phair or its
                            third-party licensors. Except as expressly
                            authorized by Phair, you may not make use of the
                            Materials. There are no implied licenses in these
                            Terms and Phair reserves all rights to the Materials
                            not granted expressly in these Terms.
                        </PText>
                        <PH3>5. Third-Party Terms</PH3>
                        <PText>
                            Phair may provide tools through the Services that
                            enable you to export information, including User
                            Content, to third-party services, including through
                            features that allow you to link your account on the
                            Services with an account on the third-party service,
                            such as Google or Stripe, or through our
                            implementation of third-party buttons (such as
                            "like" or "share" buttons). By using one of these
                            tools, you hereby authorize that Phair to transfer
                            that information to the applicable third-party
                            service. Third-party services are not under Phair's
                            control, and, to the fullest extent permitted by
                            law, Phair is not responsible for any third-party
                            service's use of your exported information. The
                            Services may also contain links to third-party
                            websites. Linked websites are not under Phair's
                            control, and Phair is not responsible for their
                            content. Further, Phair will not provide exports of
                            third-party data because the option to link to owned
                            data that is already available. Please be sure to
                            review the terms of use and privacy policy of any
                            third-party services before you share any User
                            Content or information with such third-party
                            services. Once sharing occurs, Phair will have no
                            control over the information that has been shared.
                        </PText>
                        <PH3>6. User Content</PH3>
                        <PText>
                            <b>6.1 User Content General Terms</b>. Certain
                            features of the Services may permit users to submit,
                            upload, publish, broadcast, or otherwise transmit ("
                            <b>Post</b>") content to the Services, including
                            Product images, descriptions, and any other works of
                            authorship or other works ("<b>User Content</b>").
                            You retain any copyright and other proprietary
                            rights that you may hold in the User Content that
                            you Post to the Services, subject to the licenses
                            granted in these Terms.
                        </PText>
                        <PText>
                            <b>6.2 Limited License Grant to Phair</b>. By
                            Posting User Content to or via the Services, you
                            grant Phair a worldwide, non-exclusive,
                            royalty-free, fully paid right and license (with the
                            right to sublicense through multiple tiers) to host,
                            store, transfer, publicly display, publicly perform
                            (including by means of a digital audio
                            transmission), communicate to the public, reproduce,
                            modify for the purpose of formatting for display,
                            create derivative works as authorized in these
                            Terms, and distribute your User Content, in whole or
                            in part, in any media formats and through any media
                            channels, in each instance whether now known or
                            hereafter developed. You agree to pay all monies
                            owing to any person or entity resulting from Posting
                            your User Content and from Phair's exercise of the
                            license set forth in this Section.
                        </PText>
                        <PText>
                            <b>
                                6.3 User Content Representations and Warranties
                            </b>
                            . You must not Post User Content if you are not the
                            owner of or are not fully authorized to grant rights
                            in all of the elements of that User Content. Phair
                            disclaims any and all liability in connection with
                            User Content. You are solely responsible for your
                            User Content and the consequences of providing User
                            Content via the Services. By providing User Content
                            via the Services, you affirm, represent, and warrant
                            to us that:
                        </PText>
                        <POl>
                            <PLi>
                                you are the creator and owner of the User
                                Content, or have the necessary licenses, rights,
                                consents, and permissions to authorize Phair and
                                users of the Services to use and distribute your
                                User Content as necessary to exercise the
                                licenses granted by you in this Section, in the
                                manner contemplated by Phair, the Services, and
                                these Terms;
                            </PLi>
                            <PLi>
                                your User Content, and the Posting or other use
                                of your User Content as contemplated by these
                                Terms, does not and will not: (i) infringe,
                                violate, misappropriate, or otherwise breach any
                                third-party right, including any copyright,
                                trademark, patent, trade secret, moral right,
                                privacy right, right of publicity, or any other
                                intellectual property, contract, or proprietary
                                right; (ii) slander, defame, libel, or invade
                                the right of privacy, publicity or other
                                property rights of any other person; or (iii)
                                cause Phair to violate any law or regulation or
                                require us to obtain any further licenses from
                                or pay any royalties, fees, compensation or
                                other amounts or provide any attribution to any
                                third parties; and
                            </PLi>
                            <PLi>
                                your User Content could not be deemed by a
                                reasonable person to be objectionable, profane,
                                indecent, pornographic, harassing, threatening,
                                embarrassing, hateful, or otherwise
                                inappropriate.
                            </PLi>
                        </POl>
                        <PText>
                            <b>6.4 User Content Disclaimer</b>. We are under no
                            obligation to edit or control User Content that you
                            or other users Post and will not be in any way
                            responsible or liable for User Content. Phair may,
                            however, at any time and without prior notice,
                            screen, remove, edit, or block any User Content that
                            in our sole judgment violates these Terms, is
                            alleged to violate the rights of third parties, or
                            is otherwise objectionable. You understand that,
                            when using the Services, you will be exposed to User
                            Content from a variety of sources and acknowledge
                            that User Content may be inaccurate, offensive,
                            indecent, or objectionable. You agree to waive, and
                            do waive, any legal or equitable right or remedy you
                            have or may have against Phair with respect to User
                            Content. If notified by a user or content owner that
                            User Content allegedly does not conform to these
                            Terms, we may investigate the allegation and
                            determine in our sole discretion whether to remove
                            the User Content, which we reserve the right to do
                            at any time and without notice. For clarity, Phair
                            does not permit infringing activities on the
                            Services.
                        </PText>
                        <PText>
                            <b>6.5 Monitoring Content</b>. Phair does not
                            control and does not have any obligation to monitor:
                            (a) User Content; (b) any content made available by
                            third parties; or (c) the use of the Services by its
                            users. You acknowledge and agree that Phair reserves
                            the right to, and may from time to time, monitor any
                            and all information transmitted or received through
                            the Services for operational and other purposes. If
                            at any time Phair chooses to monitor the content,
                            then Phair still assumes no responsibility or
                            liability for content or any loss or damage incurred
                            as a result of the use of content. During
                            monitoring, information may be examined, recorded,
                            copied, and used in accordance with our Privacy
                            Policy. Phair may block, filter, mute, remove or
                            disable access to any User Content uploaded to or
                            transmitted through the Services without any
                            liability to the user who Posted such User Content
                            to the Services or to any other users of the
                            Services.
                        </PText>
                        <PH3>7. Prohibited Conduct</PH3>
                        <PText>BY USING THE SERVICE, YOU AGREE NOT TO:</PText>
                        <POl>
                            <PLi>
                                use the Services or sell any Products for any
                                illegal purpose or in violation of any local,
                                state, national, or international law;
                            </PLi>
                            <PLi>
                                violate, encourage others to violate, or provide
                                instructions on how to violate, any right of a
                                third party, including by infringing or
                                misappropriating any third-party intellectual
                                property right;
                            </PLi>
                            <PLi>
                                access, search, or otherwise use any portion of
                                the Services through the use of any engine,
                                software, tool, agent, device, or mechanism
                                (including spiders, robots, crawlers, and data
                                mining tools) other than the software or search
                                agents provided by Phair;
                            </PLi>
                            <PLi>
                                interfere with security-related features of the
                                Services, including by: (i) disabling or
                                circumventing features that prevent or limit
                                use, printing or copying of any content; or (ii)
                                reverse engineering or otherwise attempting to
                                discover the source code of any portion of the
                                Services except to the extent that the activity
                                is expressly permitted by applicable law;
                            </PLi>
                            <PLi>
                                interfere with the operation of the Services or
                                any user's enjoyment of the Services, including
                                by:
                                <POl type="i">
                                    <PLi>
                                        uploading or otherwise disseminating any
                                        virus, adware, spyware, worm, or other
                                        malicious code;
                                    </PLi>
                                    <PLi>
                                        making any unsolicited offer or
                                        advertisement to another user of the
                                        Services;
                                    </PLi>
                                    <PLi>
                                        collecting personal information about
                                        another user or third party without
                                        consent; or
                                    </PLi>
                                    <PLi>
                                        interfering with or disrupting any
                                        network, equipment, or server connected
                                        to or used to provide the Services
                                    </PLi>
                                    <PLi>
                                        harassing any user with unsolicited,
                                        unwelcome, disrespectful, physically
                                        abusive or offensive behavior. Examples
                                        of harassment include, but are not
                                        limited to, user targeting attempts
                                        (social media bans, server bans,
                                        attacking other users' social media),
                                        unwanted comments or remarks, jokes,
                                        slurs, online messages, unwanted
                                        physical intimidation or touch and
                                        literature including pictures and
                                        posters, depicting people in a negative
                                        light based on prohibited grounds, e.g.
                                        racial or religious harassment, age
                                        harassment, etc.
                                    </PLi>
                                </POl>
                            </PLi>
                            <PLi>
                                perform any fraudulent activity including
                                impersonating any person or entity, claiming a
                                false affiliation or identity, accessing any
                                other Services account without permission;
                            </PLi>
                            <PLi>
                                sell or otherwise transfer the access granted
                                under these Terms or any Materials (as defined
                                in Section 4.3) or any right or ability to view,
                                access, or use any Materials; or
                            </PLi>
                            <PLi>
                                attempt to do any of the acts described in this
                                Section 7 or assist or permit any person in
                                engaging in any of the acts described in this
                                Section 7.
                            </PLi>
                        </POl>
                        <PH3>8. Intellectual Property Rights Protection</PH3>
                        <PText>
                            <b>8.1 Respect of Third Party Rights</b>. Phair
                            respects the intellectual property rights of others,
                            takes the protection of intellectual property rights
                            very seriously, and asks users of the Services to do
                            the same. Infringing activity will not be tolerated
                            on or through the Services.
                        </PText>
                        <PText>
                            <b>8.2 DMCA Notification</b>. We comply with the
                            provisions of the Digital Millennium Copyright Act
                            applicable to Internet service providers (17 U.S.C.
                            § 512, as amended). If you have an intellectual
                            property rights-related complaint about any material
                            on the Services, you may contact legal@phair.io.
                        </PText>
                        <PText>
                            <b>
                                8.3 Procedure for Reporting Claimed Infringement
                            </b>
                            . If you believe that any content made available on
                            or through the Services has been used or exploited
                            in a manner that infringes an intellectual property
                            right you own or control, then please promptly send
                            a written “Notification of Claimed Infringement” to
                            legal@phair.io containing the following information:
                        </PText>
                        <POl>
                            <PLi>
                                an electronic or physical signature of the
                                person authorized to act on behalf of the owner
                                of the copyright or other right being infringed;
                            </PLi>
                            <PLi>
                                a description of the copyrighted work or other
                                intellectual property right that you claim has
                                been infringed;
                            </PLi>
                            <PLi>
                                a description of the material that you claim is
                                infringing and where it is located on the
                                Services;
                            </PLi>
                            <PLi>
                                your address, telephone number, and email
                                address;
                            </PLi>
                            <PLi>
                                a statement by you that you have a good faith
                                belief that the use of the materials on the
                                Services of which you are complaining is not
                                authorized by the copyright or other
                                intellectual property right owner, its agent, or
                                the law; and
                            </PLi>
                            <PLi>
                                a statement by you that the above information in
                                your notice is accurate and that, under penalty
                                of perjury, you are the copyright or other
                                intellectual property right owner or authorized
                                to act on the copyright or intellectual property
                                owner's behalf. Your Notification of Claimed
                                Infringement may be shared by Phair with the
                                user alleged to have infringed a right you own
                                or control as well as with the operators of
                                publicly available databases that track
                                notifications of claimed infringement, and you
                                consent to Phair making such disclosures. You
                                should consult with your own lawyer or see 17
                                U.S.C. § 512 to confirm your obligations to
                                provide a valid notice of claimed infringement.
                            </PLi>
                        </POl>
                        <PText>
                            <b>8.4 Repeat Infringers</b>. Phair policy is to:
                            (a) remove or disable access to material that Phair
                            believes in good faith, upon notice from an
                            intellectual property rights owner or authorized
                            agent, is infringing the intellectual property
                            rights of a third party by being made available
                            through the Services; and (b) in appropriate
                            circumstances, to terminate the accounts of and
                            block access to the Service by any user who
                            repeatedly or egregiously infringes other people's
                            copyright or other intellectual property rights.
                            Phair will terminate the accounts of users that are
                            determined by Phair to be repeat infringers. Phair
                            reserves the right, however, to suspend or terminate
                            accounts of users in our sole discretion.
                        </PText>
                        <PText>
                            <b>8.5 Counter Notification</b>. If you receive a
                            notification from Phair that material made available
                            by you on or through the Services has been the
                            subject of a Notification of Claimed Infringement,
                            then you will have the right to provide Phair with
                            what is called a “Counter Notification.” To be
                            effective, a Counter Notification must be in
                            writing, provided to legal@phair.io, and include
                            substantially the following information:
                            <POl>
                                <PLi>
                                    your physical or electronic signature;
                                </PLi>
                                <PLi>
                                    identification of the material that has been
                                    removed or to which access has been disabled
                                    and the location at which the material
                                    appeared before it was removed or access to
                                    it was disabled;
                                </PLi>
                                <PLi>
                                    a statement under penalty of perjury that
                                    you have a good faith belief that the
                                    material was removed or disabled as a result
                                    of mistake or misidentification of the
                                    material to be removed or disabled; and
                                </PLi>
                                <PLi>
                                    your name, address, and telephone number,
                                    and a statement that you consent to the
                                    jurisdiction of Federal District Court for
                                    the judicial district in which the address
                                    is located, or if you are residing outside
                                    of the United States, then for any judicial
                                    district in which Phair may be found, and
                                    that you will accept services of process
                                    from the person who provided notification
                                    under Section 8.2 above or an agent of that
                                    person. A party submitting a Counter
                                    Notification should consult a lawyer or see
                                    17 U.S.C. § 512 to confirm the party's
                                    obligations to provide a valid counter
                                    notification under the Copyright Act.
                                </PLi>
                            </POl>
                        </PText>
                        <PText>
                            <b>
                                8.6 Reposting of Content Subject to a Counter
                                Notification
                            </b>
                            . If you submit a Counter Notification to Phair in
                            response to a Notification of Claimed Infringement,
                            then Phair will promptly provide the person who
                            provided the Notification of Claimed Infringement
                            with a copy of your Counter Notification and inform
                            that person that Phair will replace the removed User
                            Content or cease disabling access to it in 10
                            business days, and Phair will replace the removed
                            User Content and cease disabling access to it not
                            less than 10, nor more than 14, business days
                            following receipt of the Counter Notification,
                            unless Phair receives notice from the party that
                            submitted the Notification of Claimed Infringement
                            that such person has filed an action seeking a court
                            order to restrain the user from engaging in
                            infringing activity relating to the material on
                            Phair's system or network.
                        </PText>
                        <PText>
                            <b>
                                8.7 False Notifications of Claimed Infringement
                                or Counter Notifications
                            </b>
                            . The Copyright Act provides at 17 U.S.C. § 512(f)
                            that: “[a]ny person who knowingly materially
                            misrepresents under [Section 512 of the Copyright
                            Act (17 U.S.C. § 512)]
                            <POl>
                                <PLi>
                                    that material or activity is infringing, or
                                </PLi>
                                <PLi>
                                    that material or activity was removed or
                                    disabled by mistake or misidentification,
                                    will be liable for any damages, including
                                    costs and attorneys' fees, incurred by the
                                    alleged infringer, by any copyright owner or
                                    copyright owner's authorized licensee, or by
                                    a service provider, who is injured by such
                                    misrepresentation, as the result of [Phair]
                                    relying upon such misrepresentation in
                                    removing or disabling access to the material
                                    or activity claimed to be infringing, or in
                                    replacing the removed material or ceasing to
                                    disable access to it.” Phair reserves the
                                    right to seek damages from any party that
                                    submits a Notification of Claimed
                                    Infringement or Counter Notification in
                                    violation of the law.
                                </PLi>
                            </POl>
                        </PText>
                        <PH3>9. Modification of Terms</PH3>
                        <PText>
                            We may, from time to time, change these Terms.
                            Please check these Terms periodically for changes.
                            Revisions will be effective immediately except that,
                            for existing users, material revisions will be
                            effective 30 days after posting or notice to you of
                            the revisions unless otherwise stated. We may
                            require that you accept modified Terms in order to
                            continue to use the Service. If you do not agree to
                            the modified Terms, then you should remove your User
                            Content and discontinue your use of the Services.
                            Except as expressly permitted in this Section 9,
                            these Terms may be amended only by a written
                            agreement signed by authorized representatives of
                            the parties to these Terms.
                        </PText>
                        <PH3>
                            10. Term, Termination, and Modification of the
                            Service
                        </PH3>
                        <PText>
                            <b>10.1 Term</b>. These Terms are effective
                            beginning when you accept the Terms or first
                            download, install, access, or use the Services, and
                            ending when terminated as described in Section 10.2.
                        </PText>
                        <PText>
                            <b>10.2 Termination</b>. If you violate any
                            provision of these Terms, then your authorization to
                            access the Services and these Terms automatically
                            terminate. In addition, Phair may, at its sole
                            discretion, terminate these Terms or your account on
                            the Services, or suspend or terminate your access to
                            the Services, at any time for any reason or no
                            reason, with or without notice, and without any
                            liability to you arising from such termination. You
                            may terminate your account and these Terms at any
                            time by contacting customer service at
                            support@phair.io.
                        </PText>
                        <PText>
                            <b>10.3 Effect of Termination</b>. Upon termination
                            of these Terms: (a) your license rights will
                            terminate and you must immediately cease all use of
                            the Services; (b) you will no longer be authorized
                            to access your account or the Services; (c) you must
                            pay Phair any unpaid amount that was due prior to
                            termination; (d) you may lose access to certain
                            Products managed by Phair, and (e) all payment
                            obligations accrued prior to termination and
                            Sections 4.3, 5.3, 10.3, 11, 12, 13, 14, and 15 will
                            survive. You are solely responsible for retaining
                            copies of any User Content you Post to the Services
                            since upon termination of your account, you may lose
                            access rights to any User Content you Posted to the
                            Services. If your account has been terminated for a
                            breach of these Terms, then you are prohibited from
                            creating a new account on the Services using a
                            different name, email address or other forms of
                            account verification.
                        </PText>
                        <PText>
                            <b>10.4 Modification of the Services</b>. Phair
                            reserves the right to modify or discontinue all or
                            any portion of the Services at any time (including
                            by limiting or discontinuing certain features of the
                            Services), temporarily or permanently, without
                            notice to you. Phair will have no liability for any
                            change to the Services, including any paid-for
                            functionalities of the Services, or any suspension
                            or termination of your access to or use of the
                            Services. You should retain copies of any User
                            Content you Post to the Services so that you have
                            permanent copies in the event the Services is
                            modified in such a way that you lose access to User
                            Content you Posted to the Services.
                        </PText>
                        <PH3>11. Indemnity</PH3>
                        <PText>
                            To the fullest extent permitted by law, you are
                            responsible for your use of the Services, and you
                            will defend and indemnify Phair, its affiliates and
                            their respective shareholders, directors, managers,
                            members, officers, employees, consultants, and
                            agents (together, the "<b>Phair Entities</b>") from
                            and against every claim brought by a third party,
                            and any related liability, damage, loss, and
                            expense, including attorneys' fees and costs,
                            arising out of or connected with: (1) your
                            unauthorized use of, or misuse of, the Services or
                            any Product; (2) your violation of any portion of
                            these Terms, any representation, warranty, or
                            agreement referenced in these Terms, or any
                            applicable law or regulation; (3) your violation of
                            any third-party right, including any intellectual
                            property right or publicity, confidentiality, other
                            property, or privacy right; (4) any dispute
                            regarding any Product, or (5) any dispute or issue
                            between you and any third party. We reserve the
                            right, at our own expense, to assume the exclusive
                            defense and control of any matter otherwise subject
                            to indemnification by you (without limiting your
                            indemnification obligations with respect to that
                            matter), and in that case, you agree to cooperate
                            with our defense of those claims.
                        </PText>
                        <PH3>12. Disclaimers; No Warranties by Phair</PH3>
                        <POl>
                            <PLi>
                                THE SERVICES AND ALL MATERIALS AND CONTENT
                                AVAILABLE THROUGH THE SERVICES ARE PROVIDED “AS
                                IS” AND ON AN “AS AVAILABLE” BASIS. PHAIR
                                DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                                EXPRESS OR IMPLIED, RELATING TO THE SERVICES AND
                                ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE
                                SERVICES, INCLUDING: (A) ANY IMPLIED WARRANTY OF
                                MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                PURPOSE, TITLE, QUIET ENJOYMENT, OR
                                NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING
                                OUT OF COURSE OF DEALING, USAGE, OR TRADE. PHAIR
                                DOES NOT WARRANT THAT THE SERVICES OR ANY
                                PORTION OF THE SERVICES, OR ANY MATERIALS OR
                                CONTENT OFFERED THROUGH THE SERVICES, WILL BE
                                UNINTERRUPTED, SECURE, OR FREE OF ERRORS,
                                VIRUSES, OR OTHER HARMFUL COMPONENTS, AND PHAIR
                                DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL
                                BE CORRECTED.
                            </PLi>
                            <PLi>
                                ALL PRODUCTS ARE PROVIDED SOLELY BY THE
                                APPLICABLE SELLER, ANY PURCHASE OR SALE OF
                                PRODUCTS IS ONLY FACILITATED BY PHAIR. YOU AGREE
                                THAT PHAIR WILL NOT HAVE ANY LIABILITY TO YOU,
                                AND PHAIR HEREBY DISCLAIMS ALL SUCH LIABILITY,
                                REGARDING THE USE OR FUNCTION OF ANY PRODUCT.
                                ANY DISPUTES REGARDING A PRODUCT ARE SOLELY
                                BETWEEN THE APPLICABLE BUYER AND SELLER.
                            </PLi>
                            <PLi>
                                NO ADVICE OR INFORMATION, WHETHER ORAL OR
                                WRITTEN, OBTAINED BY YOU FROM THE SERVICES OR
                                PHAIR ENTITIES OR ANY MATERIALS OR CONTENT
                                AVAILABLE THROUGH THE SERVICES WILL CREATE ANY
                                WARRANTY REGARDING ANY OF THE PHAIR ENTITIES OR
                                THE SERVICES THAT IS NOT EXPRESSLY STATED IN
                                THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY
                                DAMAGE THAT MAY RESULT FROM THE SERVICES AND
                                YOUR DEALING WITH ANY OTHER SERVICES USER. YOU
                                UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF
                                THE SERVICES AT YOUR OWN DISCRETION AND RISK,
                                AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE
                                TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM
                                OR MOBILE DEVICE USED IN CONNECTION WITH THE
                                SERVICES) OR ANY LOSS OF DATA, INCLUDING USER
                                CONTENT.
                            </PLi>
                            <PLi>
                                THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN
                                THIS SECTION 12 APPLY TO THE FULLEST EXTENT
                                PERMITTED BY LAW. Phair does not disclaim any
                                warranty or other right that Phair is prohibited
                                from disclaiming under applicable law.
                            </PLi>
                        </POl>
                        <PH3>13. Limitation of Liability </PH3>
                        <POl>
                            <PLi>
                                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO
                                EVENT WILL THE PHAIR ENTITIES BE LIABLE TO YOU
                                FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                                CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING
                                DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY
                                OTHER INTANGIBLE LOSS) ARISING OUT OF OR
                                RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR
                                INABILITY TO ACCESS OR USE, THE SERVICES OR ANY
                                MATERIALS OR CONTENT ON THE SERVICES, WHETHER
                                BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
                                NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY,
                                AND WHETHER OR NOT ANY PHAIR ENTITY HAS BEEN
                                INFORMED OF THE POSSIBILITY OF DAMAGE.
                            </PLi>
                            <PLi>
                                EXCEPT AS PROVIDED IN SECTIONS 14.5 AND 14.6 AND
                                TO THE FULLEST EXTENT PERMITTED BY LAW, THE
                                AGGREGATE LIABILITY OF THE PHAIR ENTITIES TO YOU
                                FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE
                                USE OF OR ANY INABILITY TO USE ANY PORTION OF
                                THE SERVICES OR OTHERWISE UNDER THESE TERMS,
                                WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS
                                LIMITED TO THE GREATER OF:
                                <POl type="A">
                                    <PLi>
                                        THE AMOUNT YOU HAVE PAID TO PHAIR FOR
                                        ACCESS TO AND USE OF THE SERVICES IN THE
                                        12 MONTHS PRIOR TO THE EVENT OR
                                        CIRCUMSTANCE GIVING RISE TO THE CLAIM
                                        AND
                                    </PLi>
                                    <PLi>US$100.</PLi>
                                </POl>
                            </PLi>
                            <PLi>
                                EACH PROVISION OF THESE TERMS THAT PROVIDES FOR
                                A LIMITATION OF LIABILITY, DISCLAIMER OF
                                WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED
                                TO AND DOES ALLOCATE THE RISKS BETWEEN THE
                                PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN
                                ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN
                                BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS
                                SEVERABLE AND INDEPENDENT OF ALL OTHER
                                PROVISIONS OF THESE TERMS. THE LIMITATIONS IN
                                THIS SECTION 13 WILL APPLY EVEN IF ANY LIMITED
                                REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                            </PLi>
                        </POl>
                        <PH3>14. Dispute Resolution and Arbitration</PH3>
                        <PText>
                            YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO
                            THESE TERMS, YOU AND PHAIR ARE EACH WAIVING THE
                            RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
                            CLASS ACTION.
                        </PText>
                        <PText>
                            <b>14.1 Generally</b>. Except as described in
                            Section 17.2 and 17.3, you and Phair agree that
                            every dispute arising in connection with these
                            Terms, the Services, or communications from us will
                            be resolved through binding arbitration. Arbitration
                            uses a neutral arbitrator instead of a judge or
                            jury, is less formal than a court proceeding, may
                            allow for more limited discovery than in court, and
                            is subject to very limited review by courts. This
                            agreement to arbitrate disputes includes all claims
                            whether based in contract, tort, statute, fraud,
                            misrepresentation, or any other legal theory, and
                            regardless of whether a claim arises during or after
                            the termination of these Terms. Any dispute relating
                            to the interpretation, applicability, or
                            enforceability of this binding arbitration agreement
                            will be resolved by the arbitrator.
                        </PText>
                        <PText>
                            <b>14.2 Exceptions</b>. Although we are agreeing to
                            arbitrate most disputes between us, nothing in these
                            Terms will be deemed to waive, preclude, or
                            otherwise limit the right of either party to: (a)
                            bring an individual action in small claims court;
                            (b) pursue an enforcement action through the
                            applicable federal, state, or local agency if that
                            action is available; (c) seek injunctive relief in a
                            court of law in aid of arbitration; or (d) to file
                            suit in a court of law to address an intellectual
                            property infringement claim.
                        </PText>
                        <PText>
                            <b>14.3 Opt-Out</b>. If you do not wish to resolve
                            disputes by binding arbitration, you may opt out of
                            the provisions of this Section 14 within 30 days
                            after the date that you agree to these Terms by
                            sending a letter to legal@phair.io that specifies:
                            your full legal name, the email address associated
                            with your account on the Services, and a statement
                            that you wish to opt out of arbitration (“Opt-Out
                            Notice”). Once Phair receives your Opt-Out Notice,
                            this Section 14 will be void and any action arising
                            out of these Terms will be resolved as set forth in
                            Section 15.2. The remaining provisions of these
                            Terms will not be affected by your Opt-Out Notice.
                        </PText>
                        <PText>
                            <b>14.4 Arbitrator</b>. This arbitration agreement,
                            and any arbitration between us, is subject to the
                            Federal Arbitration Act and will be administered by
                            the American Arbitration Association (“AAA”) under
                            its Consumer Arbitration Rules (collectively, “AAA
                            Rules”) as modified by these Terms. The AAA Rules
                            and filing forms are available online at
                            www.adr.org, by calling the AAA at +1-800-7787879,
                            or by contacting Phair.
                        </PText>
                        <PText>
                            <b>14.5 Commencing Arbitration</b>. Before
                            initiating arbitration, a party must first send a
                            written notice of the dispute to the other party by
                            certified U.S. Mail or by Federal Express (signature
                            required) or, only if that other party has not
                            provided a current physical address, then by
                            electronic mail (“Notice of Arbitration”). The
                            Notice of Arbitration must: (a) identify the name or
                            account number of the party making the claim; (b)
                            describe the nature and basis of the claim or
                            dispute; and (c) set forth the specific relief
                            sought (“Demand”). The parties will make good faith
                            efforts to resolve the claim directly, but if the
                            parties do not reach an agreement to do so within 30
                            days after the Notice of Arbitration is received,
                            you or Phair may commence an arbitration proceeding.
                            Payment of all filing, case-management,
                            administrative, hearing, and arbitrator fees
                            (“Arbitration Fees”) will be governed by the AAA's
                            rules and fee schedules, unless otherwise stated in
                            this section 14.
                        </PText>
                        <PText>
                            <b>14.6 Arbitration Proceedings</b>. Any arbitration
                            hearing will take place in the county and state of
                            your [residence/billing address] unless we agree
                            otherwise or, if the claim is for US$10,000 or less
                            (and does not seek injunctive relief), you may
                            choose whether the arbitration will be conducted:
                            (a) solely on the basis of documents submitted to
                            the arbitrator; (b) through a telephonic or video
                            hearing; or (c) by an in-person hearing as
                            established by the AAA Rules in the county (or
                            parish) of your [residence/billing address]. During
                            the arbitration, the amount of any settlement offer
                            made by you or Phair must not be disclosed to the
                            arbitrator until after the arbitrator makes a final
                            decision and award, if any. Regardless of the manner
                            in which the arbitration is conducted, the
                            arbitrator must issue a reasoned written decision
                            sufficient to explain the essential findings and
                            conclusions on which the decision and award, if any,
                            are based.
                        </PText>
                        <PText>
                            <b>14.7 Arbitration Relief</b>. Except as provided
                            in Section 14.8, the arbitrator can award any relief
                            that would be available if the claims had been
                            brought in a court of competent jurisdiction. The
                            arbitrator's award shall be final and binding on all
                            parties , except (1) for judicial review expressly
                            permitted by law or (2) if the arbitrator's award
                            includes an award of injunctive relief against a
                            party, in which case that party shall have the right
                            to seek judicial review of the injunctive relief in
                            a court of competent jurisdiction that shall not be
                            bound by the arbitrator's application or conclusions
                            of law. Judgment on the award may be entered in any
                            court having jurisdiction.
                        </PText>
                        <PText>
                            <b>14.8 No Class Actions</b>. YOU AND PHAIR AGREE
                            THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
                            YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A
                            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                            REPRESENTATIVE PROCEEDING. Further, unless both you
                            and Phair agree otherwise, the arbitrator may not
                            consolidate more than one person's claims, and may
                            not otherwise preside over any form of a
                            representative or class proceeding.
                        </PText>
                        <PText>
                            <b>
                                14.9 Modifications to this Arbitration Provision
                            </b>
                            . If Phair makes any substantive change to this
                            arbitration provision, you may reject the change by
                            sending us written notice within 30 days of the
                            change to legal@phair.io, in which case your account
                            with Phair will be immediately terminated and this
                            arbitration provision, as in effect immediately
                            prior to the changes you rejected will survive.
                        </PText>
                        <PText>
                            <b>14.10 Enforceability</b>. If Section 14.8 or the
                            entirety of this Section 14 is found to be
                            unenforceable, or if Phair receives an Opt-Out
                            Notice from you, then the entirety of this Section
                            14 will be null and void and, in that case, the
                            exclusive jurisdiction and venue described in
                            Section 15.2 will govern any action arising out of
                            or related to these Terms.
                        </PText>
                        <PH3>15. Miscellaneous</PH3>
                        <PText>
                            <b>15.1 General Terms</b>. These Terms, including
                            the{" "}
                            <a href="/privacy" target="_blank">
                                Privacy Policy
                            </a>{" "}
                            and any other agreements expressly incorporated by
                            reference into these Terms, are the entire and
                            exclusive understanding and agreement between you
                            and Phair regarding your use of the Services. You
                            may not assign or transfer these Terms or your
                            rights under these Terms, in whole or in part, by
                            operation of law or otherwise, without our prior
                            written consent. We may assign these Terms and all
                            rights granted under these Terms, including with
                            respect to your User Content, at any time without
                            notice or consent. The failure to require
                            performance of any provision will not affect our
                            right to require performance at any other time after
                            that, nor will a waiver by us of any breach or
                            default of these Terms, or any provision of these
                            Terms, be a waiver of any subsequent breach or
                            default or a waiver of the provision itself. Use of
                            Section headers in these Terms is for convenience
                            only and will not have any impact on the
                            interpretation of any provision. Throughout these
                            Terms the use of the word "including" means
                            "including but not limited to". If any part of these
                            Terms is held to be invalid or unenforceable, then
                            the unenforceable part will be given effect to the
                            greatest extent possible, and the remaining parts
                            will remain in full force and effect.
                        </PText>
                        <PText>
                            <b>15.2 Privacy Policy</b>. Please read the{" "}
                            <a href="/privacy" target="_blank">
                                Phair Privacy Policy
                            </a>{" "}
                            (the "<b>Privacy Policy</b>") carefully for
                            information relating to our collection, use,
                            storage, and disclosure of your personal
                            information. The Phair Privacy Policy is
                            incorporated by this reference into, and made a part
                            of, these Terms.
                        </PText>
                        <PText>
                            <b>15.3 Additional Terms</b>. Your use of the
                            Services is subject to all additional terms,
                            policies, rules, or guidelines applicable to the
                            Service or certain features of the Services that we
                            may post on or link to from the Services (the "
                            <b>Additional Terms</b>"). The purchase or sale of
                            certain Products may also require Additional Terms.
                            All Additional Terms are incorporated by this
                            reference into, and made a part of, these Terms.
                        </PText>
                        <PText>
                            <b>15.4 Consent to Electronic Communications</b>. By
                            using the Services, you consent to receiving certain
                            electronic communications from us as further
                            described in our{" "}
                            <a href="/privacy" target="_blank">
                                Privacy Policy
                            </a>
                            . Please read our{" "}
                            <a href="/privacy" target="_blank">
                                Privacy Policy
                            </a>{" "}
                            to learn more about our electronic communications
                            practices. You agree that any notices, agreements,
                            disclosures, or other communications that we send to
                            you electronically will satisfy any legal
                            communication requirements, including that those
                            communications be in writing.
                        </PText>
                        <PText>
                            <b>15.5 Notice to California Residents</b>. If you
                            are a California resident, then under California
                            Civil Code Section 1789.3, you may contact the
                            Complaint Assistance Unit of the Division of
                            Consumer Services of the California Department of
                            Consumer Affairs in writing at 1625 N. Market Blvd.,
                            Suite S-202, Sacramento, California 95834, or by
                            telephone at +1-800-952-5210 in order to resolve a
                            complaint regarding the Services or to receive
                            further information regarding use of the Services.
                        </PText>
                        <PText>
                            <b>15.6 No Support</b>. We are under no obligation
                            to provide support for the Services. In instances
                            where we may offer support, the support will be
                            subject to published policies.
                        </PText>
                    </PBox>
                </PBox>
            </PSection>
        </Layout>
    );
};

const styles = {
    color: "rgb(75 85 99)",
    lineHeight: "1.8",
    maxWidth: "56rem",
    padding: "0 2rem",
    margin: "4rem auto",
    "& .terms": {
        marginBottom: "8rem",
    },
    "& .terms-title": {
        margin: "4rem 0",
        "& h2": {
            lineHeight: "2.5rem",
            fontWeight: "700",
            fontSize: "2.5rem",
            color: "rgb(17 24 39)",
        },
    },
    "& a": {
        color: "$colors$primary",
        textDecoration: "none",
        fontWeight: "500",
    },
};

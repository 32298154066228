export const changePasswordFormLabels = {
    current_password: {
        is_required: true,
        title: "Current Password",
        description: "Enter your current password",
    },
    new_password: {
        is_required: true,
        title: "New Password",
        description: "Enter your new password",
    },
    confirm_new_password: {
        is_required: true,
        title: "Confirm New Password",
        description: "Re-enter your new password",
    },
};

export const requestRefundFormLabels = {
    email: {
        is_required: true,
        title: "Email",
        description:
            "We will get back to you about your request to the email below.",
    },
    refund_reason: {
        is_required: true,
        title: "Reason for Request",
        description: "Please let us know what has been wrong.",
    },
};

export const addProductFormLables = {
    product_content: {
        is_required: false,
        title: "Product Content",
        description:
            "What does your product include? This information will be displayed on the product download page. Feel free to add links to your product, detailed descriptions, marketing materials, and anything else relevant.",
    },
    product_files: {
        is_required: false,
        title: "Product Files",
        description:
            "What comes with your product? Please upload the digital files that customers will receive upon purchase. You can upload multiple files.",
    },
    product_name: {
        is_required: true,
        title: "Product Name",
        description:
            "What name would you like for your product? Choose a name that is straightforward and memorable, avoiding excessive complexity.",
    },
    price: {
        is_required: true,
        title: "Price",
        description:
            "How much would you like to charge? For the 'Amount' option, please note that the minimum price for any product is $1. USD is now the only supported currency.",
    },
    transaction_fee: {
        is_required: true,
        title: "Transaction Fee",
        description: "Who will pay the Stripe transaction fee?",
    },
    category: {
        is_required: true,
        title: "Category",
        description: "Choose one category that best describes your product.",
    },
    tags: {
        is_required: false,
        title: "Tags",
        description:
            "Add tags to your product. These tags can help customers find your product.",
    },
    listing_status: {
        is_required: true,
        title: "Listing Status",
        description:
            "Whether your product is visible in the marketplace? Making your product public so customers can view and purchase it. You can keep your product private until it is ready to go public.",
    },
    cover_image: {
        is_required: true,
        title: "Cover Image",
        description:
            "Upload a cover image to display and showcase your product. This image will also be used as the thumbnail of your product. Only one image can be uploaded.",
    },
    product_images: {
        is_required: false,
        title: "Product Images",
        description:
            "Upload images to help customers know more details of your product. You can upload more than one image.",
    },
    product_highlights: {
        is_required: false,
        title: "Product Highlights",
        description: "Provide short highlights of your product's features.",
    },
    product_description: {
        is_required: false,
        title: "Product Description",
        description:
            "Explain in detail what comes with your product when customers purchase.",
    },
    terms_conditions: {
        is_required: false,
        title: "Terms, Conditions, and License",
        description:
            "What rules, specifications, and requirements you would like the customers follow after they purchase your product. This field is optional.",
    },
};

export const shopSettingsFormLables = {
    shop_name: {
        is_required: true,
        title: "Shop Name",
        description: "Name your shop / brand / business.",
    },
    seller_name: {
        is_required: false,
        title: "Display Name",
        description: "Your name as the seller displayed in your store.",
    },
    shop_cover_image: {
        is_required: false,
        title: "Shop Cover Image",
        description:
            "The image used in your store page banner. A high resolution image is preferred.",
    },
    shop_logo: {
        is_required: false,
        title: "Shop Logo",
        description: "The logo image of your shop / brand / business.",
    },
    shop_description: {
        is_required: false,
        title: "Shop Description",
        description: "One sentence to describe your shop.",
    },
    shop_announcement: {
        is_required: false,
        title: "Shop Announcement",
        description:
            "What would you like your customers know when they visit your store page?",
    },
};

export const accountSettingsFormLabels = {
    preferred_name: {
        is_required: true,
        title: "Preferred Name",
        description: "The name appears in your public profile.",
    },
    email: {
        is_required: true,
        title: "Email",
        description:
            "Your email address for sign in and receive notifications from Phair.",
    },
    profile_image: {
        is_required: true,
        title: "Profile Image",
        description: "The avatar appears in your public profile.",
    },
};

export const orderReviewFormLabels = {
    rate: {
        is_required: false,
        title: "Rate",
        description: "How would you rate the product?",
    },
    comment: {
        is_required: false,
        title: "Comment",
        description: "What did you like or dislike?",
    },
};

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const StoreOrders = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How to see all orders in my store?</PH1>
                <PText>
                    To see all orders in your store, go to the seller{" "}
                    <b>dashboard</b> -{">"} <b>orders</b> page. You can view and
                    send receipts of the orders by clicking the receipt button
                    in the last column.
                </PText>
            </PBox>
            <HelpNav
                prev={"Delete a product"}
                prev_link={"/help/delete-a-product"}
                next={"Purchase history"}
                next_link={"/help/purchase-history"}
            />
        </Layout>
    );
};

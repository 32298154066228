import { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Pagination } from "antd";
import {
    PBox,
    PH1,
    PH4,
    PHighlightLink,
    PLink,
    PImg,
    PText,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import EditableProducts from "../../components/seller/EditableProducts";
import { Loader } from "../../components/Loader";
import { fetchProductListDetailsByMerchantID } from "../../../actions/product";
import EditableProductsGrid from "../../components/seller/EditableProductsGrid";
import EditableProductsList from "../../components/seller/EditableProductsList";

class Listings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            displayOption: "list",
            page: 1,
            pageSize: 10,
        };
    }
    componentDidMount() {
        this.props
            .fetchProductListDetailsByMerchantID(
                this.props.match.params.shop_uuid,
                (this.state.page - 1) * this.state.pageSize,
                this.state.pageSize,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles} className="listings">
                    <PBox className="listings-card">
                        <PBox className="listings-title">
                            <PH1>All Products</PH1>
                            <PLink
                                href={`/seller/${this.props.match.params.shop_uuid}/create`}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1rem"
                                    height="1rem"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
                                </svg>{" "}
                                New Product
                            </PLink>
                        </PBox>

                        {this.props.productList?.length > 0 && (
                            <PBox className="listings-display-options">
                                <PBox
                                    className="listings-display-option"
                                    onClick={() => {
                                        this.setState({
                                            displayOption: "list",
                                        });
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#000000"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M80,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H88A8,8,0,0,1,80,64Zm136,56H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,64H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM44,52A12,12,0,1,0,56,64,12,12,0,0,0,44,52Zm0,64a12,12,0,1,0,12,12A12,12,0,0,0,44,116Zm0,64a12,12,0,1,0,12,12A12,12,0,0,0,44,180Z"></path>
                                    </svg>
                                </PBox>
                                <PBox
                                    className="listings-display-option"
                                    onClick={() => {
                                        this.setState({
                                            displayOption: "grid",
                                        });
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#000000"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M104,40H56A16,16,0,0,0,40,56v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,104,40Zm0,64H56V56h48v48Zm96-64H152a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,200,40Zm0,64H152V56h48v48Zm-96,32H56a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V152A16,16,0,0,0,104,136Zm0,64H56V152h48v48Zm96-64H152a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V152A16,16,0,0,0,200,136Zm0,64H152V152h48v48Z"></path>
                                    </svg>
                                </PBox>
                                <PBox
                                    className="listings-display-option"
                                    onClick={() => {
                                        this.setState({
                                            displayOption: "table",
                                        });
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#000000"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM40,112H80v32H40Zm56,0H216v32H96ZM216,64V96H40V64ZM40,160H80v32H40Zm176,32H96V160H216v32Z"></path>
                                    </svg>
                                </PBox>
                            </PBox>
                        )}

                        {this.props.productList?.length > 0 && (
                            <PBox className="listing-display-data">
                                {this.state.displayOption === "table" && (
                                    <EditableProducts
                                        data={this.props.productList}
                                    />
                                )}
                                {this.state.displayOption === "grid" && (
                                    <EditableProductsGrid
                                        shop_uuid={
                                            this.props.match.params.shop_uuid
                                        }
                                        data={this.props.productList}
                                    />
                                )}
                                {this.state.displayOption === "list" && (
                                    <EditableProductsList
                                        shop_uuid={
                                            this.props.match.params.shop_uuid
                                        }
                                        data={this.props.productList}
                                    />
                                )}
                                <PBox className="listing-display-pagination">
                                    <Pagination
                                        showQuickJumper
                                        defaultCurrent={1}
                                        total={this.props.totalProducts}
                                        showSizeChanger={false}
                                        pageSize={this.state.pageSize}
                                        onChange={async (page) => {
                                            await this.setState({ page: page });
                                            await this.props.fetchProductListDetailsByMerchantID(
                                                this.props.match.params
                                                    .shop_uuid,
                                                (page - 1) *
                                                    this.state.pageSize,
                                                this.state.pageSize,
                                                this.props.history
                                            );
                                        }}
                                    />
                                </PBox>
                            </PBox>
                        )}
                        {this.props.productList?.length === 0 && (
                            <PBox className="listings-no-data">
                                <PImg src="/images/no-data.jpg" />
                                <PText>
                                    You don't have any products yet. Add your
                                    fantastic items to get started.
                                </PText>
                            </PBox>
                        )}
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    margin: "0rem auto",
    maxWidth: "80rem",
    "& .listings-card": {
        margin: "0rem 2rem 2rem 2rem",
        backgroundColor: "#fff",
        padding: "2rem 2rem",
        "& .listings-title": {
            display: "flex",
            justifyContent: "space-between",
            "& h1": {
                fontSize: "1.875rem",
                lineHeight: "2.25rem",
                fontWeight: "700",
                color: "rgb(17 24 39)",
                //textAlign: "center",
            },
            "& a": {
                display: "flex",
                alignItems: "center",
                margin: "auto 1rem",
                color: "#fff",
                fontWeight: "600",
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                padding: "0.5rem 0.75rem",
                backgroundColor: "$colors$primary",
                borderRadius: "0.375rem",
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: "$colors$primary500",
                },
            },
            "& svg": {
                fill: "#fff",
                marginRight: "0.5rem",
            },
        },
    },
    "& .listings-display-options": {
        display: "flex",
        marginTop: "2rem",
    },
    "& .listings-display-option": {
        cursor: "pointer",
        marginRight: "6px",
        "&:hover": {
            "& svg": { fill: "$colors$secondary" },
        },
    },
    "& .listing-display-pagination": {
        display: "flex",
        justifyContent: "center",
    },
    "& .listings-no-data": {
        padding: "30px 0",
        textAlign: "center",
        "& p": {
            fontSize: "1rem",
            fontWeight: "500",
            color: "rgb(107 114 128)",
        },
        "& img": {
            width: "12rem",
        },
        "& a": {
            fontSize: "20px",
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "$colors$mediumSecondary",
            borderColor: "$colors$mediumSecondary",
            marginTop: "40px",
            textDecoration: "none",
            "&:hover": {
                backgroundColor: "#fff",
                color: "$colors$mediumSecondary",
            },
        },
    },
};

function mapStateToProps(state) {
    return {
        productList:
            state.productReducer?.fetchProductListDetailsByMerchant
                ?.productList,
        totalProducts:
            state.productReducer?.fetchProductListDetailsByMerchant
                ?.totalProducts,
        userAuth: state.authReducer?.userAuth,
    };
}

export default connect(mapStateToProps, {
    fetchProductListDetailsByMerchantID,
})(withRouter(Listings));

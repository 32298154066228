import clsx from "clsx";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
    PTable,
} from "../theme/BaseTheme";

export const SellPlan = () => {
    return (
        <PSection css={styles}>
            <PBox className="sell-plan">
                <PContainer>
                    <PText className="sell-plan-subtitle">Pricing</PText>
                    <PH1 className="sell-plan-title">
                        Earn More with Phair Membership
                    </PH1>
                    <PText className="sell-plan-coming-soon">
                        Coming Soon ...
                    </PText>
                    <PBox className="sell-plan-table-container">
                        <PBox className="sell-plan-table">
                            <PBox className={clsx("table-cell")}></PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "basic",
                                    "border-color-top",
                                    "border-color-left",
                                    "border-color-right"
                                )}
                            >
                                Basic
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "premium",
                                    "border-highlight-top",
                                    "border-highlight-left",
                                    "border-highlight-right"
                                )}
                            >
                                Premium
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M243.84,76.19a12.08,12.08,0,0,0-13.34-1.7l-50.21,25L138.37,29.86a12.11,12.11,0,0,0-20.74,0L75.71,99.52l-50.19-25A12.11,12.11,0,0,0,8.62,89.12l37,113.36a8,8,0,0,0,11.68,4.4C57.55,206.73,83.12,192,128,192s70.45,14.73,70.68,14.87a8,8,0,0,0,11.71-4.39l37-113.33A12.06,12.06,0,0,0,243.84,76.19Zm-68,80.61a8,8,0,0,1-7.87,6.61,8.36,8.36,0,0,1-1.4-.12,228.2,228.2,0,0,0-77.22,0,8,8,0,0,1-2.78-15.76,244.42,244.42,0,0,1,82.78,0A8,8,0,0,1,175.88,156.8Z" />
                                </svg>
                            </PBox>
                            <PBox className={clsx("table-row")}>Fees</PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "cell-feature",
                                    "border-bottom"
                                )}
                            >
                                Membership Options
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-color-left",
                                    "border-color-right",
                                    "border-bottom"
                                )}
                            >
                                $0
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-bottom",
                                    "border-highlight-right",
                                    "border-highlight-left"
                                )}
                            >
                                Membership Fee Applies
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "cell-feature",
                                    "border-bottom"
                                )}
                            >
                                Platform Fee
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-color-right",
                                    "border-bottom",
                                    "border-color-left"
                                )}
                            >
                                6% + $0.3
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-bottom",
                                    "border-highlight-right",
                                    "border-highlight-left"
                                )}
                            >
                                4% + $0.2
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "cell-feature",
                                    "border-bottom"
                                )}
                            >
                                Free Product Listing
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-color-right",
                                    "border-color-left"
                                )}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
                                </svg>
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-highlight-right",
                                    "border-highlight-left"
                                )}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
                                </svg>
                            </PBox>
                            <PBox className={clsx("table-row")}>Features</PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "cell-feature",
                                    "border-bottom"
                                )}
                            >
                                Product Storage
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-color-right",
                                    "border-bottom",
                                    "border-color-left"
                                )}
                            >
                                5 GB
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-bottom",
                                    "border-highlight-right",
                                    "border-highlight-left"
                                )}
                            >
                                Unlimited
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "cell-feature",
                                    "border-bottom"
                                )}
                            >
                                Email Updates to Customers
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-color-right",
                                    "border-bottom",
                                    "border-color-left"
                                )}
                            ></PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-bottom",
                                    "border-highlight-right",
                                    "border-highlight-left"
                                )}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
                                </svg>
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "cell-feature",
                                    "border-bottom"
                                )}
                            >
                                Opportunity to be Featured
                            </PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-color-right",
                                    "border-color-bottom",
                                    "border-color-left"
                                )}
                            ></PBox>
                            <PBox
                                className={clsx(
                                    "table-cell",
                                    "border-highlight-bottom",
                                    "border-highlight-right",
                                    "border-highlight-left"
                                )}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
                                </svg>
                            </PBox>
                        </PBox>
                    </PBox>
                </PContainer>
            </PBox>
        </PSection>
    );
};

const styles = {
    marginTop: "80px",
    "& .sell-plan": { marginBottom: "60px" },
    "& .sell-plan-table": {},
    "& .sell-plan-container": {
        position: "relative",
    },
    "& .sell-plan-subtitle": {
        textAlign: "center",
        margin: "0",
        padding: "0",
        fontSize: "16px",
        fontWeight: "600",
        background: "linear-gradient(to right, #f47f68,#5344a9)",
        backgroundClip: "text",
        "-webkit-background-clip": "text",
        backgroundSize: "200% 200%",
        color: "transparent",
    },
    "& .sell-plan-title": {
        textAlign: "center",
        fontFamily: "Poppins, sans-serif",
        fontSize: "2rem",
        fontWeight: "500",
        lineHeight: "3rem",
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "1.25rem",
        paddingBottom: "0",
        marginTop: "0",
        marginBottom: "1rem",
    },
    "& .sell-plan-coming-soon": {
        textAlign: "center",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        fontSize: "12px",
        marginTop: "0",
        position: "relative",
        width: "200px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "4px 0",
        color: "#fff",
    },
    "& .sell-plan-coming-soon::before": {
        content: "",
        position: "absolute",
        top: "0px",
        left: "0px",
        zIndex: "-1",
        width: "100%",
        height: "100%",
        background: "linear-gradient(to right, #f5c63c,#f47f68,#f5c63c)",
        borderRadius: "3px",
        transform: "skew(30deg)",
    },
    "& .sell-plan-coming-soon::after": {
        content: "",
        position: "absolute",
        top: "0px",
        left: "0px",
        zIndex: "-1",
        width: "100%",
        height: "100%",
        background: "linear-gradient(to right,#f5c63c, #f47f68,#f5c63c)",
        borderRadius: "3px",
        transform: "skew(-30deg)",
    },
    "& .sell-plan-table": {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 auto",
        padding: "2rem 0 0 0",
        maxWidth: "1080px",
    },
    "& .table-cell": {
        boxSizing: "border-box",
        flexGrow: "1",
        padding: "0.8em, 1.2em",
        overflow: "hidden",
        listStyleType: "none",
        //outline: "1px solid #ddd",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1px 0 0 1px",
        width: `calc(33.33% - 1px)`,
        minHeight: "60px",
        "&:first-child": {
            outline: "1px solid #fff",
            background: "transparent",
            display: "none",
            "@bp2": {
                display: "block",
            },
        },
        "&:nth-child(3)": {
            //outline: "1px solid $colors$label",
        },
        "&:nth-child(-n+3)": {
            padding: "0 40px",
        },
    },
    "& .cell-feature": {
        width: "100%",
        justifyContent: "start",
        fontWeight: "500",
        paddingLeft: "20px",
        "@bp2": {
            width: `calc(33.33% - 1px)`,
        },
    },
    "& .table-row": {
        boxSizing: "border-box",
        flexGrow: "1",
        width: "100%",
        padding: "0.8em, 1.2em",
        overflow: "hidden",
        listStyleType: "none",
        //outline: "1px solid #ddd",
        backgroundColor: "$colors$lightPrimary",
        borderRadius: "8px",
        margin: "0px 0 0 1px",
        minHeight: "60px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        fontWeight: "600",
        fontSize: "20px",
        paddingLeft: "20px",
    },
    "& .border-right": {
        borderRight: "1px solid #ddd",
    },
    "& .border-bottom": {
        borderBottom: "1px solid #ddd",
    },
    "& .border-left": {
        borderLeft: "1px solid #ddd",
    },
    "& .border-top": {
        borderTop: "1px solid #ddd",
    },
    "& .border-color-right": {
        borderRight: "1px solid #ddd",
    },
    "& .border-color-bottom": {
        borderBottom: "1px solid #ddd",
    },
    "& .border-color-left": {
        borderLeft: "1px solid #ddd",
    },
    "& .border-color-top": {
        borderTop: "1px solid #ddd",
    },
    "& .border-highlight-right": {
        borderRight: "3px solid $colors$darkSecondary",
    },
    "& .border-highlight-bottom": {
        borderBottom: "3px solid $colors$darkSecondary",
    },
    "& .border-highlight-left": {
        borderLeft: "3px solid $colors$darkSecondary",
    },
    "& .border-highlight-top": {
        borderTop: "3px solid $colors$darkSecondary",
    },
    "& .basic": {
        fontWeight: "600",
        fontSize: "20px",
    },
    "& .premium": {
        fontWeight: "600",
        fontSize: "20px",
        backgroundColor: "$colors$darkSecondary",
        color: "#fff",
        position: "relative",
        "& svg": {
            width: "24px",
            height: "24px",
            fill: "$colors$quinary",
            transform: "rotate(20deg)",
            position: "absolute",
            top: "4px",
            left: `calc(50% + 40px)`,
        },
    },
};

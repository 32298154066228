import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import AccountForm from "../../components/seller/AccountForm";
import { PBox, PH1 } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { fetchUserAccountByMerchantID } from "../../../actions/account";

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchUserAccountByMerchantID(
                this.props.match.params.shop_uuid,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles} className="account">
                    <PBox className="account-card">
                        <PH1>Account Settings</PH1>
                        <AccountForm />
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    margin: "0rem auto",
    maxWidth: "80rem",
    "& .account-card": {
        margin: "0rem 2rem 2rem 2rem",
        backgroundColor: "#fff",
        padding: "2rem 2rem",
        "& h1": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            fontWeight: "700",
            color: "rgb(17 24 39)",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.accountReducer?.fetchUserAccount.userAccount,
    };
}

export default connect(mapStateToProps, {
    fetchUserAccountByMerchantID,
})(withRouter(Account));

import { createStitches } from "@stitches/react";

export const {
    styled,
    css,
    globalCss,
    keyframes,
    theme,
    createTheme,
    getCssText,
    config,
} = createStitches({
    prefix: "p-base",
    media: {
        bp1: "(min-width: 480px)",
        bp2: "(min-width: 640px)",
        bp3: "(min-width: 768px)",
        bp4: "(min-width: 1024px)",
        bp5: "(min-width: 1200px)",
        bp6: "(min-width: 1367px)",
        bp7: "(min-width: 1440px)",
    },
});

export const BaseTheme = createTheme("p-base-theme", {
    colors: {
        //primary: "#EC8D31",
        //secondary: "#FDBF46",
        //tertiary: "#FED6E1",
        //quaternary: "#1BA37B",
        //quinary: "#49C9E0",
        //lightBackground: "#fff",
        //text: "#002346",

        /*primary: "#5142fc",
        secondary: "#e250e5",
        tertiary: "#4b50e6",
        quaternary: "#181b81",
        quinary: "#8a208c",*/

        //primary: "#5344a9",
        primary: "#6d28d9",
        lightPrimary: "rgb(238, 236, 255)",
        secondary: "#7a5197",
        tertiary: "#bb5098",
        quaternary: "#f47f68",
        quinary: "#f5c63c",

        darkSecondary: "rgb(72, 38, 104)",
        lightSecondary: "#e2c4ff",
        mediumSecondary: "rgb(140,104,205)",

        label: "#7a798a",
        lightBackground: "#f8f8f8",
        text: "#1b1b1b",
        article: "#3f3f46",

        heading: "#212121",
        link: "$colors$primary",
        borderColor: "#e6e6e6",
        background: "#eff1f7",

        primary950: "#2e1065",
        primary900: "#4c1d95",
        primary800: "#5b21b6",
        primary700: "#6d28d9",
        primary600: "#7c3aed",
        primary500: "#8b5cf6",
        primary400: "#a78bfa",
        primary300: "#c4b5fd",
        primary200: "#ddd6fe",
        primary100: "#ede9fe",
        primary50: "#f5f3ff",
    },
    boxShadows: {
        dashboardCard: "0 1px 2px rgba(56, 65, 74, 0.15)",
    },
    fonts: {
        body: "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",

        heading:
            "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",

        monospace: "Menlo, monospace",
    },
    fontSizes: [
        "12px", // 0
        "14px", // 1
        "16px", // 2
        "18px", // 3
        "20px", // 4
        "22px", // 5
        "24px", // 6
        "28px", // 7
        "30px", // 8
        "32px", // 9
        "36px", // 10
        "42px", // 11
        "48px", // 12
        "52px", // 13
        "64px", // 14
        "72px", // 15
        "80px", // 16
        "96px", // 17
    ],
    fontWeights: [
        "100", // 1
        "200", // 2
        "300", // 3
        "400", // 4
        "500", // 5
        "600", // 6
        "700", // 7
    ],
    lineHeights: [
        "0.8", // 1
        "1", // 2
        "1.125", // 3
        "1.25", // 4
        "1.4", // 5
        "1.5", // 6
        "1.6", // 7
        "1.7", // 8
        "1.8", // 9
        "2.0", // 10
    ],
    lineBreaks: {
        auto: "auto",
        loose: "loose",
        normal: "normal",
        strict: "strict",
        anywhere: "anywhere",
    },
    letterSpacings: [
        "normal", // 1
        "-1px", // 2
        "-0.5px", // 3
        "0px", // 4
        "1px", // 5
        "2px", // 6
        "3px", // 7
    ],
    spaces: [
        "0px", // 0
        "4px", // 1
        "8px", // 2
        "12px", // 3
        "16px", // 4
        "20px", // 5
        "24px", // 6
        "28px", // 7
        "32px", // 8
        "36px", // 9
        "40px", // 10
        "44px", // 11
        "48px", // 12
        "60px", // 13
        "72px", // 14
        "84px", // 15
        "96px", // 16
        "120px", // 17
        "160px", // 18
    ],
    zIndices: [
        "0", // 1
        "10", // 2
        "20", // 3
        "30", // 4
        "40", // 5
        "50", // 6
        "60", // 7
        "70", // 8
        "80", // 9
        "90", // 10
        "100", // 11
    ],
});

export const PBox = styled("div", {});
export const PContainer = styled("div", {
    "@bp1": { maxWidth: "100%" },
    "@bp2": { maxWidth: "100%" },
    "@bp3": { maxWidth: "100%" },
    "@bp4": { maxWidth: "970px" },
    "@bp5": { maxWidth: "1140px" },
    "@bp6": { maxWidth: "1260px" },
    "@bp7": { maxWidth: "1260px" },
    paddingLeft: "$spaces$6",
    paddingRight: "$spaces$6",
    margin: "0 auto",
});
export const PBlogContainer = styled("div", {
    "@bp1": { maxWidth: "480px" },
    "@bp2": { maxWidth: "480px" },
    "@bp3": { maxWidth: "640px" },
    "@bp4": { maxWidth: "720px" },
    paddingLeft: "$spaces$6",
    paddingRight: "$spaces$6",
    margin: "0 auto",
});
export const PButton = styled("button", {});
export const PH1 = styled("h1", {});
export const PH2 = styled("h2", {});
export const PH3 = styled("h3", {});
export const PH4 = styled("h4", {});
export const PH5 = styled("h5", {});
export const PH6 = styled("h6", {});
export const PText = styled("p", {});
export const PBlockquote = styled("blockquote", {});
export const PHr = styled("hr", {});
export const PFigure = styled("figure", {});
export const PImg = styled("img", {});
export const PFigCaption = styled("figcaption", {});
export const PInput = styled("input", {});
export const PLabel = styled("label", {});
export const PForm = styled("form", {});
export const PCode = styled("code", {});
export const PPre = styled("pre", {});
export const PTable = styled("table", {});
export const PTbody = styled("tbody", {});
export const POl = styled("ol", {});
export const PUl = styled("ul", {});
export const PLi = styled("li", {});
export const PLink = styled("a", {});
export const PSpan = styled("span", {});
export const PI = styled("i", {});
export const PSection = styled("section", {});
export const PNav = styled("nav", {});
export const PDl = styled("dl", {});
export const PDt = styled("dt", {});
export const PDd = styled("dd", {});

export const PHighlightLink = styled("a", {
    cursor: "pointer",
    border: "2px solid",
    borderRadius: "40px",
    padding: "12px 40px",
    textDecoration: "none",
    fontWeight: "600",
    transition: "all .3s ease-in-out",
    "-moz-transition": "all .3s ease-in-out",
    "-webkit-transition": "all .3s ease-in-out",
});

export const PHighlightButton = styled("button", {
    border: "2px solid",
    borderRadius: "40px",
    padding: "12px 40px",
    textDecoration: "none",
    fontWeight: "600",
    transition: "all .3s ease-in-out",
    "-moz-transition": "all .3s ease-in-out",
    "-webkit-transition": "all .3s ease-in-out",
    cursor: "pointer",
});

export const PFlowButton = styled("button", {
    border: "1px solid",
    borderRadius: "8px",
    padding: "8px 20px",
    textDecoration: "none",
    fontWeight: "600",
    transition: "all .3s ease-in-out",
    "-moz-transition": "all .3s ease-in-out",
    "-webkit-transition": "all .3s ease-in-out",
    cursor: "pointer",
});

export const PFlowLink = styled("a", {
    border: "1px solid",
    borderRadius: "8px",
    padding: "8px 20px",
    textDecoration: "none",
    fontWeight: "600",
    transition: "all .3s ease-in-out",
    "-moz-transition": "all .3s ease-in-out",
    "-webkit-transition": "all .3s ease-in-out",
    cursor: "pointer",
});

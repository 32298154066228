import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Display from "../../sections/Display";
import { Layout } from "./Layout";
import { fetchProductList } from "../../../actions/product";
import { Loader } from "../../components/Loader";
import ProductSlider from "../../sections/ProductSlider";

class Item extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.props.fetchProductList().then(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Display />
                <ProductSlider
                    title={"You May Also Like"}
                    linkToMore={"/featured"}
                    productList={this.props.productList}
                />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        productList: state.productReducer?.fetchProductList?.productList,
    };
}

export default connect(mapStateToProps, { fetchProductList })(withRouter(Item));

import { PBox, PH1, PH3, PLi, PText, PUl } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const PrivacyPolicy = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>Privacy Policy</PH1>
                <PText>
                    <i>Last Updated: November 14, 2023</i>
                </PText>
                <PText>
                    This privacy statement describes how the seller collects and
                    uses the personal information buyer provides during the
                    transaction on Phair. It also describes the choices
                    available to you regarding its use of your personal
                    information and how you can access and update this
                    information.
                </PText>
                <PH3>Collection and Use of Personal Information</PH3>
                <PText>
                    We collect the following personal information from you:
                </PText>
                <PUl>
                    <PLi>
                        Order Information such as name, billing address, phone
                        number, order number
                    </PLi>
                </PUl>
                <PText>We use this information to:</PText>
                <PUl>
                    <PLi>Fulfill your order</PLi>
                    <PLi>Respond to customer service requests</PLi>
                </PUl>
                <PH3>Information Sharing</PH3>
                <PText>
                    We will share your personal information with third parties
                    only in the ways that are described in this privacy
                    statement. We do not sell your personal information to third
                    parties. We may provide your personal information to
                    companies that provide services to help us with our business
                    activities such as processing payments or offering customer
                    service. These companies are authorized to use your personal
                    information only as necessary to provide these services to
                    us. We may also disclose your personal information as
                    required by law such as to comply with a subpoena, or
                    similar legal process.
                </PText>
                <PH3>Security</PH3>
                <PText>
                    The security of your personal information is important to
                    us. We follow generally accepted industry standards to
                    protect the personal information submitted to us, both
                    during transmission and once we receive it.
                </PText>
            </PBox>
            <HelpNav
                prev={"Navigate to dashboard"}
                prev_link={"/help/navigate-to-dashboard"}
                next={"Seller Code of Conduct"}
                next_link={"/help/seller-code-of-conduct"}
            />
        </Layout>
    );
};

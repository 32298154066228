import {
    PBox,
    PCode,
    PH1,
    PH2,
    PH3,
    PImg,
    PLi,
    PLink,
    POl,
    PText,
    PUl,
} from "../../../theme/BaseTheme";
import { BlogPostLayout } from "../BlogPostLayout";
import { keys } from "../../../../config/keys";

export const MidjourneyPromptsSeamlessPatterns = () => {
    return (
        <BlogPostLayout category="Inspiration">
            <PH1>
                20 Midjourney Prompts to Create Seamless Pattern for Passive
                Income
            </PH1>
            <PBox className="blog-hero">
                <PText>Phair Team | Feb 2, 2024 | 5 min read</PText>
            </PBox>
            <PBox className="blog-banner-img">
                <PImg
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/banner.png"
                    }
                />
            </PBox>
            <PText>
                Looking to make passive income from generative AI art? Think
                about creating and selling seamless pattern textures with
                Midjourney. These patterns are a favorite in the print-on-demand
                market because they're perfect for a vast array of products.
            </PText>
            <PText>
                The process of producing and monetizing stunning seamless
                patterns with Midjourney is straightforward and user-friendly. A
                quick prompt and a couple of clicks could unlock significant
                earning opportunities from this AI-driven side project.
            </PText>
            <PH2>1. The "--tile" parameter</PH2>
            <PText>
                Let's dive right in and craft amazing patterns using the
                powerful Midjourney parameter "--tile".
            </PText>
            <PText>
                The "--tile" parameter is ideal for designing seamless patterns
                suitable for fabrics, wallpapers, and textures. The images
                produced are tiled, allowing them to be repeated across large
                areas for a uniform appearance.
            </PText>
            <PText>Notice that:</PText>
            <PUl>
                <PLi>
                    "--tile" works with Model Versions 1 2 3 test testp 5 5.1
                    5.2 and 6.
                </PLi>
                <PLi>--tile only generates a single tile.</PLi>
            </PUl>
            <PText>
                To use the Tile parameter in Midjourney, simply add "--tile" to
                the end of your prompt.
            </PText>
            <PH2>
                2. 20 example Midjourney prompts to create seamless patterns
            </PH2>
            <PText>Prompt 1: </PText>
            <PCode>
                /imagine small cute embroidery geometrical pattern, minimal
                design, crème background --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/1.png"
                    }
                />
            </PBox>
            <PText>Prompt 2: </PText>
            <PCode>
                /imagine lavender and bronze simple lilac floral design with
                crisp outlines --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/2.png"
                    }
                />
            </PBox>
            <PText>Prompt 3: </PText>
            <PCode>
                /imagine geometric pattern, pastel colors, seamless pattern
                --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/3.png"
                    }
                />
            </PBox>
            <PText>Prompt 4: </PText>
            <PCode>
                /imagine minimalist theme wallpaper pattern for babies, seamless
                --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/4.png"
                    }
                />
            </PBox>
            <PText>Prompt 5: </PText>
            <PCode>/imagine simple line pattern background, cute --tile</PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/5.png"
                    }
                />
            </PBox>
            <PText>Prompt 6: </PText>
            <PCode>
                /imagine seamless pattern of vintage watercolor, art nouveau,
                soft hues quadrilateral continuity --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/6.png"
                    }
                />
            </PBox>
            <PText>Prompt 7: </PText>
            <PCode>
                /imagine textile print, seamless pattern, blue pastel
                variations, vector, high quality luxury --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/7.png"
                    }
                />
            </PBox>
            <PText>Prompt 8: </PText>
            <PCode>
                /imagine solid pastel background with white simple line doodle
                --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/8.png"
                    }
                />
            </PBox>
            <PText>Prompt 9: </PText>
            <PCode>
                /imagine clear distinct fruit pattern, watermelon, hand drawn
                --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/9.png"
                    }
                />
            </PBox>
            <PText>Prompt 10: </PText>
            <PCode>
                /imagine creative patterns with simple strokes, rich colors,
                ultra-high resolution, fine details, and morandi colors --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/10.png"
                    }
                />
            </PBox>
            <PText>Prompt 11: </PText>
            <PCode>
                /imagine pastel blue and yellow cat paw print pattern, unique
                --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/11.png"
                    }
                />
            </PBox>
            <PText>Prompt 12: </PText>
            <PCode>
                /imagine simple cute minimalistic random satisfying item
                pattern, no shading, line drawing, black and white, thick line
                --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/12.png"
                    }
                />
            </PBox>
            <PText>Prompt 13: </PText>
            <PCode>
                /imagine charlotte russe mushroom pattern watercolor pastel
                pattern light background --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/14.png"
                    }
                />
            </PBox>
            <PText>Prompt 14: </PText>
            <PCode>
                /imagine hand drawn geometric icon doodle element, blank
                background,flat color --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/15.png"
                    }
                />
            </PBox>
            <PText>Prompt 15: </PText>
            <PCode>
                /imagine a minimalistic blob pattern with a light coloured
                background as a base --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/16.png"
                    }
                />
            </PBox>
            <PText>Prompt 16: </PText>
            <PCode>
                /imagine create a cute pattern with a soft and gentle light blue
                background, featuring a variety of small, minimalist kawaii
                músic illustrations --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/17.png"
                    }
                />
            </PBox>
            <PText>Prompt 17: </PText>
            <PCode>/imagine gueth japanese garden patterns --tile</PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/18.png"
                    }
                />
            </PBox>
            <PText>Prompt 18: </PText>
            <PCode>
                /imagine japanese good luck symbols hand drawn flat design
                --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/19.png"
                    }
                />
            </PBox>
            <PText>Prompt 19: </PText>
            <PCode>
                /imagine tortoise shell pattern pastel , seamless --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/13.png"
                    }
                />
            </PBox>
            <PText>Prompt 20: </PText>
            <PCode>
                /imagine spring, simple 2D svg vector illustration --style raw
                --tile
            </PCode>
            <PBox style={{ display: "flex", justifyContent: "center" }}>
                <PImg
                    style={{ margin: "2rem 0", width: "24rem" }}
                    src={
                        "https://s3.us-east-2.amazonaws.com/phair.bucket.prod.client/blog-midjourney-prompts-seamless-patterns/21.png"
                    }
                />
            </PBox>
            <PText>
                These prompts serve as initial inspiration for crafting seamless
                patterns. By altering objects, colors, and styles, a wide
                variety of variations and designs can be achieved. Experimenting
                with these prompts repeatedly can lead to unexpected and
                delightful results.
            </PText>
            <PH2>3. Monetizing Your Creations</PH2>
            <PText>
                Platforms such as Etsy and Gumroad make it straightforward to
                transform your designs into real products. Additionally,
                consider exploring <PLink href={"/sell"}>Phair</PLink>, an
                emerging comprehensive digital marketplace for generating
                passive income. Selling on Phair is hassle-free— for more
                details, refer to this{" "}
                <PLink href={"/blog/how-to-sell-on-phair"}>blog post</PLink>. By
                offering your creations across multiple platforms, you can
                diversify your income streams and potentially increase your
                earnings.
            </PText>
            <PH2>Conclusion</PH2>
            <PText>
                Creating seamless patterns with Midjourney is not just an
                artistic endeavor; it's a viable business strategy. As you
                explore the vast array of prompts and unleash your creativity,
                remember that each pattern holds the potential to captivate
                audiences and generate income. Embrace the journey, experiment
                with designs, and watch as your creations set trends in the
                print-on-demand marketplace.
            </PText>
        </BlogPostLayout>
    );
};

import { useState } from "react";
import { Link } from "react-router-dom";
import { PBox, PUl, PLi, PLink, PH4 } from "../theme/BaseTheme";

export const HelpMenu = (props) => {
    const [activeItem, setActiveItem] = useState(window.location.pathname);
    return (
        <PBox css={styles}>
            <PBox className="help-menu-background"></PBox>
            <PBox className="help-menu-list-wrapper">
                <PUl className="help-menu-list">
                    <PH4>Support Center</PH4>
                    <Link to="/help">
                        <PLi
                            className={
                                activeItem === "/help" ? "is-active" : ""
                            }
                        >
                            Home
                        </PLi>
                    </Link>
                    <PH4>Getting Started</PH4>
                    <Link to="/help/start-selling">
                        <PLi
                            className={
                                activeItem === "/help/start-selling"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Start selling on Phair
                        </PLi>
                    </Link>
                    <Link to="/help/fees">
                        <PLi
                            className={
                                activeItem === "/help/fees" ? "is-active" : ""
                            }
                        >
                            Fees
                        </PLi>
                    </Link>
                    <Link to="/help/need-help">
                        <PLi
                            className={
                                activeItem === "/help/need-help"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Need help
                        </PLi>
                    </Link>
                    <PH4>Products</PH4>
                    <Link to="/help/create-a-product">
                        <PLi
                            className={
                                activeItem === "/help/create-a-product"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Create a product
                        </PLi>
                    </Link>
                    <Link to="/help/edit-a-product">
                        <PLi
                            className={
                                activeItem === "/help/edit-a-product"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Edit a product
                        </PLi>
                    </Link>
                    <Link to="/help/go-public">
                        <PLi
                            className={
                                activeItem === "/help/go-public"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Go public
                        </PLi>
                    </Link>
                    {/*<Link to="/help/product-review-process">
                    <PLi
                        className={
                            activeItem === "/help/product-review-process"
                                ? "is-active"
                                : ""
                        }
                    >
                        Product review process
                    </PLi>
                    </Link>*/}
                    <Link to="/help/delete-a-product">
                        <PLi
                            className={
                                activeItem === "/help/delete-a-product"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Delete a product
                        </PLi>
                    </Link>
                    <PH4>Orders</PH4>
                    <Link to="/help/store-orders">
                        <PLi
                            className={
                                activeItem === "/help/store-orders"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Store orders
                        </PLi>
                    </Link>
                    <Link to="/help/purchase-history">
                        <PLi
                            className={
                                activeItem === "/help/purchase-history"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Purchase history
                        </PLi>
                    </Link>
                    <Link to="/help/refund">
                        <PLi
                            className={
                                activeItem === "/help/refund" ? "is-active" : ""
                            }
                        >
                            Refund
                        </PLi>
                    </Link>
                    <Link to="/help/download-link-email">
                        <PLi
                            className={
                                activeItem === "/help/download-link-email"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Download link email
                        </PLi>
                    </Link>
                    <PH4>Payments</PH4>
                    <Link to="/help/getting-paid">
                        <PLi
                            className={
                                activeItem === "/help/getting-paid"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Getting paid
                        </PLi>
                    </Link>
                    <Link to="/help/payment-methods">
                        <PLi
                            className={
                                activeItem === "/help/payment-methods"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Payment methods
                        </PLi>
                    </Link>
                    <Link to="/help/sales-tax">
                        <PLi
                            className={
                                activeItem === "/help/sales-tax"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Sales tax
                        </PLi>
                    </Link>
                    <PH4>Store</PH4>
                    <Link to="/help/customization">
                        <PLi
                            className={
                                activeItem === "/help/customization"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Customization
                        </PLi>
                    </Link>
                    <Link to="/help/navigate-to-dashboard">
                        <PLi
                            className={
                                activeItem === "/help/navigate-to-dashboard"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Navigate to dashboard
                        </PLi>
                    </Link>
                    <PH4>Policies</PH4>

                    <Link to="/help/privacy-policy">
                        <PLi
                            className={
                                activeItem === "/help/privacy-policy"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Privacy Policy
                        </PLi>
                    </Link>
                    <Link to="/help/seller-code-of-conduct">
                        <PLi
                            className={
                                activeItem === "/help/seller-code-of-conduct"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Seller Code of Conduct
                        </PLi>
                    </Link>
                    <Link to="/help/anti-discrimination">
                        <PLi
                            className={
                                activeItem === "/help/anti-discrimination"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Anti-Discrimination Policy
                        </PLi>
                    </Link>
                    <Link to="/help/us-only">
                        <PLi
                            className={
                                activeItem === "/help/us-only"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            US Only
                        </PLi>
                    </Link>
                    <Link to="/help/ownership-and-rights">
                        <PLi
                            className={
                                activeItem === "/help/ownership-and-rights"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Ownership and Rights
                        </PLi>
                    </Link>
                    <Link to="/help/prohibited-products">
                        <PLi
                            className={
                                activeItem === "/help/prohibited-products"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            Prohibited Products
                        </PLi>
                    </Link>
                </PUl>
            </PBox>
        </PBox>
    );
};

const styles = {
    position: "relative",
    display: "block",
    flex: "none",
    borderRight: "1px solid $colors$borderColor",
    "& .help-menu-background": {
        //backgroundColor: "rgb(248 250 252)",
        width: "50vw",
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        display: "block",
    },
    "& .help-menu-list-wrapper": {
        width: "18rem",
        padding: "2rem 2rem 4rem 0rem",
        overflowX: "hidden",
        overflowY: "auto",
        position: "sticky",
        top: "4rem",
        height: `calc(100vh - 4rem)`,
    },
    "& .help-menu-list": {
        listStyleType: "none",
        fontSize: "14px",
        paddingBottom: "4rem",
        "& a": {
            textDecoration: "none",
            color: "$colors$label",
        },
        "& li": {
            cursor: "pointer",
            textAlign: "left",
            padding: "6px 0 6px 20px",
            borderLeft: "1px solid $colors$borderColor",
        },
        "& li:hover": {
            backgroundColor: "$colors$lightBackground",
            color: "$colors$primary",
            fontWeight: "600",
            borderLeft: "1px solid $colors$primary",
        },
        "& .is-active": {
            backgroundColor: "$colors$lightBackground",
            borderLeft: "1px solid $colors$primary",
            color: "$colors$primary",
            fontWeight: "600",
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
        },
    },
};

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const EditProduct = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How to edit my product?</PH1>
                <PText>
                    To edit a product, you can go to the <b>dashboard</b> -{" "}
                    <b>listings page</b>, select the product you would like to
                    edit, then click the edit button in the last column.
                </PText>
                <PText>
                    The product editing form is the same as the product creating
                    form. You can make changes and save it.
                </PText>
                {/*<PText>
                    Please note that no matter whether your product was private,
                    public or pending approval, if you mark the status to be{" "}
                    <b>public</b> during editing, your product will go into the
                    product review process and the status will be set to{" "}
                    <b>pending approval</b> temporarily. Your product will go{" "}
                    <b>public</b> after it is approved. To reduce the waiting
                    time, please make all necessary changes in private status.
                    Once your product goes public, make as few changes as
                    possible.
    </PText>*/}
            </PBox>
            <HelpNav
                prev={"Create a product"}
                prev_link={"/help/create-a-product"}
                next={"Go public"}
                next_link={"/help/go-public"}
            />
        </Layout>
    );
};

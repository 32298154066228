import { PBox, PH1 } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import ShopSettings from "../../components/seller/ShopSettings";

export const SellerShop = (props) => {
    return (
        <Layout>
            <PBox css={styles} className="seller-shop">
                <PBox className="seller-shop-card">
                    <PH1>Shop Settings</PH1>
                    <ShopSettings />
                </PBox>
            </PBox>
        </Layout>
    );
};

const styles = {
    margin: "0rem auto",
    maxWidth: "80rem",
    "& .seller-shop-card": {
        margin: "0rem 2rem 2rem 2rem",
        backgroundColor: "#fff",
        padding: "2rem 2rem",
        "& h1": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            fontWeight: "700",
            color: "rgb(17 24 39)",
        },
    },
};

import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PImg,
    PHighlightButton,
    PH3,
} from "../theme/BaseTheme";
import { keys } from "../../config/keys";

export const SellWhat = () => {
    return (
        <PSection css={styles}>
            <PBox className="sell-what">
                <PBox className="sell-what-container">
                    <PText className="sell-what-subtitle">
                        All Kinds of Digital Goods
                    </PText>
                    <PH1 className="sell-what-title">
                        Locate Your Niche in <br />
                        Our Varied Categories
                    </PH1>
                    <PBox className="sell-what-items-wrapper">
                        <PBox className="sell-what-center">
                            <PBox className="sell-what-column-full">
                                <PBox className="sell-what-item">
                                    <PImg
                                        src={
                                            keys.imageBaseUrl +
                                            "sell-illustration.jpg"
                                        }
                                    />
                                    <PText>Illustrations</PText>
                                </PBox>
                            </PBox>
                            <PBox className="sell-what-column-two">
                                <PBox className="sell-what-item">
                                    <PImg
                                        src={
                                            keys.imageBaseUrl +
                                            "sell-graphics.jpg"
                                        }
                                    />
                                    <PText>Graphics</PText>
                                </PBox>
                            </PBox>
                            <PBox className="sell-what-column-three">
                                <PBox className="sell-what-item">
                                    <PImg
                                        src={keys.imageBaseUrl + "sell-app.png"}
                                    />
                                    <PText>Apps</PText>
                                </PBox>
                            </PBox>
                        </PBox>
                        <PBox className="sell-what-column-one">
                            <PBox className="sell-what-item">
                                <PImg
                                    src={
                                        keys.imageBaseUrl +
                                        "sell-printables.png"
                                    }
                                />
                                <PText>Printables</PText>
                            </PBox>
                            <PBox className="sell-what-item">
                                <PImg
                                    src={keys.imageBaseUrl + "sell-icons.jpg"}
                                />
                                <PText>Icons</PText>
                            </PBox>
                        </PBox>
                        <PBox className="sell-what-column-four">
                            <PBox className="sell-what-item">
                                <PImg
                                    src={keys.imageBaseUrl + "sell-mockup.jpg"}
                                />
                                <PText>Mockups</PText>
                            </PBox>
                            <PBox className="sell-what-item">
                                <PImg
                                    src={
                                        keys.imageBaseUrl + "sell-templates.png"
                                    }
                                />
                                <PText>Templates</PText>
                            </PBox>
                            <PBox className="sell-what-item">
                                <PImg
                                    src={keys.imageBaseUrl + "sell-fonts.jpg"}
                                />
                                <PText>Fonts</PText>
                            </PBox>
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-what": {
        padding: "0 1.5rem",
        maxWidth: "80rem",
        margin: "8rem auto",
        overflow: "hidden",
        "@bp2": { marginTop: "10rem" },
        paddingLeft: "4rem",
        paddingRight: "4rem",
    },
    "& .sell-what-container": {
        position: "relative",
    },
    "& .sell-what-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .sell-what-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "2.5rem" },
    },
    "& .sell-what-text": {
        fontSize: "20px",
        lineHeight: "1.7",
        color: "#293745",
        textAlign: "center",
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "80px",
    },
    "& .sell-what-start-button": {
        backgroundColor: "$colors$quaternary",
        color: "#fff",
        margin: "20px",
    },
    "& .sell-what-items-wrapper": {
        display: "grid",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gridTemplateRows: "repeat(1, minmax(0, 1fr))",
        gridAutoFlow: "column",
        maxWidth: "72rem",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "5rem",
        color: "rgb(17 24 39)",
        lineHeight: "1.5rem",
        fontSize: "0.875rem",
        gap: "2rem",
        "@bp2": {
            maxWidth: "42rem",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
        "@bp4": {
            maxWidth: "72rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
    },
    "& .sell-what-item": {
        position: "relative",
        "& p": {
            fontSize: "1.125rem",
            fontWeight: "600",
            letterSpacing: "0.125rem",
            backgroundColor: "#fff",
            opacity: "0.85",
            textAlign: "center",
            width: `calc(100% - 0.125rem)`,
            position: "absolute",
            bottom: "0.5rem",
            padding: "0.5rem 0",
            marginLeft: "0.125rem",
        },
        "& img": {
            margin: "0.5rem 0",
            borderRadius: "1rem",
            width: "100%",
            border: "1px solid $colors$borderColor",
            boxShadow: "0 0 0 #0000, 0 0 0 #0000, 0 0 0 #0000",
        },
        "&:hover": {
            transform: "translate(0, -0.5rem)",
            transition: "0.5s ease",
        },
    },
    "& .sell-what-center": {
        gridColumn: "1 / 2",
        "@bp2": { gridColumn: "1 / 3" },
        "@bp4": { gridColumn: "2 / 4" },
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
    "& .sell-what-column-one": {
        gridColumn: "1 / 2",
        marginTop: "-1rem",
        "@bp4": { marginTop: "0" },
    },
    "& .sell-what-column-full": {
        gridColumn: "1 / 3",
        "@bp2": { marginBottom: "0.5rem" },
    },
    "& .sell-what-column-two": {
        gridColumn: "1 / 2",
        marginRight: "1rem",
    },
    "& .sell-what-column-three": {
        gridColumn: "2 / 3",
        marginLeft: "1rem",
    },
    "& .sell-what-column-four": {
        gridColumn: "1 / 2",
        marginTop: "-1rem",
        "@bp2": { gridColumn: "2 / 3" },
        "@bp4": { gridColumn: "4 / 5", marginTop: "0" },
    },
};

import { Fragment, useState } from "react";
import { PBox, PH2, PSpan, PText } from "../../theme/BaseTheme";
import { Pagination } from "./Pagination";
import { Rating } from "./Rating";
import { Progress } from "antd";

const ReviewItem = (props) => {
    const [isHelpful, setIsHelpful] = useState(false);
    return (
        <PBox className="review-item">
            <Rating rating={props.rating} />
            <PText className="review-item-comment">{props.comment}</PText>
            <PText className="review-item-customer-date">
                {props.customer} | {props.date}
            </PText>
            {/*<PText
                className="review-item-helpful"
                onClick={() => {
                    setIsHelpful(!isHelpful);
                }}
            >
                {!isHelpful && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000000"
                        viewBox="0 0 256 256"
                    >
                        <path d="M234,80.12A24,24,0,0,0,216,72H160V56a40,40,0,0,0-40-40,8,8,0,0,0-7.16,4.42L75.06,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H204a24,24,0,0,0,23.82-21l12-96A24,24,0,0,0,234,80.12ZM32,112H72v88H32ZM223.94,97l-12,96a8,8,0,0,1-7.94,7H88V105.89l36.71-73.43A24,24,0,0,1,144,56V80a8,8,0,0,0,8,8h64a8,8,0,0,1,7.94,9Z"></path>
                    </svg>
                )}
                {isHelpful && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 256"
                        width="20"
                        height="20"
                    >
                        <path d="M234,80.12A24,24,0,0,0,216,72H160V56a40,40,0,0,0-40-40,8,8,0,0,0-7.16,4.42L75.06,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H204a24,24,0,0,0,23.82-21l12-96A24,24,0,0,0,234,80.12ZM32,112H72v88H32Z" />
                    </svg>
                )}
                Helpful?
                </PText>*/}
        </PBox>
    );
};

export const Reviews = (props) => {
    const [reviewData] = useState(props.data);
    const [currentPage, setCurrentPage] = useState(1);
    const [reviewsPerPage] = useState(4);

    const indexOfLastReview = currentPage * reviewsPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
    const currentReviews = reviewData?.slice(
        indexOfFirstReview,
        indexOfLastReview
    );

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(reviewData?.length / reviewsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <PBox css={styles} className="reviews">
            <PH2 className="reviews-title">Reviews</PH2>
            {props.data?.length > 0 && (
                <PBox className="reviews-summary">
                    <PBox className="reviews-overall">
                        <PText className="reviews-summary-label">Overall</PText>
                        <PText className="reviews-overall-value">
                            {props.stats?.average_rating}
                        </PText>
                        <Rating
                            rating={props.stats?.average_rating}
                            reviews={props.stats?.number_of_ratings}
                        />
                    </PBox>
                    <PBox className="reviews-snapshot">
                        <PText className="reviews-summary-label">
                            Rating Snapshot
                        </PText>
                        <PBox className="reviews-snapshot-item">
                            <PSpan>5 stars</PSpan>
                            <PBox className="reviews-snapshot-item-pct">
                                <Progress
                                    percent={
                                        (props.stats?.number_of_five_stars /
                                            props.stats?.number_of_ratings) *
                                        100
                                    }
                                    strokeColor="#5344a9"
                                    trailColor="rgb(238, 236, 255)"
                                />
                            </PBox>
                        </PBox>
                        <PBox className="reviews-snapshot-item">
                            <PSpan>4 stars</PSpan>
                            <PBox className="reviews-snapshot-item-pct">
                                <Progress
                                    percent={
                                        (props.stats?.number_of_four_stars /
                                            props.stats?.number_of_ratings) *
                                        100
                                    }
                                    strokeColor="#5344a9"
                                    trailColor="rgb(238, 236, 255)"
                                />
                            </PBox>
                        </PBox>
                        <PBox className="reviews-snapshot-item">
                            <PSpan>3 stars</PSpan>
                            <PBox className="reviews-snapshot-item-pct">
                                <Progress
                                    percent={
                                        (props.stats?.number_of_three_stars /
                                            props.stats?.number_of_ratings) *
                                        100
                                    }
                                    strokeColor="#5344a9"
                                    trailColor="rgb(238, 236, 255)"
                                />
                            </PBox>
                        </PBox>
                        <PBox className="reviews-snapshot-item">
                            <PSpan>2 stars</PSpan>
                            <PBox className="reviews-snapshot-item-pct">
                                <Progress
                                    percent={
                                        (props.stats?.number_of_two_stars /
                                            props.stats?.number_of_ratings) *
                                        100
                                    }
                                    strokeColor="#5344a9"
                                    trailColor="rgb(238, 236, 255)"
                                />
                            </PBox>
                        </PBox>
                        <PBox className="reviews-snapshot-item">
                            <PSpan>1 stars</PSpan>
                            <PBox className="reviews-snapshot-item-pct">
                                <Progress
                                    percent={
                                        (props.stats?.number_of_one_star /
                                            props.stats?.number_of_ratings) *
                                        100
                                    }
                                    strokeColor="#5344a9"
                                    trailColor="rgb(238, 236, 255)"
                                />
                            </PBox>
                        </PBox>
                    </PBox>
                </PBox>
            )}
            {props.data?.length > 0 && (
                <Fragment>
                    {currentReviews?.map((item, idx) => {
                        return (
                            <ReviewItem
                                rating={item?.rating}
                                comment={item?.comment}
                                customer={item?.user?.user_name}
                                date={new Date(item?.updated_at).toDateString()}
                            />
                        );
                    })}
                    <Pagination
                        indexOfFirstReview={indexOfFirstReview + 1}
                        indexOfLastReview={indexOfLastReview}
                        totalReviews={reviewData.length}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        currentPage={currentPage}
                        reviewsPerPage={reviewsPerPage}
                    />
                </Fragment>
            )}
            {(!props.data || props.data?.length === 0) && (
                <PText>Currently there are 0 reviews for this product.</PText>
            )}
        </PBox>
    );
};

const styles = {
    marginLeft: "20px",
    "& .reviews-title": {
        fontSize: "18px",
        fontWeight: "600",
        marginTop: "0",
    },
    "& .review-item": {
        margin: "20px 0px 20px 0",
        borderBottom: "1px solid $colors$borderColor",
    },
    "& .review-item-comment": {
        lineHeight: "1.25rem",
    },
    "& .review-item-customer-date": {
        color: "$colors$label",
        fontSize: "12px",
    },
    "& .review-item-helpful": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontSize: "12px",
        "& svg": {
            marginRight: "10px",
        },
    },
    "& .reviews-snapshot-item": {
        display: "flex",
        alignItems: "center",
        "& span": {
            margin: "0",
            padding: "0 16px 5px 0",
        },
    },
    "& .reviews-snapshot-item-pct": {
        "& > div": {
            width: "200px",
        },
        "& span": {
            padding: "1px 0 0 8px",
        },
    },
    "& .reviews-summary-label": {
        margin: "0 0 6px 0",
        padding: "0",
        fontSize: "15px",
        color: "$colors$label",
        display: "none",
    },
    "& .reviews-summary": {
        display: "flex",
        alignItems: "start",
        paddingRight: "12px",
        paddingBottom: "16px",
        justifyContent: "space-between",
        borderBottom: "1px solid $colors$borderColor",
    },
    "& .reviews-overall-value": {
        fontSize: "48px",
        margin: "0",
        padding: "0 0 12px 0",
    },
};

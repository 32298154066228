import { PBox, PText } from "../views/theme/BaseTheme";

export const faqData = [
    {
        title: "Buying on Phair",
        content: [
            {
                title: "How to search for items on Phair?",
                content: (
                    <PBox>
                        <PText>
                            You can use the search bar at the top of Phair.io to
                            look for items. You can enter descriptive terms in
                            the search bar to start your search. The search
                            results will be displayed in the order of how
                            relavant the items are to what you search for.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "How to check out on Phair?",
                content: (
                    <PBox>
                        <PText>
                            You can quickly purchase a product by clicking{" "}
                            <b>Buy Now</b> on the item display page.
                        </PText>
                        <PText>
                            To save a product to purchase later, you can click{" "}
                            <b>Add To Favorites</b> on the item display page.
                            You can click the heart icon on the top right of
                            Phair.io to see all the items you saved. Then you
                            can click <b>Buy Now</b> in the saved item card to
                            buy it directly.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "Why am I being charged state sales tax on my Phair order?",
                content: (
                    <PBox>
                        <PText>
                            Many US states recently enacted laws that require
                            online marketplaces to collect sales tax on eligible
                            purchases. Purchases that require us to charge sales
                            tax differ between states. We're required to charge
                            sales tax if the state you're in mandates it, often
                            no matter where the seller is located.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "Why am I being charged transaction fees on my Phair order?",
                content: (
                    <PBox>
                        <PText>
                            We pair with Stripe to provide secure and fast
                            payments for transactions on Phair. For each
                            transaction, Stripe charges 2.9% + $0.3 transaction
                            fee for processing the payment.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "What payment methods can I use to check out on Phair?",
                content: (
                    <PBox>
                        <PText>
                            We offer multiple ways to accept payments, such as
                            credit card, debit card, Google Pay, and Apple Pay.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "Why was my credit card declined during checkout?",
                content: (
                    <PBox>
                        <PText>
                            There are several reasons why your credit card
                            payment may not have been processed when checking
                            out, such as insufficient funds, expired card,
                            incorrect CVC, incorrect card number, etc.
                        </PText>
                        <PText>
                            You can update the payment information for your
                            order, such as card number, expiration date, CVC,
                            and billing address, and try submitting the order
                            again.
                        </PText>
                        <PText>
                            Your bank may flag any unexpected activity on your
                            account. This includes first time orders and
                            high-value purchases, regardless of the amount of
                            funds or credit limit available. Your bank may
                            require your verbal authorization to proceed with a
                            transaction.
                        </PText>
                        <PText>
                            If you are unable to resolve the payment issue with
                            your bank in a timely manner, we recommend trying a
                            different credit card, or a different payment
                            method.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "Can I purchase an item if I am not in US?",
                content: (
                    <PBox>
                        <PText>
                            Sorry, Phair is only available to U.S. based
                            customers currently. However, we're rapidly
                            expanding. Please revisit us soon to see if we've
                            included your region, or drop us a message to
                            express interest in having your area added.
                        </PText>
                    </PBox>
                ),
            },
        ],
    },
    {
        title: "Selling on Phair",
        content: [
            {
                title: "How do fees work on Phair?",
                content: (
                    <PBox>
                        <PText>
                            Joining and starting a shop on Phair is free. We
                            don't charge product listing fees on Phair. There
                            are two types of selling fees: a platform fee and a
                            payment processing (transaction) fee. Once an item
                            sells, there is a 6% + $0.3 USD platform fee on the
                            sale price collected from the seller. There is also
                            a 2.9% + $0.3 USD payment processing (transaction)
                            fee collected from the buyer by Stripe.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "What do I need to do to create a shop?",
                content: (
                    <PBox>
                        <PText>
                            It's easy to set up a shop on Phair. Create an Phair
                            account (if you don't already have one), choose a
                            shop name, create a product listing, get your
                            product approved, and connect Phair with your Stripe
                            account (so you can get paid).
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "How do I get paid?",
                content: (
                    <PBox>
                        <PText>
                            We partner with Stripe, an easy-to-use and secure
                            payment system. You may accept payments from a
                            variety of payment methods such as credit and debit
                            cards, Google Wallet, and Apple Pay. Funds from your
                            sales are deposited directly to your bank account.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "What can I sell on Phair?",
                content: (
                    <PBox>
                        <PText>
                            Phair provides a marketplace for creators to sell
                            digital products they create (at least 18 years old)
                            online. You could sell basically anything digital on
                            Phair (unless it's prohibited by our Terms).
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "Can I participate if I am not in US?",
                content: (
                    <PBox>
                        <PText>
                            We require sellers to have a US legal entity, US
                            bank account, and EIN/SSN. You may not offer to sell
                            or sell on Phair outside of the U.S.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "More questions about selling on Phair?",
                content: (
                    <PBox>
                        <PText>
                            Please check our{" "}
                            <a href="/help">seller support center</a> for more
                            questions and answers about selling on Phair.
                        </PText>
                    </PBox>
                ),
            },
        ],
    },
    {
        title: "Your Orders",
        content: [
            {
                title: "How to download the digital product I purchase?",
                content: (
                    <PBox>
                        <PText>
                            After your payment is confirmed, we will send an
                            email with links to download the product to the
                            email address you enter in the payment form. You can
                            click the link in the email to download all the
                            items you purchase. If the item you purchase
                            contains more than one file, you will download each
                            file separately in the download page.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "How to cancel or request refunds of an order?",
                content: (
                    <PBox>
                        <PText>
                            Sorry, currently all orders cannot be cancelled and
                            are non-refundable. We recommend to review your
                            orders carefully before making payments.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "How to get help with an order?",
                content: (
                    <PBox>
                        <PText>
                            If you are running into issues of making payments,
                            receiving the products, finding your previous
                            orders, or requesting receipts, please reach out to
                            our support team. Send us an email at
                            buyer_support@phair.io.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "Where can I find all my orders?",
                content: (
                    <PBox>
                        <PText>
                            After signing in to your Phair account, you can go
                            to your order history page by clicking the user icon
                            on the top right of Phair.io and clicking{" "}
                            <b>My Orders</b>. You can find all your orders in
                            the Order History page. Then you can select the
                            order you would like to check further by clicking{" "}
                            <b>Order Details</b> of that order.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "What should I do if I did not receive the download link email?",
                content: (
                    <PBox>
                        <PText>
                            Please allow some time for deliveries of your
                            download link emails. Please also check your spam
                            folder or bulk email folder just in case the
                            download link email got delivered there instead of
                            your inbox.
                        </PText>
                        <PText>
                            If you still cannot find the download link email,
                            you can request to resend the download link email as
                            below.
                        </PText>
                        <PText>
                            After signing in to your Phair account, you can go
                            to your order history page by clicking the user icon
                            on the top right of Phair.io and clicking{" "}
                            <b>My Orders</b>. Then you can select the order you
                            would like to request to resend download link email
                            by clicking <b>Order Details</b>. In the Items card,
                            you can click <b>Did Not Receive</b> and click{" "}
                            <b>Resend</b> in the popped card.
                        </PText>
                        <PText>
                            You can request to resend the download link email at
                            most <b>3</b> times per order. The download link
                            email will be sent to the email address you entered
                            during payment.
                        </PText>
                        <PText>
                            If you don't have a Phair account, please send us an
                            email with your name, the order ID, and the item you
                            purchase to buyer_support@phair.io.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "How to leave a review on Phair?",
                content: (
                    <PBox>
                        <PText>
                            After signing in to your Phair account, you can go
                            to your order history page by clicking the user icon
                            on the top right of Phair.io and clicking{" "}
                            <b>My Orders</b>. Then you can select the order you
                            would like to write a review by clicking{" "}
                            <b>Order Details</b>. In the Items card, you can
                            click <b>Write A Review</b> to rate the product and
                            write comments. One star is the lowest and five star
                            is the highest.
                        </PText>
                        <PText>
                            You can edit your review after submission by
                            clicking <b>Edit Your Review</b> and then clicking{" "}
                            <b>Edit</b>. You can also delete your submitted
                            review by clicking <b>Delete</b>. Deleted reviews
                            cannot be recovered.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "How to request a receipt of an order?",
                content: (
                    <PBox>
                        <PText>
                            After signing in your Phair account, you can go to
                            your order history page by clicking the user icon on
                            the top right of Phair.io and clicking{" "}
                            <b>My Orders</b>. Then you can select the order you
                            would like to request a receipt by clicking{" "}
                            <b>Order Details</b>.
                        </PText>
                        <PText>
                            You can then view the receipt or send the receipt of
                            the order by clicking the corresponding buttons on
                            the top right of the Order Details page.
                        </PText>
                    </PBox>
                ),
            },
        ],
    },
    {
        title: "Your Phair Account",
        content: [
            {
                title: "How to create a Phair account?",
                content: (
                    <PBox>
                        <PText>
                            Once you have a Phair account, you can add items to
                            your favorites, review all your orders, request to
                            resend download link emails directly, start selling
                            on Phair, etc.
                        </PText>
                        <PText>
                            To create an account on Phair, please review Phair's
                            Terms and Conditions and Privacy Policy first. Then
                            you can go to <b>Sign In</b> at the top right of
                            Phair.io, and click <b>Create an Account Now</b> at
                            the bottom. You can fill out the registration form
                            and then check your email inbox for confirmation
                            email. After confirming your email, you registration
                            is finished. You must be at least 18 years old to
                            use Phair's service.
                        </PText>
                        <PText>
                            You can also choose <b>Continue with Google</b> to
                            register on Phair. By signing in with your google
                            account on Phair, you will have a Phair account
                            directly.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "How to sign in or out of Phair?",
                content: (
                    <PBox>
                        <PText>
                            To sign in to your Phair account, you can click{" "}
                            <b>Sign In</b> at the top right. Then you can either
                            enter your email and password of your Phair account,
                            or choose <b>Continue with Google</b> to sign in.
                        </PText>
                        <PText>
                            To sign out of your Phair account, you can click the
                            user icon at the top right, then click{" "}
                            <b>Sign Out</b>.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "How to change or reset my password?",
                content: (
                    <PBox>
                        <PText>
                            There are two ways you can reset your password.
                        </PText>
                        <PText>
                            If you forgot your password and cannot sign in to
                            your Phair account, you can click <b>Sign In</b> at
                            the top right. In the Sign In page, click{" "}
                            <b>Forgot your password</b> and enter your email
                            address you used to create your Phair account. We
                            will send you an email with instructions to reset
                            your password to that email address.
                        </PText>
                        <PText>
                            You can also change your password in your Phair's
                            account settings page. You can click the user icon
                            at the top right, and then click <b>Profile</b>. In
                            the Password card, you can click{" "}
                            <b>Change Password</b>. Then you can enter your
                            current password and the new password. After
                            clicking <b>Submit</b>, your password will be
                            changed immediately.
                        </PText>
                    </PBox>
                ),
            },
            {
                title: "How to change the profile of my Phair account?",
                content: (
                    <PBox>
                        <PText>
                            You can sign in to your Phair account. At the top
                            right of Phair.io, you can click the user icon and
                            then click <b>Profile</b>. In the profile page, you
                            can change your preferred name, your profile image
                            and your password.
                        </PText>
                    </PBox>
                ),
            },
        ],
    },
];

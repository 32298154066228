import { Component, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { PBox, PH4, PSpan, PTable, PText } from "../../theme/BaseTheme";
import { Logo } from "../Logo";
import { Loader } from "../Loader";
import { fetchTransactionRecord } from "../../../actions/order";

class SellerTransactionRecord extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchTransactionRecord(
                this.props.match.params.shop_uuid,
                this.props.match.params.order_uuid,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        console.log(
            "payment to: ",
            this.props.record[this.props.match.params.order_uuid]?.order
                ?.merchant?.seller?.seller_name
        );
        return (
            <PBox css={styles}>
                <PBox className="transaction-record">
                    <PBox className="transaction-record-header">
                        <Logo />
                        <PBox></PBox>
                    </PBox>
                    <PBox className="transaction-record-banner">
                        <PBox className="transaction-record-banner-left">
                            <PText>
                                <b>Payment To</b>:{" "}
                                {
                                    this.props.record[
                                        this.props.match.params.order_uuid
                                    ]?.order?.merchant?.seller?.seller_name
                                }
                            </PText>
                            <PText>
                                <b>Store Name</b>:{" "}
                                {
                                    this.props.record[
                                        this.props.match.params.order_uuid
                                    ]?.order?.merchant?.shop_name
                                }
                            </PText>
                            <PText>
                                <b>Store ID</b>:{" "}
                                {
                                    this.props.record[
                                        this.props.match.params.order_uuid
                                    ]?.order?.merchant?.uuid
                                }
                            </PText>
                        </PBox>
                        <PBox className="transaction-record-banner-right">
                            <PText>
                                <b>Order No.</b>:{" "}
                                {
                                    this.props.record[
                                        this.props.match.params.order_uuid
                                    ]?.order?.order?.order_number
                                }
                            </PText>
                            <PText>
                                <b>Order Date</b>:{" "}
                                {new Date(
                                    this.props.record[
                                        this.props.match.params.order_uuid
                                    ]?.order?.order?.created_at
                                ).toDateString()}
                            </PText>
                            <PText>
                                <b>Amount Paid</b>:{" "}
                                {/* Todo: Add the sum of all order_items, currently we only have 1 order_item.*/}
                                {this.props.record[
                                    this.props.match.params.order_uuid
                                ]?.order?.order?.order_items[0]
                                    ?.stripe_destination_payment_object
                                    ?.amount_captured && (
                                    <PSpan>
                                        $
                                        {this.props.record[
                                            this.props.match.params.order_uuid
                                        ]?.order?.order?.order_items[0]
                                            ?.stripe_destination_payment_object
                                            ?.amount_captured / 100}
                                    </PSpan>
                                )}
                                {!this.props.record[
                                    this.props.match.params.order_uuid
                                ]?.order?.order?.order_items[0]
                                    ?.stripe_destination_payment_object
                                    ?.amount_captured && <PSpan>$0</PSpan>}
                            </PText>
                        </PBox>
                    </PBox>
                    <PBox className="transaction-record-summary">
                        <PH4>Summary</PH4>
                        <PTable>
                            <tr>
                                <td>No.</td>
                                <td>Item</td>
                                <td>Price</td>
                                <td>Quantity</td>
                                <td>Total</td>
                            </tr>
                            {this.props.record[
                                this.props.match.params.order_uuid
                            ]?.order?.order?.order_items?.map((item, idx) => {
                                return (
                                    <tr>
                                        <td>{idx + 1}</td>
                                        <td>{item.product.product_name}</td>
                                        <td>{item.product.price}</td>
                                        <td>{item.quantity}</td>
                                        <td>${item.item_subtotal}</td>
                                    </tr>
                                );
                            })}
                        </PTable>
                        <PBox className="transaction-record-sub-total">
                            <PH4>Sub Total</PH4>
                            <PText>
                                $
                                {
                                    this.props.record[
                                        this.props.match.params.order_uuid
                                    ]?.order?.order?.subtotal
                                }
                            </PText>
                        </PBox>
                        <PBox className="transaction-record-shipping-charge">
                            <PH4>Platform Fee</PH4>
                            {this.props.record[
                                this.props.match.params.order_uuid
                            ]?.order?.order?.order_items[0]
                                ?.stripe_destination_payment_object
                                ?.amount_captured && (
                                <PText>
                                    - $
                                    {(
                                        Math.round(
                                            (this.props.record[
                                                this.props.match.params
                                                    .order_uuid
                                            ]?.order?.order?.subtotal *
                                                0.06 +
                                                0.3) *
                                                100
                                        ) / 100
                                    ).toFixed(2)}
                                </PText>
                            )}
                            {!this.props.record[
                                this.props.match.params.order_uuid
                            ]?.order?.order?.order_items[0]
                                ?.stripe_destination_payment_object
                                ?.amount_captured && <PText>$0</PText>}
                        </PBox>
                        <PBox className="transaction-record-shipping-charge">
                            <PH4>Transaction Fee</PH4>
                            {this.props.record[
                                this.props.match.params.order_uuid
                            ]?.order?.order?.order_items[0]
                                ?.stripe_destination_payment_object
                                ?.amount_captured && (
                                <PText>
                                    - $
                                    {(
                                        Math.round(
                                            (this.props.record[
                                                this.props.match.params
                                                    .order_uuid
                                            ]?.order?.order?.subtotal -
                                                this.props.record[
                                                    this.props.match.params
                                                        .order_uuid
                                                ]?.order?.order?.subtotal *
                                                    0.06 -
                                                0.3 -
                                                this.props.record[
                                                    this.props.match.params
                                                        .order_uuid
                                                ]?.order?.order?.order_items[0]
                                                    ?.stripe_destination_payment_object
                                                    ?.amount_captured /
                                                    100) *
                                                100
                                        ) / 100
                                    ).toFixed(2)}
                                </PText>
                            )}
                            {!this.props.record[
                                this.props.match.params.order_uuid
                            ]?.order?.order?.order_items[0]
                                ?.stripe_destination_payment_object
                                ?.amount_captured && <PText>$0</PText>}
                        </PBox>
                        <PBox className="transaction-record-total">
                            <PH4>Amount Paid</PH4>
                            {/* Todo: Add the sum of all order_items, currently we only have 1 order_item.*/}
                            {this.props.record[
                                this.props.match.params.order_uuid
                            ]?.order?.order?.order_items[0]
                                ?.stripe_destination_payment_object
                                ?.amount_captured && (
                                <PText>
                                    $
                                    {this.props.record[
                                        this.props.match.params.order_uuid
                                    ]?.order?.order?.order_items[0]
                                        ?.stripe_destination_payment_object
                                        ?.amount_captured / 100}
                                </PText>
                            )}
                            {!this.props.record[
                                this.props.match.params.order_uuid
                            ]?.order?.order?.order_items[0]
                                ?.stripe_destination_payment_object
                                ?.amount_captured && <PText>$0</PText>}
                        </PBox>
                    </PBox>
                    <PBox className="transaction-record-footer">
                        <PText>
                            Questions about this transaction? Reach our customer
                            supports at <b>support@phair.io</b>.
                        </PText>
                    </PBox>
                </PBox>
            </PBox>
        );
    }
}

const styles = {
    backgroundColor: "#fff",
    position: "relative",
    "& .transaction-record": {
        padding: "20px",
        color: "$colors$text",
        border: "1px solid black",
        boxShadow: "0 2px 3px rgba(36,36,60,.06)",
        maxWidth: "640px",
        margin: "0 auto",
    },
    "& h4": { margin: "10px 0" },
    "& p": { margin: "8px 0" },
    "& .transaction-record-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px",
        marginBottom: "20px",
        textAlign: "right",
    },
    "& .transaction-record-banner": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "top",
        padding: "10px 0",
    },
    "& .transaction-record-banner-right": {
        textAlign: "right",
    },
    "& .transaction-record-shopper-address": {
        padding: "20px 0",
    },
    /*"& .transaction-record-banner": {
        display: "flex",
        padding: "10px 0",
        justifyContent: "space-between",
        "& .transaction-record-buyer-address": {},
        "& .transaction-record-detail": {
            textAlign: "right",
        },
    },*/
    "& .transaction-record-summary table": {
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: "10px",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        textAlign: "right",
        "& tr:first-child": {
            fontWeight: 700,
            borderBottom: "1px solid $colors$borderColor",
        },
        "& tr:last-child": {
            borderBottom: "1px solid black",
        },
        "& td": {
            padding: "10px 8px",
        },

        "& tr td:nth-child(1)": {
            textAlign: "left",
        },
        "& tr td:nth-child(2)": {
            textAlign: "left",
        },
        "& tr td:nth-child(3)": {
            textAlign: "left",
        },
    },
    "& .transaction-record-sub-total": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "right",
        margin: "0 8px 0 auto",
        width: "320px",
    },
    "& .transaction-record-discount, & .transaction-record-shipping-charge, & .transaction-record-tax, & .transaction-record-total":
        {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "right",
            width: "320px",
            margin: "0 8px 0 auto",
        },
    "& .transaction-record-total": {
        borderTop: "1px solid black",
        margin: "10px 8px 10px auto",
        padding: "10px 0px",
        fontSize: "20px",
        width: "320px",
    },
    "& .transaction-record-footer": {
        margin: "20px 0 20px 0",
        textAlign: "center",
    },
};

function mapStateToProps(state) {
    return {
        record: state.orderReducer?.fetchTransactionRecord,
        //merchant: state.orderReducer?.fetchTransactionRecord?.merchant,
    };
}

export default connect(mapStateToProps, {
    fetchTransactionRecord,
})(withRouter(SellerTransactionRecord));

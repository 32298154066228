import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Banner } from "../../sections/Banner";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import ProductSlider from "../../sections/ProductSlider";
import TopCategories from "../../sections/TopCategories";
import { BannerImg } from "../../sections/BannerImg";
import TopSellers from "../../sections/TopSellers";
import { fetchLandingPageProductLists } from "../../../actions/product";
import { NewBlogs } from "../../sections/NewBlogs";

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.props.fetchLandingPageProductLists().then(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Banner />
                <ProductSlider
                    title={"Featured"}
                    linkToMore={"/featured"}
                    productList={this.props.featuredProducts}
                    sliderName={"featured-products-slider"}
                />
                <TopCategories />
                <ProductSlider
                    title={"Newly Added"}
                    linkToMore={"/new"}
                    productList={this.props.newProducts}
                    sliderName={"new-products-slider"}
                />
                <ProductSlider
                    title={"Phair's Picks"}
                    linkToMore={"/picks"}
                    productList={this.props.pickedProducts}
                    sliderName={"picks-products-slider"}
                />
                <NewBlogs />
                <TopSellers topMerchants={this.props.topMerchants} />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        featuredProducts:
            state.productReducer?.fetchLandingPageProductLists?.productList
                ?.featured_products,
        newProducts:
            state.productReducer?.fetchLandingPageProductLists?.productList
                ?.new_products,
        pickedProducts:
            state.productReducer?.fetchLandingPageProductLists?.productList
                ?.picked_products,
        topMerchants:
            state.productReducer?.fetchLandingPageProductLists?.productList
                ?.top_merchants,
    };
}

export default connect(mapStateToProps, {
    fetchLandingPageProductLists,
})(withRouter(Landing));

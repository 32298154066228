import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PBox, PLink, PText, PH2, PImg, PSpan } from "../../theme/BaseTheme";
import { Popover } from "antd";

const data = [
    {
        thumbnail: "/images/9369841.jpg",
        desc: "A set of well-crafted illustrations which include categories like Artificial intelligence, Machine learning, Blockchain, and more.",
        price: "7.59",
    },
    {
        thumbnail: "/images/62146.jpg",
        desc: "Machine learning, Blockchain, and more.",
        price: "1.90",
    },
    {
        thumbnail: "/images/natalie-behn-4XTXxJl4NQw-unsplash.jpg",
        desc: "A set of well-crafted illustrations blockchain, and more.",
        price: "2.59",
    },
];

class OrderSummary extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {}

    render() {
        return (
            <PBox css={styles} className="order-summary">
                {this.props.showButton && (
                    <PLink className="checkout-button" href="/checkout-billing">
                        Proceed To Checkout
                    </PLink>
                )}
                {this.props.showSummary && (
                    <PBox className="order-summary-card">
                        <PBox className="order-summary-title">
                            <PH2>Order Summary</PH2>
                            {/*<PText>
                                {this.props.saveProducts.length}{" "}
                                {this.props.saveProducts.length > 1
                                    ? "items"
                                    : "item"}
                                </PText>*/}
                        </PBox>
                        <PBox className="order-summary-subtotal">
                            <PText>Subtotal</PText>
                            <PText>$ {this.props.price}</PText>
                        </PBox>
                        <PBox className="order-summary-tax">
                            <PText>
                                Transaction Fee{" "}
                                {(this.props.estimatedTax ||
                                    this.props.estimatedTax === 0) && (
                                    <Popover
                                        content={
                                            <div style={{ width: "280px" }}>
                                                A 2.9% + $0.3 fee will be
                                                charged for processing the
                                                payment by Stripe.
                                            </div>
                                        }
                                        title="Transaction Fee"
                                        trigger="click"
                                        className="estimated-tax-popover"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 256 256"
                                            height="18"
                                            width="18"
                                        >
                                            <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z" />
                                        </svg>
                                    </Popover>
                                )}
                            </PText>
                            <PText>$ {this.props.transactionFee}</PText>
                        </PBox>
                        {(this.props.tax || this.props.tax === 0) && (
                            <PBox className="order-summary-tax">
                                <PText>Tax</PText>
                                <PText>$ {this.props.tax}</PText>
                            </PBox>
                        )}
                        {(this.props.estimatedTax ||
                            this.props.estimatedTax === 0) && (
                            <PBox className="order-summary-tax">
                                <PText>
                                    Estimated Tax{" "}
                                    <Popover
                                        content={
                                            <div style={{ width: "280px" }}>
                                                This is only an estimated amount
                                                of your sales tax. An accurate
                                                amount will be calculated and
                                                collected during payment
                                                processing. The final amount
                                                will be shown in your receipt.
                                            </div>
                                        }
                                        title="Estimated Tax"
                                        trigger="click"
                                        className="estimated-tax-popover"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 256 256"
                                            height="18"
                                            width="18"
                                        >
                                            <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z" />
                                        </svg>
                                    </Popover>
                                </PText>
                                <PText>$ {this.props.estimatedTax}</PText>
                            </PBox>
                        )}
                        {(this.props.orderAmount ||
                            this.props.orderAmount === 0) && (
                            <PBox className="order-summary-total">
                                <PText>Total</PText>
                                <PText>$ {this.props.orderAmount}</PText>
                            </PBox>
                        )}
                        {(this.props.estimatedOrderAmount ||
                            this.props.estimatedOrderAmount === 0) && (
                            <PBox className="order-summary-total">
                                <PText>Estimated Total</PText>
                                <PText>
                                    $ {this.props.estimatedOrderAmount}
                                </PText>
                            </PBox>
                        )}
                    </PBox>
                )}
                {this.props.showBag && (
                    <PBox className="order-summary-bag">
                        <PH2>Order Details</PH2>
                        <PBox className="order-summary-bag-item">
                            <PBox className="order-summary-bag-item-thumbnail">
                                <PImg src={this.props.cover} />
                            </PBox>
                            <PBox className="order-summary-bag-item-desc-price">
                                <PBox className="order-summary-bag-item-desc">
                                    <PText>{this.props.name}</PText>
                                </PBox>
                                <PBox className="order-summary-bag-item-price">
                                    <PText>${this.props.price}</PText>
                                </PBox>
                            </PBox>
                        </PBox>
                    </PBox>
                )}
            </PBox>
        );
    }
}

const styles = {
    "& .checkout-button": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "$colors$primary",
        padding: "12px",
        borderRadius: "12px",
        cursor: "pointer",
        textTransform: "uppercase",
        fontWeight: "600",
        fontSize: "16px",
        margin: "10px 0",
        textDecoration: "none",
        color: "#000",
        "&:hover": {
            opacity: "0.6",
        },
    },
    "& .order-summary-card": {
        margin: "28px 0",
        padding: "12px 24px",
        border: "1px solid $colors$borderColor",
        borderRadius: "12px",
    },
    "& .order-summary-title": {
        borderBottom: "1px solid $colors$borderColor",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
        "& h2": {
            fontWeight: "500",
        },
    },
    "& .order-summary-subtotal": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .order-summary-tax": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .order-summary-total": {
        borderTop: "1px solid $colors$borderColor",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px",
        marginBottom: "6px",
    },
    "& .order-summary-bag": {
        border: "1px solid $borderColor",
        borderRadius: "12px",
        margin: "28px 0",
        padding: "12px 24px",
        "& h2": {
            fontWeight: "500",
            paddingBottom: "20px",
            borderBottom: "1px solid $colors$borderColor",
        },
    },
    "& .order-summary-bag-item": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "12px 0",
        fontSize: "15px",
        "& .order-summary-bag-item-thumbnail": {
            width: "60px",
            height: "60px",
            marginRight: "10px",
            "& img": {
                width: "60px",
                height: "60px",
                objectFit: "cover",
                borderRadius: "12px",
            },
        },
        "& .order-summary-bag-item-desc-price": {
            flexGrow: "1",
            color: "$colors$text",
            "& .order-summary-bag-item-desc": {
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
                marginBottom: "4px",
                fontSize: "16px",
                fontWeight: "500",
                marginTop: "0",
            },
            "& .order-summary-bag-item-price": {
                fontSize: "16px",
                marginTop: "8px",
                fontWeight: "500",
            },
            "& p": { margin: "0" },
        },
    },
    "& .estimated-tax-popover": {
        cursor: "pointer",
    },
};

function mapStateToProps(state) {
    return {
        saveProducts: state.saveReducer?.manageSave?.saveProducts,
    };
}

export default connect(mapStateToProps, {})(withRouter(OrderSummary));

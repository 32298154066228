import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const GettingPaid = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How do I get paid?</PH1>
                <PText>
                    In order to process card payments and payout businesses, we
                    have partnered with Stripe. All payouts will be made via
                    Stripe through ACH.
                </PText>
                <PText>
                    To integrate Stripe, in the <b>dashboard</b> -{">"}{" "}
                    <b>payments</b> page, click Connect to Stripe. This will
                    redirect you to Stripe where you can follow their onboarding
                    flow to either create your Stripe account or connect to your
                    pre-existing account.
                </PText>
            </PBox>
            <HelpNav
                prev={"Download link email"}
                prev_link={"/help/download-link-email"}
                next={"Payment methods"}
                next_link={"/help/payment-methods"}
            />
        </Layout>
    );
};

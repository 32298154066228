import { combineReducers } from "redux";

const fetchBillingInfo = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_BILLING_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                billingInfo: action.payload,
            };
        case "FETCH_BILLING_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                billingInfo: action.load,
            };
        default:
            return state;
    }
};

export default combineReducers({ fetchBillingInfo });

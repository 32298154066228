import GetInTouch from "../../sections/GetInTouch";
import { Layout } from "./Layout";

export const Contact = () => {
    return (
        <Layout>
            <GetInTouch />
        </Layout>
    );
};

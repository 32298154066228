import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import {
    Button,
    Form,
    InputNumber,
    Radio,
    Select,
    Space,
    Upload,
    Input,
    message,
} from "antd";
import { PBox, PSection, PText, PH3, PH4 } from "../../theme/BaseTheme";
import { TagInput } from "./TagInput";
import { createProduct } from "../../../actions/product";
import { Loader } from "../Loader";
import { fetchCategoryList } from "../../../actions/category";
import { s3UploadFiles } from "../../../actions/s3";
import { addProductFormLables } from "../../../data/labels";
import { FormLabel } from "./FormLabel";
import { SpinnerWhite } from "../SpinnerWhite";
import { QuillEditor } from "../editor/QuillEditor";

const { Option } = Select;

class AddProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            processing: false,
            productDescription: "",
            productDelivery: "",
            productContent: "",
            priceOption: "amount",
            transactionFee: "buyer",
        };
        this.normFile = this.normFile.bind(this);
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {
        this.props.fetchCategoryList().then(() => {
            this.setState({ isLoading: false });
        });
    }

    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    onFinish = async (values) => {
        await this.setState({ processing: true });
        console.log("Received values of form: ", values);

        if (!values.tags) {
            values.tags = { tags: [] };
        }
        values.product_description = this.state.productDescription;
        values.product_delivery = this.state.productDelivery;
        values.product_content = this.state.productContent;
        values.price_option = this.state.priceOption;
        values.transaction_fee = this.state.transactionFee;
        if (!values.price) values.price = 0;

        var data_to_upload = new FormData();
        var product_cover_image_meta = {};
        var product_images_meta = [];
        var product_files_meta = [];

        values.product_cover_image?.map((image) => {
            data_to_upload.append(
                "image",
                image.originFileObj,
                image.originFileObj.uid +
                    "-" +
                    Date.now() +
                    "-" +
                    image.originFileObj.name
            );
            product_cover_image_meta = {
                uid: image.originFileObj.uid,
                name:
                    image.originFileObj.uid +
                    "-" +
                    Date.now() +
                    "-" +
                    image.originFileObj.name,
                size: image.originFileObj.size,
            };
        });

        values.product_images?.map((image) => {
            data_to_upload.append(
                "image",
                image.originFileObj,
                image.originFileObj.uid +
                    "-" +
                    Date.now() +
                    "-" +
                    image.originFileObj.name
            );
            product_images_meta.push({
                uid: image.originFileObj.uid,
                name:
                    image.originFileObj.uid +
                    "-" +
                    Date.now() +
                    "-" +
                    image.originFileObj.name,
                size: image.originFileObj.size,
            });
        });

        values.product_files?.map((file) => {
            data_to_upload.append(
                "file",
                file.originFileObj,
                file.originFileObj.uid +
                    "-" +
                    Date.now() +
                    "-" +
                    file.originFileObj.name
            );
            product_files_meta.push({
                uid: file.originFileObj.uid,
                name:
                    file.originFileObj.uid +
                    "-" +
                    Date.now() +
                    "-" +
                    file.originFileObj.name,
                size: file.originFileObj.size,
            });
        });

        try {
            await this.props.s3UploadFiles(data_to_upload);

            values.product_cover_image = {
                ...this.props.uploadFiles[product_cover_image_meta?.name],
                ...product_cover_image_meta,
            };

            values.product_images = [];
            product_images_meta.map((product_image_meta) => {
                values.product_images.push({
                    ...this.props.uploadFiles[product_image_meta?.name],
                    ...product_image_meta,
                });
            });

            values.product_files = [];
            product_files_meta.map((product_file_meta) => {
                values.product_files.push({
                    ...this.props.uploadFiles[product_file_meta?.name],
                    ...product_file_meta,
                });
            });

            await this.props.createProduct(
                this.props.match.params.shop_uuid,
                values,
                this.props.history
            );
            this.setState({ processing: false });
        } catch (error) {
            message.error(error);
            this.setState({ processing: false });
            //this.props.history.push(
            //    `/seller/${this.props.match.params.shop_uuid}/dashboard`
            //);
        }
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PSection css={styles}>
                <PBox className="add-product">
                    <Form
                        name="create_product"
                        layout="vertical"
                        onFinish={this.onFinish}
                    >
                        <PBox className="add-product-section">
                            <PH3 className="add-product-section-title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.55,87.55,0,0,1-33.64,69.21A16.24,16.24,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16,16,0,0,0-6.23-12.66A87.59,87.59,0,0,1,40,104.5C39.74,56.83,78.26,17.15,125.88,16A88,88,0,0,1,216,104Zm-16,0a72,72,0,0,0-73.74-72c-39,.92-70.47,33.39-70.26,72.39a71.64,71.64,0,0,0,27.64,56.3h0A32,32,0,0,1,96,186v6h24V147.31L90.34,117.66a8,8,0,0,1,11.32-11.32L128,132.69l26.34-26.35a8,8,0,0,1,11.32,11.32L136,147.31V192h24v-6a32.12,32.12,0,0,1,12.47-25.35A71.65,71.65,0,0,0,200,104Z"></path>
                                </svg>
                                Content
                            </PH3>
                            <FormLabel
                                label={addProductFormLables.product_content}
                            />
                            <Form.Item name="product_content">
                                <QuillEditor
                                    value={this.state.productContent}
                                    onChange={(value) => {
                                        this.setState({
                                            productContent: value,
                                        });
                                    }}
                                />
                            </Form.Item>
                            <FormLabel
                                label={addProductFormLables.product_files}
                            />
                            <Form.Item
                                name="product_files"
                                valuePropName="fileList"
                                getValueFromEvent={this.normFile}
                                /*rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please upload the product files",
                                    },
                                ]}*/
                            >
                                <Upload.Dragger
                                    name="files"
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("ok");
                                        }, 0);
                                    }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        Click or drag file to this area to
                                        upload
                                    </p>
                                    <p className="ant-upload-hint">
                                        Support for a single or bulk upload.
                                    </p>
                                </Upload.Dragger>
                            </Form.Item>
                        </PBox>
                        <PBox className="add-product-section">
                            <PH3 className="add-product-section-title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Zm109.94-52.79a8,8,0,0,0-3.89-5.4l-29.83-17-.12-33.62a8,8,0,0,0-2.83-6.08,111.91,111.91,0,0,0-36.72-20.67,8,8,0,0,0-6.46.59L128,41.85,97.88,25a8,8,0,0,0-6.47-.6A112.1,112.1,0,0,0,54.73,45.15a8,8,0,0,0-2.83,6.07l-.15,33.65-29.83,17a8,8,0,0,0-3.89,5.4,106.47,106.47,0,0,0,0,41.56,8,8,0,0,0,3.89,5.4l29.83,17,.12,33.62a8,8,0,0,0,2.83,6.08,111.91,111.91,0,0,0,36.72,20.67,8,8,0,0,0,6.46-.59L128,214.15,158.12,231a7.91,7.91,0,0,0,3.9,1,8.09,8.09,0,0,0,2.57-.42,112.1,112.1,0,0,0,36.68-20.73,8,8,0,0,0,2.83-6.07l.15-33.65,29.83-17a8,8,0,0,0,3.89-5.4A106.47,106.47,0,0,0,237.94,107.21Zm-15,34.91-28.57,16.25a8,8,0,0,0-3,3c-.58,1-1.19,2.06-1.81,3.06a7.94,7.94,0,0,0-1.22,4.21l-.15,32.25a95.89,95.89,0,0,1-25.37,14.3L134,199.13a8,8,0,0,0-3.91-1h-.19c-1.21,0-2.43,0-3.64,0a8.08,8.08,0,0,0-4.1,1l-28.84,16.1A96,96,0,0,1,67.88,201l-.11-32.2a8,8,0,0,0-1.22-4.22c-.62-1-1.23-2-1.8-3.06a8.09,8.09,0,0,0-3-3.06l-28.6-16.29a90.49,90.49,0,0,1,0-28.26L61.67,97.63a8,8,0,0,0,3-3c.58-1,1.19-2.06,1.81-3.06a7.94,7.94,0,0,0,1.22-4.21l.15-32.25a95.89,95.89,0,0,1,25.37-14.3L122,56.87a8,8,0,0,0,4.1,1c1.21,0,2.43,0,3.64,0a8.08,8.08,0,0,0,4.1-1l28.84-16.1A96,96,0,0,1,188.12,55l.11,32.2a8,8,0,0,0,1.22,4.22c.62,1,1.23,2,1.8,3.06a8.09,8.09,0,0,0,3,3.06l28.6,16.29A90.49,90.49,0,0,1,222.9,142.12Z"></path>
                                </svg>
                                Basic Information
                            </PH3>
                            <FormLabel
                                label={addProductFormLables.product_name}
                            />
                            <Form.Item
                                name="product_name"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please enter the product name",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Product Name"
                                    id="product_name"
                                />
                            </Form.Item>
                            <FormLabel label={addProductFormLables.category} />
                            <Form.Item
                                name="category_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select one category",
                                    },
                                ]}
                            >
                                <Select placeholder="Please select one category">
                                    {this.props.categoryList.map((cat, idx) => {
                                        return (
                                            <Option value={cat.id}>
                                                {cat.category_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <FormLabel label={addProductFormLables.tags} />
                            <Form.Item name="tags">
                                <TagInput />
                            </Form.Item>
                            <FormLabel
                                label={addProductFormLables.listing_status}
                            />
                            <Form.Item
                                name="status"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please upload at least one product image",
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value="public">Public</Radio>
                                    <Radio value="private">Private</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </PBox>
                        <PBox className="add-product-section">
                            <PH3 className="add-product-section-title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M243.31,136,144,36.69A15.86,15.86,0,0,0,132.69,32H40a8,8,0,0,0-8,8v92.69A15.86,15.86,0,0,0,36.69,144L136,243.31a16,16,0,0,0,22.63,0l84.68-84.68a16,16,0,0,0,0-22.63Zm-96,96L48,132.69V48h84.69L232,147.31ZM96,84A12,12,0,1,1,84,72,12,12,0,0,1,96,84Z"></path>
                                </svg>
                                Price
                            </PH3>
                            <FormLabel label={addProductFormLables.price} />
                            <Form.Item>
                                <Radio.Group
                                    onChange={async (e) => {
                                        await this.setState({
                                            priceOption: e.target.value,
                                        });
                                    }}
                                    value={this.state.priceOption}
                                >
                                    <Radio value="amount">Amount</Radio>
                                    <Radio value="free">Free</Radio>
                                    {/*<Radio value="buyers">
                                        Buyers pay what they want
                                </Radio>*/}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="price"
                                rules={[
                                    {
                                        required:
                                            this.state.priceOption === "amount",
                                        message: "Please enter the price",
                                    },
                                ]}
                            >
                                <InputNumber
                                    formatter={(value) =>
                                        `$ ${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                        )
                                    }
                                    min={1}
                                    parser={(value) =>
                                        value.replace(/\$\s?|(,*)/g, "")
                                    }
                                    disabled={
                                        this.state.priceOption === "buyers" ||
                                        this.state.priceOption === "free"
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                            <FormLabel
                                label={addProductFormLables.transaction_fee}
                            />
                            <Form.Item>
                                <Radio.Group
                                    onChange={async (e) => {
                                        await this.setState({
                                            transactionFee: e.target.value,
                                        });
                                    }}
                                    value={this.state.transactionFee}
                                >
                                    <Radio value="buyer">Buyer</Radio>
                                    <Radio value="seller">Seller</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </PBox>
                        <PBox className="add-product-section">
                            <PH3 className="add-product-section-title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M240,120a48.05,48.05,0,0,0-48-48H152.2c-2.91-.17-53.62-3.74-101.91-44.24A16,16,0,0,0,24,40V200a16,16,0,0,0,26.29,12.25c37.77-31.68,77-40.76,93.71-43.3v31.72A16,16,0,0,0,151.12,214l11,7.33A16,16,0,0,0,186.5,212l11.77-44.36A48.07,48.07,0,0,0,240,120ZM40,199.93V40h0c42.81,35.91,86.63,45,104,47.24v65.48C126.65,155,82.84,164.07,40,199.93Zm131,8,0,.11-11-7.33V168h21.6ZM192,152H160V88h32a32,32,0,1,1,0,64Z"></path>
                                </svg>
                                Description
                            </PH3>
                            <FormLabel
                                label={addProductFormLables.cover_image}
                            />
                            <Form.Item
                                name="product_cover_image"
                                valuePropName="file"
                                getValueFromEvent={this.normFile}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please upload a cover image for your product",
                                    },
                                ]}
                            >
                                <Upload
                                    name="cover_image"
                                    listType="picture"
                                    maxCount={1}
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("ok");
                                        }, 0);
                                    }}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Click to upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                            <FormLabel
                                label={addProductFormLables.product_images}
                            />
                            <Form.Item
                                name="product_images"
                                valuePropName="fileList"
                                getValueFromEvent={this.normFile}
                            >
                                <Upload
                                    name="images"
                                    listType="picture"
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("ok");
                                        }, 0);
                                    }}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Click to upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                            <FormLabel
                                label={addProductFormLables.product_highlights}
                            />
                            <Form.Item name="product_highlights">
                                <Input.TextArea
                                    rows={4}
                                    placeholder="Product Highlights"
                                    id="product_highlights"
                                />
                            </Form.Item>
                            <FormLabel
                                label={addProductFormLables.product_description}
                            />
                            <Form.Item name="product_description">
                                <QuillEditor
                                    value={this.state.productDescription}
                                    onChange={(value) => {
                                        this.setState({
                                            productDescription: value,
                                        });
                                    }}
                                />
                            </Form.Item>
                            <FormLabel
                                label={addProductFormLables.terms_conditions}
                            />
                            <Form.Item name="product_delivery">
                                <QuillEditor
                                    value={this.state.productDelivery}
                                    onChange={(value) => {
                                        this.setState({
                                            productDelivery: value,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </PBox>
                        <Form.Item className="add-product-form-buttons">
                            <Space>
                                <Button
                                    htmlType="submit"
                                    className="add-product-form-submit-button"
                                    /* onClick={async () => {
                                        await this.setState({
                                            processing: true,
                                        });
                                    }} */
                                >
                                    {this.state.processing && <SpinnerWhite />}
                                    Create
                                </Button>
                                <Button
                                    htmlType="reset"
                                    className="add-product-form-cancel-button"
                                >
                                    Reset
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </PBox>
            </PSection>
        );
    }
}

const styles = {
    "& .add-product": {},
    "& .add-product-section": {
        border: "1px solid $colors$borderColor",
        padding: "0px 20px",
        margin: "20px 0",
        borderRadius: "12px",
    },
    "& .add-product-section-title": {
        display: "flex",
        alignItems: "center",
        paddingBottom: "10px",
        borderBottom: "1px solid $colors$borderColor",
        "& svg": {
            fill: "$colors$primary",
            marginRight: "10px",
        },
    },
    "& .add-product-form-buttons": {
        textAlign: "center",
    },
    "& .add-product-form-submit-button": {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderColor: "$colors$primary",
        fontWeight: "600",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
        padding: "4px 20px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "#fff",
            color: "$colors$primary",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            marginRight: "4px",
        },
    },
    "& .add-product-form-cancel-button": {
        cursor: "pointer",
        marginRight: "20px",
        backgroundColor: "$colors$lightPrimary",
        color: "$colors$primary",
        borderColor: "$colors$lightPrimary",
        fontWeight: "600",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
        padding: "4px 20px",
        borderRadius: "8px",
        "&:hover": {
            borderColor: "$colors$primary",
            backgroundColor: "#fff",
        },
    },
};

function mapStateToProps(state) {
    return {
        categoryList: state.categoryReducer?.fetchCategoryList?.categoryList,
        uploadFiles: state.uploadReducer?.uploadFiles?.filePaths,
    };
}

export default connect(mapStateToProps, {
    createProduct,
    fetchCategoryList,
    s3UploadFiles,
})(withRouter(AddProduct));

import { PBox, PSection, PText, PH1, PImg, PH2 } from "../theme/BaseTheme";
import { keys } from "../../config/keys";

export const SellWhy = (props) => {
    return (
        <PSection css={styles}>
            <PBox className="sell-why">
                <PBox className="sell-why-container">
                    <PText className="sell-why-subtitle">
                        Elevate Your Business
                    </PText>
                    <PH1 className="sell-why-title">
                        A Journey towards Success
                    </PH1>

                    <PBox className="sell-why-reasons-wrapper">
                        <PBox className="sell-why-reasons-img">
                            <PImg
                                className="sell-why-reasons-img"
                                src={keys.imageBaseUrl + "sell-why.jpg"}
                            />
                        </PBox>
                        <PBox className="sell-why-reasons">
                            <PBox className="sell-why-reasons-item">
                                <PH2>No Binding Exclusivity</PH2>
                                <PText>
                                    List your products with us, on your personal
                                    site, or anywhere else you choose. There's
                                    only gain in having us as a partner to
                                    promote your creations.
                                </PText>
                            </PBox>
                            <PBox className="sell-why-reasons-item">
                                <PH2>Instant Product Launches</PH2>
                                <PText>
                                    You have the freedom to create and update
                                    your products at your own pace. As soon as
                                    you're ready, they go live without any
                                    delay.
                                </PText>
                            </PBox>
                            <PBox className="sell-why-reasons-item">
                                <PH2>Low Fees, Fair Pricing</PH2>
                                <PText>
                                    Enjoy zero-cost product listings with a
                                    platform fee of 6% + $0.30 per transaction.
                                </PText>
                            </PBox>
                            <PBox className="sell-why-reasons-item">
                                <PH2>Swift and Secure Delivery</PH2>
                                <PText>
                                    Your digital products are promptly and
                                    safely delivered to your customers.
                                </PText>
                            </PBox>
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-why": {
        padding: "0 1.5rem",
        maxWidth: "80rem",
        margin: "8rem auto",
        "@bp2": { marginTop: "10rem" },
        "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    },
    "& .sell-why-container": {
        position: "relative",
    },
    "& .sell-why-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .sell-why-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "2.5rem" },
    },
    "& .sell-why-text": {
        fontSize: "20px",
        lineHeight: "1.7",
        color: "#293745",
        textAlign: "center",
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "80px",
    },
    "& .sell-why-reasons-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        gap: "4rem",
        alignItems: "flex-end",
        maxWidth: "42rem",
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: "column",
        "@bp4": { flexDirection: "row", gap: "6rem", maxWidth: "56rem" },
    },
    "& .sell-why-reasons-img": {
        objectFit: "cover",
        borderRadius: "1rem",
        height: "42rem",
        margin: "1.5rem auto",
    },
    "& .sell-why-reasons": {
        width: "100%",
        "@bp4": { flex: "1 1 auto", maxWidth: "36rem" },
    },
    "& .sell-why-reasons-item": {
        padding: "1rem 0",
        margin: "0 2rem",
        borderBottom: "1px solid $colors$borderColor",
        "& h2": {
            color: "rgb(17 24 39)",
            fontWeight: "600",
            fontSize: "1.125rem",
            lineHeight: "1.75rem",
            width: "100%",
        },
        "& p": {
            color: "rgb(75 85 99)",
            lineHeight: "1.75rem",
            fontSize: "1rem",
            width: "100%",
            marginTop: "0.5rem",
        },
        "@bp4": { margin: "0" },
    },
};

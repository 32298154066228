import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useParams, withRouter } from "react-router-dom";
import clsx from "clsx";
import { PBox, PText, PLi, PUl, PHr } from "../../theme/BaseTheme";
import { Logo } from "../../components/Logo";
import { userSignOut } from "../../../actions/auth";

const Sidebar = (props) => {
    const [activeId, setActiveId] = useState(window.location.pathname);

    const styles = {
        backgroundColor: "#fff",
        color: "rgb(156 163 175)",
        borderRight: "1px solid $colors$borderColor",
        width: props.showSidebar ? "280px" : "0px",
        height: "100vh",
        overflow: "scroll",
        transition: "ease-in-out 0.3s",
        marginLeft: "0",
        boxShadow: "0 12px 30px rgba(80,143,244,.1)",
        "& .logo": {
            margin: "14px",
            textAlign: "center",
        },
        "& .dashboard-sidebar-handler": {
            transition: "ease-in-out 0.3s",
            position: "absolute",
            top: "60px",
            left: props.showSidebar ? "280px" : "0px",
            margin: "0",
            background: "#fff",
            zIndex: "10",
            boxShadow: "0px 32px 120px rgba(20, 24, 31, 0.08)",
            border: "1px solid $colors$borderColor",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            "& svg": {
                padding: "10px",
                fill: "#000",
            },
            "&:hover": {
                cursor: "pointer",
            },
        },
        "& .dashboard-sidebar-hr": {
            margin: "0 30px",
            borderTop: "none",
            borderBottom: "1px solid $colors$borderColor",
        },
        "& .dashboard-sidebar-nav": {
            listStyle: "none",
            margin: "0 2rem",
            padding: "16px 0 0 0",
            "& a": {
                textDecoration: "none",
                color: "$colors$lightSecondary",
            },
        },
        "& .dashboard-sidebar-nav-title": {
            fontWeight: "700",
            fontSize: "14px",
            margin: "1rem 0",
        },
        "& .dashboard-sidebar-nav-item": {
            //margin: "8px 0px",
            borderRadius: "0.375rem",
            margin: "0.75rem 0 0.25rem 0",
            cursor: "pointer",
            textDecoration: "none",
            color: "rgb(75 85 99)",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
            lineHeight: "1.5rem",
            fontSize: "0.875rem",
            padding: "0.25rem 0.75rem",
            "& svg": { paddingRight: "10px", fill: "rgb(156 163 175)" },
            "&:hover": {
                color: "$colors$primary",
                textDecoration: "none",
                fontWeight: "600",
                backgroundColor: "rgb(249 250 251)",
                "& svg": { fill: "$colors$primary" },
            },
        },
        "& .active-item": {
            color: "$colors$primary",
            textDecoration: "none",
            fontWeight: "600",
            backgroundColor: "rgb(249 250 251)",
            "& svg": { fill: "$colors$primary" },
        },
    };

    return (
        <PBox css={styles} className="dashboard-sidebar">
            <Logo />
            {/*<PHr className="dashboard-sidebar-hr" />*/}
            <PUl className="dashboard-sidebar-nav">
                <PText className="dashboard-sidebar-nav-title">Overview</PText>
                <Link to={`/seller/${props.match.params.shop_uuid}/dashboard`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId ===
                                `/seller/${props.match.params.shop_uuid}/dashboard`
                                ? "active-item"
                                : ""
                        )}
                        onClick={() => {
                            setActiveId(
                                `/seller/${props.match.params.shop_uuid}/dashboard`
                            );
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M240,208H224V115.55a16,16,0,0,0-5.17-11.78l-80-75.48a1.14,1.14,0,0,1-.11-.11,16,16,0,0,0-21.53,0l-.11.11L37.17,103.77A16,16,0,0,0,32,115.55V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM48,115.55l.11-.1L128,40l79.9,75.43.11.1V208H160V160a16,16,0,0,0-16-16H112a16,16,0,0,0-16,16v48H48ZM144,208H112V160h32Z"></path>
                        </svg>
                        Dashboard
                    </PLi>
                </Link>
                <Link to={`/seller/${props.match.params.shop_uuid}/listings`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId ===
                                `/seller/${props.match.params.shop_uuid}/listings`
                                ? "active-item"
                                : ""
                        )}
                        onClick={() => {
                            setActiveId(
                                `/seller/${props.match.params.shop_uuid}/listings`
                            );
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M88,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H96A8,8,0,0,1,88,64Zm128,56H96a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,64H96a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM56,56H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Z"></path>
                        </svg>
                        Listings
                    </PLi>
                </Link>
            </PUl>
            {/*<PHr className="dashboard-sidebar-hr" />*/}
            <PUl className="dashboard-sidebar-nav">
                <PText className="dashboard-sidebar-nav-title">
                    Statistics
                </PText>

                <Link to={`/seller/${props.match.params.shop_uuid}/orders`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId ===
                                `/seller/${props.match.params.shop_uuid}/orders`
                                ? "active-item"
                                : ""
                        )}
                        onClick={() => {
                            setActiveId(
                                `/seller/${props.match.params.shop_uuid}/orders`
                            );
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M168,128a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,128Zm-8,24H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16ZM216,48V200a32,32,0,0,1-32,32H72a32,32,0,0,1-32-32V48A16,16,0,0,1,56,32H72V24a8,8,0,0,1,16,0v8h32V24a8,8,0,0,1,16,0v8h32V24a8,8,0,0,1,16,0v8h16A16,16,0,0,1,216,48Zm-16,0H184v8a8,8,0,0,1-16,0V48H136v8a8,8,0,0,1-16,0V48H88v8a8,8,0,0,1-16,0V48H56V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16Z"></path>
                        </svg>
                        Orders
                    </PLi>
                </Link>
                <Link to={`/seller/${props.match.params.shop_uuid}/payment`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId ===
                                `/seller/${props.match.params.shop_uuid}/payment`
                                ? "active-item"
                                : ""
                        )}
                        onClick={() => {
                            setActiveId(
                                `/seller/${props.match.params.shop_uuid}/payment`
                            );
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M224,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48Zm0,16V88H32V64Zm0,128H32V104H224v88Zm-16-24a8,8,0,0,1-8,8H168a8,8,0,0,1,0-16h32A8,8,0,0,1,208,168Zm-64,0a8,8,0,0,1-8,8H120a8,8,0,0,1,0-16h16A8,8,0,0,1,144,168Z"></path>
                        </svg>
                        Payments
                    </PLi>
                </Link>
                {/*<Link to={`/seller/${props.match.params.shop_uuid}/analytics`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId ===
                                `/seller/${props.match.params.shop_uuid}/analytics`
                                ? "active-item"
                                : ""
                        )}
                        onClick={() => {
                            setActiveId(
                                `/seller/${props.match.params.shop_uuid}/analytics`
                            );
                        }}
                    >
                        Analytics
                    </PLi>
                    </Link>*/}
            </PUl>
            {/*<PHr className="dashboard-sidebar-hr" />*/}
            <PUl className="dashboard-sidebar-nav">
                <PText className="dashboard-sidebar-nav-title">Settings</PText>
                {/*<Link to={`/seller/${props.match.params.shop_uuid}/billing`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId ===
                                `/seller/${props.match.params.shop_uuid}/billing`
                                ? "active-item"
                                : ""
                        )}
                        onClick={() => {
                            setActiveId(
                                `/seller/${props.match.params.shop_uuid}/billing`
                            );
                        }}
                    >
                        Billing
                    </PLi>
                    </Link>*/}

                <Link to={`/seller/${props.match.params.shop_uuid}/shop`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId ===
                                `/seller/${props.match.params.shop_uuid}/shop`
                                ? "active-item"
                                : ""
                        )}
                        onClick={() => {
                            setActiveId(
                                `/seller/${props.match.params.shop_uuid}/shop`
                            );
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M232,96a7.89,7.89,0,0,0-.3-2.2L217.35,43.6A16.07,16.07,0,0,0,202,32H54A16.07,16.07,0,0,0,38.65,43.6L24.31,93.8A7.89,7.89,0,0,0,24,96v16a40,40,0,0,0,16,32v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V144a40,40,0,0,0,16-32ZM54,48H202l11.42,40H42.61Zm50,56h48v8a24,24,0,0,1-48,0Zm-16,0v8a24,24,0,0,1-48,0v-8ZM200,208H56V151.2a40.57,40.57,0,0,0,8,.8,40,40,0,0,0,32-16,40,40,0,0,0,64,0,40,40,0,0,0,32,16,40.57,40.57,0,0,0,8-.8Zm-8-72a24,24,0,0,1-24-24v-8h48v8A24,24,0,0,1,192,136Z"></path>
                        </svg>
                        Shop
                    </PLi>
                </Link>
                <Link to={`/seller/${props.match.params.shop_uuid}/account`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId ===
                                `/seller/${props.match.params.shop_uuid}/account`
                                ? "active-item"
                                : ""
                        )}
                        onClick={() => {
                            setActiveId(
                                `/seller/${props.match.params.shop_uuid}/account`
                            );
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120ZM72.57,200a64,64,0,0,1,110.86,0ZM216,200H201.33a80.14,80.14,0,0,0-43.69-42.28,48,48,0,1,0-59.28,0A80.14,80.14,0,0,0,54.67,200H40V56H216V200Z"></path>
                        </svg>
                        Account
                    </PLi>
                </Link>
            </PUl>
            <PUl className="dashboard-sidebar-nav">
                <PText className="dashboard-sidebar-nav-title">Supports</PText>

                <Link to={`/faqs`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId === `/faqs` ? "active-item" : ""
                        )}
                        onClick={() => {
                            setActiveId(`/faqs`);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180ZM128,72c-22.06,0-40,16.15-40,36v4a8,8,0,0,0,16,0v-4c0-11,10.77-20,24-20s24,9,24,20-10.77,20-24,20a8,8,0,0,0-8,8v8a8,8,0,0,0,16,0v-.72c18.24-3.35,32-17.9,32-35.28C168,88.15,150.06,72,128,72Zm104,56A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                        </svg>
                        FAQ
                    </PLi>
                </Link>
                <Link to={`/help`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId === `/help` ? "active-item" : ""
                        )}
                        onClick={() => {
                            setActiveId(`/help`);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M224,48H160a40,40,0,0,0-32,16A40,40,0,0,0,96,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H96a24,24,0,0,1,24,24,8,8,0,0,0,16,0,24,24,0,0,1,24-24h64a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM96,192H32V64H96a24,24,0,0,1,24,24V200A39.81,39.81,0,0,0,96,192Zm128,0H160a39.81,39.81,0,0,0-24,8V88a24,24,0,0,1,24-24h64ZM160,88h40a8,8,0,0,1,0,16H160a8,8,0,0,1,0-16Zm48,40a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,128Zm0,32a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,160Z"></path>
                        </svg>
                        Help
                    </PLi>
                </Link>
            </PUl>
            <PUl className="dashboard-sidebar-nav">
                <PText className="dashboard-sidebar-nav-title">Account</PText>

                <Link to={`/sign-out`}>
                    <PLi
                        className={clsx(
                            "dashboard-sidebar-nav-item",
                            activeId === `/sign-out` ? "active-item" : ""
                        )}
                        onClick={() => {
                            setActiveId(`/sign-out`);
                            props.userSignOut().then(() => {
                                props.history.push("/sign-out");
                            });
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#000000"
                            viewBox="0 0 256 256"
                        >
                            <path d="M112,216a8,8,0,0,1-8,8H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h56a8,8,0,0,1,0,16H48V208h56A8,8,0,0,1,112,216Zm109.66-93.66-40-40a8,8,0,0,0-11.32,11.32L196.69,120H104a8,8,0,0,0,0,16h92.69l-26.35,26.34a8,8,0,0,0,11.32,11.32l40-40A8,8,0,0,0,221.66,122.34Z"></path>
                        </svg>
                        Sign Out
                    </PLi>
                </Link>
            </PUl>
        </PBox>
    );
};

export default connect(null, { userSignOut })(withRouter(Sidebar));

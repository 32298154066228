import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const PurchaseHistory = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How to see all orders I purchase?</PH1>
                <PText>
                    First you need to sign in to your account. In the top right
                    corner, click the user icon button, then click{" "}
                    <b>my orders</b>. You will find your purchase history.
                </PText>
            </PBox>
            <HelpNav
                prev={"Store orders"}
                prev_link={"/help/store-orders"}
                next={"Refund"}
                next_link={"/help/refund"}
            />
        </Layout>
    );
};

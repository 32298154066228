import { PBox, PH1, PText, PFlowButton } from "../../theme/BaseTheme";
import { Form, Input } from "antd";

export const Name = (props) => {
    return (
        <PBox css={styles}>
            <Form name="set-shop-name" onFinish={props.next}>
                <PBox className="name-content">
                    <PH1>Name Your Shop</PH1>
                    <PText>You can draft a name now and change it later.</PText>
                    <PBox className="name-form">
                        <Form.Item
                            name="store-name"
                            rules={[
                                {
                                    validator: async (_, shopName) => {
                                        if (!props.shopName) {
                                            return Promise.reject(
                                                new Error(
                                                    "Please enter your shop name."
                                                )
                                            );
                                        }
                                    },
                                },
                            ]}
                            style={{ textAlign: "left" }}
                        >
                            <Input
                                placeholder="Shop Name"
                                defaultValue={props.shopName}
                                onChange={(e) => {
                                    props.setShopName(e.target.value);
                                }}
                            />
                        </Form.Item>
                    </PBox>
                </PBox>
                <PFlowButton
                    type="primary"
                    className="name-prev-button"
                    onClick={() => props.prev()}
                >
                    Prev
                </PFlowButton>
                <PFlowButton
                    type="primary"
                    className="name-next-button"
                    htmlType="submit"
                >
                    Next
                </PFlowButton>
            </Form>
        </PBox>
    );
};

const styles = {
    "& .name-form": {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "48px",
        "@bp3": {
            width: "50%",
        },
    },
    "& .name-content": {
        minHeight: "480px",
    },
    "& .name-prev-button": {
        cursor: "pointer",
        marginRight: "20px",
        backgroundColor: "$colors$lightPrimary",
        color: "$colors$primary",
        borderColor: "$colors$lightPrimary",
        "&:hover": {
            borderColor: "$colors$Primary",
            backgroundColor: "#fff",
        },
    },
    "& .name-next-button": {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderColor: "$colors$primary",
        "&:hover": {
            backgroundColor: "#fff",
            color: "$colors$primary",
        },
    },
};

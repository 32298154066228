import { PContainer, PSection, PBox } from "../../theme/BaseTheme";
import OrderSummary from "../../components/seller/OrderSummary";
import PaymentInfo from "../../components/seller/PaymentInfo";
import { Layout } from "./Layout";

export const CheckoutPayment = () => {
    return (
        <Layout>
            <PSection css={styles}>
                <PBox className="payment-section">
                    <PContainer>
                        <PBox className="payment-section-wrapper">
                            <PBox className="payment-section-info">
                                <PaymentInfo />
                            </PBox>
                            <OrderSummary
                                showButton={false}
                                showSummary={true}
                                showBag={true}
                            />
                        </PBox>
                    </PContainer>
                </PBox>
            </PSection>
        </Layout>
    );
};

const styles = {
    "& .payment-section": {
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    "& .payment-section-wrapper": {
        display: "grid",
        gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
        "@bp1": {
            "& .payment-section-info": {
                gridColumn: "1 / 13",
            },
            "& .order-summary": {
                marginTop: "10px",
                marginBottom: "10px",
                gridColumn: "1 / 13",
            },
        },
        "@bp3": {
            "& .payment-section-info": {
                marginRight: "60px",
                gridColumn: "1 / 9",
            },
            "& .order-summary": {
                marginTop: "10px",
                marginBottom: "10px",
                gridColumn: "9 / 13",
            },
        },
    },
};

import { PContainer, PSection, PBox } from "../theme/BaseTheme";
import SaveItems from "../components/landing/SaveItems";
import OrderSummary from "../components/seller/OrderSummary";

export const SaveSummary = (props) => {
    return (
        <PSection css={styles}>
            <PBox className="cart-summary">
                <PContainer>
                    <PBox className="cart-summary-wrapper">
                        <SaveItems />
                        {/*<OrderSummary
                            showButton={true}
                            showSummary={true}
                            showBag={false}
    />*/}
                    </PBox>
                </PContainer>
            </PBox>
        </PSection>
    );
};

const styles = {
    "& .cart-summary": {
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    "& .cart-summary-wrapper": {
        display: "grid",
        gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
        "@bp1": {
            "& .cart-items": {
                gridColumn: "1 / 13",
            },
            "& .order-summary": {
                marginTop: "10px",
                marginBottom: "10px",
                gridColumn: "1 / 13",
            },
        },
        /*"@bp3": {
            "& .cart-items": {
                marginRight: "60px",
                gridColumn: "1 / 9",
            },
            "& .order-summary": {
                marginTop: "10px",
                marginBottom: "10px",
                gridColumn: "9 / 13",
            },
        },*/
    },
};

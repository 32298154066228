import { BaseTheme, PText, PBox } from "../../theme/BaseTheme";
import { Logo } from "../../components/Logo";
import { ConfigProvider } from "antd";
import { Footer } from "../../sections/Footer";

export const DownloadLayout = (props) => {
    const year = new Date().getFullYear();
    return (
        <div className={BaseTheme}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#5344a9",
                        colorTextBase: "#002346",
                    },
                }}
            >
                <PBox css={styles} className="download-layout">
                    <Logo />
                    <PBox className="download-layout-content">
                        {props.children}
                    </PBox>
                    <PBox className="download-layout-footer">
                        <Footer />
                    </PBox>
                </PBox>
            </ConfigProvider>
        </div>
    );
};

const styles = {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    color: "$colors$text",
    fontFamily: "$fonts$body",
    "& .logo": {
        margin: "3rem 0 4rem 0",
        zIndex: "10",
    },
    "& .download-layout-content": {
        zIndex: "10",
    },
    "& .download-layout-footer": {
        width: "100%",
    },
};

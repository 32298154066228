import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PBox, PSection, PHr, PH3, PFlowButton } from "../../theme/BaseTheme";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Space, Upload, Input } from "antd";
import { editUserAccount } from "../../../actions/account";
import {
    accountSettingsFormLabels,
    changePasswordFormLabels,
} from "../../../data/labels";
import { FormLabel } from "./FormLabel";
import { s3UploadFiles, s3DeleteFiles } from "../../../actions/s3";
import { changePassword } from "../../../actions/auth";
import { SpinnerWhite } from "../SpinnerWhite";
const PasswordForm = (props) => {
    const onChangePwdFinish = (values) => {
        props.changePassword(values);
        props.closeModal();
    };
    const passwordFormStyles = {
        "& .password-form-submit-button": {
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "#5344a9",
            borderColor: "#5344a9",
            fontWeight: "600",
            transition: "all .3s ease-in-out",
            "-moz-transition": "all .3s ease-in-out",
            "-webkit-transition": "all .3s ease-in-out",
            padding: "4px 20px",
            borderRadius: "8px",
            "&:hover": {
                backgroundColor: "#fff",
                color: "#5344a9",
            },
        },
        "& .password-form-cancel-button": {
            cursor: "pointer",
            marginRight: "20px",
            backgroundColor: "rgb(238, 236, 255)",
            color: "#5344a9",
            borderColor: "rgb(238, 236, 255)",
            fontWeight: "600",
            transition: "all .3s ease-in-out",
            "-moz-transition": "all .3s ease-in-out",
            "-webkit-transition": "all .3s ease-in-out",
            padding: "4px 20px",
            borderRadius: "8px",
            "&:hover": {
                borderColor: "#5344a9",
                backgroundColor: "#fff",
            },
        },
    };
    return (
        <PBox css={passwordFormStyles}>
            <Form
                name="change_password_form"
                layout="vertical"
                onFinish={onChangePwdFinish}
            >
                <FormLabel label={changePasswordFormLabels.current_password} />
                <Form.Item
                    name="current_password"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your current password",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <FormLabel label={changePasswordFormLabels.new_password} />
                <Form.Item
                    name="new_password"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your new password",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <FormLabel
                    label={changePasswordFormLabels.confirm_new_password}
                />
                <Form.Item
                    name="confirm_new_password"
                    dependencies={["new_password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your password",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("new_password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        "The new password that you entered do not match"
                                    )
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button
                            htmlType="submit"
                            className="password-form-submit-button"
                        >
                            Submit
                        </Button>
                        <Button
                            htmlType="reset"
                            className="password-form-cancel-button"
                            onClick={() => {
                                props.closeModal();
                            }}
                        >
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </PBox>
    );
};

class AccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordModalOpen: false,
            processing: false,
        };
        this.normFile = this.normFile.bind(this);
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {}

    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    onFinish = (values) => {
        console.log("Received values of form: ", values);

        var data_to_upload = new FormData();
        var data_to_delete = [];
        var profile_image_meta = {};

        if (Array.isArray(values.profile_image)) {
            values.profile_image?.map((image) => {
                data_to_upload.append(
                    "image",
                    image.originFileObj,
                    image.originFileObj.uid +
                        "-" +
                        Date.now() +
                        "-" +
                        image.originFileObj.name
                );
                profile_image_meta = {
                    uid: image.originFileObj.uid,
                    name:
                        image.originFileObj.uid +
                        "-" +
                        Date.now() +
                        "-" +
                        image.originFileObj.name,
                    size: image.originFileObj.size,
                };
            });
            data_to_delete.push(this.props.userAccount.profile_image);
        }

        this.props
            .s3UploadFiles(data_to_upload)
            .then(() => {
                this.props.s3DeleteFiles(data_to_delete);
            })
            .then(() => {
                if (Array.isArray(values.profile_image)) {
                    values.profile_image = {
                        ...this.props.uploadFiles[profile_image_meta.name],
                        ...profile_image_meta,
                    };
                } else {
                    values.profile_image =
                        this.props.userAccount?.profile_image;
                }
            })
            .then(async () => {
                await this.props.editUserAccount(values);
                this.setState({ processing: false });
            });
    };

    render() {
        return (
            <PSection css={styles}>
                <PBox className="account-form">
                    <Form
                        name="validate_other"
                        layout="vertical"
                        onFinish={this.onFinish}
                        initialValues={{
                            preferred_name:
                                this.props.userAccount?.preferred_name,
                            email: this.props.userAccount?.email,
                            profile_image:
                                this.props.userAccount?.profile_image,
                        }}
                    >
                        <PBox className="account-form-section">
                            <PH3 className="account-form-section-title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Zm109.94-52.79a8,8,0,0,0-3.89-5.4l-29.83-17-.12-33.62a8,8,0,0,0-2.83-6.08,111.91,111.91,0,0,0-36.72-20.67,8,8,0,0,0-6.46.59L128,41.85,97.88,25a8,8,0,0,0-6.47-.6A112.1,112.1,0,0,0,54.73,45.15a8,8,0,0,0-2.83,6.07l-.15,33.65-29.83,17a8,8,0,0,0-3.89,5.4,106.47,106.47,0,0,0,0,41.56,8,8,0,0,0,3.89,5.4l29.83,17,.12,33.62a8,8,0,0,0,2.83,6.08,111.91,111.91,0,0,0,36.72,20.67,8,8,0,0,0,6.46-.59L128,214.15,158.12,231a7.91,7.91,0,0,0,3.9,1,8.09,8.09,0,0,0,2.57-.42,112.1,112.1,0,0,0,36.68-20.73,8,8,0,0,0,2.83-6.07l.15-33.65,29.83-17a8,8,0,0,0,3.89-5.4A106.47,106.47,0,0,0,237.94,107.21Zm-15,34.91-28.57,16.25a8,8,0,0,0-3,3c-.58,1-1.19,2.06-1.81,3.06a7.94,7.94,0,0,0-1.22,4.21l-.15,32.25a95.89,95.89,0,0,1-25.37,14.3L134,199.13a8,8,0,0,0-3.91-1h-.19c-1.21,0-2.43,0-3.64,0a8.08,8.08,0,0,0-4.1,1l-28.84,16.1A96,96,0,0,1,67.88,201l-.11-32.2a8,8,0,0,0-1.22-4.22c-.62-1-1.23-2-1.8-3.06a8.09,8.09,0,0,0-3-3.06l-28.6-16.29a90.49,90.49,0,0,1,0-28.26L61.67,97.63a8,8,0,0,0,3-3c.58-1,1.19-2.06,1.81-3.06a7.94,7.94,0,0,0,1.22-4.21l.15-32.25a95.89,95.89,0,0,1,25.37-14.3L122,56.87a8,8,0,0,0,4.1,1c1.21,0,2.43,0,3.64,0a8.08,8.08,0,0,0,4.1-1l28.84-16.1A96,96,0,0,1,188.12,55l.11,32.2a8,8,0,0,0,1.22,4.22c.62,1,1.23,2,1.8,3.06a8.09,8.09,0,0,0,3,3.06l28.6,16.29A90.49,90.49,0,0,1,222.9,142.12Z"></path>
                                </svg>
                                Account
                            </PH3>
                            <FormLabel
                                label={accountSettingsFormLabels.preferred_name}
                            />
                            <Form.Item
                                name="preferred_name"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please enter your preferred name",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Preferred Name"
                                    id="preferred-name"
                                />
                            </Form.Item>
                            {/*<FormLabel
                                label={accountSettingsFormLabels.email}
                            />
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your email",
                                    },
                                ]}
                            >
                                <Input placeholder="Email" id="email" />
                            </Form.Item>*/}
                            <FormLabel
                                label={accountSettingsFormLabels.profile_image}
                            />
                            <Form.Item
                                name="profile_image"
                                valuePropName="file"
                                getValueFromEvent={this.normFile}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please upload one profile image",
                                    },
                                ]}
                            >
                                <Upload
                                    name="profile"
                                    listType="picture"
                                    maxCount={1}
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("ok");
                                        }, 0);
                                    }}
                                    defaultFileList={[
                                        {
                                            uid:
                                                typeof this.props.userAccount
                                                    ?.profile_image === "string"
                                                    ? JSON.parse(
                                                          this.props.userAccount
                                                              ?.profile_image
                                                      ).uid
                                                    : this.props.userAccount
                                                          ?.profile_image.uid,
                                            name:
                                                typeof this.props.userAccount
                                                    ?.profile_image === "string"
                                                    ? JSON.parse(
                                                          this.props.userAccount
                                                              ?.profile_image
                                                      ).name
                                                    : this.props.userAccount
                                                          ?.profile_image.name,
                                            url:
                                                typeof this.props.userAccount
                                                    ?.profile_image === "string"
                                                    ? JSON.parse(
                                                          this.props.userAccount
                                                              ?.profile_image
                                                      ).url
                                                    : this.props.userAccount
                                                          ?.profile_image.url,
                                        },
                                    ]}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Click to upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item className="account-form-buttons">
                                <Space>
                                    <Button
                                        htmlType="submit"
                                        className="account-form-submit-button"
                                        onClick={() => {
                                            this.setState({ processing: true });
                                        }}
                                    >
                                        {this.state.processing && (
                                            <SpinnerWhite />
                                        )}
                                        Update
                                    </Button>
                                    <Button
                                        htmlType="reset"
                                        className="account-form-cancel-button"
                                    >
                                        Cancel
                                    </Button>
                                </Space>
                            </Form.Item>
                        </PBox>
                    </Form>
                    <PBox className="account-form-pwd-section">
                        <PH3 className="account-form-section-title">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M48,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0Zm84,54.5L112,117V96a8,8,0,0,0-16,0v21L76,110.5a8,8,0,0,0-5,15.22l20,6.49-12.34,17a8,8,0,1,0,12.94,9.4l12.34-17,12.34,17a8,8,0,1,0,12.94-9.4l-12.34-17,20-6.49A8,8,0,0,0,132,110.5ZM238,115.64A8,8,0,0,0,228,110.5L208,117V96a8,8,0,0,0-16,0v21l-20-6.49a8,8,0,0,0-4.95,15.22l20,6.49-12.34,17a8,8,0,1,0,12.94,9.4l12.34-17,12.34,17a8,8,0,1,0,12.94-9.4l-12.34-17,20-6.49A8,8,0,0,0,238,115.64Z"></path>
                            </svg>
                            Password
                        </PH3>
                        <PFlowButton
                            className="account-form-change-pwd-button"
                            onClick={() => {
                                this.setState({ isPasswordModalOpen: true });
                            }}
                        >
                            Change Password
                        </PFlowButton>
                        <Modal
                            title="Change Password"
                            open={this.state.isPasswordModalOpen}
                            footer={null}
                            onCancel={() => {
                                this.setState({
                                    isPasswordModalOpen: false,
                                });
                            }}
                        >
                            <PasswordForm
                                closeModal={() => {
                                    this.setState({
                                        isPasswordModalOpen: false,
                                    });
                                }}
                                changePassword={this.props.changePassword}
                            />
                        </Modal>
                    </PBox>
                    {/* Todo: delete account */}
                    {/*<PBox className="account-form-danger-section">
                        <PH3 className="account-form-section-title">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
                            </svg>
                            Danger Zone
                        </PH3>
                        <PFlowButton className="account-form-delete-button">
                            Delete My Account
                        </PFlowButton>
                            </PBox>*/}
                </PBox>
            </PSection>
        );
    }
}

const styles = {
    "& .account-form-section": {
        border: "1px solid $colors$borderColor",
        padding: "0px 20px 0px 20px",
        margin: "2rem 0",
        borderRadius: "0.5rem",
        "& .account-form-section-title": {
            display: "flex",
            alignItems: "center",
            paddingBottom: "10px",
            borderBottom: "1px solid $colors$borderColor",
            "& svg": {
                fill: "$colors$primary",
                marginRight: "10px",
            },
        },
    },
    "& .account-form-pwd-section": {
        border: "1px solid $colors$borderColor",
        padding: "0px 20px 20px 20px",
        margin: "2rem 0",
        borderRadius: "0.5rem",
        "& .account-form-section-title": {
            display: "flex",
            alignItems: "center",
            paddingBottom: "10px",
            borderBottom: "1px solid $colors$borderColor",
            "& svg": {
                fill: "$colors$primary",
                marginRight: "10px",
            },
        },
        "& .account-form-change-pwd-button": {
            cursor: "pointer",
            backgroundColor: "#fff",
            color: "$colors$primary",
            borderColor: "$colors$primary",
            boxShadow: "$boxShadows$dashboardCard",

            "&:hover": {
                color: "#fff",
                backgroundColor: "$colors$primary",
            },
        },
    },
    "& .account-form-danger-section": {
        border: "1px dashed #ff4d4f",
        padding: "0px 20px 20px 20px",
        margin: "80px 0 20px 0",
        borderRadius: "0.5rem",
        "& h3": {
            color: "#ff4d4f",
        },
        "& .account-form-section-title": {
            display: "flex",
            alignItems: "center",
            paddingBottom: "10px",
            borderBottom: "1px solid #ff4d4f",
            "& svg": {
                fill: "#ff4d4f",
                marginRight: "10px",
            },
        },
        "& .account-form-delete-button": {
            cursor: "pointer",
            backgroundColor: "#fff",
            color: "#ff4d4f",
            borderColor: "#ff4d4f",
            "&:hover": {
                color: "#fff",
                backgroundColor: "#ff4d4f",
            },
        },
    },

    "& .account-form-buttons": {
        textAlign: "center",
    },
    "& .change-password-form-buttons": {
        display: "flex",
        justifyContent: "end",
    },
    "& .account-form-submit-button": {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderColor: "$colors$primary",
        fontWeight: "600",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
        padding: "4px 20px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "#fff",
            color: "$colors$primary",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            marginRight: "4px",
        },
    },
    "& .account-form-cancel-button": {
        cursor: "pointer",
        marginRight: "20px",
        backgroundColor: "$colors$lightPrimary",
        color: "$colors$primary",
        borderColor: "$colors$lightPrimary",
        fontWeight: "600",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
        padding: "4px 20px",
        borderRadius: "8px",
        "&:hover": {
            borderColor: "$colors$primary",
            backgroundColor: "#fff",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.accountReducer?.fetchUserAccount?.userAccount,
        uploadFile: state.uploadReducer?.uploadSingleFile?.filePath,
        uploadFiles: state.uploadReducer?.uploadFiles?.filePaths,
    };
}

export default connect(mapStateToProps, {
    s3DeleteFiles,
    s3UploadFiles,
    editUserAccount,
    changePassword,
})(withRouter(AccountForm));

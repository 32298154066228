import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Input, Select, Space, Button, message } from "antd";
import { PBox, PText, PH1 } from "../../theme/BaseTheme";
import { editBankInfo } from "../../../actions/billing";

const countries = [
    { label: "United States", value: "United States" },
    { label: "China", value: "China" },
];

class BankInfo extends Component {
    constructor(props) {
        super(props);
        this.normFile = this.normFile.bind(this);
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {}

    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    onFinish = (values) => {
        console.log("Received values of form: ", values);

        this.props
            .editBankInfo(this.props.match.params.shop_uuid, values)
            .then(() => {
                message.success("Bank info successfully updated");
            });
    };

    render() {
        return (
            <PBox css={styles}>
                <PH1>Bank Information</PH1>
                <Form
                    name="bank_info"
                    initialValues={{
                        country_of_residence:
                            this.props.bankInfo?.country_of_residence,
                        first_name: this.props.bankInfo?.first_name,
                        last_name: this.props.bankInfo?.last_name,
                        date_of_birth: this.props.bankInfo?.date_of_birth,
                        address_number: this.props.bankInfo?.address_number,
                        street_name: this.props.bankInfo?.street_name,
                        address_line_2: this.props.bankInfo?.address_line_2,
                        city: this.props.bankInfo?.city,
                        state: this.props.bankInfo?.state,
                        postal_code: this.props.bankInfo?.postal_code,
                        phone_number: this.props.bankInfo?.phone_number,
                    }}
                    onFinish={this.onFinish}
                >
                    <Form.Item
                        name="country_of_residence"
                        label="Country of Residence"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Please select your country of residence.",
                            },
                        ]}
                    >
                        <Select
                            size="large"
                            options={countries}
                            placeholder="Country"
                        />
                    </Form.Item>
                    <Form.Item
                        name="first_name"
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your first name.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your last name.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                        name="date_of_birth"
                        label="Date of Birth"
                        rules={[
                            {
                                required: true,
                                message: "Please input your date of birth.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="MM/DD/YYYY" />
                    </Form.Item>
                    <PText>Taxpayer Address</PText>
                    <PText>
                        This should be the same address used when filing taxes,
                        not a P.O. Box or business address.
                    </PText>
                    <Form.Item
                        name="address_number"
                        label="Number"
                        rules={[
                            {
                                required: true,
                                message: "Please input address number.",
                            },
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        name="street_name"
                        label="Street Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input street name.",
                            },
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        name="address_line_2"
                        label="Address Line 2 (optional)"
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item name="city" label="City / Town (optional)">
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        name="state"
                        label="State / Province / Region (optional)"
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        name="postal_code"
                        label="Postal Code (optional)"
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        name="phone_number"
                        label="Phone Number"
                        rules={[
                            {
                                required: true,
                                message: "Please input phone number.",
                            },
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                    >
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button htmlType="reset">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </PBox>
        );
    }
}

const styles = {
    "& h1": {
        padding: "20px 0",
        margin: "20px auto 40px auto",
        textAlign: "center",
        borderBottom: "1px solid $colors$primary",
        width: "50%",
    },
};

function mapStateToProps(state) {
    return { bankInfo: state.bankReducer?.fetchBankInfo?.bankInfo };
}

export default connect(mapStateToProps, { editBankInfo })(withRouter(BankInfo));

import { PBox } from "../../theme/BaseTheme";

export const Pagination = (props) => {
    return (
        <PBox css={styles}>
            <PBox className="pagination">
                <PBox className="pagination-text">{`${
                    props.indexOfFirstReview
                } - ${Math.min(
                    props.indexOfLastReview,
                    props.totalReviews
                )} of ${props.totalReviews} Reviews`}</PBox>
                <PBox className="pagination-navs">
                    {props.currentPage !== 1 && (
                        <PBox
                            className="pagination-prev"
                            onClick={props.previousPage}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
                            </svg>
                        </PBox>
                    )}
                    {props.currentPage !==
                        Math.ceil(
                            props.totalReviews / props.reviewsPerPage
                        ) && (
                        <PBox
                            className="pagination-next"
                            onClick={props.nextPage}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#000000"
                                viewBox="0 0 256 256"
                            >
                                <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                            </svg>
                        </PBox>
                    )}
                </PBox>
            </PBox>
        </PBox>
    );
};

const styles = {
    "& .pagination": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 60px 20px 0",
    },
    "& .pagination-navs": {
        display: "flex",
        alignItems: "center",
    },
    "& .pagination-prev, & .pagination-next": {
        padding: "10px",
        borderRadius: "40px",
        cursor: "pointer",
        margin: "0 6px",
        backgroundColor: "$colors$lightBackground",
        "& svg": { margin: "0", verticalAlign: "top", fill: "$colors$primary" },
        "&:hover": {
            backgroundColor: "$colors$primary",
            "& svg": { fill: "#fff" },
        },
    },
};

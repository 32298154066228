import { Component, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { PBox, PFlowLink, PH1, PLink } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import SellerTransactionRecord from "../../components/seller/SellerTransactionRecord";
import { Modal, Form, Input, Space, Button } from "antd";
import { FormLabel } from "../../components/seller/FormLabel";
import { sendTransactionRecord } from "../../../actions/order";

const RecordForm = (props) => {
    const recordFormStyles = {
        "& .transaction-form-submit-button": {
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "#5344a9",
            borderColor: "#5344a9",
            fontWeight: "600",
            transition: "all .3s ease-in-out",
            "-moz-transition": "all .3s ease-in-out",
            "-webkit-transition": "all .3s ease-in-out",
            padding: "4px 20px",
            borderRadius: "8px",
            "&:hover": {
                backgroundColor: "#fff",
                color: "#5344a9",
            },
        },
        "& .transaction-form-cancel-button": {
            cursor: "pointer",
            marginRight: "20px",
            backgroundColor: "rgb(238, 236, 255)",
            color: "#5344a9",
            borderColor: "rgb(238, 236, 255)",
            fontWeight: "600",
            transition: "all .3s ease-in-out",
            "-moz-transition": "all .3s ease-in-out",
            "-webkit-transition": "all .3s ease-in-out",
            padding: "4px 20px",
            borderRadius: "8px",
            "&:hover": {
                borderColor: "#5344a9",
                backgroundColor: "#fff",
            },
        },
    };

    const onSendFinish = (values) => {
        props.sendTransactionRecord(
            values.email,
            props.shop_uuid,
            props.order_uuid
        );
        props.closeModal();
    };
    return (
        <PBox css={recordFormStyles}>
            <Form
                name="send_transaction_record"
                layout="vertical"
                onFinish={onSendFinish}
            >
                <FormLabel
                    label={{
                        is_required: true,
                        title: "Email",
                        description:
                            "We'll send the transaction record to the email entered below.",
                    }}
                />
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your email.",
                        },
                    ]}
                >
                    <Input placeholder="Email" id="email" />
                </Form.Item>
                <Form.Item className="transaction-form-buttons">
                    <Space>
                        <Button
                            htmlType="submit"
                            className="transaction-form-submit-button"
                        >
                            Submit
                        </Button>
                        <Button
                            htmlType="reset"
                            className="transaction-form-cancel-button"
                            onClick={() => {
                                props.closeModal();
                            }}
                        >
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </PBox>
    );
};

class Record extends Component {
    constructor(props) {
        super(props);
        this.state = { isSendModalOpen: false };
    }

    render() {
        return (
            <Layout>
                <PBox css={styles} className="record">
                    <PBox className="record-card">
                        <PH1>Transaction Record</PH1>
                        <SellerTransactionRecord />
                        <PBox className="record-detail-button-group">
                            <PFlowLink
                                href={`/seller/${this.props.match.params.shop_uuid}/orders`}
                                className="record-detail-back"
                            >
                                Back to Orders
                            </PFlowLink>
                            <PFlowLink
                                className="record-detail-send"
                                onClick={() => {
                                    this.setState({ isSendModalOpen: true });
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M227.32,28.68a16,16,0,0,0-15.66-4.08l-.15,0L19.57,82.84a16,16,0,0,0-2.42,29.84l85.62,40.55,40.55,85.62A15.86,15.86,0,0,0,157.74,248q.69,0,1.38-.06a15.88,15.88,0,0,0,14-11.51l58.2-191.94c0-.05,0-.1,0-.15A16,16,0,0,0,227.32,28.68ZM157.83,231.85l-.05.14L118.42,148.9l47.24-47.25a8,8,0,0,0-11.31-11.31L107.1,137.58,24,98.22l.14,0L216,40Z"></path>
                                </svg>{" "}
                                Email
                            </PFlowLink>
                            <Modal
                                title="Get an email transaction record"
                                open={this.state.isSendModalOpen}
                                footer={null}
                                onCancel={() => {
                                    this.setState({ isSendModalOpen: false });
                                }}
                            >
                                <RecordForm
                                    closeModal={() => {
                                        this.setState({
                                            isSendModalOpen: false,
                                        });
                                    }}
                                    shop_uuid={
                                        this.props.match.params.shop_uuid
                                    }
                                    order_uuid={
                                        this.props.match.params.order_uuid
                                    }
                                    sendTransactionRecord={
                                        this.props.sendTransactionRecord
                                    }
                                />
                            </Modal>
                        </PBox>
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    margin: "20px",
    "& .record-card": {
        margin: "10px 20px 10px 20px",
        backgroundColor: "#fff",
        padding: "10px 30px",
        borderRadius: "10px",
        "& h1": {
            fontSize: "32px",
            fontWeight: "500",
            textAlign: "center",
        },
    },
    "& .record-detail-button-group": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px 0",
        "& a": {
            margin: "10px 10px",
            textDecoration: "none",
            cursor: "pointer",
        },
        //"& a:hover": { opacity: "0.6" },
        "& .record-detail-print": {
            padding: "10px",
            backgroundColor: "$colors$link",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            "& svg": {
                fill: "#fff",
                marginRight: "8px",
            },
        },
        "& .record-detail-send": {
            display: "flex",
            alignItems: "center",
            backgroundColor: "$colors$primary",
            color: "#fff",
            padding: "8px 16px",
            borderRadius: "8px",
            cursor: "pointer",
            border: "1px solid $colors$primary",
            "& svg": { marginRight: "8px", fill: "#fff" },
            "&:hover": {
                backgroundColor: "#fff",
                color: "$colors$primary",
                "& svg": { fill: "$colors$primary" },
            },
        },
        "& .record-detail-back": {
            fontWeight: "500",
            "&:hover": {},
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {
    sendTransactionRecord,
})(withRouter(Record));

import {
    PBox,
    PH1,
    PH2,
    PH3,
    PImg,
    PLi,
    PLink,
    POl,
    PText,
    PUl,
} from "../../../theme/BaseTheme";
import { BlogPostLayout } from "../BlogPostLayout";
import { keys } from "../../../../config/keys";

export const ConnectToStripe = () => {
    return (
        <BlogPostLayout category="Store Center">
            <PH1>
                Step-by-Step Guide to Connecting Your Stripe Account with Phair
            </PH1>
            <PBox className="blog-hero">
                <PText>Phair Team | Jan 21, 2024 | 5 min read</PText>
            </PBox>
            <PBox className="blog-banner-img">
                <PImg src={keys.imageBaseUrl + "stripe-connect.png"} />
            </PBox>
            <PText>Hello, Phair Sellers!</PText>
            <PText>
                Ready to start earning through your Phair shop? One crucial step
                is setting up your Stripe account for seamless transactions.
                Don't worry, it's a straightforward process, and I'm here to
                guide you through it, step by step. Let's get your earnings
                flowing!
            </PText>
            <PH2>Getting Started with Stripe on Phair</PH2>
            <PH3>1. Navigate to Stripe Setup</PH3>
            <PText>
                Once your shop on Phair is up and running (check{" "}
                <PLink href={"/blog/how-to-sell-on-phair"} target="_blank">
                    here
                </PLink>{" "}
                for how to open a shop), head over to your dashboard. Click on
                "Payments" in the left menu. Here, you'll find the option to
                begin the Stripe onboarding process. Make sure to have your
                personal details, business information, and bank info handy for
                the setup.
            </PText>
            <PImg
                src={keys.imageBaseUrl + "connect-to-stripe-1.png"}
                style={{
                    width: "90%",
                    display: "block",
                    margin: "40px auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
            />
            <PH3>2. Opening a Stripe Account</PH3>
            <PText>
                Click on "Setup Payouts on Stripe". A new browser tab will open.
                Enter the email you wish to use for your Stripe account and
                click 'continue'.
            </PText>
            <PImg
                src={keys.imageBaseUrl + "connect-to-stripe-2.png"}
                style={{
                    width: "90%",
                    display: "block",
                    margin: "40px auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
            />
            <PH3>3. Secure Your Account</PH3>
            <PText>
                Log in to your account. Or create a password for your Stripe
                account and verify it with the 6-digit code sent to your phone.
            </PText>
            <PImg
                src={keys.imageBaseUrl + "connect-to-stripe-3.png"}
                style={{
                    width: "60%",
                    display: "block",
                    margin: "40px auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
            />
            <PH3>4. Business Information</PH3>
            <PText>
                Next up, fill in the basic details about your business –
                location and business type. This info helps Stripe understand
                more about your venture.
            </PText>
            <PImg
                src={keys.imageBaseUrl + "connect-to-stripe-4.png"}
                style={{
                    width: "60%",
                    display: "block",
                    margin: "40px auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
            />
            <PH3>5. Personal Details</PH3>
            <PText>
                Now, enter your personal information. Stripe needs this to
                verify your identity and ensure everything is above board.
            </PText>
            <PImg
                src={keys.imageBaseUrl + "connect-to-stripe-5.png"}
                style={{
                    width: "60%",
                    display: "block",
                    margin: "40px auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
            />
            <PH3>6. More About Your Business</PH3>
            <PText>
                Provide further details about your business. This includes your
                industry, website, and a description of what you sell. To link
                your Phair storefront, just grab the URL from "My Storefront" in
                your dashboard and paste it here.
            </PText>
            <PImg
                src={keys.imageBaseUrl + "connect-to-stripe-6.png"}
                style={{
                    width: "60%",
                    display: "block",
                    margin: "40px auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
            />
            <PH3>7. Bank Account Info</PH3>
            <PText>
                Here's an important part - enter your bank details. This is
                where your earnings from Phair sales will be deposited.
            </PText>
            <PImg
                src={keys.imageBaseUrl + "connect-to-stripe-7.png"}
                style={{
                    width: "60%",
                    display: "block",
                    margin: "40px auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
            />
            <PH3>8. Review and Submit</PH3>
            <PText>
                Finally, review all the information you've entered. Once you're
                sure everything's correct, confirm and submit.
            </PText>

            <PH2>Pausing and Resuming the Onboarding Process</PH2>
            <PUl>
                <PLi>
                    <b>Pause Anytime:</b> If you need to stop the process for
                    any reason, simply hit "Return to Phair" or close the tab.
                    Your progress is saved automatically.
                </PLi>
                <PLi>
                    <b>Resuming Setup:</b> To continue where you left off, go to
                    your dashboard, click on payments, and select "Setup Payouts
                    on Stripe".
                </PLi>
            </PUl>
            <PH2>Finalizing Your Stripe Connection</PH2>
            <PUl>
                <PLi>
                    <b>Verify Your Email:</b> After completing the onboarding
                    process, don't forget to verify your email address. Check
                    your inbox for a verification email from Stripe and click
                    the "verify email address" button. Your account isn't fully
                    connected until this step is done.
                </PLi>
            </PUl>

            <PH2>Congratulations!</PH2>
            <PText>
                And that's it! You're all set up to receive payouts via Stripe
                when someone purchases your product on Phair. It's smooth
                sailing from here on out.
            </PText>

            <PText>Happy selling, and here's to your success on Phair!</PText>
        </BlogPostLayout>
    );
};

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const DownloadLinkEmail = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>
                    I did not receive the download link after payment. What can
                    I do?
                </PH1>
                <PText>
                    Please allow 24 hours to receive your download link email.
                    This is usually due to delay of payment processing. If you
                    have not received the email after 24 hours, please contact
                    us at buyer_support@phair.io with your order number, name
                    and email.
                </PText>
            </PBox>
            <HelpNav
                prev={"Refund"}
                prev_link={"/help/refund"}
                next={"Getting paid"}
                next_link={"/help/getting-paid"}
            />
        </Layout>
    );
};

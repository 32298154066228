import React, { Fragment, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import Landing from "./views/pages/landing/Landing";
import Item from "./views/pages/landing/Item";
import Search from "./views/pages/landing/Search";
import Shop from "./views/pages/landing/Shop";
import Save from "./views/pages/landing/Save";
import CheckoutBilling from "./views/pages/landing/CheckoutBilling";
import Sell from "./views/pages/landing/Sell";
import { CheckoutPayment } from "./views/pages/landing/CheckoutPayment";
import CheckoutConfirm from "./views/pages/landing/CheckoutConfirm";
import { Contact } from "./views/pages/landing/Contact";
import { ContactConfirm } from "./views/pages/landing/ContactConfirm";
import CheckoutFreeConfirm from "./views/pages/landing/CheckoutFreeConfirm";

import { FAQs } from "./views/pages/terms/FAQs";
import { Licenses } from "./views/pages/terms/Licenses";
import { Privacy } from "./views/pages/terms/Privacy";
import { Terms } from "./views/pages/terms/Terms";
import Sitemap from "./views/pages/landing/Sitemap";

import Dashboard from "./views/pages/seller/Dashboard";
import Listings from "./views/pages/seller/Listings";
import Orders from "./views/pages/seller/Orders";
import Billing from "./views/pages/seller/Billing";
import { Analytics } from "./views/pages/seller/Analytics";
import Account from "./views/pages/seller/Account";
import { SellerShop } from "./views/pages/seller/SellerShop";
import Record from "./views/pages/seller/Record";
import { Create } from "./views/pages/seller/Create";
import Edit from "./views/pages/seller/Edit";
import Payments from "./views/pages/seller/Payments";

import { Error404 } from "./views/pages/error/Error404";
import { Error500 } from "./views/pages/error/Error500";

import SignIn from "./views/pages/authentication/SignIn";
import SignUp from "./views/pages/authentication/SignUp";
import SignOut from "./views/pages/authentication/SignOut";
import ResetPassword from "./views/pages/authentication/ResetPassword";
import ForgotPassword from "./views/pages/authentication/ForgotPassword";
import ForgotPasswordConfirm from "./views/pages/authentication/ForgotPasswordConfirm";
import RegistrationSuccess from "./views/pages/authentication/RegistrationSuccess";
import EmailNotVerified from "./views/pages/authentication/EmailNotVerified";
import EmailConfirmation from "./views/pages/authentication/EmailConfirmation";
import SessionExpired from "./views/pages/authentication/SessionExpired";

import Profile from "./views/pages/buyer/Profile";
import OrderHistory from "./views/pages/buyer/OrderHistory";
import OrderDetails from "./views/pages/buyer/OrderDetails";

import Flow from "./views/pages/open/Flow";
import DownloadFiles from "./views/pages/download/DownloadFiles";

import { StartSelling } from "./views/pages/help/introduction/StartSelling";
import { Fees } from "./views/pages/help/introduction/Fees";
import { NeedHelp } from "./views/pages/help/introduction/NeedHelp";
import { CreateProduct } from "./views/pages/help/products/CreateProduct";
import { EditProduct } from "./views/pages/help/products/EditProduct";
import { GoPublic } from "./views/pages/help/products/GoPublic";
import { ProductReviewProcess } from "./views/pages/help/products/ProductReviewProcess";
import { DeleteProduct } from "./views/pages/help/products/DeleteProduct";
import { StoreOrders } from "./views/pages/help/orders/StoreOrders";
import { PurchaseHistory } from "./views/pages/help/orders/PurchaseHistory";
import { Refund } from "./views/pages/help/orders/Refund";
import { DownloadLinkEmail } from "./views/pages/help/orders/DownloadLinkEmail";
import { GettingPaid } from "./views/pages/help/payments/GettingPaid";
import { PaymentMethods } from "./views/pages/help/payments/PaymentMethods";
import { SalesTax } from "./views/pages/help/payments/SalesTax";
import { Customization } from "./views/pages/help/store/Customization";
import { NavigateToDashboard } from "./views/pages/help/store/NavigateToDashboard";
import { PrivacyPolicy } from "./views/pages/help/policies/PrivacyPolicy";
import { SellerCodeOfConduct } from "./views/pages/help/policies/SellerCodeOfConduct";
import { AntiDiscrimination } from "./views/pages/help/policies/AntiDiscrimination";
import { USOnly } from "./views/pages/help/policies/USOnly";
import { OwnershipAndRights } from "./views/pages/help/policies/OwnershipAndRights";
import { ProhibitedProducts } from "./views/pages/help/policies/ProhibitedProducts";
import { Help } from "./views/pages/help/Help";
import MoreProducts from "./views/pages/landing/MoreProducts";

import { BlogHome } from "./views/pages/blog/BlogHome";
import { HowToSellNotionTemplates } from "./views/pages/blog/tutorials/HowToSellNotionTemplates";
import { WelcomeToPhair } from "./views/pages/blog/news/WelcomeToPhair";
import { HowToSellOnPhair } from "./views/pages/blog/shop/HowToSellOnPhair";
import { NotionTemplatesTrends2024 } from "./views/pages/blog/trends/NotionTemplatesTrends2024";
import { UIKitTrends2024 } from "./views/pages/blog/trends/UIKitTrends2024";
import { ConnectToStripe } from "./views/pages/blog/shop/ConnectToStripe";
import { MidjourneyPromptsSeamlessPatterns } from "./views/pages/blog/inspiration/MidjourneyPromptsSeamlessPatterns";

const UserRoute = (props) => {
    return props.isLoggedIn ? <Route {...props} /> : <Redirect to="/sign-in" />;
};

const SellerRoute = (props) => {
    return props.isLoggedIn && props.isSeller ? (
        <Route {...props} />
    ) : (
        <Redirect to="/sign-in" />
    );
};

const OpenShopRoute = (props) => {
    return props.isLoggedIn && !props.isSeller ? (
        <Route {...props} />
    ) : props.isSeller ? (
        <Redirect to={`/seller/${props.shopUUID}/dashboard`} />
    ) : (
        <Redirect to="/sign-in" />
    );
};

const ScrollToTop = () => {
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, []);

    return null;
};

const AppRoutes = (props) => {
    return (
        <Fragment>
            <ScrollToTop />
            <Switch>
                {/* Landing */}
                <Route component={Landing} exact path="/" />
                <Route
                    exact
                    path="/item/:item_uuid"
                    render={(props) => (
                        <Item {...props} key={window.location.pathname} />
                    )}
                />
                <Route component={Search} exact path="/search" />
                <Route component={Shop} exact path="/shop/:shop_uuid" />
                <Route component={Shop} exact path="/shop/:shop_uuid/:page" />
                <Route component={Save} exact path="/favorite" />
                <Route
                    component={CheckoutBilling}
                    exact
                    path="/checkout/:product_uuid"
                />
                <Route
                    component={CheckoutPayment}
                    exact
                    path="/checkout-payment"
                />
                <Route
                    component={CheckoutConfirm}
                    exact
                    path="/checkout-confirm"
                />
                <Route
                    component={CheckoutFreeConfirm}
                    exact
                    path="/checkout-free-confirm/:order_number"
                />
                <Route component={Contact} exact path="/contact" />
                <Route component={Sitemap} exact path="/sitemap" />
                <Route
                    component={ContactConfirm}
                    exact
                    path="/contact-confirm"
                />
                <Route component={Sell} exact path="/sell" />
                <Route component={MoreProducts} exact path="/featured" />
                <Route component={MoreProducts} exact path="/new" />
                <Route component={MoreProducts} exact path="/picks" />
                {/* Terms */}
                <Route component={FAQs} exact path="/faqs" />
                {/* <Route component={Licenses} exact path="/licenses" /> */}
                <Route component={Privacy} exact path="/privacy" />
                <Route component={Terms} exact path="/terms" />
                {/* Seller Dashboard */}
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Dashboard}
                    exact={true}
                    path="/seller/:shop_uuid/dashboard"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Listings}
                    exact={true}
                    path="/seller/:shop_uuid/listings"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Orders}
                    exact={true}
                    path="/seller/:shop_uuid/orders"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Analytics}
                    exact={true}
                    path="/seller/:shop_uuid/analytics"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Billing}
                    exact={true}
                    path="/seller/:shop_uuid/billing"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={SellerShop}
                    exact={true}
                    path="/seller/:shop_uuid/shop"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Account}
                    exact={true}
                    path="/seller/:shop_uuid/account"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Record}
                    exact={true}
                    path="/seller/:shop_uuid/record/:order_uuid"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Create}
                    exact={true}
                    path="/seller/:shop_uuid/create"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Edit}
                    exact={true}
                    path="/seller/:shop_uuid/item/:item_uuid/edit"
                />
                <SellerRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    component={Payments}
                    exact={true}
                    path="/seller/:shop_uuid/payment"
                />
                {/* Buyer Dashboard */}
                <UserRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    component={Profile}
                    exact={true}
                    path="/buyer/:user_uuid/profile"
                />
                <UserRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    component={OrderHistory}
                    exact={true}
                    path="/buyer/:user_uuid/order-history"
                />
                <UserRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    component={OrderDetails}
                    exact={true}
                    path="/buyer/:user_uuid/order-details/:order_uuid"
                />
                {/* Open Shop */}
                <OpenShopRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    isSeller={props.currentUser?.userAccount?.is_seller}
                    shopUUID={props.currentUser?.userAccount?.shop_uuid}
                    component={Flow}
                    exact={true}
                    path="/open-shop-flow/:user_uuid"
                />
                {/* Error Pages */}
                <Route component={Error404} exact path="/404" />
                <Route component={Error500} exact path="/500" />
                {/* Download Pages */}
                <Route
                    component={DownloadFiles}
                    exact
                    path="/downloads/:token"
                />
                {/* Authentication Pages */}
                <Route component={SignIn} exact path="/sign-in" />
                <Route component={SignUp} exact path="/sign-up" />
                <Route component={SignOut} exact path="/sign-out" />
                <Route
                    component={SessionExpired}
                    exact
                    path="/session-expired"
                />
                <Route
                    component={RegistrationSuccess}
                    exact
                    path="/registration-success"
                />
                <Route
                    component={EmailNotVerified}
                    exact
                    path="/email-not-verified"
                />
                <Route
                    component={ForgotPassword}
                    exact
                    path="/forgot-password"
                />
                <Route
                    component={ResetPassword}
                    exact
                    path="/reset-password/:token"
                />
                <Route
                    component={ForgotPasswordConfirm}
                    exact
                    path="/forgot-password-confirm"
                />
                <Route
                    component={EmailConfirmation}
                    exact
                    path="/email-confirmation/:token"
                />
                {/* Help Pages */}
                <Route
                    component={StartSelling}
                    exact
                    path="/help/start-selling"
                />
                <Route component={Fees} exact path="/help/fees" />
                <Route component={NeedHelp} exact path="/help/need-help" />
                <Route
                    component={CreateProduct}
                    exact
                    path="/help/create-a-product"
                />
                <Route
                    component={EditProduct}
                    exact
                    path="/help/edit-a-product"
                />
                <Route component={GoPublic} exact path="/help/go-public" />
                {/*<Route
                component={ProductReviewProcess}
                exact
                path="/help/product-review-process"
                />*/}
                <Route
                    component={DeleteProduct}
                    exact
                    path="/help/delete-a-product"
                />
                <Route
                    component={StoreOrders}
                    exact
                    path="/help/store-orders"
                />
                <Route
                    component={PurchaseHistory}
                    exact
                    path="/help/purchase-history"
                />
                <Route component={Refund} exact path="/help/refund" />
                <Route
                    component={DownloadLinkEmail}
                    exact
                    path="/help/download-link-email"
                />
                <Route
                    component={GettingPaid}
                    exact
                    path="/help/getting-paid"
                />
                <Route
                    component={PaymentMethods}
                    exact
                    path="/help/payment-methods"
                />
                <Route component={SalesTax} exact path="/help/sales-tax" />
                <Route
                    component={Customization}
                    exact
                    path="/help/customization"
                />
                <Route
                    component={NavigateToDashboard}
                    exact
                    path="/help/navigate-to-dashboard"
                />
                <Route
                    component={PrivacyPolicy}
                    exact
                    path="/help/privacy-policy"
                />
                <Route
                    component={SellerCodeOfConduct}
                    exact
                    path="/help/seller-code-of-conduct"
                />
                <Route
                    component={AntiDiscrimination}
                    exact
                    path="/help/anti-discrimination"
                />
                <Route component={USOnly} exact path="/help/us-only" />
                <Route
                    component={OwnershipAndRights}
                    exact
                    path="/help/ownership-and-rights"
                />
                <Route
                    component={ProhibitedProducts}
                    exact
                    path="/help/prohibited-products"
                />
                <Route component={Help} exact path="/help" />
                {/* Blog */}
                <Route component={BlogHome} exact path="/blog" />
                {/* Blog News */}
                <Route
                    component={WelcomeToPhair}
                    exact
                    path="/blog/welcome-to-phair"
                />
                {/* Store Center */}
                <Route
                    component={HowToSellOnPhair}
                    exact
                    path="/blog/how-to-sell-on-phair"
                />
                <Route
                    component={ConnectToStripe}
                    exact
                    path="/blog/connect-to-stripe"
                />
                {/*<Route
                    component={HowToCreateAListing}
                    exact
                    path="/blog/how-to-create-a-listing"
            />*/}
                {/* Blog Tutorials */}
                <Route
                    component={HowToSellNotionTemplates}
                    exact
                    path="/blog/how-to-sell-notion-templates"
                />
                {/* Blog Trends */}
                <Route
                    component={NotionTemplatesTrends2024}
                    exact
                    path="/blog/notion-templates-trends-2024"
                />
                <Route
                    component={UIKitTrends2024}
                    exact
                    path="/blog/ui-kit-trends-2024"
                />
                {/* Blog Inspiration */}
                <Route
                    component={MidjourneyPromptsSeamlessPatterns}
                    exact
                    path="/blog/midjourney-prompts-seamless-patterns"
                />
                {/* 404 */}
                <Redirect to="/404" />
            </Switch>
        </Fragment>
    );
};

export default AppRoutes;

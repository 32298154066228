import { Checkbox } from "antd";
import { PBox, PH1, PFlowButton, PText } from "../../theme/BaseTheme";

const options = [
    "Tutorials",
    "eBooks",
    "Photography",
    "Software",
    "Fonts",
    "Music",
    "Templates",
    "Documents",
    "Videos",
    "Graphics",
    "Illustrations",
    "Memberships",
    "Data Sets",
    "Apps",
    "Digital Media",
    "Icons",
    "Online Tools",
    "Printables",
];

export const Sell = (props) => {
    return (
        <PBox css={styles}>
            <PBox className="sell-content">
                <PH1>What do you plan to sell?</PH1>
                <PText>
                    Pick as many as you like, you can always change these later.
                </PText>
                <PBox className="sell-options">
                    <Checkbox.Group
                        options={options}
                        defaultValue={props.checkedValues}
                        onChange={(checkedValues) => {
                            props.setCheckedValues(checkedValues);
                        }}
                    />
                </PBox>
            </PBox>
            <PFlowButton
                type="primary"
                className="sell-prev-button"
                onClick={() => props.prev()}
            >
                Prev
            </PFlowButton>
            <PFlowButton
                type="primary"
                className="sell-next-button"
                onClick={() => props.next()}
            >
                Next
            </PFlowButton>
        </PBox>
    );
};

const styles = {
    "& .sell-options": {
        marginTop: "40px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "80%",
        "@bp3": {
            width: "50%",
        },
        "& label": {
            lineHeight: "3.2",
            paddingLeft: "12px",
            paddingRight: "12px",
        },
    },
    "& .sell-content": {
        minHeight: "480px",
    },
    "& .sell-prev-button": {
        cursor: "pointer",
        marginRight: "20px",
        backgroundColor: "$colors$lightPrimary",
        color: "$colors$primary",
        borderColor: "$colors$lightPrimary",
        "&:hover": {
            borderColor: "$colors$Primary",
            backgroundColor: "#fff",
        },
    },
    "& .sell-next-button": {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderColor: "$colors$primary",
        "&:hover": {
            backgroundColor: "#fff",
            color: "$colors$primary",
        },
    },
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PBox, PSection, PH3 } from "../../theme/BaseTheme";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Space, Upload, Input, message } from "antd";
import { Loader } from "../Loader";
import { fetchMerchantToEdit } from "../../../actions/merchant";
import { editMerchant } from "../../../actions/merchant";
import { uploadSingleFile } from "../../../actions/upload";
import { FormLabel } from "./FormLabel";
import { shopSettingsFormLables } from "../../../data/labels";
import { s3DeleteFiles, s3UploadFiles } from "../../../actions/s3";
import { SpinnerWhite } from "../SpinnerWhite";
import { QuillEditor } from "../editor/QuillEditor";

class ShopSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            processing: false,
            shopAnnouncement: this.props.merchant?.shop_announcement,
        };
        this.normFile = this.normFile.bind(this);
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {
        this.props
            .fetchMerchantToEdit(
                this.props.match.params.shop_uuid,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    onFinish = async (values) => {
        console.log("Received values of form: ", values);

        values.shop_announcement = this.state.shopAnnouncement;

        var data_to_upload = new FormData();
        var data_to_delete = [];
        var shop_cover_image_meta = {};
        var shop_logo_meta = {};
        if (Array.isArray(values.shop_cover_image)) {
            values.shop_cover_image?.map((image) => {
                data_to_upload.append(
                    "image",
                    image.originFileObj,
                    image.originFileObj.uid +
                        "-" +
                        Date.now() +
                        "-" +
                        image.originFileObj.name
                );
                shop_cover_image_meta = {
                    uid: image.originFileObj.uid,
                    name:
                        image.originFileObj.uid +
                        "-" +
                        Date.now() +
                        "-" +
                        image.originFileObj.name,
                    size: image.originFileObj.size,
                };
            });
            data_to_delete.push(this.props.merchant?.shop_cover_image);
        }
        if (Array.isArray(values.shop_logo)) {
            values.shop_logo?.map((image) => {
                data_to_upload.append(
                    "image",
                    image.originFileObj,
                    image.originFileObj.uid +
                        "-" +
                        Date.now() +
                        "-" +
                        image.originFileObj.name
                );
                shop_logo_meta = {
                    uid: image.originFileObj.uid,
                    name:
                        image.originFileObj.uid +
                        "-" +
                        Date.now() +
                        "-" +
                        image.originFileObj.name,
                    size: image.originFileObj.size,
                };
            });
            data_to_delete.push(this.props.merchant?.shop_logo);
        }

        try {
            await this.props.s3UploadFiles(data_to_upload);
            await this.props.s3DeleteFiles(data_to_delete);

            if (Array.isArray(values.shop_cover_image)) {
                values.shop_cover_image = {
                    ...this.props.uploadFiles[shop_cover_image_meta.name],
                    ...shop_cover_image_meta,
                };
            } else {
                values.shop_cover_image =
                    this.props.userAccount?.shop_cover_image;
            }
            if (Array.isArray(values.shop_logo)) {
                values.shop_logo = {
                    ...this.props.uploadFiles[shop_logo_meta.name],
                    ...shop_logo_meta,
                };
            } else {
                values.shop_logo = this.props.userAccount?.shop_logo;
            }

            await this.props.editMerchant(
                this.props.match.params.shop_uuid,
                values
            );
            this.setState({ processing: false });
        } catch (error) {
            message.error(error);
            this.setState({ processing: false });
        }
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PSection css={styles}>
                <PBox className="shop-settings">
                    <Form
                        name="edit_shop"
                        layout="vertical"
                        onFinish={this.onFinish}
                        initialValues={{
                            shop_name: this.props.merchant?.shop_name,
                            seller_name:
                                this.props.merchant?.seller?.seller_name,
                            shop_cover_image:
                                this.props.merchant?.shop_cover_image,
                            shop_logo: this.props.merchant?.shop_logo,
                            shop_description:
                                this.props.merchant?.shop_description,
                            shop_announcement:
                                this.props.merchant?.shop_announcement,
                        }}
                    >
                        <PBox className="shop-settings-section">
                            <PH3 className="shop-settings-section-title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M232,96a7.89,7.89,0,0,0-.3-2.2L217.35,43.6A16.07,16.07,0,0,0,202,32H54A16.07,16.07,0,0,0,38.65,43.6L24.31,93.8A7.89,7.89,0,0,0,24,96v16a40,40,0,0,0,16,32v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V144a40,40,0,0,0,16-32ZM54,48H202l11.42,40H42.61Zm50,56h48v8a24,24,0,0,1-48,0Zm-16,0v8a24,24,0,0,1-48,0v-8ZM200,208H56V151.2a40.57,40.57,0,0,0,8,.8,40,40,0,0,0,32-16,40,40,0,0,0,64,0,40,40,0,0,0,32,16,40.57,40.57,0,0,0,8-.8Zm-8-72a24,24,0,0,1-24-24v-8h48v8A24,24,0,0,1,192,136Z"></path>
                                </svg>
                                Your Shop
                            </PH3>
                            <FormLabel
                                label={shopSettingsFormLables.shop_name}
                            />
                            <Form.Item
                                name="shop_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your shop name",
                                    },
                                ]}
                            >
                                <Input placeholder="Shop Name" id="shop_name" />
                            </Form.Item>
                        </PBox>
                        <PBox className="shop-settings-section">
                            <PH3 className="shop-settings-section-title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M240,120a48.05,48.05,0,0,0-48-48H152.2c-2.91-.17-53.62-3.74-101.91-44.24A16,16,0,0,0,24,40V200a16,16,0,0,0,26.29,12.25c37.77-31.68,77-40.76,93.71-43.3v31.72A16,16,0,0,0,151.12,214l11,7.33A16,16,0,0,0,186.5,212l11.77-44.36A48.07,48.07,0,0,0,240,120ZM40,199.93V40h0c42.81,35.91,86.63,45,104,47.24v65.48C126.65,155,82.84,164.07,40,199.93Zm131,8,0,.11-11-7.33V168h21.6ZM192,152H160V88h32a32,32,0,1,1,0,64Z"></path>
                                </svg>
                                Announcement
                            </PH3>
                            <FormLabel
                                label={shopSettingsFormLables.shop_announcement}
                            />
                            <Form.Item name="shop_announcement">
                                <QuillEditor
                                    value={this.state.shopAnnouncement}
                                    onChange={(value) => {
                                        this.setState({
                                            shopAnnouncement: value,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </PBox>
                        <PBox className="shop-settings-section">
                            <PH3 className="shop-settings-section-title">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path>
                                </svg>
                                Customization
                            </PH3>
                            <FormLabel
                                label={shopSettingsFormLables.seller_name}
                            />
                            <Form.Item name="seller_name">
                                <Input
                                    placeholder="Seller Name"
                                    id="seller-name"
                                />
                            </Form.Item>
                            <FormLabel
                                label={shopSettingsFormLables.shop_cover_image}
                            />
                            <Form.Item
                                name="shop_cover_image"
                                valuePropName="file"
                                getValueFromEvent={this.normFile}
                            >
                                <Upload
                                    name="cover_image"
                                    listType="picture"
                                    maxCount={1}
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("ok");
                                        }, 0);
                                    }}
                                    defaultFileList={[
                                        {
                                            uid: this.props.merchant
                                                ?.shop_cover_image?.uid,
                                            name: this.props.merchant
                                                ?.shop_cover_image?.name,
                                            url: this.props.merchant
                                                ?.shop_cover_image?.url,
                                        },
                                    ]}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Click to upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                            <FormLabel
                                label={shopSettingsFormLables.shop_logo}
                            />
                            <Form.Item
                                name="shop_logo"
                                valuePropName="file"
                                getValueFromEvent={this.normFile}
                            >
                                <Upload
                                    name="logo"
                                    listType="picture"
                                    maxCount={1}
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("ok");
                                        }, 0);
                                    }}
                                    defaultFileList={[
                                        {
                                            uid: this.props.merchant?.shop_logo
                                                ?.uid,
                                            name: this.props.merchant?.shop_logo
                                                ?.name,
                                            url: this.props.merchant?.shop_logo
                                                ?.url,
                                        },
                                    ]}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Click to upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                            <FormLabel
                                label={shopSettingsFormLables.shop_description}
                            />
                            <Form.Item name="shop_description">
                                <Input.TextArea
                                    rows={2}
                                    placeholder="Shop Description"
                                    id="shop-description"
                                />
                            </Form.Item>
                        </PBox>

                        <Form.Item className="shop-settings-form-buttons">
                            <Space>
                                <Button
                                    htmlType="submit"
                                    className="shop-settings-form-submit-button"
                                    onClick={() => {
                                        this.setState({ processing: true });
                                    }}
                                >
                                    {this.state.processing && <SpinnerWhite />}
                                    Update
                                </Button>
                                <Button
                                    htmlType="reset"
                                    className="shop-settings-form-cancel-button"
                                >
                                    Cancel
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </PBox>
            </PSection>
        );
    }
}

const styles = {
    "& .shop-settings-section": {
        border: "1px solid $colors$borderColor",
        padding: "0px 20px",
        margin: "20px 0",
        borderRadius: "0.5rem",
        //boxShadow: "$boxShadows$dashboardCard",
    },
    "& .shop-settings-section-title": {
        display: "flex",
        alignItems: "center",
        paddingBottom: "10px",
        borderBottom: "1px solid $colors$borderColor",
        "& svg": {
            fill: "$colors$primary",
            marginRight: "10px",
        },
    },
    "& .shop-settings-form-buttons": {
        textAlign: "center",
    },
    "& .shop-settings-form-submit-button": {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderColor: "$colors$primary",
        fontWeight: "600",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
        padding: "4px 20px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "#fff",
            color: "$colors$primary",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            marginRight: "4px",
        },
    },
    "& .shop-settings-form-cancel-button": {
        cursor: "pointer",
        marginRight: "20px",
        backgroundColor: "$colors$lightPrimary",
        color: "$colors$primary",
        borderColor: "$colors$lightPrimary",
        fontWeight: "600",
        transition: "all .3s ease-in-out",
        "-moz-transition": "all .3s ease-in-out",
        "-webkit-transition": "all .3s ease-in-out",
        padding: "4px 20px",
        borderRadius: "8px",
        "&:hover": {
            borderColor: "$colors$primary",
            backgroundColor: "#fff",
        },
    },
};

function mapStateToProps(state) {
    return {
        merchant: state.merchantReducer?.fetchMerchantEdit?.merchant,
        uploadFile: state.uploadReducer?.uploadSingleFile?.filePath,
        uploadFiles: state.uploadReducer?.uploadFiles?.filePaths,
    };
}

export default connect(mapStateToProps, {
    fetchMerchantToEdit,
    editMerchant,
    uploadSingleFile,
    s3DeleteFiles,
    s3UploadFiles,
})(withRouter(ShopSettings));

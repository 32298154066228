import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PLink,
    PText,
    PBox,
    PHighlightButton,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { resendVerification } from "../../../actions/auth";

class EmailNotVerified extends Component {
    componentDidMount() {}
    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles}>
                    <PBox className="title">
                        <PH2>Please Verifiy Your Email</PH2>
                    </PBox>
                    <PBox className="email-not-verified">
                        <PBox className="email-not-verified-title">
                            <PBox>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="64"
                                    height="64"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
                                </svg>
                            </PBox>
                            <PText>
                                We have sent an email to{" "}
                                <b>{this.props.userEmail?.email}</b>. You need
                                to verify your email to continue. <br /> <br />
                                If you have not received the email, please check
                                your "Spam" or "Bulk Email" folder. You can also
                                click the resend button below to have another
                                email sent to you.
                            </PText>
                        </PBox>
                        <PHighlightButton
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className="email-not-verified-button"
                            onClick={() => {
                                this.props.resendVerification(
                                    this.props.userEmail?.email
                                );
                            }}
                        >
                            Resend
                        </PHighlightButton>
                    </PBox>
                    <PBox className="sign-in-link">
                        <PText>
                            Already confirmed your email?{" "}
                            <PLink href="/sign-in">Sign In</PLink>
                        </PText>
                    </PBox>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .email-not-verified": {
        width: "400px",
        marginTop: "2rem",
        backgroundColor: "#fff",
        padding: "3rem 2rem 2rem 2rem",
        borderRadius: "0.5rem",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    },

    "& .title": {
        fontSize: "18px",
        marginTop: "2rem",
    },
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$primary",
        "&:hover": {
            color: "#000",
        },
    },
    "& .email-not-verified-button": {
        color: "#fff",
        backgroundColor: "$colors$primary700",
        width: "100%",
        borderColor: "$colors$primary700",
        marginTop: "16px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "$colors$primary500",
            borderColor: "$colors$primary500",
        },
        margin: "20px 0",
    },
    "& .email-not-verified-title": {
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
            backgroundColor: "#fff",
            borderRadius: "100px",
            marginBottom: "2rem",
            fill: "$colors$primary",
        },
    },
    "& .sign-in-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};

function mapStateToProps(state) {
    return {
        userEmail: state.authReducer?.verifyToken?.userEmail,
    };
}
export default connect(mapStateToProps, { resendVerification })(
    withRouter(EmailNotVerified)
);

import Card from "../components/landing/Card";
import { PSection, PH1, PBox, PContainer } from "../theme/BaseTheme";

export const Products = (props) => {
    return (
        <PSection css={styles}>
            <PBox className="products">
                <PBox className="products-list">
                    {props.productList?.map((item, idx) => {
                        return (
                            <Card
                                key={"product-" + idx.toString()}
                                product={
                                    props.isSearchResult ? item.item : item
                                }
                            />
                        );
                    })}
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    maxWidth: "80rem",
    marginLeft: "auto",
    marginRight: "auto",

    "& .products": {
        marginTop: "2rem",
        marginBottom: "2rem",
    },

    "& .products-list": {
        display: "grid",
        gridGap: "2rem",
        "@bp1": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp3": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp5": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
        //"@bp6": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
};

import axios from "axios";
import { message } from "antd";

export const stripeOnboardUser = () => async (dispatch) => {
    // https://github.com/stripe-samples/connect-onboarding-for-standard/tree/main
    await axios
        .post(`/api/stripes/onboard-user/`)
        .then((response) => {
            window.open(response.data.link, "_blank");
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const stripeCheckOnboarded = () => async (dispatch) => {
    await axios
        .get(`/api/stripes/check-onboarded`)
        .then((response) => {
            dispatch({
                type: "STRIPE_CHECK_ONBOARDED_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const fetchStripeClientSecret = () => async (dispatch) => {
    await axios
        .post(`/api/stripes/account-session`)
        .then((response) => {
            dispatch({
                type: "FETCH_CLIENT_SECRET_SUCCESS",
                payload: response.data.client_secret,
            });
        })
        .catch((error) => [message.error(error?.response?.data?.message, 5)]);
};

export const createStripePaymentIntent =
    (product_uuid, history) => async (dispatch) => {
        try {
            const response = await axios.post(
                `/api/stripes/create-payment-intent/${product_uuid}`
            );
            return response.data;
        } catch (error) {
            if (error?.response?.data?.redirect)
                history.push(error?.response?.data?.redirect);
            message.error(error?.response?.data?.message, 5);
        }
    };

export const calculateStripeTax = (values) => async (dispatch) => {
    await axios
        .post(`/api/stripes/calculate-tax`, values)
        .then((response) => {})
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

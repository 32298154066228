import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { PBox, PSpan, PLink } from "../../theme/BaseTheme";
import CheckoutSignIn from "../../pages/landing/CheckoutSignIn";
import { Modal } from "antd";

class CardForPay extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, isSignInModalOpen: false };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles} className="card-for-pay">
                <PLink
                    className="card-for-pay-button"
                    onClick={() => {
                        if (this.props.userAuth?.userAccount?.uuid) {
                            window.location = `/checkout/${this.props.product_uuid}`;
                        } else {
                            this.setState({ isSignInModalOpen: true });
                        }
                    }}
                    //href={`/checkout-sign-in/${this.props.product_uuid}`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 256 256"
                    >
                        <path d="M136,120v56a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0ZM239.86,98.11,226,202.12A16,16,0,0,1,210.13,216H45.87A16,16,0,0,1,30,202.12l-13.87-104A16,16,0,0,1,32,80H68.37L122,18.73a8,8,0,0,1,12,0L187.63,80H224a16,16,0,0,1,15.85,18.11ZM89.63,80h76.74L128,36.15ZM224,96H32L45.87,200H210.13Zm-51.16,23.2-5.6,56A8,8,0,0,0,174.4,184a7.44,7.44,0,0,0,.81,0,8,8,0,0,0,7.95-7.2l5.6-56a8,8,0,0,0-15.92-1.6Zm-89.68,0a8,8,0,0,0-15.92,1.6l5.6,56a8,8,0,0,0,8,7.2,7.44,7.44,0,0,0,.81,0,8,8,0,0,0,7.16-8.76Z"></path>
                    </svg>
                    <PSpan>Buy Now</PSpan>
                </PLink>
                <Modal
                    open={this.state.isSignInModalOpen}
                    footer={null}
                    onCancel={() => {
                        this.setState({
                            isSignInModalOpen: false,
                        });
                    }}
                >
                    <CheckoutSignIn
                        closeModal={() => {
                            this.setState({
                                isSignInModalOpen: false,
                            });
                        }}
                        product_uuid={this.props.product_uuid}
                        user_uuid={this.props.userAuth?.userAccount?.uuid}
                    />
                </Modal>
            </PBox>
        );
    }
}

const styles = {
    "& .card-for-pay-button": {
        backgroundColor: "#eeecff",
        //width: `calc(100% - 40px)`,
        width: "100%",
        textAlign: "center",
        color: "$colors$primary",
        textDecoration: "none",
        marginTop: "10px",
        marginBottom: "10px",

        cursor: "pointer",
        padding: "12px 18px",
        borderRadius: "24px",
        fontWeight: "500",
        fontSize: "1rem",
        display: "inline-block",
        transition: "all 0.3s ease",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "& svg": {
            fill: "$colors$primary",
            marginRight: "10px",
        },

        "&:hover": {
            backgroundColor: "$colors$primary",
            color: "#fff",
            "& svg": { fill: "#fff" },
        },
    },
};

function mapStateToProps(state) {
    return { userAuth: state.authReducer?.userAuth };
}

export default connect(mapStateToProps, {})(withRouter(CardForPay));

import { message } from "antd";
import axios from "axios";

export const uploadSingleFile = (value) => async (dispatch) => {
    if (value.has("file")) {
        await axios
            .post(`/api/uploads/single-file`, value)
            .then((response) => {
                message.success(response.data.message, 3);
                dispatch({
                    type: "UPLOAD_SINGLE_FILE_SUCCESS",
                    payload: response?.data.filePath,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    }
};

export const uploadFileArray = (value) => async (dispatch) => {
    if (value.has("file")) {
        await axios
            .post(`/api/uploads/file-array`, value)
            .then(async (response) => {
                message.success(response.data.message, 3);
                dispatch({
                    type: "UPLOAD_FILES_SUCCESS",
                    payload: response?.data.filePaths,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    }
};

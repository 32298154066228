import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Pagination } from "antd";
import { Products } from "../../sections/Products";
import { Layout } from "./Layout";
import { fetchProductList } from "../../../actions/product";
import { Loader } from "../../components/Loader";
import Filters from "../../sections/Filters";
import { PContainer, PBox, PH2, PH4, PText } from "../../theme/BaseTheme";
import { fetchSearchProductsFull } from "../../../actions/filter";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            searchKey: "",
            page: 1,
            pageSize: 20,
            urlParams: new URLSearchParams(window.location.search),
            resultKey: "",
        };
    }
    async componentDidMount() {
        await this.setState({ isLoading: true });
        await this.props.fetchSearchProductsFull(
            this.state.urlParams,
            (this.state.page - 1) * this.state.pageSize,
            this.state.pageSize
        );
        await this.setState({
            resultKey: "search_full" + this.state.urlParams.toString(),
        });
        await this.setState({ isLoading: false });
    }

    async componentDidUpdate(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            await this.setState({ isLoading: true });
            await this.props.fetchSearchProductsFull(
                this.state.urlParams,
                (this.state.page - 1) * this.state.pageSize,
                this.state.pageSize
            );
            await this.setState({
                resultKey: "search_full" + this.state.urlParams.toString(),
            });
            await this.setState({ isLoading: false });
        }
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PContainer>
                    <PBox css={styles}>
                        <Filters
                            offset={(this.state.page - 1) * this.state.pageSize}
                            limit={this.state.pageSize}
                        />
                        <PBox className="search-results-wrapper">
                            <PText className="search-results-text">
                                <b>
                                    {
                                        this.props.searchResults[
                                            this.state.resultKey
                                        ]?.totalSearchProducts
                                    }{" "}
                                    results{" "}
                                    {this.props.searchResults[
                                        this.state.resultKey
                                    ]?.searchKey !== "null" && (
                                        <span>
                                            for:{" "}
                                            {
                                                this.props.searchResults[
                                                    this.state.resultKey
                                                ]?.searchKey
                                            }
                                        </span>
                                    )}
                                </b>
                            </PText>
                            {this.props.searchResults[this.state.resultKey]
                                ?.searchProducts?.length === 0 && (
                                <PBox className="search-results-no-results">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="96"
                                        height="96"
                                        fill="currentColor"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M128,24A104,104,0,1,0,232,128,104.13,104.13,0,0,0,128,24Zm-18.34,98.34a8,8,0,0,1-11.32,11.32L88,123.31,77.66,133.66a8,8,0,0,1-11.32-11.32L76.69,112,66.34,101.66A8,8,0,0,1,77.66,90.34L88,100.69,98.34,90.34a8,8,0,0,1,11.32,11.32L99.31,112ZM128,192a12,12,0,1,1,12-12A12,12,0,0,1,128,192Zm61.66-69.66a8,8,0,0,1-11.32,11.32L168,123.31l-10.34,10.35a8,8,0,0,1-11.32-11.32L156.69,112l-10.35-10.34a8,8,0,0,1,11.32-11.32L168,100.69l10.34-10.35a8,8,0,0,1,11.32,11.32L179.31,112Z"></path>
                                    </svg>
                                    <PH4>
                                        No results for{" "}
                                        {
                                            this.props.searchResults[
                                                this.state.resultKey
                                            ]?.searchKey
                                        }
                                        . Try searching a category or other
                                        keywords.
                                    </PH4>
                                </PBox>
                            )}
                            {this.props.searchResults[this.state.resultKey]
                                ?.searchProducts?.length !== 0 && (
                                <Products
                                    productList={
                                        this.props.searchResults[
                                            this.state.resultKey
                                        ]?.searchProducts
                                    }
                                    isSearchResult={true}
                                />
                            )}
                            <PBox className="search-results-pagination">
                                <Pagination
                                    defaultCurrent={1}
                                    total={
                                        this.props.searchResults[
                                            this.state.resultKey
                                        ]?.totalSearchProducts
                                    }
                                    pageSize={this.state.pageSize}
                                    showSizeChanger={false}
                                    showQuickJumper={true}
                                    onChange={async (page) => {
                                        await this.setState({
                                            page: page,
                                        });
                                        await this.props.fetchSearchProducts(
                                            this.state.urlParams.get("key"),
                                            (this.state.page - 1) *
                                                this.state.pageSize,
                                            this.state.pageSize
                                        );
                                    }}
                                />
                            </PBox>
                        </PBox>
                    </PBox>
                </PContainer>
            </Layout>
        );
    }
}

const styles = {
    display: "flex",
    "& .filters": {
        marginTop: "40px",
        marginBottom: "40px",
        minWidth: "200px",
        marginRight: "20px",
    },
    "& .search-results-wrapper": {
        marginTop: "40px",
        flexGrow: "1",
        "& h4": {
            fontWeight: "500",
        },
    },
    "& .search-results-text": {
        marginLeft: "30px",
    },
    "& .search-results-no-results": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "$colors$text",
    },
    "& .search-results-pagination": {
        display: "flex",
        justifyContent: "center",
        marginTop: "40px",
        marginBottom: "80px",
    },
    "& .products-title": {
        textAlign: "center",
        fontFamily: "Poppins, sans-serif",
        fontSize: "2.5rem",
        fontWeight: "500",
        lineHeight: "2.5rem",
        paddingTop: "1.25rem",
        marginTop: "3rem",
        marginBottom: "3rem",
    },
};

function mapStateToProps(state) {
    return {
        searchResults: state.filterReducer?.fetchSearchProductsFull,
    };
}

export default connect(mapStateToProps, {
    fetchProductList,
    fetchSearchProductsFull,
})(withRouter(Search));

import { message } from "antd";
import axios from "axios";

// For use in landing page, fetch all products
export const fetchProductList = () => async (dispatch) => {
    await axios
        .get("/api/products/all")
        .then(async (response) => {
            dispatch({
                type: "FETCH_PRODUCT_LIST_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const fetchLandingPageProductLists = () => async (dispatch) => {
    await axios
        .get("/api/products/landing")
        .then(async (response) => {
            dispatch({
                type: "FETCH_LANDING_PRODUCT_LISTS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const fetchFeaturedProducts = (offset, limit) => async (dispatch) => {
    await axios
        .get(`/api/products/featured/${offset}/${limit}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_FEATURED_PRODUCTS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const fetchNewProducts = (offset, limit) => async (dispatch) => {
    await axios
        .get(`/api/products/new/${offset}/${limit}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_NEW_PRODUCTS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const fetchPickedProducts = (offset, limit) => async (dispatch) => {
    await axios
        .get(`/api/products/picks/${offset}/${limit}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_PICKED_PRODUCTS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// For use in landing page showing shop items
export const fetchProductListByMerchantID =
    (shop_uuid, offset, limit) => async (dispatch) => {
        await axios
            .get(`/api/products/all/${shop_uuid}/${offset}/${limit}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_PRODUCT_LIST_BY_MERCHANT_SUCCESS",
                    payload: response?.data,
                    shop_uuid: shop_uuid,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For use in seller dashboard showing listings
export const fetchProductListDetailsByMerchantID =
    (shop_uuid, offset, limit, history) => async (dispatch) => {
        await axios
            .get(`/api/products/all_details/${shop_uuid}/${offset}/${limit}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_PRODUCT_LIST_DETAILS_BY_MERCHANT_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
                if (error?.response?.data?.redirect)
                    history.push(error?.response?.data?.redirect);
            });
    };

// For use in seller dashboard showing top products
export const fetchTopProductListByMerchantID =
    (shop_uuid) => async (dispatch) => {
        await axios
            .get(`/api/products/top/${shop_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_TOP_PRODUCT_LIST_BY_MERCHANT_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For displaying a product in landing page
export const fetchProductToDisplay =
    (product_uuid, history) => async (dispatch) => {
        await axios
            .get(`/api/products/${product_uuid}`)
            .then(async (response) => {
                if (response.data?.redirect)
                    history.push(response.data?.redirect);
                dispatch({
                    type: "FETCH_PRODUCT_DISPLAY_SUCCESS",
                    payload: response?.data,
                    product_uuid: product_uuid,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For use in seller dashboard to edit product
export const fetchProductToEdit =
    (shop_uuid, product_uuid, history) => async (dispatch) => {
        await axios
            .get(`/api/products/${shop_uuid}/${product_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_PRODUCT_EDIT_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
                if (error?.response?.data?.redirect)
                    history.push(error?.response?.data?.redirect);
            });
    };

// For use in seller's dashboard for creating new product
export const createProduct =
    (shop_uuid, value, history) => async (dispatch) => {
        await axios
            .post(`/api/products/create/${shop_uuid}`, value)
            .then((response) => {
                message.success(response.data.message, 3);
                if (response.data?.redirect)
                    history.push(response.data?.redirect);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For use in seller's dashboard for deleting product by id
export const deleteProduct = (shop_uuid, product_uuid) => async (dispatch) => {
    await axios
        .delete(`/api/products/${shop_uuid}/${product_uuid}`)
        .then((response) => {
            message.success(response.data.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// For use in seller's dashboard for editing product by id
export const editProduct =
    (shop_uuid, product_uuid, data, history) => async (dispatch) => {
        await axios
            .post(`/api/products/edit/${shop_uuid}/${product_uuid}`, data)
            .then((response) => {
                message.success(response.data.message, 3);
                dispatch({
                    type: "FETCH_PRODUCT_EDIT_SUCCESS",
                    payload: response?.data?.product,
                });
                if (response.data?.redirect)
                    history.push(response.data?.redirect);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

export const fetchSitemap = () => async (dispatch) => {
    await axios
        .get(`/api/products/sitemap`)
        .then((response) => {
            dispatch({
                type: "FETCH_SITEMAP_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

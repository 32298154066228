import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const USOnly = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>Can I still participate if I am not in the US?</PH1>
                <PText>
                    We require sellers to have a US legal entity, US bank
                    account, and EIN/SSN. You may not offer to sell or sell
                    Products on the Phair Sites outside of the U.S. The Phair.io
                    is only available to US-based buyers.
                </PText>
            </PBox>
            <HelpNav
                prev={"Anti-Discrimination Policy"}
                prev_link={"/help/anti-discrimination"}
                next={"Ownership and Rights"}
                next_link={"/help/ownership-and-rights"}
            />
        </Layout>
    );
};

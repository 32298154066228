import axios from "axios";
import { message } from "antd";

export const fetchUser = () => async (dispatch) => {
    await axios
        .get(`/api/auths/current-user`)
        .then((response) => {
            dispatch({
                type: "SIGN_IN_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({ type: "SIGN_IN_FAIL" });
        });
};

export const userSignUp = (data, history) => async (dispatch) => {
    await axios
        .post(`/api/auths/sign-up`, data)
        .then((response) => {
            message.success(response.data.message, 3);
            dispatch({
                type: "VERIFY_TOKEN_SUCCESS",
                payload: response.data,
            });
            if (response.data.redirect) history.push(response.data.redirect);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const userSignIn = (data, history) => async (dispatch) => {
    await axios
        .post(`/api/auths/sign-in`, data)
        .then((response) => {
            message.success(response?.data?.message, 3);
            dispatch({
                type: "SIGN_IN_SUCCESS",
                payload: response.data,
            });
            dispatch({
                type: "FETCH_SAVE_ITEMS_SUCCESS",
                payload: response.data.save_items,
            });
            history.push(response.data.redirect);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
            dispatch({
                type: "VERIFY_TOKEN_SUCCESS",
                payload: error?.response?.data,
            });
            if (error?.response?.data?.redirect)
                history.push(error?.response?.data?.redirect);
        });
};

export const resendVerification = (user_email) => async (dispatch) => {
    await axios
        .post(`/api/auths/resend-confirmation/${user_email}`)
        .then((response) => {
            message.success(response?.data?.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const confirmEmail = (token, history) => async (dispatch) => {
    console.log("confirming email: ", token);
    await axios
        .post(`/api/auths/confirmation/${token}`)
        .then((response) => {
            message.success(response?.data?.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
            history.push("/email-not-verified");
        });
};

export const userSignOut = () => async (dispatch) => {
    await axios
        .post(`/api/auths/sign-out`)
        .then((response) => {
            message.success(response.data.message, 3);
            dispatch({ type: "SIGN_OUT_SUCCESS" });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const userSessionExpired = () => async (dispatch) => {
    await axios
        .post(`/api/auths/session-expired`)
        .then((response) => {
            message.success(response.data.message, 3);
            dispatch({ type: "SIGN_OUT_SUCCESS" });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const userForgotPassword = (data, history) => async (dispatch) => {
    await axios
        .post(`/api/auths/forgot-password`, data)
        .then((response) => {
            message.success(response.data.message, 3);
            dispatch({
                type: "VERIFY_TOKEN_SUCCESS",
                payload: response.data,
            });
            history.push("/forgot-password-confirm");
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const confirmResetPwdToken = (token, history) => async (dispatch) => {
    await axios
        .post(`/api/auths/reset-password/${token}`)
        .then((response) => {
            message.success(response?.data?.message, 3);
            dispatch({
                type: "VERIFY_TOKEN_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
            history.push("/sign-in");
        });
};

export const resendResetPassword = (user_email) => async (dispatch) => {
    await axios
        .post(`/api/auths/resend-reset-password/${user_email}`)
        .then((response) => {
            message.success(response?.data?.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const userResetPassword =
    (user_email, data, history) => async (dispatch) => {
        await axios
            .patch(`/api/auths/reset-password/${user_email}`, data)
            .then((response) => {
                message.success(response.data.message, 3);
                history.push("/sign-in");
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

export const userVerifyToken = () => async (dispatch) => {
    await axios
        .get(`/api/auths/verify-token`)
        .then((response) => {
            if (response.data.authorized)
                message.success("User is logged in", 3);
        })
        .catch((error) => {
            message.error(error?.response.data?.message, 5);
        });
};

export const changePassword = (values) => async (dispatch) => {
    await axios
        .post("/api/auths/change-password", values)
        .then((response) => {
            message.success(response.data.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

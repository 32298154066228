import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { SellBanner } from "../../sections/SellBanner";
import { SellFaq } from "../../sections/SellFaq";
import { SellWhat } from "../../sections/SellWhat";
import { SellHow } from "../../sections/SellHow";
import { SellPlan } from "../../sections/SellPlan";
import { SellAction } from "../../sections/SellAction";
import { SellWhy } from "../../sections/SellWhy";

class Sell extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <SellBanner user_uuid={this.props.userAccount?.uuid} />
                <SellHow />
                <SellWhy />
                <SellWhat />
                {/*<SellPlan />*/}
                <SellFaq />
                <SellAction user_uuid={this.props.userAccount?.uuid} />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(Sell));

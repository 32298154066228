import { PBox, PH1 } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import Chart from "react-apexcharts";

const lineChartData = {
    type: "line",
    height: 90,
    options: {
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#000"],
        fill: {
            type: "solid",
            opacity: 1,
        },
        stroke: {
            curve: "smooth",
            width: 3,
        },
        yaxis: {
            min: 0,
            max: 100,
        },
        tooltip: {
            theme: "light",
            fixed: {
                enabled: false,
            },
            x: {
                show: false,
            },
            y: {
                title: "Total Order",
            },
            marker: {
                show: false,
            },
        },
    },
    series: [
        {
            name: "series1",
            data: [45, 66, 41, 89, 25, 44, 9, 54],
        },
    ],
};

const barChartData = {
    height: 480,
    type: "bar",
    options: {
        chart: {
            id: "bar-chart",
            stacked: true,
            toolbar: {
                show: true,
            },
            zoom: {
                enabled: true,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "50%",
            },
        },
        xaxis: {
            type: "category",
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
        },
        legend: {
            show: true,
            fontSize: "14px",
            fontFamily: `'Roboto', sans-serif`,
            position: "bottom",
            offsetX: 20,
            labels: {
                useSeriesColors: false,
            },
            markers: {
                width: 16,
                height: 16,
                radius: 5,
            },
            itemMargin: {
                horizontal: 15,
                vertical: 8,
            },
        },
        fill: {
            type: "solid",
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: true,
        },
    },
    series: [
        {
            name: "Investment",
            data: [35, 125, 35, 35, 35, 80, 35, 20, 35, 45, 15, 75],
        },
        {
            name: "Loss",
            data: [35, 15, 15, 35, 65, 40, 80, 25, 15, 85, 25, 75],
        },
        {
            name: "Profit",
            data: [35, 145, 35, 35, 20, 105, 100, 10, 65, 45, 30, 10],
        },
        {
            name: "Maintenance",
            data: [0, 0, 75, 0, 0, 115, 0, 0, 0, 0, 150, 0],
        },
    ],
};

export const Analytics = (props) => {
    return (
        <Layout>
            <PBox css={styles} className="analytics">
                <PH1>Analytics</PH1>
                <PBox>
                    <Chart {...lineChartData} />
                </PBox>
                <PBox>
                    <Chart {...barChartData} />
                </PBox>
            </PBox>
        </Layout>
    );
};

const styles = {
    margin: "40px",
    "& h1": {
        padding: "20px 0",
        margin: "20px auto 40px auto",
        textAlign: "center",
        borderBottom: "1px solid $colors$primary",
        width: "50%",
    },
};

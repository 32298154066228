import { message } from "antd";
import axios from "axios";

export const createReview = (user_uuid, value) => async (dispatch) => {
    await axios
        .post(`/api/reviews/create/${user_uuid}`, value)
        .then((response) => {
            message.success(response.data.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const editReview = (user_uuid, review_id, value) => async (dispatch) => {
    await axios
        .put(`/api/reviews/edit/${user_uuid}/${review_id}`, value)
        .then((response) => {
            message.success(response.data.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const deleteReview = (user_uuid, review_id) => async (dispatch) => {
    await axios
        .delete(`/api/reviews/${user_uuid}/${review_id}`)
        .then((response) => {
            message.success(response.data.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

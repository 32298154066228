import { Component, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Form, Space, Input } from "antd";
import { Loader } from "../Loader";
import { PBox, PSpan, PLink } from "../../theme/BaseTheme";
import { FormLabel } from "../seller/FormLabel";
import { PFlowButton } from "../../theme/BaseTheme";
import { sendFreeProduct } from "../../../actions/order";

const SendProductForm = (props) => {
    const [customerEmail, setCustomerEmail] = useState("");

    const onSendProductFinish = async (values) => {
        await props.sendFreeProduct(
            props.product_uuid,
            customerEmail,
            props.user_uuid,
            props.history
        );
        props.closeModal();
    };
    const sendProductFormStyles = {
        "& .send-product-form-submit-button": {
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "#5344a9",
            borderColor: "#5344a9",
            "&:hover": {
                backgroundColor: "#fff",
                color: "#5344a9",
            },
        },
        "& .send-product-form-cancel-button": {
            cursor: "pointer",
            marginRight: "20px",
            backgroundColor: "rgb(238, 236, 255)",
            color: "#5344a9",
            borderColor: "rgb(238, 236, 255)",
            "&:hover": {
                borderColor: "#5344a9",
                backgroundColor: "#fff",
            },
        },
    };
    return (
        <PBox css={sendProductFormStyles}>
            <Form
                name="send_product"
                layout="vertical"
                onFinish={onSendProductFinish}
            >
                <FormLabel
                    label={{
                        is_required: true,
                        title: "Email",
                        description:
                            "We'll send the product to the email entered below.",
                    }}
                />
                <Form.Item
                    name="email"
                    rules={[
                        {
                            validator: async (_, email) => {
                                if (!customerEmail) {
                                    return Promise.reject(
                                        new Error("Please enter your email.")
                                    );
                                }
                            },
                        },
                    ]}
                >
                    <Input
                        placeholder="Email"
                        id="email"
                        onChange={(e) => {
                            setCustomerEmail(e.target.value);
                        }}
                    />
                </Form.Item>
                <Form.Item className="account-form-buttons">
                    <Space>
                        <PFlowButton
                            type="primary"
                            htmlType="submit"
                            className="send-product-form-submit-button"
                        >
                            Send
                        </PFlowButton>
                        <PFlowButton
                            htmlType="reset"
                            className="send-product-form-cancel-button"
                            onClick={() => {
                                props.closeModal();
                            }}
                        >
                            Cancel
                        </PFlowButton>
                    </Space>
                </Form.Item>
            </Form>
        </PBox>
    );
};

class CardForFree extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, isSendProductModalOpen: false };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles} className="card-for-free">
                <PLink
                    className="card-for-free-button"
                    onClick={() => {
                        this.setState({ isSendProductModalOpen: true });
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 256 256"
                    >
                        <path d="M136,120v56a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0ZM239.86,98.11,226,202.12A16,16,0,0,1,210.13,216H45.87A16,16,0,0,1,30,202.12l-13.87-104A16,16,0,0,1,32,80H68.37L122,18.73a8,8,0,0,1,12,0L187.63,80H224a16,16,0,0,1,15.85,18.11ZM89.63,80h76.74L128,36.15ZM224,96H32L45.87,200H210.13Zm-51.16,23.2-5.6,56A8,8,0,0,0,174.4,184a7.44,7.44,0,0,0,.81,0,8,8,0,0,0,7.95-7.2l5.6-56a8,8,0,0,0-15.92-1.6Zm-89.68,0a8,8,0,0,0-15.92,1.6l5.6,56a8,8,0,0,0,8,7.2,7.44,7.44,0,0,0,.81,0,8,8,0,0,0,7.16-8.76Z"></path>
                    </svg>
                    <PSpan>Send Me Now</PSpan>
                </PLink>
                <Modal
                    title="Get the Product for Free!"
                    open={this.state.isSendProductModalOpen}
                    footer={null}
                    onCancel={() => {
                        this.setState({
                            isSendProductModalOpen: false,
                        });
                    }}
                >
                    <SendProductForm
                        closeModal={() => {
                            this.setState({
                                isSendProductModalOpen: false,
                            });
                        }}
                        sendFreeProduct={this.props.sendFreeProduct}
                        product_uuid={this.props.product_uuid}
                        user_uuid={this.props.userAuth?.userAccount?.uuid}
                        history={this.props.history}
                    />
                </Modal>
            </PBox>
        );
    }
}

const styles = {
    "& .card-for-free-button": {
        backgroundColor: "#eeecff",
        //width: `calc(100% - 40px)`,
        width: "100%",
        textAlign: "center",
        color: "$colors$primary",
        textDecoration: "none",
        marginTop: "10px",
        marginBottom: "10px",

        cursor: "pointer",
        padding: "12px 18px",
        borderRadius: "24px",
        fontWeight: "500",
        fontSize: "1rem",
        display: "inline-block",
        transition: "all 0.3s ease",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "& svg": {
            fill: "$colors$primary",
            marginRight: "10px",
        },

        "&:hover": {
            backgroundColor: "$colors$primary",
            color: "#fff",
            "& svg": { fill: "#fff" },
        },
    },
};

function mapStateToProps(state) {
    return {
        userAuth: state.authReducer?.userAuth,
    };
}

export default connect(mapStateToProps, { sendFreeProduct })(
    withRouter(CardForFree)
);

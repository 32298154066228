import { combineReducers } from "redux";

const fetchDownloads = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_DOWNLOAD_LINKS_SUCCESS":
            state[action.key] = {
                downloads: action.payload,
            };

        default:
            return state;
    }
};

export default combineReducers({ fetchDownloads });

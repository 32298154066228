import { PBox, PH1, PFlowButton, PText } from "../../theme/BaseTheme";
import { Checkbox, Form, Input } from "antd";

export const Profile = (props) => {
    return (
        <PBox css={styles}>
            <Form name="set-profile" onFinish={props.next}>
                <PBox className="profile-content">
                    <PH1>Profile</PH1>
                    <PText>Some more information to setup your shop.</PText>
                    <PBox className="profile-form">
                        <PText>
                            What would you like the customers to call you?
                        </PText>
                        <Input
                            placeholder="Seller Display Name"
                            id="seller-display-name"
                            defaultValue={props.sellerDisplayName}
                            onChange={(e) => {
                                props.setSellerDisplayName(e.target.value);
                            }}
                        />
                        <PText>One sentence to describe your shop.</PText>
                        <Input
                            placeholder="Shop Description"
                            id="shop-description"
                            defaultValue={props.shopDescription}
                            onChange={(e) => {
                                props.setShopDescription(e.target.value);
                            }}
                        />
                        <Form.Item
                            name="certify-age"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: async (_, checked) => {
                                        if (!props.certifyAge) {
                                            return Promise.reject(
                                                new Error(
                                                    "You must be at least 18 years old to open a store on Phair."
                                                )
                                            );
                                        }
                                    },
                                },
                            ]}
                        >
                            <Checkbox
                                defaultChecked={props.certifyAge}
                                onChange={(e) => {
                                    props.setCertifyAge(e.target.checked);
                                }}
                            >
                                I certify that I am at least 18 years of age.
                            </Checkbox>
                        </Form.Item>
                    </PBox>
                </PBox>
                <PFlowButton
                    type="primary"
                    className="profile-prev-button"
                    onClick={() => props.prev()}
                >
                    Prev
                </PFlowButton>
                <PFlowButton type="primary" className="profile-next-button">
                    Next
                </PFlowButton>
            </Form>
        </PBox>
    );
};

const styles = {
    "& .profile-form": {
        marginTop: "40px",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "left",
        width: "80%",
        "@bp3": {
            width: "50%",
        },
        "& label": {
            marginTop: "20px",
        },
        "& span": {
            fontSize: "16px",
            color: "$colors$text",
        },
    },
    "& .profile-content": {
        minHeight: "480px",
    },
    "& .profile-prev-button": {
        cursor: "pointer",
        marginRight: "20px",
        backgroundColor: "$colors$lightPrimary",
        color: "$colors$primary",
        borderColor: "$colors$lightPrimary",
        "&:hover": {
            borderColor: "$colors$Primary",
            backgroundColor: "#fff",
        },
    },
    "& .profile-next-button": {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "$colors$primary",
        borderColor: "$colors$primary",
        "&:hover": {
            backgroundColor: "#fff",
            color: "$colors$primary",
        },
    },
};

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const NeedHelp = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>Need Help?</PH1>
                <PText>
                    For sellers, if you are running into issues setting up your
                    store or stocking your store with listings, please reach out
                    to our seller support team. Send us an email at
                    seller_support@phair.io. To help us, please describe the
                    issue you are having, describe at what step in the process
                    you are at, include details on the device that you are on,
                    and attach any applicable screenshots.{" "}
                    <b>
                        Please also be sure to list your store name, as well as
                        your first and last name when reaching out to our
                        support team.
                    </b>
                </PText>
                <PText>
                    For buyers, if you are running into issues of making
                    payments, receiving the products, finding your previous
                    orders, or requesting receipts, please reach out to our
                    buyer support team. Send us an email at
                    buyer_support@phair.io.
                </PText>
            </PBox>
            <HelpNav
                prev={"Fees"}
                prev_link={"/help/fees"}
                next={"Create a product"}
                next_link={"/help/create-a-product"}
            />
        </Layout>
    );
};

import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Pagination } from "antd";
import { Products } from "../../sections/Products";
import ShopBanner from "../../sections/ShopBanner";
import { Layout } from "./Layout";
import { fetchProductListByMerchantID } from "../../../actions/product";
import { Loader } from "../../components/Loader";
import { PH1, PBox } from "../../theme/BaseTheme";

class Shop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: parseInt(this.props.match.params.page),
            pageSize: 12,
            shopProductLists: [],
        };
    }
    async componentDidMount() {
        if (!this.props.match.params.page) {
            this.props.history.push(
                `/shop/${this.props.match.params.shop_uuid}/1`
            );
        }
        await this.props.fetchProductListByMerchantID(
            this.props.match.params.shop_uuid,
            (this.state.page - 1) * this.state.pageSize,
            this.state.pageSize
        );
        await this.setState({
            shopProductLists:
                this.props.shopProductLists[this.props.match.params.shop_uuid]
                    ?.productList,
            isLoading: false,
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles}>
                    <ShopBanner />
                    <PH1 className="shop-title">Shop Products</PH1>
                    <Products
                        productList={this.state.shopProductLists?.productList}
                        isSearchResult={false}
                    />
                    <PBox className="products-pagination">
                        <Pagination
                            defaultCurrent={this.state.page}
                            current={this.state.page}
                            total={this.state.shopProductLists?.totalProducts}
                            pageSize={this.state.pageSize}
                            showSizeChanger={false}
                            showQuickJumper={true}
                            onChange={async (page) => {
                                window.location.href = `/shop/${this.props.match.params.shop_uuid}/${page}`;
                            }}
                        />
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    marginBottom: "2.5rem",

    "& .shop-title": {
        fontSize: "1.5rem",
        fontWeight: "700",
        lineHeight: "2rem",
        color: "rgb(17 24 39)",
        margin: "2rem 0",
        maxWidth: "80rem",
        marginLeft: "auto",
        marginRight: "auto",
    },
    "& .products-pagination": {
        display: "flex",
        justifyContent: "center",
        marginTop: "40px",
        marginBottom: "80px",
    },
};

function mapStateToProps(state) {
    return {
        shopProductLists: state.productReducer?.fetchProductListByMerchant,
    };
}

export default connect(mapStateToProps, { fetchProductListByMerchantID })(
    withRouter(Shop)
);

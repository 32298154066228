import React, { useState } from "react";
import { ConfigProvider } from "antd";
import Sidebar from "./Sidebar";
import { BaseTheme, PBox } from "../../theme/BaseTheme";
import DashboardHeader from "../../sections/DashboardHeader";

export const Layout = (props) => {
    const [showSidebar, setShowSidebar] = useState(true);

    const styles = {
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        color: "$colors$text",
        fontFamily: "$fonts$body",
        "& .dashboard-header": {
            transition: "ease-in-out 0.3s",
        },
        "& .dashboard-container": {
            transition: "ease-in-out 0.3s",
            width: showSidebar ? `calc(100vw - 280px)` : "100vw",
            height: "100vh",
        },
        "& .dashboard-content": {
            transition: "ease-in-out 0.3s",
            height: `calc(100vh - 90px)`,
            overflow: "scroll",
            backgroundColor: "#fff",
            //backgroundColor: "#fbe8d6",
        },
        "& .dashboard-sidebar-container": {
            height: "100vh",
        },
    };

    return (
        <div className={BaseTheme}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#5344a9",
                        colorTextBase: "#002346",
                    },
                }}
            >
                <PBox css={styles} className="dashboard-layout">
                    <PBox className="dashboard-sidebar-container">
                        <Sidebar
                            showSidebar={showSidebar}
                            setShowSidebar={(value) => setShowSidebar(value)}
                        />
                    </PBox>
                    <PBox className="dashboard-container">
                        <DashboardHeader
                            showSidebar={showSidebar}
                            setShowSidebar={(value) => setShowSidebar(value)}
                        />
                        <PBox className="dashboard-content">
                            {props.children}
                        </PBox>
                    </PBox>
                </PBox>
            </ConfigProvider>
        </div>
    );
};

import { useState } from "react";
import clsx from "clsx";
import { PBox, PH3 } from "../../theme/BaseTheme";
import { HTMLRenderWrapper } from "../editor/HTMLRenderWrapper";

export const Accordion = (props) => {
    const [isActive, setIsActive] = useState(true);
    return (
        <PBox css={styles}>
            <PBox className={clsx("accordion", isActive ? "active" : "")}>
                <PBox
                    className="accordion-title"
                    onClick={() => setIsActive(!isActive)}
                >
                    <PH3>{props.title}</PH3>
                    <PBox className="accordion-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                            width="16"
                            height="16"
                        >
                            <path d="M215.39,92.94A8,8,0,0,0,208,88H48a8,8,0,0,0-5.66,13.66l80,80a8,8,0,0,0,11.32,0l80-80A8,8,0,0,0,215.39,92.94Z" />
                        </svg>
                    </PBox>
                </PBox>
                {isActive && (
                    <PBox className="accordion-content">
                        <HTMLRenderWrapper>{props.content}</HTMLRenderWrapper>
                    </PBox>
                )}
            </PBox>
        </PBox>
    );
};

const styles = {
    transition: "all 0.3s ease",
    "& .accordion": { margin: "32px 0px", transition: "all 0.3s ease" },
    "& .accordion-title": {
        height: "40px",
        padding: "0 20px",
        borderRadius: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& h3": {
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "26px",
        },
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "$colors$lightBackground",
        },
    },
    "& .accordion-content": {
        transition: "all 0.3s ease",
        padding: "12px 20px",
        color: "$colors$text",
        lineHeight: "1.5rem",
        fontSize: "16px",
        wordBreak: "break-all",
        overflowWrap: "break-word",
    },
    "& .accordion-icon": {
        transition: "transform 0.3s ease",
    },
    "& .active": {
        transition: "all 0.3s ease",
        "& .accordion-icon": {
            transform: "rotate(-180deg)",
        },
    },
};

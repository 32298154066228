import { keyframes } from "@stitches/react";
import ReactTypingEffect from "react-typing-effect";
import clsx from "clsx";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PImg,
    PHighlightLink,
    PLink,
} from "../theme/BaseTheme";

export const Banner = () => {
    return (
        <PSection css={styles}>
            <PBox className="banner">
                <PBox className="banner-container">
                    <PBox className="banner-background">
                        <PBox className="banner-background-gradient"></PBox>
                    </PBox>
                    <PBox className="banner-title-wrapper">
                        <PH1 className="banner-title">
                            Find Your Perfect{" "}
                            <ReactTypingEffect
                                text={[
                                    "Digital Products",
                                    "Tutorials",
                                    "eBooks",
                                    "Photography",
                                    "Software",
                                    "Fonts",
                                    "Music",
                                    "Templates",
                                    "Documents",
                                    "Videos",
                                    "Graphics",
                                    "Illustrations",
                                    "Memberships",
                                    "Data Sets",
                                    "Apps",
                                    "Digital Media",
                                    "Icons",
                                    "Online Tools",
                                    "Printables",
                                ]}
                                eraseDelay={1000}
                                typingDelay={1000}
                                cursor=" "
                                className="banner-typed-text"
                            />
                            {/*<span className="banner-cursor">&nbsp;</span>*/}
                        </PH1>
                        <PBox className="banner-text">
                            <PText>
                                Your all-in-one marketplace for creative digital
                                products. <br />
                                An eCommerce platform to turn your ideas into
                                profits.
                            </PText>
                            <PLink
                                className="banner-browsing-button"
                                href="/featured"
                            >
                                Start Exploring
                            </PLink>
                            <PLink
                                className="banner-selling-button"
                                href="/sell"
                            >
                                Open a Shop <span>→</span>
                            </PLink>
                        </PBox>
                    </PBox>
                    <PImg
                        src="/images/banner-2.png"
                        className={clsx("banner-img-1", "banner-svg")}
                    />
                    <PImg
                        src="/images/banner-1.png"
                        className={clsx("banner-img-2", "banner-svg")}
                    />
                    <PImg
                        src="/images/banner-3.png"
                        className={clsx("banner-img-3", "banner-svg")}
                    />
                    <PImg
                        src="/images/banner-4.png"
                        className={clsx("banner-img-4", "banner-svg")}
                    />
                    <PImg
                        src="/images/banner-5.png"
                        className={clsx("banner-img-5", "banner-svg")}
                    />
                    <PImg
                        src="/images/banner-6.png"
                        className={clsx("banner-img-6", "banner-svg")}
                    />
                </PBox>
            </PBox>
        </PSection>
    );
};

const blink = keyframes({
    "0%": { backgroundColor: "black" },
    "49%": { backgroundColor: "black" },
    "50%": { backgroundColor: "transparent" },
    "99%": { backgroundColor: "transparent" },
    "100%": { backgroundColor: "black" },
});

const moveUpAndDown = keyframes({
    "0%": { transform: "translateY(0)" },
    "50%": { transform: "translateY(-12px)" },
    "100%": { transform: "translateY(0)" },
});

const styles = {
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .banner": {
        padding: "2rem 2rem",
        margin: "4rem auto",
        maxWidth: "80rem",
    },
    "& .banner-container": {
        position: "relative",
    },
    "& .banner-background": {
        display: "none",
        position: "absolute",
        left: "0",
        top: "-15rem",
        filter: "blur(64px)",
        zIndex: "-10",
    },
    "& .banner-background-gradient": {
        width: "72.1875rem",
        height: "40rem",
        left: "calc(50% - 30rem)",
        opacity: "0.3",
        transform: "rotate(30deg)",
        backgroundImage: "linear-gradient(to top right, #a78bfa, #a78bfa)",
        clipPath:
            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
    },
    "& .banner-title-wrapper": {
        margin: "2rem auto",
        maxWidth: "52rem",
        textAlign: "center",
        marginBottom: "60px",
    },
    "& .banner-title": {
        fontSize: "3.75rem",
        lineHeight: "1.2",
        color: "rgb(17 24 39)",
        fontWeight: "700",
        padding: "4rem 4rem 0rem 4rem",
        margin: "0",
    },
    "& .banner-typed-text": {
        color: "$colors$primary",
    },
    "& .banner-cursor": {
        display: "inline-block",
        marginLeft: "0.6rem",
        width: "6px",
        animation: `${blink} 1s infinite`,
        backgroundColor: "black",
    },
    "& .banner-text": {
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "3rem",
        "& p": {
            marginBottom: "3rem",
            color: "rgb(75 85 99)",
            lineHeight: "2rem",
            fontSize: "1.125rem",
            marginTop: "2rem",
        },
    },
    "& .banner-browsing-button": {
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: "#fff",
        padding: "0.625rem 0.875rem",
        backgroundColor: "$colors$primary",
        borderRadius: "0.375rem",
        textDecoration: "none",
        marginRight: "2rem",
        "&:hover": {
            backgroundColor: "$colors$primary500",
        },
    },
    "& .banner-selling-button": {
        color: "rgb(17 24 39)",
        lineHeight: "1.5rem",
        fontWeight: "600",
        fontSize: "0.875rem",
        textDecoration: "none",
    },
    "& .banner-img-1": {
        position: "absolute",
        top: "5rem",
        left: "2rem",
        width: "2.5rem",
        height: "2.5rem",
        animation: `${moveUpAndDown} 5s infinite`,
        animationDelay: "1s",
    },
    "& .banner-img-2": {
        position: "absolute",
        top: "-4rem",
        left: "30%",
        width: "2.5rem",
        height: "2.5rem",
        animation: `${moveUpAndDown} 5s infinite`,
        animationDelay: "2s",
    },
    "& .banner-img-3": {
        position: "absolute",
        top: "-2.5rem",
        right: "30%",
        width: "2.5rem",
        height: "2.5rem",
        animation: `${moveUpAndDown} 5s infinite`,
        animationDelay: "3s",
    },
    "& .banner-img-4": {
        position: "absolute",
        top: "7.5rem",
        right: "2rem",
        width: "2.5rem",
        height: "2.5rem",
        animation: `${moveUpAndDown} 5s infinite`,
        animationDelay: "4s",
    },
    "& .banner-img-5": {
        position: "absolute",
        bottom: "-4rem",
        left: "15%",
        width: "3rem",
        height: "3rem",
        animation: `${moveUpAndDown} 5s infinite`,
        animationDelay: "5s",
    },
    "& .banner-img-6": {
        position: "absolute",
        bottom: "-4.5rem",
        right: "15%",
        width: "3.5rem",
        height: "3.5rem",
        animation: `${moveUpAndDown} 5s infinite`,
        animationDelay: "6s",
    },
    "& .banner-svg": {
        padding: "0.5rem",
    },
};

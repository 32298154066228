import { ConfigProvider } from "antd";
import Header from "../../sections/Header";
import { Footer } from "../../sections/Footer";
import { PContainer, PBox } from "../../theme/BaseTheme";
import { HelpMenu } from "../../sections/HelpMenu";
import { BaseTheme } from "../../theme/BaseTheme";

export const Layout = (props) => {
    return (
        <div className={BaseTheme}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#6d28d9",
                        colorTextBase: "#002346",
                    },
                }}
            >
                <PBox css={styles} className="help-layout">
                    <Header />
                    <PBox className="help-center-wrapper">
                        <PBox className="help-center-menu">
                            <HelpMenu />
                        </PBox>
                        <PBox className="help-center-content-container">
                            <PBox className="help-center-content">
                                {props.children}
                            </PBox>
                        </PBox>
                    </PBox>
                    {/*<Footer />*/}
                </PBox>
            </ConfigProvider>
        </div>
    );
};

const styles = {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    color: "$colors$article",
    position: "relative",
    fontFamily: "$fonts$body",
    "& .help-center-wrapper": {
        display: "flex",
        flexDirection: "row",
        margin: "0 auto",
        flex: "1 1 auto",
        position: "relative",
        width: "100%",
        justifyContent: "center",
        height: "100%",
        overflow: "hidden",
        maxWidth: "88rem",
    },
    "& .help-center-menu": {
        position: "relative",
        flex: "none",
        display: "block",
    },
    "& .help-center-content-container": {
        padding: "2rem 4rem",
        minWidth: "0",
        display: "block",
        flex: "1 1 auto",
        fontSize: "1rem",
        lineHeight: "1.75rem",
        position: "relative",
        overflowY: "scroll",
        backgroundColor: "#fff",
        color: "#3f3f46",
        fontFamily:
            "ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",

        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": { display: "none" },
        "& h1": {
            fontWeight: "700",
            fontSize: "1.5rem",
            lineHeight: "2rem",
            marginTop: "2.5rem",
            marginBottom: "2rem",
        },
        "& h1:first-child": {
            marginTop: "1rem",
        },
        "& h3": {
            fontSize: "1.1rem",
            fontWeight: "700",
        },
        "& p": {
            //color: "$colors$label",
        },
        "& ul": {
            //color: "$colors$label",
        },
    },
    "& .help-center-content": {
        margin: "0 auto",
    },
};

import { message } from "antd";
import axios from "axios";

export const addProductToSave =
    (product, user_uuid) => (dispatch, getState) => {
        const existing = getState().saveReducer.manageSave.saveProducts.filter(
            (p) => p.product.id === product.id
        ).length;
        let products = [...getState().saveReducer.manageSave.saveProducts];
        if (existing === 0) {
            products = [{ product, quantity: 1 }, ...products];
        }
        if (existing === 1) {
            let _product = products.find((p) => p.product.id === product.id);
            const index = products.indexOf(_product);
            const filtered =
                getState().saveReducer.manageSave.saveProducts.filter(
                    (p) => p.product.id !== product.id
                );
            _product.quantity++;
            filtered.splice(index, 0, _product);
        }

        if (user_uuid) {
            axios
                .post(`/api/save/add/${user_uuid}/${product.uuid}`)
                .then((response) => {
                    message.success(response.data.message, 3);
                })
                .catch((error) => {
                    message.error(error?.response?.data?.message, 5);
                });
        }

        dispatch({ type: "ADD_PRODUCT_TO_SAVE", payload: products });
    };

export const removeProductFromSave =
    (product, user_uuid) => (dispatch, getState) => {
        const existing = getState().saveReducer.manageSave.saveProducts.find(
            (p) => p.product.id === product.id
        );
        let products = [...getState().saveReducer.manageSave.saveProducts];
        if (existing.quantity === 1) {
            products = products.filter((p) => p.product.id !== product.id);
        }
        if (existing.quantity > 1) {
            let _product = products.find((p) => p.product.id === product.id);
            _product.quantity--;
        }

        if (user_uuid) {
            axios
                .delete(`/api/save/del_one/${user_uuid}/${product.uuid}`)
                .then((response) => {
                    message.success(response?.data?.message, 3);
                })
                .catch((error) => {
                    message.error(error?.response?.data?.message, 5);
                });
        }

        dispatch({ type: "REMOVE_PRODUCT_FROM_SAVE", payload: products });
    };

export const removeProductsFromSave =
    (product, user_uuid) => (dispatch, getState) => {
        let products = [...getState().saveReducer.manageSave.saveProducts];
        products = products.filter((p) => p.product.id !== product.id);

        if (user_uuid) {
            axios
                .delete(`/api/save/delete/${user_uuid}/${product.uuid}`)
                .then((response) => {
                    message.success(response?.data?.message, 3);
                })
                .catch((error) => {
                    message.error(error?.response?.data?.message, 5);
                });
        }

        dispatch({
            type: "REMOVE_PRODUCTS_FROM_SAVE",
            payload: products,
        });
    };

import {
    PSection,
    PBox,
    PContainer,
    PH2,
    PH3,
    PH4,
    PText,
    PUl,
    PLi,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";

export const Privacy = (props) => {
    return (
        <Layout>
            <PSection css={styles}>
                <PBox className="privacy">
                    <PBox className="privacy-title">
                        <PH2>Privacy Policy</PH2>
                    </PBox>
                    <PBox>
                        <PText>
                            <i>Last updated: Dec 6th, 2023</i>
                        </PText>
                        <PText>
                            Phair Labs LLC ("<b>Phair</b>", "<b>we</b>", "
                            <b>our</b>
                            ", and/or "<b>us</b>") value the privacy of
                            individuals who use our website at phair.io, as well
                            as other related websites, applications, products
                            and services that link to this Privacy Policy
                            (collectively, our "<b>Services</b>"). This privacy
                            policy (the "<b>Privacy Policy</b>") explains how we
                            collect, use, and share information from users of
                            our Services ("
                            <b>Users</b>"). As used in this Privacy Policy,
                            "Personal Information" means any information
                            relating to an identified or identifiable
                            individual. By using our Services, you agree to the
                            collection, use, disclosure, and procedures this
                            Privacy Policy describes. Beyond the Privacy Policy,
                            your use of our Service is also subject to our Terms
                            and Conditions and our California Resident Privacy
                            Notice.
                        </PText>
                        <PH3>Information We Collect</PH3>
                        <PText>
                            We may collect a variety of information from or
                            about you or your devices from various sources, as
                            described below.
                        </PText>
                        <PText>
                            If you do not provide your information when
                            requested, you may not be able to use our Services
                            if that information is necessary to provide you with
                            our Services or if we are legally required to
                            collect it.
                        </PText>
                        <PH4>A. Information You Provide to Us</PH4>
                        <PText>
                            <b>Registration and Profile Information</b>. When
                            you sign up for an account, register to use our
                            Services, or sign up for notifications or updates,
                            we ask you for your Personal Information, including
                            your email address.
                        </PText>
                        <PText>
                            <b>Communications</b>. If you contact us directly,
                            we may receive additional Personal Information about
                            you. For example, when you contact us for customer
                            support, we may receive your name, email address,
                            phone number, the contents of the messages or
                            attachments that you may send to us, and other
                            information you choose to provide. If you subscribe
                            to our newsletter, then we will collect your email
                            address. When we send you emails, we may track
                            whether you open them to learn how to deliver a
                            better customer experience and improve our Services.
                        </PText>
                        <PText>
                            <b>Payment Information</b>. When you make a payment
                            to Phair, your credit card information, debit card
                            information, billing information, and any other
                            financial information necessary to complete your
                            payment (“Payment Information”) is processed by our
                            third-party payment processor (Stripe), and we do
                            not collect, store, or process your Payment
                            Information. For more information, please see the
                            Payment Processing section below.
                        </PText>
                        <PH4>
                            B. Information We Collect When You Use Our Services
                        </PH4>
                        <PText>
                            <b>Location Information</b>. When you use our
                            Services, we may infer your general location
                            information (for example, your IP address may
                            indicate your general geographic region).
                        </PText>
                        <PText>
                            <b>Usage Information</b>. To help us understand how
                            you use our Services and to help us improve them, we
                            automatically receive information about your
                            interactions with our Services, such as the pages or
                            other content you view, the searches you conduct,
                            telemetry data, and the dates and times of your
                            visits.
                        </PText>
                        <PText>
                            <b>Analytics Partners</b>. We use an analytics
                            service, Google Analytics, to collect and process
                            certain analytics data. The service may also collect
                            information about your use of other websites, apps,
                            and online resources. You can learn about Google's
                            practices by going to{" "}
                            <a
                                href="https://www.google.com/policies/privacy/partners/"
                                target="_blank"
                            >
                                https://www.google.com/policies/privacy/partners/
                            </a>{" "}
                            and opt out of them by downloading the Google
                            Analytics Opt-out Browser Add-on, available at{" "}
                            <a
                                href="https://tools.google.com/dlpage/gaoptout"
                                target="_blank"
                            >
                                https://tools.google.com/dlpage/gaoptout
                            </a>
                            .
                        </PText>
                        <PText>
                            <b>
                                Information from Cookies and Similar
                                Technologies
                            </b>
                            . We and our third-party partners may collect
                            Personal Information using cookies, which are small
                            files of letters and numbers that we store on your
                            browser or the hard drive of your computer. They
                            contain information that is transferred to your
                            computer's hard drive. We and our third-party
                            partners may also use pixel tags and web beacons on
                            our Services. These are tiny graphic images placed
                            on web pages or in our emails that allow us to
                            determine whether you have performed a specific
                            action. We use cookies, beacons, invisible tags, and
                            similar technologies (collectively “Cookies”) to
                            collect information about your browsing activities
                            and to distinguish you from other users of our
                            Services. This aids your experience when you use our
                            Services and allows us to improve the functionality
                            of our Services. Cookies can be used for performance
                            management (i.e., collecting information on how our
                            Services are being used for analytics purposes).
                            They can also be used for functionality management,
                            enabling us to make your visit more efficient by,
                            for example, remembering passwords, and log-in
                            details. Below is an overview of the types of
                            Cookies we and third parties may use to collect
                            Personal Information.
                        </PText>
                        <PUl>
                            <PLi>
                                <b>Strictly Necessary Cookies</b>. Some Cookies
                                are strictly necessary to make our Services
                                available to you. We cannot provide you with our
                                Services without this type of Cookies. We use
                                the following necessary Cookies:
                            </PLi>
                            <PText>
                                Name: Phair Authenticator <br />
                                More Information: Internal company authenticator
                            </PText>
                            <PLi>
                                <b>Functional Cookies</b>. Functional Cookies
                                are used to recognize you when you return to our
                                Services. This enables us to adapt our content
                                for you and remember your preferences. For
                                example, we use functional Cookies to remember
                                your choice of language or region. We use the
                                following functional Cookies:
                            </PLi>
                            <PText>
                                Name: Stripe Session ID <br />
                                More Information: To provide fraud prevention
                            </PText>
                            <PLi>
                                <b>Analytical or Performance Cookies</b>. We
                                also use Cookies for website analytics purposes
                                to operate, maintain and improve our Services.
                                We may use our own analytics Cookies or use
                                third-party analytics providers such as Google
                                Analytics. Google uses the data collected to
                                track and monitor the use of our Service. For
                                more information on the privacy practices of
                                Google, please visit the Google Privacy Terms
                                web page:
                                <a
                                    href="https://policies.google.com/privacy?hl=en"
                                    target="_blank"
                                >
                                    https://policies.google.com/privacy?hl=en
                                </a>
                                . We also encourage you to review the Google's
                                policy for safeguarding your data:
                                <a
                                    href="https://support.google.com/analytics/answer/6004245"
                                    target="_blank"
                                >
                                    https://support.google.com/analytics/answer/6004245
                                </a>
                                . These providers may also collect information
                                about your use of other websites, apps, and
                                online resources. We also use the following
                                analytical or performance Cookies:
                            </PLi>
                            <PText>
                                Name: Google Analytics ID <br />
                                More Information: To store and count page views
                            </PText>
                        </PUl>
                        <PText>
                            Please review your web browser's "Help" file to
                            learn the proper way to modify your cookie settings.
                            Please note that if you delete or choose not to
                            accept cookies from our Services, you may not be
                            able to utilize the features of our Services to
                            their fullest potential. You can find more
                            information about your rights and choices, and how
                            to block the use of certain Cookies in the section
                            Your Rights and Choices below.
                        </PText>
                        <PH4>C. Payment Processing</PH4>
                        <PText>
                            When you make purchases through our Services, your
                            payment is processed by a third-party payment
                            processor Stripe ("<b>Payment Processor</b>"). This
                            Payment Processor may collect Payment Information
                            and other Personal Information from you, including
                            your name, email address, and billing address. The
                            use of your Personal Information by the Payment
                            Processor is governed by its terms and conditions
                            and privacy policy, and Personal Information we
                            receive about you from our Payment Processor is
                            governed by this Privacy Policy.
                        </PText>
                        <PH4>D. Information We Receive from Third Parties</PH4>
                        <PText>
                            <b>Information From Third-Party Services</b>. If you
                            choose to link our Services to a third-party
                            account, such as Google we may receive Personal
                            Information about you including your profile
                            information and your photo, and your use of the
                            third-party account. If you wish to limit the
                            information available to us, you should visit the
                            privacy settings of your third-party accounts to
                            learn about your options.
                        </PText>
                        <PText>
                            <b>Other Third Parties</b>. We may receive
                            additional information about you, such as
                            demographic data, from third parties such as data or
                            marketing partners and combine it with other
                            information we have about you.
                        </PText>
                        <PH3>How We Use the Information We Collect</PH3>
                        <PText>
                            We use the Personal Information we collect:
                        </PText>
                        <PUl>
                            <PLi>
                                To provide, maintain, improve, and enhance our
                                Services;
                            </PLi>
                            <PLi>
                                To personalize your experience on our Services
                                such as by providing tailored content;
                            </PLi>
                            <PLi>
                                To understand and analyze how you use our
                                Services and develop new products, services,
                                features, and functionalities;
                            </PLi>
                            <PLi>
                                To communicate with you, provide you with
                                updates and other information relating to our
                                Services, provide information that you request,
                                respond to comments and questions, and otherwise
                                provide customer support;
                            </PLi>
                            <PLi>
                                To send you SMS messages and push notifications;
                            </PLi>
                            <PLi>
                                For marketing and advertising purposes, such as
                                developing and providing promotional and
                                advertising materials that may be relevant,
                                valuable, or otherwise of interest to you;
                            </PLi>
                            <PLi>
                                In de-identified and aggregated form for any
                                lawful purpose;
                            </PLi>
                            <PLi>
                                To facilitate transactions and payments; To find
                                and prevent fraud, and respond to trust and
                                safety issues that may arise;
                            </PLi>
                            <PLi>
                                For compliance purposes, including enforcing our
                                Terms of Use or other legal rights, or as may be
                                required by applicable laws and regulations or
                                requested by any judicial process or
                                governmental agency; and
                            </PLi>
                            <PLi>
                                For other purposes for which we provide specific
                                notice at the time the Personal Information is
                                collected.
                            </PLi>
                        </PUl>
                        <PH3>How We Share the Information We Collect</PH3>
                        <PText>
                            We do not share or otherwise disclose the Personal
                            Information we collect from you except as described
                            below or otherwise disclosed to you at the time of
                            the collection.
                        </PText>
                        <PText>
                            <b>Partners and Affiliates</b>. We may share any
                            information with our partners, corporate affiliates,
                            parents, or subsidiaries for any purpose described
                            in this Privacy Policy.
                        </PText>
                        <PText>
                            <b>Vendors and Service Providers</b>. We may share
                            any information we receive with vendors and service
                            providers retained in connection with the provision
                            of our Services.
                        </PText>
                        <PText>
                            <b>Marketing</b>. We do not rent, sell, or share
                            information about you with non-affiliated companies
                            for their direct marketing purposes unless we have
                            your permission.
                        </PText>
                        <PText>
                            <b>Analytics Partners</b>. We use an analytics
                            service, Google Analytics, to collect and process
                            certain analytics data. The service may also collect
                            information about your use of other websites, apps,
                            and online resources. You can learn about Google's
                            practices by going to{" "}
                            <a
                                href="https://www.google.com/policies/privacy/partners/"
                                target="_blank"
                            >
                                https://www.google.com/policies/privacy/partners/
                            </a>{" "}
                            and opt out of them by downloading the Google
                            Analytics Opt-out Browser Add-on, available at{" "}
                            <a
                                href="https://tools.google.com/dlpage/gaoptout"
                                target="_blank"
                            >
                                https://tools.google.com/dlpage/gaoptout
                            </a>
                            .
                        </PText>
                        <PText>
                            <b>As Required by Law and Similar Disclosures</b>.
                            We may access, preserve, and disclose your Personal
                            Information if we believe doing so is required or
                            appropriate to: (i) comply with law enforcement
                            requests and legal process, such as a court order or
                            subpoena; (ii) respond to your requests; or (iii)
                            protect your, our, or others' rights, property, or
                            safety. For the avoidance of doubt, the disclosure
                            of your information may occur if you post any
                            objectionable content on or through our Services.
                        </PText>
                        <PText>
                            <b>Merger, Sale, or Other Asset Transfers</b>. We
                            may disclose and transfer your Personal Information
                            to service providers, advisors, potential
                            transactional partners, or other third parties in
                            connection with the consideration, negotiation, or
                            completion of a corporate transaction in which we
                            are acquired by or merged with another company or we
                            sell, liquidate, or transfer all or a portion of our
                            business or assets.
                        </PText>
                        <PText>
                            <b>Consent</b>. We may also disclose Personal
                            Information from or about you or your devices with
                            your permission.
                        </PText>
                        <PH3>Your Choices</PH3>
                        <PText>
                            <b>Marketing Communications</b>. You can unsubscribe
                            from our promotional emails via the link provided in
                            the emails. Even if you opt out of receiving
                            promotional messages from us, you will continue to
                            receive administrative messages from us.
                        </PText>
                        <PText>
                            <b>Do Not Track</b>. There is no accepted standard
                            on how to respond to Do Not Track signals, and we do
                            not respond to such signals.
                        </PText>
                        <PText>
                            If you choose not to provide us with the Personal
                            Information we collect, some features of our
                            Services may not work as intended.
                        </PText>
                        <PText>
                            <b>How to Manage Cookies</b>. You can block Cookies
                            by setting your internet browser to block some or
                            all Cookies. However, if you use your browser
                            settings to block all Cookies (including essential
                            Cookies) you may not be able to access all or parts
                            of our Services. By using our Services, you consent
                            to our use of Cookies and our processing of Personal
                            Information collected through such Cookies, in
                            accordance with this Privacy Policy. You can
                            withdraw your consent at any time by deleting placed
                            Cookies and disabling Cookies in your browser, or as
                            explained below. You can change your browser
                            settings to block or notify you when you receive a
                            Cookie, delete Cookies or browse our Services using
                            your browser's anonymous usage setting. Please refer
                            to your browser instructions or help screen to learn
                            more about how to adjust or modify your browser
                            settings. If you do not agree to our use of Cookies
                            or similar technologies which store information on
                            your device, you should change your browser settings
                            accordingly. You should understand that some
                            features of our Services may not function properly
                            if you do not accept Cookies or these technologies.
                            Where required by applicable law, you will be asked
                            to consent to certain Cookies and similar
                            technologies before we use or install them on your
                            computer or other device.
                        </PText>
                        <PH3>Third Parties</PH3>
                        <PText>
                            Our Services may contain links to other websites,
                            products, or services that we do not own or operate
                            ("
                            <b>Third-Party Services</b>"). We are not
                            responsible for the privacy practices of these
                            Third-Party Services. Please be aware that this
                            Privacy Policy does not apply to your activities on
                            these Third-Party Services or any information you
                            disclose to these Third-Party Services. If you have
                            any questions about how these other sites use your
                            Personal Information, you should contact them
                            directly. We encourage you to read their privacy
                            policies before providing any information to them.
                        </PText>
                        <PH3>Retention</PH3>
                        <PText>
                            We take measures to delete your Personal Information
                            or keep it in a form that does not permit
                            identifying you when such information is no longer
                            necessary for the purposes for which we process it
                            unless we are required by law to keep this
                            information for a longer period. When we process
                            Personal Information for our own purposes, we
                            determine the retention period taking into account
                            various criteria, such as the type of services
                            provided to you, the nature and length of our
                            relationship with you, possible re-enrollment with
                            our Services, the impact on our Services we provide
                            to you if we delete some Personal Information from
                            or about you, and mandatory retention periods
                            provided by law and the statute of limitations.
                        </PText>
                        <PH3>Security</PH3>
                        <PText>
                            We make reasonable efforts to protect your Personal
                            Information by using physical and electronic
                            safeguards designed to improve the security of the
                            Personal Information we maintain. However, as no
                            electronic transmission or storage of Personal
                            Information can be entirely secure, we can make no
                            guarantees as to the security or privacy of your
                            Personal Information.
                        </PText>
                        <PH3>International Visitors</PH3>
                        <PText>
                            Our Services are hosted in the United States
                            (“U.S.”) and intended for visitors located within
                            the U.S. If you choose to use our Services from
                            Europe or other regions of the world with laws
                            governing data collection and use that may differ
                            from U.S. law, then please note that you are
                            transferring your Personal Information outside of
                            those regions to the U.S. for storage and
                            processing. We may transfer your Personal
                            Information from Europe to the U.S. and other third
                            countries based on European Commission-approved
                            Standard Contractual Clauses, as needed to perform
                            our services that you have requested from us, or
                            with your consent. Also, we may transfer your
                            Personal Information from the U.S. to other
                            countries or regions in connection with storage and
                            processing of data, fulfilling your requests, and
                            operating our Services. By providing any
                            information, including Personal Information, on or
                            to our Services, you consent to such transfer,
                            storage, and processing.
                        </PText>
                        <PH3>Children's Privacy</PH3>
                        <PText>
                            We do not knowingly collect, maintain, or use
                            Personal Information from children under 18 years of
                            age ("
                            <b>Minors</b>"), and no parts of our Services are
                            directed to Minors. If you learn that a Minor has
                            provided us with Personal Information in violation
                            of this Privacy Policy, then you may alert us at
                            support@phair.io.
                        </PText>
                        <PH3>Changes to this Privacy Policy</PH3>
                        <PText>
                            We will post any adjustments to the Privacy Policy
                            on this page, and the revised version will be
                            effective when it is posted. If we materially change
                            the ways in which we use or share Personal
                            Information previously collected from you through
                            our Services, we will notify you through our
                            Services, by email, or other communication.
                        </PText>
                        <PH3>Contact Information</PH3>
                        <PText>
                            If you have any questions, comments, or concerns
                            about our processing activities, please email us at
                            support@phair.io.
                        </PText>
                    </PBox>
                </PBox>
            </PSection>
        </Layout>
    );
};

const styles = {
    color: "rgb(75 85 99)",
    lineHeight: "1.8",
    maxWidth: "56rem",
    padding: "0 2rem",
    margin: "4rem auto",
    "& .privacy": {
        marginBottom: "8rem",
    },
    "& .privacy-title": {
        margin: "4rem 0",
        "& h2": {
            lineHeight: "2.5rem",
            fontWeight: "700",
            fontSize: "2.5rem",
            color: "rgb(17 24 39)",
        },
    },
    "& a": {
        color: "$colors$primary",
        textDecoration: "none",
        fontWeight: "500",
    },
};

import { PText } from "../../theme/BaseTheme";

export const FormLabel = (props) => {
    return (
        <PText>
            {props.label.is_required && <span style={{ color: "red" }}>*</span>}{" "}
            <b>{props.label.title}</b> - {props.label.description}
        </PText>
    );
};

import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { PBox, PLink } from "../../theme/BaseTheme";
import { addProductToSave } from "../../../actions/save";

class SaveProduct extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles} className="save-product">
                <PLink
                    className="save-product-button"
                    onClick={() =>
                        this.props.addProductToSave(
                            this.props.product,
                            this.props.userAuth?.userAccount?.uuid
                        )
                    }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 256"
                        width="24"
                        height="24"
                    >
                        <path d="M240,94c0,70-103.79,126.66-108.21,129a8,8,0,0,1-7.58,0C119.79,220.66,16,164,16,94A62.07,62.07,0,0,1,78,32c20.65,0,38.73,8.88,50,23.89C139.27,40.88,157.35,32,178,32A62.07,62.07,0,0,1,240,94Z" />
                    </svg>
                    Add To Favorites
                </PLink>
            </PBox>
        );
    }
}

const styles = {
    "& .save-product-button": {
        cursor: "pointer",
        border: "1px solid $colors$borderColor",
        borderRadius: "24px",
        padding: "8px 18px",
        fontWeight: "500",
        fontSize: "1rem",
        color: "$colors$primary",
        width: `calc(100% - 40px)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "& svg": {
            //fill: "rgb(255, 77, 79)",
            fill: "$colors$primary",
            marginRight: "10px",
        },
        "&:hover": {
            borderColor: "$colors$primary",
            //backgroundColor: "$colors$background",
        },
    },
};

function mapStateToProps(state) {
    return { userAuth: state.authReducer?.userAuth };
}

export default connect(mapStateToProps, { addProductToSave })(
    withRouter(SaveProduct)
);

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AccountForm from "../../components/seller/AccountForm";
import { Layout } from "./Layout";
import { PSection, PBox, PContainer, PH1, PText } from "../../theme/BaseTheme";
import { Loader } from "../../components/Loader";
import { fetchUserAccount } from "../../../actions/account";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchUserAccount(
                this.props.match.params.user_uuid,
                this.props.history
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PSection css={styles}>
                    <PBox className="profile">
                        <PContainer>
                            <PBox className="profile-title">
                                <PH1>Profile</PH1>
                                <PText>
                                    Manage your personal information and
                                    password.
                                </PText>
                            </PBox>
                            <AccountForm />
                        </PContainer>
                    </PBox>
                </PSection>
            </Layout>
        );
    }
}

const styles = {
    margin: "0 auto",
    padding: "6rem 2rem",
    maxWidth: "64rem",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",

    "& .profile-title": {
        margin: "0 auto 4rem 0",
        "& h1": {
            fontSize: "1.875rem",
            lineHeight: "2.25rem",
            color: "rgb(17 24 39)",
            fontWeight: "700",
            margin: "0",
        },
        "& p": {
            color: "rgb(107 114 128)",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            marginTop: "0.5rem",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.accountReducer?.fetchUserAccount.userAccount,
    };
}

export default connect(mapStateToProps, { fetchUserAccount })(
    withRouter(Profile)
);

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const GoPublic = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How to get my products to go public?</PH1>
                <PText>
                    In the creating or editing a product page, setting the
                    status to be <b>public</b> will get your product to go
                    public and to be shown in the Phair marketplace.
                </PText>
            </PBox>
            <HelpNav
                prev={"Edit a product"}
                prev_link={"/help/edit-a-product"}
                next={"Delete a product"}
                next_link={"/help/delete-a-product"}
            />
        </Layout>
    );
};

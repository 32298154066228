import {
    PBox,
    PHighlightButton,
    PHighlightLink,
    PH4,
    PImg,
    PLink,
    PText,
} from "../../theme/BaseTheme";
import { Space, Popconfirm } from "antd";
import { Rating } from "../landing/Rating";

export const EditableRow = (props) => {
    return (
        <PBox css={styles}>
            <PBox className="item-row">
                <PBox className="item-cover">
                    <PImg alt="image" src={props.img} />
                </PBox>
                <PBox className="item-desc">
                    <PH4 className="item-title">{props.title}</PH4>

                    <PText className="item-highlights">
                        {props.product_highlights}
                    </PText>
                    <PBox className="item-price">
                        ${props.price}{" "}
                        <svg viewBox="0 0 2 2" class="nc rd aol">
                            <circle cx="1" cy="1" r="1"></circle>
                        </svg>{" "}
                        {props.number_of_orders} orders{" "}
                        <svg viewBox="0 0 2 2" class="nc rd aol">
                            <circle cx="1" cy="1" r="1"></circle>
                        </svg>{" "}
                        ${props.revenue} earned{" "}
                        <svg viewBox="0 0 2 2" class="nc rd aol">
                            <circle cx="1" cy="1" r="1"></circle>
                        </svg>{" "}
                        {props.status}
                    </PBox>
                </PBox>
                <PBox className="item-buttons">
                    {props.status === "public" && (
                        <PLink href={`/item/${props.uuid}`} target="_blank">
                            View
                        </PLink>
                    )}
                    <PLink
                        href={`/seller/${props.shop_uuid}/item/${props.uuid}/edit`}
                    >
                        Edit
                    </PLink>

                    <Popconfirm
                        title="Delete the product"
                        description="Are you sure to delete this product? This action cannot be undone."
                        onConfirm={() => {
                            props
                                .deleteProduct(props.shop_uuid, props.uuid)
                                .then(() => {
                                    window.location.reload(true);
                                });
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <PLink>Delete</PLink>
                    </Popconfirm>
                </PBox>
            </PBox>
        </PBox>
    );
};

const styles = {
    borderTop: "1px solid $colors$borderColor",
    "& .item-row": {
        backgroundColor: "#fff",
        padding: "2rem 1rem",
        display: "flex",
        //boxShadow: "rgba(47, 83, 109, 0.12) 0px 3px 16px",
    },
    "& .item-cover": {
        display: "block",
        position: "relative",
        width: "6rem",
        height: "6rem",
        margin: "0 auto",
        borderRadius: "0.5rem",
        flexShrink: "0",
        overflow: "hidden",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        "& .item-cover-buttons": {
            cursor: "pointer",
            position: "absolute",
            right: "13px",
            top: "11px",
            backgroundColor: "#14141f",
            padding: "2px 11px",
            borderRadius: "8px",
            fontWeight: "300px",
            fontSize: "14px",
            lineHeight: "24px",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            "& svg": {
                fill: "#fff",
                margin: "0 4px 0 0",
                display: "block",
                padding: "0",
            },
            "& svg:hover": { fill: "$colors$secondary" },
        },
    },
    "& .item-buttons": {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "start",
        "& a": {
            marginBottom: "0.25rem",
            padding: "0.25rem 1rem",
            color: "rgb(17 24 39)",
            fontWeight: "600",
            border: "1px solid $colors$borderColor",
            textDecoration: "none",
            cursor: "pointer",
            borderRadius: "0.375rem",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
        },
        "& a:hover": {
            backgroundColor: "#f3f4f6",
        },
    },
    "& .item-desc": {
        flexGrow: "1",
        margin: "0 0.5rem",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
    },
    "& .item-title": {
        display: "block",
        textOverflow: "ellipsis",
        padding: "0 0.5rem",
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: "rgb(17 24 39)",
        margin: "0",
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": "3",
        "& a": {
            color: "$colors$text",
            textDecoration: "none",
        },
    },
    "& .item-highlights": {
        padding: "0",
        margin: "0.5rem",
        color: "rgb(107, 114, 128)",
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": "3",
    },
    "& .item-rating": {
        paddingLeft: "8px",
        paddingRight: "8px",
        marginTop: "0.75rem",
        marginBottom: "12px",
    },
    "& .item-price": {
        lineHeight: "1.3",
        margin: "0.5rem 0.5rem",
        display: "flex",
        alignItems: "center",
        color: "rgb(17 24 39)",
        fontWeight: "500",
        "& a": {
            textDecoration: "none",
            color: "$colors$primary",
            "&:hover": {
                color: "$colors$primary500",
                fontWeight: "500",
            },
        },
        "& b": {
            fontWeight: "600",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: "rgb(17 24 39)",
        },
        "& svg": {
            width: "0.125rem",
            height: "0.125rem",
            display: "block",
            verticalAlign: "middle",
            margin: "0 0.25rem",
        },
    },
    "& .item-status": {
        color: "$colors$text",
        paddingLeft: "10px",
        paddingRight: "10px",
        margin: "0 0 12px 0",
    },
};

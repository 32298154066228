import clsx from "clsx";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
} from "../theme/BaseTheme";

export const SellHow = () => {
    return (
        <PSection css={styles}>
            <PBox className="sell-how">
                <PBox className="sell-how-container">
                    <PText className="sell-how-subtitle">
                        Get Started in Minutes
                    </PText>
                    <PH1 className="sell-how-title">
                        Jumpstart Your Selling <br /> Adventure with Ease
                    </PH1>

                    <PBox className="sell-how-steps-wrapper">
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-1"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M232,96a7.89,7.89,0,0,0-.3-2.2L217.35,43.6A16.07,16.07,0,0,0,202,32H54A16.07,16.07,0,0,0,38.65,43.6L24.31,93.8A7.89,7.89,0,0,0,24,96v16a40,40,0,0,0,16,32v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V144a40,40,0,0,0,16-32ZM54,48H202l11.42,40H42.61Zm50,56h48v8a24,24,0,0,1-48,0Zm-16,0v8a24,24,0,0,1-48,0v-8ZM200,208H56V151.2a40.57,40.57,0,0,0,8,.8,40,40,0,0,0,32-16,40,40,0,0,0,64,0,40,40,0,0,0,32,16,40.57,40.57,0,0,0,8-.8Zm-8-72a24,24,0,0,1-24-24v-8h48v8A24,24,0,0,1,192,136Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>1. Open a Store</PH3>
                            <PText>
                                Fill out basic information and open your store
                                in less than 10 minutes.
                            </PText>
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-2"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-90.34-5.66a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32L144,147.31V208a8,8,0,0,0,16,0V147.31l18.34,18.35a8,8,0,0,0,11.32-11.32Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>2. Upload Products</PH3>
                            <PText>
                                Add images and descriptions to help buyers know
                                the details.
                            </PText>
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-3"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M149.61,85.71l-89.6,88a8,8,0,0,1-11.22,0L10.39,136a8,8,0,1,1,11.22-11.41L54.4,156.79l84-82.5a8,8,0,1,1,11.22,11.42Zm96.1-11.32a8,8,0,0,0-11.32-.1l-84,82.5-18.83-18.5a8,8,0,0,0-11.21,11.42l24.43,24a8,8,0,0,0,11.22,0l89.6-88A8,8,0,0,0,245.71,74.39Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>3. Go Public</PH3>
                            <PText>
                                Set your product status to public and your
                                product will go live instantly.
                            </PText>
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-4"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M239.37,70.1A13.16,13.16,0,0,0,227.9,61l-37.22-3.15L176.16,24a13.24,13.24,0,0,0-24.31,0L137.33,57.86,100.1,61a13.13,13.13,0,0,0-7.49,23.06l28.16,24-8.43,35.73a13.1,13.1,0,0,0,5,13.58,13.25,13.25,0,0,0,14.63.7l32-19,32,19a13.25,13.25,0,0,0,14.63-.7,13.09,13.09,0,0,0,5-13.58l-8.43-35.73,28.15-24A13.07,13.07,0,0,0,239.37,70.1Zm-43.86,27a13.06,13.06,0,0,0-4.26,13l7.31,31-27.78-16.51a13.24,13.24,0,0,0-13.56,0L129.44,141l7.31-31a13,13,0,0,0-4.25-13L108.24,76.38l32.09-2.72a13.16,13.16,0,0,0,11-7.94L164,36.24l12.64,29.48a13.18,13.18,0,0,0,11,7.94l32.09,2.72ZM85.66,125.66l-56,56a8,8,0,0,1-11.32-11.32l56-56a8,8,0,0,1,11.32,11.32Zm16,56-56,56a8,8,0,0,1-11.32-11.32l56-56a8,8,0,0,1,11.32,11.32Zm72-11.32a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32-11.32l56-56A8,8,0,0,1,173.66,170.34Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>4. Start Earning</PH3>
                            <PText>
                                Connect to your Stripe account and receive your
                                payouts.
                            </PText>
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-how": {
        padding: "0 1.5rem",
        maxWidth: "80rem",
        margin: "8rem auto",
        "@bp2": { marginTop: "10rem" },
        "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    },
    "& .sell-how-container": {
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
    },
    "& .sell-how-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .sell-how-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.5rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "3rem" },
    },
    "& .sell-how-text": {
        fontSize: "20px",
        lineHeight: "1.7",
        color: "#293745",
        textAlign: "center",
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "80px",
    },
    "& .sell-how-steps-wrapper": {
        position: "relative",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        maxWidth: "80rem",
        marginTop: "4rem",
        marginLeft: "auto",
        marginRight: "auto",

        overflow: "hidden",
        display: "grid",
        maxWidth: "42rem",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gap: "2rem",
        "@bp4": {
            paddingLeft: "2rem",
            paddingRight: "2rem",
            maxWidth: "none",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "6rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
    },
    "& .sell-how-steps-item": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        "& h3": {
            marginBottom: "0",
        },
        "& p": {
            lineHeight: "1.5",
        },
        "& svg": {
            width: "1.75rem",
            height: "1.75rem",
            padding: "0rem",
            borderRadius: "50%",
            fill: "$colors$primary",
            backgroundColor: "#fff",
        },
    },
    "& .sell-how-steps-step": {
        display: "flex",
        position: "relative",
        width: "100%",
    },
    "& .sell-how-steps-line": {
        width: "auto",
        height: "1px",
        position: "static",
        backgroundColor: "$colors$borderColor",
        marginLeft: "1rem",
        transform: "translate(0, 0.875rem)",
        flex: "1 1 auto",
    },
};

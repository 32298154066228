import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PHighlightButton,
    PText,
    PBox,
    PLink,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { userSessionExpired } from "../../../actions/auth";

class SessionExpired extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.userSessionExpired();
    }

    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles}>
                    <PBox className="title">
                        <PH2>Session Expired</PH2>
                    </PBox>
                    <PBox className="session-expired">
                        <PBox className="session-expired-title">
                            <PBox>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="64"
                                    height="64"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M136,80v43.47l36.12,21.67a8,8,0,0,1-8.24,13.72l-40-24A8,8,0,0,1,120,128V80a8,8,0,0,1,16,0Zm-8-48A95.44,95.44,0,0,0,60.08,60.15C52.81,67.51,46.35,74.59,40,82V64a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16H49c7.15-8.42,14.27-16.35,22.39-24.57a80,80,0,1,1,1.66,114.75,8,8,0,1,0-11,11.64A96,96,0,1,0,128,32Z"></path>
                                </svg>
                            </PBox>
                            <PText>
                                Your session has expired, please sign in again.
                            </PText>
                        </PBox>
                        <PHighlightButton
                            type="primary"
                            size="large"
                            className="sign-in-button"
                            style={{}}
                            onClick={() => {
                                this.props.history.push("/sign-in");
                            }}
                        >
                            Sign In
                        </PHighlightButton>
                    </PBox>
                    <PBox className="home-link">
                        <PText>
                            Go back to <PLink href="/">Home</PLink>
                        </PText>
                    </PBox>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .session-expired": {
        width: "400px",
        marginTop: "2rem",
        backgroundColor: "#fff",
        padding: "3rem 2rem 2rem 2rem",
        borderRadius: "0.5rem",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    },
    "& .title": {
        fontSize: "18px",
        marginTop: "2rem",
    },
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        textDecoration: "none",
        "&:hover": {
            color: "#000",
            cursor: "pointer",
        },
    },
    "& .session-expired-title": {
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h2": {
            fontWeight: "400",
        },
        "& svg": {
            backgroundColor: "#fff",
            borderRadius: "100px",
            fill: "$colors$primary",
            marginBottom: "2rem",
        },
    },
    "& .sign-in-button": {
        color: "#fff",
        backgroundColor: "$colors$primary700",
        width: "100%",
        borderColor: "$colors$primary700",
        marginTop: "16px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "$colors$primary500",
            borderColor: "$colors$primary500",
        },
        margin: "20px 0",
    },
    "& .home-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};

export default connect(null, { userSessionExpired })(
    withRouter(SessionExpired)
);

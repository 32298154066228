import { ConfigProvider } from "antd";
import { BaseTheme, PText, PBox } from "../../theme/BaseTheme";
import { Logo } from "../../components/Logo";

export const AuthenticationLayout = (props) => {
    const year = new Date().getFullYear();
    return (
        <div className={BaseTheme}>
            <ConfigProvider theme={{ token: { colorPrimary: "#6d28d9" } }}>
                <PBox css={styles} className="authentication-layout">
                    {/*<PBox className="background-image-top">
                        <svg
                            id="visual"
                            viewBox="0 0 900 600"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                        >
                            <g transform="translate(0, 0)">
                                <path
                                    d="M216.3 0C215.7 30.5 215 61 199.9 82.8C184.8 104.6 155.2 117.6 132.9 132.9C110.6 148.3 95.6 165.9 74.6 180.2C53.7 194.4 26.8 205.4 0 216.3L0 0Z"
                                    fill="#f47f68"
                                ></path>
                            </g>
                        </svg>
                    </PBox>
                    <PBox className="background-image-bottom">
                        <svg
                            id="visual"
                            viewBox="0 0 900 600"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                        >
                            <g transform="translate(900, 600)">
                                <path
                                    d="M-216.3 0C-217.3 -32.4 -218.3 -64.8 -199.9 -82.8C-181.4 -100.8 -143.6 -104.5 -122.3 -122.3C-101.1 -140.2 -96.5 -172.3 -78.8 -190.3C-61.2 -208.4 -30.6 -212.3 0 -216.3L0 0Z"
                                    fill="#f47f68"
                                ></path>
                            </g>
                        </svg>
    </PBox>*/}

                    <Logo />
                    <PBox className="authentication-layout-content">
                        {props.children}
                    </PBox>
                    <PBox className="authentication-layout-footer">
                        <PText>
                            {/*@ {year} Phair.io{" "}
                            | Made with{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 256 256"
                                width="16px"
                                height="16px"
                            >
                                <path d="M240,94c0,70-103.79,126.66-108.21,129a8,8,0,0,1-7.58,0C119.79,220.66,16,164,16,94A62.07,62.07,0,0,1,78,32c20.65,0,38.73,8.88,50,23.89C139.27,40.88,157.35,32,178,32A62.07,62.07,0,0,1,240,94Z" />
                            </svg>
                            {" and "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 256 256"
                                width="18px"
                                height="18px"
                            >
                                <path d="M208,80H32a8,8,0,0,0-8,8v48a96.3,96.3,0,0,0,32.54,72H32a8,8,0,0,0,0,16H208a8,8,0,0,0,0-16H183.46a96.59,96.59,0,0,0,27-40.09A40,40,0,0,0,248,128v-8A40,40,0,0,0,208,80Zm24,48a24,24,0,0,1-17.2,23,95.78,95.78,0,0,0,1.2-15V97.38A24,24,0,0,1,232,120ZM112,56V24a8,8,0,0,1,16,0V56a8,8,0,0,1-16,0Zm32,0V24a8,8,0,0,1,16,0V56a8,8,0,0,1-16,0ZM80,56V24a8,8,0,0,1,16,0V56a8,8,0,0,1-16,0Z" />
                            </svg>{" "}
    by Phair Labs*/}
                        </PText>
                    </PBox>
                </PBox>
            </ConfigProvider>
        </div>
    );
};

const styles = {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    color: "$colors$text",
    position: "relative",
    fontFamily: "$fonts$body",
    backgroundColor: "#f9fafb",
    "& .background-image-top": {
        position: "absolute",
        left: "0",
        top: "0",
        zIndex: "1",
        "& svg": { display: "block", width: "80vw", height: "auto" },
    },
    "& .background-image-bottom": {
        position: "absolute",
        right: "0",
        bottom: "0",
        zIndex: "1",
        "& svg": { display: "block", width: "80vw", height: "auto" },
    },
    "& .logo": {
        margin: "60px 0 0px 0",
        zIndex: "10",
    },
    "& .authentication-layout-content": {
        zIndex: "10",
    },
    "& .authentication-layout-footer": {
        margin: "40px 0",
        "& p": {
            display: "flex",
            alignItems: "center",
            color: "$colors$text",
            justifyContent: "center",
            "& svg": {
                fill: "$colors$primary",
                margin: "0 4px",
            },
        },
    },
};

export const EditorModules = {
    toolbar: [
        [{ header: [] }],
        ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        [{ align: [] }],
        ["emoji"],
        ["link"],
    ],
    clipboard: {
        matchVisual: false,
    },
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
};

export const EditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "indent",
    "align",
    "link",
    "emoji",
];

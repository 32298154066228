import axios from "axios";
import { message } from "antd";

// For use in open shop flow
export const createMerchant =
    (value, user_uuid, history) => async (dispatch) => {
        await axios
            .post(`/api/merchants/create/${user_uuid}`, value)
            .then(async (response) => {
                dispatch({
                    type: "CREATE_MERCHANT_SUCCESS",
                    payload: response?.data,
                });
                if (response.data.redirect)
                    history.push(response.data.redirect);
                message.success(response.data.message, 3);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For use in merchant landing page
export const fetchMerchantToDisplay = (shop_uuid) => async (dispatch) => {
    await axios
        .get(`/api/merchants/display/${shop_uuid}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_MERCHANT_DISPLAY_SUCCESS",
                payload: response?.data,
                shop_uuid: shop_uuid,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// For use in seller dashboard to edit shop settings
export const fetchMerchantToEdit = (shop_uuid, history) => async (dispatch) => {
    await axios
        .get(`/api/merchants/${shop_uuid}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_MERCHANT_EDIT_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
            if (error?.response?.data?.redirect)
                history.push(error?.response?.data?.redirect);
        });
};

// For use in merchant dashboard shop settings page
export const editMerchant = (shop_uuid, data) => async (dispatch) => {
    await axios
        .put(`/api/merchants/edit/${shop_uuid}`, data)
        .then((response) => {
            message.success(response?.data?.message, 3);
            dispatch({
                type: "FETCH_MERCHANT_EDIT_SUCCESS",
                payload: response?.data?.merchant,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// For use in merchant dashboard page
export const fetchMerchantStatsByMerchantID =
    (shop_uuid, history) => async (dispatch) => {
        await axios
            .get(`/api/merchants/stats/${shop_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_MERCHANT_STATS_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
                if (error?.response?.data?.redirect)
                    history.push(error?.response?.data?.redirect);
            });
    };

// For use in landing page, showing top merchants / sellers
export const fetchTopMerchants = () => async (dispatch) => {
    await axios
        .get(`/api/merchants/top`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_TOP_MERCHANTS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const Fees = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>What fees do Phair charge?</PH1>
                <PText>
                    For sellers, we charge a 6% + $0.3 flat fee for each
                    transaction on Phair. Buyers will pay for the Stripe
                    transaction fees (2.9% + $0.3) during purchase.
                </PText>
            </PBox>
            <HelpNav
                prev={"Start selling on Phair"}
                prev_link={"/help/start-selling"}
                next={"Need help"}
                next_link={"/help/need-help"}
            />
        </Layout>
    );
};

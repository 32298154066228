import {
    PBox,
    PH1,
    PH2,
    PH3,
    PImg,
    PLi,
    PLink,
    POl,
    PText,
    PUl,
} from "../../../theme/BaseTheme";
import { BlogPostLayout } from "../BlogPostLayout";
import { keys } from "../../../../config/keys";

export const HowToSellOnPhair = () => {
    return (
        <BlogPostLayout category="Store Center">
            <PH1>So, You Wanna Sell on Phair? Let's Make It Happen!</PH1>
            <PBox className="blog-hero">
                <PText>Phair Team | Jan 3, 2024 | 5 min read</PText>
            </PBox>
            <PBox className="blog-banner-img">
                <PImg src={keys.imageBaseUrl + "start-selling.jpg"} />
            </PBox>
            <PH2>Hey Future Phair Star! 🌟</PH2>
            <PText>
                Hello there! Ready to jump into the world of selling on Phair?
                Super cool decision! Selling here isn't just about transactions;
                it's about being part of a vibrant digital marketplace and
                sharing your creativity with folks who'll love what you do. Let
                me guide you through getting started - it's easy and fun!
            </PText>
            <PH2>Step-by-Step: Becoming a Phair Seller</PH2>
            <PH3>1. Getting Onboard</PH3>
            <PUl>
                <PLi>
                    <b>Join the Gang:</b> First off, let's get you signed up.
                    Just swing by the Phair website and click on that sign-in
                    button. From there, you'll see a{" "}
                    <a href="/sign-up">Create an Account Now</a> link - give
                    that a click. Then, it's just a matter of filling out your
                    details in the <b>Register Account</b> form. Simple as that!
                </PLi>
            </PUl>
            <PImg
                src={keys.imageBaseUrl + "register.png"}
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PUl>
                <PLi>
                    <b>Open Your Store:</b> It's time to get your shop up and
                    running! Kick things off by following our straightforward
                    'Open a Store' process. Just click on{" "}
                    <a href="/sell">Become a Seller</a>, then hit 'Get Started'.
                    A nifty <b>Open a Store</b> flow will appear, guiding you
                    step-by-step to set up your storefront. It's super
                    intuitive! Once you complete the flow, your seller dashboard
                    will spring to life, ready for action.
                </PLi>
            </PUl>
            <PImg
                src={keys.imageBaseUrl + "open-store-flow.png"}
                style={{
                    width: "90%",
                    display: "block",
                    margin: "40px auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
            />
            <PH3>2. Setting Up Payments</PH3>
            <PUl>
                <PLi>
                    <b>Connect to Stripe:</b> To make sure you get your earnings
                    smoothly, connect your account to Stripe. In your seller
                    dashboard, you'll find <b>Payments</b> in the left menu.
                    Give that a click, then hit <b>Setup Payouts on Stripe</b>.
                    The onboarding process for Stripe is straightforward - just
                    enter your personal and business information as prompted
                    (check{" "}
                    <PLink href={"/blog/connect-to-stripe"} target="_blank">
                        here
                    </PLink>{" "}
                    for a step-by-step guide). This way, whenever you make a
                    sale, the payout heads straight to your bank account.
                    Cha-ching!
                </PLi>
            </PUl>
            <PImg
                src={keys.imageBaseUrl + "stripe-onboard.png"}
                style={{
                    width: "90%",
                    display: "block",
                    margin: "40px auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
            />
            <PH3>3. Listing Like a Pro</PH3>
            <PUl>
                <PLi>
                    <b>Your First Listing:</b> Have a fantastic item ready for
                    sale? Simply click the <b>Plus</b> button at the top right
                    of your seller dashboard to begin crafting your product
                    listing. Add an eye-catching title, an enticing description,
                    and upload some dazzling images. Keep in mind, the more
                    detailed your information, the more appealing it is to
                    buyers!
                </PLi>
                <PLi>
                    <b>Go Live in a Jiffy:</b> The best part? Your product goes
                    live as soon as you're done listing. No waiting, no fuss!
                </PLi>
            </PUl>
            <PH2>A Few Friendly Tips</PH2>
            <PUl>
                <PLi>
                    <b>Price Right:</b> Not sure about the price? Take a peek at
                    similar items on Phair. Be competitive but fair to the value
                    of your work.
                </PLi>
                <PLi>
                    <b>Shout it Out:</b> Share your listings far and wide -
                    social media, blogs, wherever your audience hangs out. The
                    more you promote, the more people see your stuff.
                </PLi>
                <PLi>
                    <b>More is More:</b> Keep adding new products. A bustling
                    store attracts more buyers.
                </PLi>
                <PLi>
                    <b>Stay Creative:</b> Keep experimenting with new product
                    ideas. The digital world is your oyster!
                </PLi>
                <PLi>
                    <b>Patience Pays Off:</b> Every big seller started small.
                    Keep at it, and you'll see growth.
                </PLi>
            </PUl>
            <PH2>Wrapping It Up</PH2>
            <PText>
                And that's it! You're now ready to start your selling journey on
                Phair. We're stoked to have you onboard and can't wait to see
                all the awesome stuff you're going to list. Have fun, and we're
                here to help you make your Phair experience amazing.
            </PText>

            <br />
            <PText>
                To your fabulous success,
                <br />
                Phair Team
            </PText>
        </BlogPostLayout>
    );
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tabs } from "antd";
import { PBox, PH1 } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import BankInfo from "../../components/seller/BankInfo";
import PaymentInfo from "../../components/seller/PaymentInfo";
import BillingInfo from "../../components/seller/BillingInfo";
import { Loader } from "../../components/Loader";
import { fetchBankInfo, fetchBillingInfo } from "../../../actions/billing";

class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchBankInfo(
                this.props.match.params.shop_uuid,
                this.props.history
            )
            .then(() => {
                this.props.fetchBillingInfo(
                    this.props.match.params.shop_uuid,
                    this.props.history
                );
            })
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles} className="billing">
                    <Tabs
                        defaultActiveKey="bank_info"
                        items={[
                            {
                                key: "bank_info",
                                label: "Bank Info",
                                children: <BankInfo />,
                            },
                            {
                                key: "payment_info",
                                label: "Payment Info",
                                children: <PaymentInfo />,
                            },
                            {
                                key: "billing_info",
                                label: "Billing Info",
                                children: <BillingInfo />,
                            },
                        ]}
                    />
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    margin: "40px",
};

function mapStateToProps(state) {
    return {
        billingInfo: state.billingReducer?.fetchBillingInfo?.billingInfo,
        bankInfo: state.bankReducer?.fetchBankInfo?.bankInfo,
    };
}

export default connect(mapStateToProps, {
    fetchBankInfo,
    fetchBillingInfo,
})(withRouter(Billing));

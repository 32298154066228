import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Button, Form, Space, message } from "antd";
import { PBox, PH1 } from "../../theme/BaseTheme";
import { editBillingInfo } from "../../../actions/billing";

const states = [
    { label: "California", value: "California" },
    {
        label: "North Carolina",
        value: "North Carolina",
    },
];

const countries = [
    { label: "United States", value: "United States" },
    { label: "China", value: "China" },
];

class PaymentInfo extends Component {
    constructor(props) {
        super(props);
        this.normFile = this.normFile.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
    }

    componentDidMount() {}

    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    onFinish = (values) => {
        console.log("Received values of form: ", values);
        this.props
            .editBillingInfo(this.props.match.params.shop_uuid, values)
            .then(() => {
                message.success("Payment info successfully updated");
            });
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        return (
            <PBox css={styles} className="payment-info">
                <PH1>Payment Information</PH1>
                <Form
                    name="basic"
                    labelCol={{
                        span: 0,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: "100%",
                    }}
                    initialValues={{
                        credit_card_number:
                            this.props.billingInfo?.credit_card_number,
                        expiration_date:
                            this.props.billingInfo?.expiration_date,
                        cvc: this.props.billingInfo?.cvc,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="credit_card_number"
                        label="Credit Card Number"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Please enter your credit card number.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Credit Card Number" />
                    </Form.Item>
                    <Form.Item
                        name="expiration_date"
                        label="Expiration Date"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the expiration date.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="MM / YY" />
                    </Form.Item>
                    <Form.Item
                        name="cvc"
                        label="CVC"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your email.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="CVC" />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                    >
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button htmlType="reset">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </PBox>
        );
    }
}

const styles = {
    "& h1": {
        padding: "20px 0",
        margin: "20px auto 40px auto",
        textAlign: "center",
        borderBottom: "1px solid $colors$primary",
        width: "50%",
    },
};

function mapStateToProps(state) {
    return { billingInfo: state.billingReducer?.fetchBillingInfo?.billingInfo };
}

export default connect(mapStateToProps, { editBillingInfo })(
    withRouter(PaymentInfo)
);

import { PBox, PH4, PTable, PText } from "../../theme/BaseTheme";
import { Logo } from "../Logo";

export const BuyerReceiptDetail = (props) => {
    return (
        <PBox css={styles}>
            <PBox className="receipt-detail">
                <PBox className="receipt-detail-header">
                    <Logo />
                    <PBox></PBox>
                </PBox>
                <PBox className="receipt-detail-shopper-address">
                    <PText>
                        Thank you for your order, Rui! We hope you enjoyed the
                        products. This is the receipt of your order{" "}
                        <b>{props.order_number}</b>.
                    </PText>
                </PBox>
                <PBox className="receipt-detail-banner">
                    <PBox className="receipt-detail-buyer-address">
                        <PText>
                            <b>Billed To</b>: {props.customer_name}
                        </PText>
                        <PText>
                            {props.customer_address_line1}
                            {", "}
                            {props.customer_address_line2}
                            {props.customer_address_line2 && ", "}
                            {props.customer_address_city}
                            {", "}
                            {props.customer_address_state}
                            {", "}
                            {props.customer_address_postal_code}
                        </PText>
                        <PText>
                            <b>Email</b>: {props.customer_email}
                        </PText>
                        <PText>
                            <b>Payment Information</b>: {props.payment_method}{" "}
                            {props.payment_last4}
                        </PText>
                    </PBox>
                    <PBox className="receipt-detail-detail">
                        <PText>
                            <b>Order</b>: {props.order_number}
                        </PText>
                        <PText>
                            <b>Date Ordered</b>: {props.order_date}
                        </PText>
                        <PText>
                            <b>Total</b>: ${props.total}
                        </PText>
                    </PBox>
                </PBox>
                <PBox className="receipt-detail-summary">
                    <PH4>Order Summary</PH4>
                    <PTable>
                        <tr>
                            <td>No.</td>
                            <td>Item</td>
                            <td>Price</td>
                            <td>Quantity</td>
                            <td>Total</td>
                        </tr>
                        {props.order_items.map((item, idx) => {
                            return (
                                <tr>
                                    <td>{idx + 1}</td>
                                    <td>{item.product.product_name}</td>
                                    <td>{item.product.price}</td>
                                    <td>{item.quantity}</td>
                                    <td>${item.item_subtotal}</td>
                                </tr>
                            );
                        })}
                    </PTable>
                    <PBox className="receipt-detail-sub-total">
                        <PH4>Sub Total</PH4>
                        <PText>${props.subtotal}</PText>
                    </PBox>
                    {/*<PBox className="receipt-detail-discount">
                        <PH4>Discount</PH4>
                        <PText>-$25.50</PText>
                    </PBox>*/}
                    <PBox className="receipt-detail-shipping-charge">
                        <PH4>Transaction Fee</PH4>
                        <PText>${props.transaction_fee}</PText>
                    </PBox>
                    <PBox className="receipt-detail-tax">
                        <PH4>Tax</PH4>
                        <PText>${props.tax}</PText>
                    </PBox>
                    <PBox className="receipt-detail-total">
                        <PH4>Total</PH4>
                        <PText>${props.total}</PText>
                    </PBox>
                </PBox>
                <PBox className="receipt-detail-footer">
                    <PText>
                        Questions about your order? Reach our customer supports
                        at <b>support@phair.io</b>.
                    </PText>
                </PBox>
            </PBox>
        </PBox>
    );
};

const styles = {
    backgroundColor: "#fff",
    position: "relative",
    "& .receipt-background": {
        position: "relative",
        "& svg": { width: "100%" },
    },
    "& .receipt-detail": {
        padding: "20px",
        color: "$colors$text",
        border: "1px solid black",
        boxShadow: "0 2px 3px rgba(36,36,60,.06)",
        maxWidth: "640px",
        margin: "0 auto",
        display: "block",
    },
    "& h4": { margin: "10px 0" },
    "& p": { margin: "8px 0" },
    "& .receipt-detail-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
        textAlign: "right",
    },
    "& .receipt-detail-shopper-address": {
        borderBottom: "1px solid $colors$borderColor",
        padding: "10px 0",
    },
    "& .receipt-detail-banner": {
        display: "flex",
        padding: "10px 0",
        justifyContent: "space-between",
        "& .receipt-detail-buyer-address": {},
        "& .receipt-detail-detail": {
            textAlign: "right",
        },
    },
    "& .receipt-detail-summary table": {
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: "10px",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        textAlign: "right",
        "& tr": {
            borderBottom: "1px solid $colors$borderColor",
        },
        "& td": {
            padding: "10px 8px",
        },
        "& tr:first-child": {
            fontWeight: 700,
        },
        "& tr td:nth-child(1)": {
            textAlign: "left",
        },
        "& tr td:nth-child(2)": {
            textAlign: "left",
        },
        "& tr td:nth-child(3)": {
            textAlign: "left",
        },
    },
    "& .receipt-detail-sub-total": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "right",
        margin: "0 0 0 auto",
        width: "300px",
    },
    "& .receipt-detail-discount, & .receipt-detail-shipping-charge, & .receipt-detail-tax, & .receipt-detail-total":
        {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "right",
            width: "300px",
            margin: "0 0 0 auto",
        },
    "& .receipt-detail-total": {
        borderTop: "1px solid black",
        margin: "10px 0 10px auto",
        padding: "10px 0px",
        fontSize: "20px",
        width: "300px",
        "& p": { width: "200px" },
    },
    "& .receipt-detail-footer": {
        margin: "20px 0 20px 0",
        textAlign: "center",
    },
};

import { combineReducers } from "redux";

const uploadFiles = (state = {}, action) => {
    switch (action.type) {
        case "UPLOAD_FILES_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                filePaths: action.payload,
            };
        case "UPLOAD_FILES_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                filePaths: action.payload,
            };
        default:
            return state;
    }
};

const uploadSingleFile = (state = {}, action) => {
    switch (action.type) {
        case "UPLOAD_SINGLE_FILE_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                filePath: action.payload,
            };
        case "UPLOAD_SINGLE_FILE_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                filePath: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({ uploadFiles, uploadSingleFile });

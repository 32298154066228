import { BaseTheme, PBlogContainer, PBox, PLink } from "../../theme/BaseTheme";
import Header from "../../sections/Header";
import { Footer } from "../../sections/Footer";
import { ConfigProvider } from "antd";

export const BlogPostLayout = (props) => {
    return (
        <div className={BaseTheme}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#5344a9",
                        colorTextBase: "#002346",
                    },
                }}
            >
                <PBox css={styles} className="blog-post-layout">
                    <Header />
                    <PBlogContainer>
                        <PBox className="category">
                            <PLink>{props.category}</PLink>
                        </PBox>
                        <PBox className="content">{props.children}</PBox>
                    </PBlogContainer>
                    <Footer />
                </PBox>
            </ConfigProvider>
        </div>
    );
};

const styles = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    color: "$colors$text",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",

    "& .category": {
        textAlign: "center",
        color: "$colors$tertiary",
        fontWeight: "600",
        marginTop: "80px",
    },
    "& .content": {
        flexGrow: "1",
        marginBottom: "120px",
        "& h1": {
            textAlign: "center",
            margin: "3rem 0",
            fontSize: "2rem",
            lineHeight: "3rem",
            fontWeight: "700",
        },
        "& h2": {
            margin: "2.5rem 0 2rem 0",
            fontSize: "1.5rem",
            fontWeight: "700",
            lineHeight: "2rem",
        },
        "& h3": {
            fontWeight: "700",
            fontSize: "1.25rem",
            lineHeight: "1.5rem",
        },
        "& p, & ol, & ul": {
            marginBottom: "1.25rem",
            fontSize: "1rem",
            lineHeight: "2rem",
            fontWeight: "400",
        },
        "& a": {
            textDecoration: "none",
            color: "$colors$primary",
            fontWeight: "600",
        },
    },
    "& .blog-hero": {
        color: "$colors$label",
        lineHeight: "20px",
        textAlign: "center",
        marginBottom: "30px",
        "& p": { fontSize: "14px" },
    },
    "& .blog-banner-img": {
        "& img": {
            width: "100%",
            marginBottom: "20px",
            borderRadius: "1rem",
        },
    },
};

import { message } from "antd";
import axios from "axios";

export const s3UploadFiles = (value) => async (dispatch) => {
    if (value.entries().next().value) {
        await axios
            .post(`/api/s3/upload-files`, value, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(async (response) => {
                message.success(response.data.message, 3);
                dispatch({
                    type: "UPLOAD_FILES_SUCCESS",
                    payload: response?.data.files,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    }
};

export const s3DeleteFiles = (value) => async (dispatch) => {
    if (value.entries().next().value) {
        await axios
            .post(`/api/s3/delete-files`, value)
            .then(async (response) => {
                message.success(response.data.message, 3);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    }
};

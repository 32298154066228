import {
    PBox,
    PH1,
    PH2,
    PImg,
    PLi,
    POl,
    PText,
    PUl,
} from "../../../theme/BaseTheme";
import { BlogPostLayout } from "../BlogPostLayout";
import { keys } from "../../../../config/keys";

export const WelcomeToPhair = () => {
    return (
        <BlogPostLayout category="News">
            <PH1>
                Hey There, Welcome to Phair - Your New Go-To for All Things
                Digital!
            </PH1>
            <PBox className="blog-hero">
                <PText>Phair Team | Jan 1, 2024 | 3 min read</PText>
            </PBox>
            <PBox className="blog-banner-img">
                <PImg src={keys.imageBaseUrl + "welcome.jpg"} />
            </PBox>
            <PH2>A Little Hello from Us</PH2>
            <PText>
                Hey there! Super excited you've dropped by. Welcome to Phair,
                our little corner of the internet where buying and selling
                digital goodies is not just easy, it's a whole lot of fun too!
                Stick around, and I'll give you a quick tour of what makes Phair
                the place to be.
            </PText>
            <PH2>Why Phair is Kinda Awesome</PH2>
            <PText>Let's dive into what makes Phair pretty special:</PText>
            <PUl>
                <PLi>
                    <b>Digital Wonderland:</b> Think of Phair as an online
                    treasure chest, packed with all sorts of digital gems - from
                    nifty software to the coolest e-books and everything in
                    between.
                </PLi>
                <PLi>
                    <b>Fair's Fair Here:</b> We're all about keeping things
                    chill and fair. Low fees for our sellers mean more cash in
                    their pockets, and buyers? You get sweet deals.
                </PLi>
                <PLi>
                    <b>Safe as Houses:</b> Every deal you make on Phair is safe
                    as can be. Payment system? Rock-solid. Your info? Locked
                    down tight.
                </PLi>
            </PUl>
            <PH2>Our Mission: More Than Just a Marketplace</PH2>
            <PText>
                We're not just here to trade. We're here to build a community
                that shouts creativity and ethics from the rooftops. Phair's all
                about bringing talented creators and eager buyers together in
                one happy, sustainable space.
            </PText>
            <PH2>Tour Time: Phair in a Nutshell</PH2>
            <PText>
                So, what's the Phair experience like? Let's peek inside:
            </PText>
            <POl>
                <PLi>
                    <b>The Welcome Mat:</b> Our homepage is where the magic
                    starts - featuring top-notch products, our star sellers, and
                    the latest cool stuff.
                </PLi>
                <PLi>
                    <b>The Treasure Trove:</b> Hop into our product listings -
                    it's like a digital playground, sorted just for you.
                </PLi>
                <PLi>
                    <b>Meet the Makers:</b> Every product has a story. Check out
                    our sellers' profiles to see the faces behind the creations.
                </PLi>
            </POl>
            <PH2>Getting in on the Fun</PH2>
            <PText>Ready to join the party? Here's how:</PText>
            <PUl>
                <PLi>
                    <b>Sign Up Quick:</b> A few clicks and you're in.
                    Personalize your profile with a snazzy pic and a bit about
                    you.
                </PLi>
                <PLi>
                    <b>Explore or Show Off:</b> Whether you're shopping or
                    selling, Phair's your stage.
                </PLi>
                <PLi>
                    <b>Have a Blast:</b> Buy, sell, and enjoy the vibe here at
                    Phair.
                </PLi>
            </PUl>
            <PH2>Perks for Everyone</PH2>
            <PText>Phair's packed with perks:</PText>
            <PUl>
                <PLi>
                    <b>Sellers:</b> Low fees, big audience, and cool tools to
                    get your stuff noticed.
                </PLi>
                <PLi>
                    <b>Buyers:</b> Top-tier digital finds, fab prices, and
                    stress-free shopping.
                </PLi>
            </PUl>
            <PH2>Here for You, Always</PH2>
            <PText>
                Got a question? Need a hand? Our support team is like a group of
                superheroes - always ready to swoop in and help. And don't
                forget to join our community. It's a great place to hang out,
                share, and stay in the loop.
            </PText>
            <PH2>The Future Looks Bright</PH2>
            <PText>What's next for Phair? Oh, we've got plans:</PText>
            <PUl>
                <PLi>
                    <b>New Toys:</b> We're always cooking up new features to
                    make your life easier.
                </PLi>
                <PLi>
                    <b>Hello, World:</b> We're growing, reaching out far and
                    wide.
                </PLi>
                <PLi>
                    <b>Your Voice Matters:</b> Your ideas and feedback are the
                    secret sauce to our future.
                </PLi>
            </PUl>
            <PText>
                Jump on board and let's make Phair not just a marketplace, but a
                trend.
            </PText>
            <PH2>Parting High Five</PH2>
            <PText>
                Thanks a million for dropping by! We're stoked to have you with
                us on this adventure. Whether you're selling your masterpieces
                or hunting for digital treasures, Phair's where you want to be.
                Can't wait to see you around.
            </PText>
            <br />
            <PText>
                Catch you later,
                <br />
                Phair Team
            </PText>
        </BlogPostLayout>
    );
};

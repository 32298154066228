import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PLink,
    PText,
    PBox,
    PHighlightButton,
    PHighlightLink,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { confirmEmail } from "../../../actions/auth";
import { Loader } from "../../components/Loader";

class EmailConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .confirmEmail(this.props.match.params.token, this.props.history)
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <AuthenticationLayout>
                <PBox css={styles}>
                    <PBox className="title">
                        <PH2>Verification Success</PH2>
                    </PBox>
                    <PBox className="email-confirmation">
                        <PBox className="email-confirmation-title">
                            <PBox>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="64"
                                    height="64"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                                </svg>
                            </PBox>
                            <PText>
                                Thank you. Please log in to your account to
                                continue.
                            </PText>
                        </PBox>
                        <PHighlightButton
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className="email-confirmation-button"
                            onClick={() => {
                                this.props.history.push("/sign-in");
                            }}
                        >
                            Log In
                        </PHighlightButton>
                    </PBox>
                    <PBox className="home-link">
                        <PText>
                            Go back to <PLink href="/">Home</PLink>
                        </PText>
                    </PBox>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .email-confirmation": {
        width: "400px",
        marginTop: "2rem",
        backgroundColor: "#fff",
        padding: "3rem 2rem 2rem 2rem",
        borderRadius: "0.5rem",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    },
    "& .title": {
        fontSize: "18px",
        marginTop: "2rem",
    },
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "$colors$primary700",
        },
    },
    "& .email-confirmation-button": {
        color: "#fff",
        backgroundColor: "$colors$primary700",
        width: "100%",
        borderColor: "$colors$primary700",
        marginTop: "16px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "$colors$primary500",
            borderColor: "$colors$primary500",
        },
        margin: "20px 0",
    },
    "& .email-confirmation-title": {
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h2": {
            fontWeight: "400",
        },
        "& svg": {
            backgroundColor: "#fff",
            borderRadius: "100px",
            fill: "$colors$primary",
            marginBottom: "2rem",
        },
    },
    "& .home-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};

function mapStateToProps(state) {
    return {};
}
export default connect(mapStateToProps, { confirmEmail })(
    withRouter(EmailConfirmation)
);

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Button, Form, Select, Space, message } from "antd";
import { PBox, PH1 } from "../../theme/BaseTheme";
import { editBillingInfo } from "../../../actions/billing";

const states = [
    { label: "California", value: "California" },
    {
        label: "North Carolina",
        value: "North Carolina",
    },
];

const countries = [
    { label: "United States", value: "United States" },
    { label: "China", value: "China" },
];

class BillingInfo extends Component {
    constructor(props) {
        super(props);
        this.normFile = this.normFile.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
    }

    componentDidMount() {}

    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    onFinish = (values) => {
        console.log("Received values of form: ", values);
        this.props
            .editBillingInfo(this.props.match.params.shop_uuid, values)
            .then(() => {
                message.success("Payment info successfully updated");
            });
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        return (
            <PBox css={styles} className="billing-info">
                <PH1>Billing Information</PH1>
                <Form
                    name="basic"
                    labelCol={{
                        span: 0,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: "100%",
                    }}
                    initialValues={{
                        first_name: this.props.billingInfo?.first_name,
                        last_name: this.props.billingInfo?.last_name,
                        email: this.props.billingInfo?.email,
                        street_address_1:
                            this.props.billingInfo?.street_address_1,
                        street_address_2:
                            this.props.billingInfo?.street_address_2,
                        postal_code: this.props.billingInfo?.postal_code,
                        city: this.props.billingInfo?.city,
                        state: this.props.billingInfo?.state,
                        country: this.props.billingInfo?.country,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="first_name"
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your first name.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your last name.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                type: "email",
                                message: "${label} is not a valid email.",
                            },
                            {
                                required: true,
                                message: "Please enter your email.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Email Address" />
                    </Form.Item>
                    <Form.Item
                        name="street_address_1"
                        label="Street Address 1"
                        rules={[
                            {
                                required: true,
                                message: "Please input your street address.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Street Address 1" />
                    </Form.Item>
                    <Form.Item
                        name="street_address_2"
                        label="Street Address 2 (optional)"
                    >
                        <Input size="large" placeholder="Street Address 2" />
                    </Form.Item>
                    <Form.Item
                        name="postal_code"
                        label="Postal Code"
                        rules={[
                            {
                                required: true,
                                message: "Please input your postal code.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Postal Code" />
                    </Form.Item>
                    <Form.Item
                        name="city"
                        label="City"
                        rules={[
                            {
                                required: true,
                                message: "Please input your city.",
                            },
                        ]}
                    >
                        <Input size="large" placeholder="City" />
                    </Form.Item>
                    <Form.Item
                        name="state"
                        label="State"
                        rules={[
                            {
                                required: true,
                                message: "Please select your state.",
                            },
                        ]}
                    >
                        <Select options={states} placeholder="State" />
                    </Form.Item>
                    <Form.Item
                        name="country"
                        label="Country"
                        rules={[
                            {
                                required: true,
                                message: "Please select your country.",
                            },
                        ]}
                    >
                        <Select options={countries} placeholder="Country" />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                    >
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button htmlType="reset">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </PBox>
        );
    }
}

const styles = {
    "& h1": {
        padding: "20px 0",
        margin: "20px auto 40px auto",
        textAlign: "center",
        borderBottom: "1px solid $colors$primary",
        width: "50%",
    },
};

function mapStateToProps(state) {
    return { billingInfo: state.billingReducer?.fetchBillingInfo?.billingInfo };
}

export default connect(mapStateToProps, { editBillingInfo })(
    withRouter(BillingInfo)
);

import { combineReducers } from "redux";
import productReducer from "./productReducer";
import merchantReducer from "./merchantReducer";
import orderReducer from "./orderReducer";
import categoryReducer from "./categoryReducer";
import uploadReducer from "./uploadReducer";
import saveReducer from "./saveReducer";
import billingReducer from "./billingReducer";
import bankReducer from "./bankReducer";
import accountReducer from "./accountReducer";
import authReducer from "./authReducer";
import stripeReducer from "./stripeReducer";
import downloadReducer from "./downloadReducer";
import filterReducer from "./filterReducer";

const reducers = {
    productReducer,
    merchantReducer,
    orderReducer,
    categoryReducer,
    uploadReducer,
    saveReducer,
    billingReducer,
    bankReducer,
    accountReducer,
    authReducer,
    stripeReducer,
    downloadReducer,
    filterReducer,
};

const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
    if (action.type === "SIGN_OUT_SUCCESS") {
        state = {};
    }
    return appReducer(state, action);
};

export default rootReducer;

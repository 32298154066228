import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table, Empty } from "antd";
import { PBox, PText, PH4, PImg } from "../../theme/BaseTheme";
import { Loader } from "../Loader";
import { fetchTopProductListByMerchantID } from "../../../actions/product";
import clsx from "clsx";

const columns = [
    {
        title: "Product",
        dataIndex: "product_name",
        key: "product",
        width: "6%",
        ellipsis: true,
        render: (_, { product_name, product_cover_image, status }) => {
            return (
                <PBox className={clsx("product-column", "product-name")}>
                    <PImg src={product_cover_image.url} />
                    <PBox>
                        <PText className="product-name">{product_name}</PText>
                        <PH4>Status: {status}</PH4>
                    </PBox>
                </PBox>
            );
        },
    },
    {
        title: "Price",
        dataIndex: "price",
        key: "price",
        width: "3%",
        render: (_, { price }) => {
            return (
                <PBox className="product-column">
                    <PText>${Math.round(price * 100) / 100}</PText>
                    <PH4>Price</PH4>
                </PBox>
            );
        },
    },
    {
        title: "Orders",
        dataIndex: "number_of_orders",
        key: "orders",
        width: "3%",
        render: (_, { number_of_orders }) => {
            return (
                <PBox className="product-column">
                    <PText>{number_of_orders}</PText>
                    <PH4>Orders</PH4>
                </PBox>
            );
        },
    },
    {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
        width: "3%",
        render: (_, { revenue }) => {
            return (
                <PBox className="product-column">
                    <PText
                        style={{ color: "rgb(64,187,130)", fontWeight: "600" }}
                    >
                        ${Math.round(revenue * 100) / 100}
                    </PText>
                    <PH4>Amount</PH4>
                </PBox>
            );
        },
    },

    //{
    //    title: "Status",
    //    dataIndex: "status",
    //    key: "status",
    //    width: "6%",
    //},

    //{
    //    title: "Rating",
    //    dataIndex: "average_rating",
    //    key: "rating",
    //    width: "2%",
    //},
    //{
    //    title: "Category",
    //    dataIndex: "category_name",
    //    key: "category",
    //    width: "3%",
    //},
];

class TopProducts extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchTopProductListByMerchantID(this.props.match.params.shop_uuid)
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        if (
            !this.props.productList ||
            !Array.isArray(this.props.productList) ||
            this.props.productList?.length === 0
        ) {
            return (
                <PBox
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Empty />
                </PBox>
            );
        }
        return (
            <PBox css={styles}>
                <Table
                    showHeader={false}
                    columns={columns}
                    dataSource={this.props.productList}
                    pagination={false}
                />
            </PBox>
        );
    }
}

const styles = {
    position: "relative",
    borderTop: "1px solid $colors$borderColor",
    "& .product-name": {
        display: "flex",
    },
    "& .product-column": {
        height: "50px",
        "& img": {
            width: "50px",
            minWidth: "50px",
            height: "50px",
            marginRight: "8px",
            borderRadius: "8px",
        },
        "& h4": {
            margin: 0,
            color: "#878a99",
            fontWeight: "400",
        },
        "& p": {
            margin: 0,
            fontWeight: "500",
        },
    },
    "& .ant-table-tbody": {
        padding: "8px 6px",
    },
    "& .ant-table-tbody > tr > td": {
        padding: "8px 6px",
    },
};

function mapStateToProps(state) {
    return {
        productList:
            state.productReducer?.fetchTopProductListByMerchant?.productList,
    };
}

export default connect(mapStateToProps, {
    fetchTopProductListByMerchantID,
})(withRouter(TopProducts));

import { PBox, PH1, PText } from "../../../theme/BaseTheme";
import { Layout } from "../Layout";
import { HelpNav } from "../HelpNav";

export const DeleteProduct = (props) => {
    return (
        <Layout>
            <PBox>
                <PH1>How to delete a product?</PH1>
                <PText>
                    To delete a listing, whether it is private or public, go to
                    the seller <b>dashboard</b> - <b>listings</b> page, find the
                    product and click the delete button in the last column.
                </PText>
                <PText>
                    Please note that deleting a product cannot be undone. All
                    uploaded images and files will be deleted.
                </PText>
                <PText>
                    If you want to deactivate a product, you can set it to{" "}
                    <b>private</b> status.
                </PText>
            </PBox>
            <HelpNav
                prev={"Go public"}
                prev_link={"/help/go-public"}
                next={"Store orders"}
                next_link={"/help/store-orders"}
            />
        </Layout>
    );
};

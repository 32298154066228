import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PBox, PH5 } from "../../theme/BaseTheme";
import Chart from "react-apexcharts";
import { Loader } from "../Loader";
import { fetchOrdersByCategoryByMerchantID } from "../../../actions/order";
import { Empty } from "antd";

const options = {
    labels: ["Comedy", "Action", "Romance", "Drama", "SciFi"],
    theme: {
        palette: "palette10", // upto palette10
    },
    dropShadow: {
        enabled: false,
    },
};
const series = [4, 5, 6, 1, 5]; //our data

class OrdersByCategory extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.props
            .fetchOrdersByCategoryByMerchantID(
                this.props.match.params.shop_uuid
            )
            .then(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        if (
            !this.props.orderCounts ||
            !Array.isArray(this.props.orderCounts) ||
            this.props.orderCounts.length === 0
        ) {
            return (
                <PBox
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Empty />
                </PBox>
            );
        }
        const orderCountOptions = {
            labels: this.props.orderCounts?.map(
                (orderByCat) => orderByCat.category_name
            ),
            /*colors: [
                "rgb(140, 104, 205)",
                "rgba(140, 104, 205, 0.85)",
                "rgba(140, 104, 205, 0.7)",
                "rgba(140, 104, 205, 0.6)",
                "rgba(140, 104, 205, 0.45)",
            ],*/
            theme: {
                palette: "palette1", // upto palette10
            },
            dropShadow: {
                enabled: false,
            },
            legend: {
                position: "bottom",
            },
        };
        const saleCountOptions = {
            labels: this.props.orderCounts?.map(
                (orderByCat) => orderByCat.category_name
            ),
            /*colors: [
                "rgb(140, 104, 205)",
                "rgba(140, 104, 205, 0.85)",
                "rgba(140, 104, 205, 0.7)",
                "rgba(140, 104, 205, 0.6)",
                "rgba(140, 104, 205, 0.45)",
            ],*/
            theme: {
                palette: "palette1", // upto palette10
            },
            dropShadow: {
                enabled: false,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$" + val.toString();
                    },
                },
            },
            legend: {
                position: "bottom",
            },
        };
        const orderCountSeries = this.props.orderCounts?.map(
            (orderByCat) => orderByCat.order_by_cat_count
        );
        const saleCountSeries = this.props.orderCounts?.map(
            (orderByCat) => orderByCat.sale_by_cat
        );
        return (
            <PBox css={styles}>
                <PBox>
                    <PH5>Orders by Category</PH5>
                    <Chart
                        options={orderCountOptions}
                        series={orderCountSeries}
                        type="donut"
                        width="100%"
                        minWidth={250}
                        height={250}
                    />
                </PBox>
                <PBox>
                    <PH5>Payments by Category</PH5>
                    <Chart
                        options={saleCountOptions}
                        series={saleCountSeries}
                        type="donut"
                        width="100%"
                        minWidth={250}
                        height={250}
                    />
                </PBox>
            </PBox>
        );
    }
}

const styles = {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",

    "& h5": {
        fontWeight: "500",
        textAlign: "center",
    },
};

function mapStateToProps(state) {
    return {
        orderCounts: state.orderReducer?.fetchOrdersByCategory?.orderCounts,
    };
}

export default connect(mapStateToProps, {
    fetchOrdersByCategoryByMerchantID,
})(withRouter(OrdersByCategory));

import { PContainer, PSection, PBox } from "../theme/BaseTheme";
import { Slider } from "../components/landing/Slider";
import Description from "../components/landing/Description";
import { Reviews } from "../components/landing/Reviews";
import { Breadcrumbs } from "../components/landing/Breadcrumbs";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Component } from "react";
import { fetchProductToDisplay } from "../../actions/product";
import { Loader } from "../components/Loader";
import { DetailCard } from "../components/landing/DetailCard";

class Display extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, product_images: [], product: {} };
    }
    async componentDidMount() {
        await this.props.fetchProductToDisplay(
            this.props.match.params.item_uuid,
            this.props.history
        );

        // Append cover image to product images
        await this.state.product_images.unshift(
            this.props.products[this.props.match.params.item_uuid]?.product
                ?.product_cover_image
        );

        await this.setState({
            isLoading: false,
            product_images: this.state.product_images.concat(
                this.props.products[this.props.match.params.item_uuid]?.product
                    ?.product_images
            ),
            product:
                this.props.products[this.props.match.params.item_uuid]?.product,
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PSection css={styles}>
                <PBox className="display">
                    <PBox className="display-wrapper">
                        <Breadcrumbs
                            category={
                                this.state.product?.category?.category_name
                            }
                        />
                        <Slider images={this.state.product_images} />
                        <DetailCard product={this.state.product} />
                        <Description product={this.state.product} />
                        <Reviews
                            data={this.state.product?.reviews}
                            stats={this.state.product?.product_stat}
                        />
                    </PBox>
                </PBox>
            </PSection>
        );
    }
}

const styles = {
    maxWidth: "80rem",
    margin: "0 auto",
    padding: "0 2rem",
    "& .display": {
        paddingTop: "40px",
        paddingBottom: "40px",
        backgroundColor: "#fff",
    },
    "& .display-wrapper": {
        display: "grid",
        gridTemplateColumns: "1fr 400px",
        gridColumnGap: "40px",
        gridRowGap: "20px",
        alignContent: "flex-start",
        "@bp1": {
            "& .breadcrumbs": { gridColumn: "1 / 3" },
            "& .slider": { gridColumn: "1 / 3" },
            "& .detail-card": { gridColumn: "1 / 3" },
            "& .reviews": { gridColumn: "1 / 3" },
            "& .desc": { gridColumn: "1 / 3" },
        },
        "@bp4": {
            "& .breadcrumbs": {
                gridColumn: "1 / 3",
                gridRow: "1 / 2",
            },
            "& .detail-card": {
                gridColumn: "2 / 3",
                gridRow: "2 / 5",
            },
            "& .slider": {
                gridColumn: "1 / 2",
                gridRow: "2 / 3",
            },
            "& .desc": {
                gridColumn: "1 / 2",
                gridRow: "3 / 4",
            },
            "& .reviews": {
                gridColumn: "1 / 2",
                gridRow: "4 / 5",
            },
        },
    },
};

function mapStateToProps(state) {
    return {
        products: state.productReducer?.fetchProductDisplay,
    };
}

export default connect(mapStateToProps, { fetchProductToDisplay })(
    withRouter(Display)
);

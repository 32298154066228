import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PText,
    PBox,
    PHighlightButton,
    PLink,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { MailOutlined } from "@ant-design/icons";

import { Input, Button, Form } from "antd";
import { userForgotPassword } from "../../../actions/auth";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
    }

    onFinish = (values) => {
        console.log("Success:", values);
        this.props.userForgotPassword(values, this.props.history);
    };
    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles}>
                    <PBox className="forgot-password-title">
                        <PH2>Forgot Password</PH2>
                    </PBox>
                    <PBox className="forgot-password">
                        <PBox className="forgot-password-notice">
                            <PText>
                                Enter your Email and we'll send you a link to
                                reset your password.
                            </PText>
                        </PBox>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 0,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: "100%",
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message:
                                            "${label} is not a valid email.",
                                    },
                                    {
                                        required: true,
                                        message: "Please enter your email.",
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Email"
                                    prefix={<MailOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    span: 24,
                                }}
                            >
                                <PHighlightButton
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    className="forgot-password-send-request-button"
                                >
                                    Send Request
                                </PHighlightButton>
                            </Form.Item>
                        </Form>
                    </PBox>
                    <PBox className="home-link">
                        <PText>
                            Go back to <PLink href="/">Home</PLink>
                        </PText>
                    </PBox>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .forgot-password": {
        width: "480px",
        marginTop: "2rem",
        backgroundColor: "#fff",
        padding: "3rem 2rem 2rem 2rem",
        borderRadius: "0.5rem",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    },
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "#000",
            cursor: "pointer",
        },
    },
    "& .forgot-password-send-request-button": {
        color: "#fff",
        backgroundColor: "$colors$primary700",
        width: "100%",
        borderColor: "$colors$primary700",
        marginTop: "16px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "$colors$primary500",
            borderColor: "$colors$primary500",
        },
    },
    "& .forgot-password-title": {
        fontSize: "18px",
        marginTop: "2rem",
    },
    "& .forgot-password-notice": {
        "& p": { color: "#16a34a" },
        backgroundColor: "#f0fdf4",
        border: "1px solid #16a34a",
        width: "100%",
        borderRadius: "4px",
        marginBottom: "20px",
        textAlign: "center",
        padding: "4px 0",
    },
    "& .home-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};

export default connect(null, { userForgotPassword })(
    withRouter(ForgotPassword)
);

import axios from "axios";
import { message } from "antd";

// For use when payment succeed and show checkout confirm page
export const fetchOrderByPaymentIntent =
    (payment_intent_id) => async (dispatch) => {
        await axios
            .get(`/api/orders/payment_succeed/${payment_intent_id}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_ORDER_PAYMENT_SUCCEED_SUCCESS",
                    payload: response?.data,
                    key: payment_intent_id,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For use in seller dashboard showing orders
export const fetchOrderListDetailsByMerchantID =
    (shop_uuid, history) => async (dispatch) => {
        await axios
            .get(`/api/orders/all_details/${shop_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_ORDER_LIST_DETAILS_BY_MERCHANT_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
                if (error?.response?.data?.redirect)
                    history.push(error?.response?.data?.redirect);
            });
    };

// For use in seller dashboard showing recent orders
export const fetchRecentOrderListByMerchantID =
    (shop_uuid) => async (dispatch) => {
        await axios
            .get(`/api/orders/recent/${shop_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_RECENT_ORDER_LIST_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For use in seller dashboard showing orders by category
export const fetchOrdersByCategoryByMerchantID =
    (shop_uuid) => async (dispatch) => {
        await axios
            .get(`/api/orders/by_cat/${shop_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_ORDER_BY_CAT_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For use in seller dashboard showing monthly earnings
export const fetchMonthlyEarningsByMerchantID =
    (shop_uuid) => async (dispatch) => {
        await axios
            .get(`/api/orders/monthly_earnings/${shop_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_MONTHLY_EARNINGS_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For use in buyer dashboard showing all purchases and reviews
export const fetchOrderHistoryByCustomerID =
    (user_uuid, history) => async (dispatch) => {
        await axios
            .get(`/api/orders/purchase/${user_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_ORDER_HISTORY_LIST_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
                if (error?.response?.data?.redirect)
                    history.push(error?.response?.data?.redirect);
            });
    };

// For use in buyer dashboard showing one order detail and submitting review
export const fetchOrderDetailByOrderID =
    (user_uuid, order_uuid, history) => async (dispatch) => {
        await axios
            .get(`/api/orders/${user_uuid}/${order_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_ORDER_DETAIL_SUCCESS",
                    payload: response?.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
                if (error?.response?.data?.redirect)
                    history.push(error?.response?.data?.redirect);
            });
    };

export const sendReceipt =
    (email, user_uuid, order_uuid) => async (dispatch) => {
        await axios
            .post(
                `/api/orders/send_receipt/${user_uuid}/${order_uuid}/${email}`
            )
            .then(async (response) => {
                message.success(response.data.message, 3);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

export const resendDownloadLink =
    (user_uuid, order_uuid) => async (dispatch) => {
        await axios
            .post(`/api/orders/send_download_link/${user_uuid}/${order_uuid}`)
            .then(async (response) => {
                message.success(response.data.message, 3);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// For use in buyer dashboard showing one order detail and submitting review
export const fetchTransactionRecord =
    (shop_uuid, order_uuid, history) => async (dispatch) => {
        await axios
            .get(`/api/orders/transaction_record/${shop_uuid}/${order_uuid}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_TRANSACTION_RECORD_SUCCESS",
                    payload: response?.data,
                    key: order_uuid,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
                if (error?.response?.data?.redirect)
                    history.push(error?.response?.data?.redirect);
            });
    };

export const sendTransactionRecord =
    (email, shop_uuid, order_uuid) => async (dispatch) => {
        await axios
            .post(
                `/api/orders/send_transaction_record/${shop_uuid}/${order_uuid}/${email}`
            )
            .then(async (response) => {
                message.success(response.data.message, 3);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

export const sendFreeProduct =
    (product_uuid, customer_email, user_uuid, history) => async (dispatch) => {
        await axios
            .post(
                `/api/orders/purchase-free-item/${product_uuid}/${customer_email}/${user_uuid}`
            )
            .then(async (response) => {
                if (response.data.redirect)
                    history.push(response.data.redirect);
                message.success(response.data.message, 3);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

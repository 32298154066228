import axios from "axios";
import { message } from "antd";

// For use in seller create new product
export const submitContactForm = (form, history) => async (dispatch) => {
    await axios
        .post("/api/contact/submit", form)
        .then(async (response) => {
            if (response.data.redirect) history.push(response.data.redirect);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

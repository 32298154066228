import { PBox, PSpan } from "../../theme/BaseTheme";

const StarSvg = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        fill="#000000"
        viewBox="0 0 256 256"
    >
        <path d="M239.2,97.29a16,16,0,0,0-13.81-11L166,81.17,142.72,25.81h0a15.95,15.95,0,0,0-29.44,0L90.07,81.17,30.61,86.32a16,16,0,0,0-9.11,28.06L66.61,153.8,53.09,212.34a16,16,0,0,0,23.84,17.34l51-31,51.11,31a16,16,0,0,0,23.84-17.34l-13.51-58.6,45.1-39.36A16,16,0,0,0,239.2,97.29Zm-15.22,5-45.1,39.36a16,16,0,0,0-5.08,15.71L187.35,216v0l-51.07-31a15.9,15.9,0,0,0-16.54,0l-51,31h0L82.2,157.4a16,16,0,0,0-5.08-15.71L32,102.35a.37.37,0,0,1,0-.09l59.44-5.14a16,16,0,0,0,13.35-9.75L128,32.08l23.2,55.29a16,16,0,0,0,13.35,9.75L224,102.26S224,102.32,224,102.33Z"></path>
    </svg>
);

const StarFillSvg = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        width="18px"
        height="18px"
    >
        <path d="M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z" />
    </svg>
);

const Star = (props) => {
    return (
        <PBox className="rating-single-star">
            <PBox className="star">{StarSvg}</PBox>
            <PBox
                className="star-fill"
                style={{
                    height: "18px",
                    width: `calc(${props.pct} * 18px)`,
                    maxWidth: `calc(${props.pct} * 18px)`,
                    overflow: "hidden",
                }}
            >
                {StarFillSvg}
            </PBox>
        </PBox>
    );
};

export const Rating = (props) => {
    var ratingScore =
        typeof props.rating === "string"
            ? parseFloat(props.rating)
            : props.rating;
    return (
        <PBox css={styles}>
            {[...Array(5).keys()].map((i) => {
                let pct;
                if (i + 1 < ratingScore) {
                    pct = 1;
                } else if (i + 1 > ratingScore + 1) {
                    pct = 0;
                } else {
                    pct = ratingScore - i;
                }
                if (Math.round(pct * 10) / 10 === 0.1) pct = 0.2;
                else if (Math.round(pct * 10) / 10 === 0.2) pct = 0.3;
                else if (Math.round(pct * 10) / 10 === 0.3) pct = 0.4;
                else if (Math.round(pct * 10) / 10 === 0.4) pct = 0.45;
                else if (Math.round(pct * 10) / 10 === 0.6) pct = 0.55;
                else if (Math.round(pct * 10) / 10 === 0.7) pct = 0.6;
                else if (Math.round(pct * 10) / 10 === 0.8) pct = 0.7;
                else if (Math.round(pct * 10) / 10 === 0.9) pct = 0.8;
                return <Star pct={pct} key={"star-" + i.toString()} />;
            })}
            <PSpan>{"(" + `${props.reviews}` + ")"}</PSpan>
        </PBox>
    );
};

const styles = {
    display: "flex",
    alignItems: "center",
    "& .rating-single-star": {
        position: "relative",
        "& .star": { height: "18px" },
        "& .star-fill": {
            position: "absolute",
            top: "0",
            left: "0",
            "& svg": {
                width: "18px",
                height: "18px",
            },
        },
    },
    "& span": {
        fontSize: "0.875rem",
        paddingLeft: "0.25rem",
        paddingBottom: "0.15rem",
    },
};
